import CustomBulletList from '@components/custom-list/CustomBulletList';
import {listOfOptions} from '@components/custom-list/CustomBulletTypes';

interface Coverages {
  header?: string;
  paragraphs?: string[];
  headerClass?: string;
  list?: listOfOptions;
}
const CoveragePanel = ({
  leftCovgs,
  rightCovgs,
  impCovgText
}: {
  leftCovgs: Coverages[];
  rightCovgs: Coverages[];
  impCovgText: string[];
}) => {
  return (
    <div className="row">
      <div className="col-md-6 pe-md-4">
        {leftCovgs.map((covg, covgIndex) => (
          <div key={covgIndex}>
            <h5 className={`fw-bold ${covg.headerClass}`}>{covg.header}</h5>
            {covg.paragraphs?.map((text, index) => <p key={index}>{text}</p>)}
            {covg.list && <CustomBulletList listOptions={covg.list} />}
          </div>
        ))}
      </div>
      <div className="col-md-6 ps-md-4">
        {rightCovgs.map((covg, covgIndex) => (
          <div className="pt-1" key={covgIndex}>
            <h5 className={`fw-bold ${covg.headerClass}`}>{covg.header}</h5>
            {covg.paragraphs?.map((text, index) => <p key={index}>{text}</p>)}
            {covg.list && <CustomBulletList listOptions={covg.list} />}
          </div>
        ))}
        {impCovgText.map((text, textIndex) => (
          <p key={textIndex} className="text-14 fst-italic">
            {text}
          </p>
        ))}
      </div>
    </div>
  );
};

export default CoveragePanel;
