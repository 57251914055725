import MobileApp from '@components/form/MobileApp';

const RightContactPanel = () => {
  return (
    <div className="col-md-6">
      <div className="mt-4">
        <h5 className="fw-bold">Claims</h5>
        <div className="mt-md-2 mb-md-3">
          <p className="contactHeader">Report a Claim:</p>
          <span>24 hours a day / 7 days a week</span>
          <br />
          <span>
            <a
              className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
              href="tel:18002201351"
            >
              (800) 220-1351
            </a>
          </span>
          <br />
          <span>
            <a
              className="text-underline"
              href="mailto:lossnotices@stillwater.com"
            >
              lossnotices@stillwater.com
            </a>
          </span>
        </div>
        <div className="my-3">
          <p className="contactHeader">General Inquiries:</p>
          <span>
            <a
              className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
              href="tel:18002201351"
            >
              (800) 220-1351
            </a>
          </span>
          <br />
          <span>
            <a className="text-underline" href="mailto:claims@stillwater.com">
              claims@stillwater.com
            </a>
          </span>
        </div>
        <div className="my-3">
          <p className="contactHeader">Emergency Roadside Assistance:</p>
          <span>
            <a
              className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
              href="tel:18775076114"
            >
              (877) 507-6114
            </a>
          </span>
          <br />
          <span>Code 23324, Plan DV16A</span>
        </div>
        <div className="my-3">
          <p className="contactHeader"> Request a Letter of Experience:</p>
          <span>
            <a className="text-underline" href="mailto:loe@stillwater.com">
              loe@stillwater.com
            </a>
          </span>
        </div>
      </div>
      <div className="mt-5">
        <h5 className="fw-bold">Offices</h5>
        <div className="mt-md-2 mb-md-3">
          <p className="contactHeader">Jacksonville, Florida</p>
          <span>
            6800 Southpoint Parkway, Suite 700 <br /> Jacksonville, FL 32216
          </span>
        </div>
        <div className="my-4">
          <p className="contactHeader">Jericho, New York</p>
          <span>
            100 Jericho Quadrangle, Suite 124 <br /> Jericho, NY 11753
          </span>
        </div>
        <div className="my-4">
          <p className="contactHeader">Omaha, Nebraska</p>
          <span>
            12500 I St., Suite 100 <br /> Omaha, NE 68137
          </span>
        </div>
      </div>
      <MobileApp mobileAppClass="mt-5" />
    </div>
  );
};

export default RightContactPanel;
