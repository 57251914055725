import RtrnKnowledgeBase from '../RtrnKnowledgeBase';
import PurchasePolicy from '../PurchasePolicy';
import CustomBulletList from '../../../../../components/custom-list/CustomBulletList';

const LandlordIns101 = () => {
  const covgForms = {
    options: [
      {
        header: 'Property damage - ',
        desc: 'This covers cost to repair or replace property damage resulting from the most common perils of fire, water, collapse, explosion, hail, lightning, smoke, vandalism and wind.'
      },
      {
        header: 'Liability against tenant lawsuits - ',
        desc:
          "This coverage protects you if your tenant or a visitor is hurt on your property due to something within your control. It includes medical expense coverage if you're" +
          ' deemed responsible for an injury that occurs on your property.'
      },

      {
        header: 'Loss rental income - ',
        desc: 'This covers rental reimbursements in the event your rental units become uninhabitable due to a loss covered by your policy.'
      },
      {
        header: 'Optional coverage - ',
        desc:
          'As a landlord, you can buy additional coverage like personal property replacement cost, identity theft protection, and water back up and sump overflow, to name a few.' +
          ' You should check with your insurance company to determine what optional coverages are available to you.'
      }
    ]
  };

  const lanlordLevels = {
    options: [
      {
        header: 'DP-1: ',
        desc:
          'This is a very basic insurance policy for rental properties and is usually the least expensive. The DP-1 is a named peril insurance policy, which means that all of the perils that are insured against are' +
          ' specifically named in the policy itself. The most common perils named are fire and lightning, explosion, windstorm and hail, riot and civil commotion, smoke, aircraft, vehicles, volcanic eruption, vandalism' +
          ' and malicious mischief. The DP-1 policy is an Actual Cash Value (ACV) insurance policy (much like an auto insurance policy), which means that depreciation will be deducted from any damages that you are awarded after a claim.'
      },
      {
        header: 'DP-2: ',
        desc:
          'This is also a named peril policy, but the list of perils is more extensive than a DP-1, and it provides more comprehensive coverage for most landlords. The named perils in a DP-2 include all those listed in a DP-1,' +
          ' in addition to burglary damage, weight of ice and snow,  falling objects, freezing of pipes, and electrical damage, to name a few. You should consult your landlord insurance policy to confirm what perils are covered.' +
          ' A DP-2 policy is a Replacement Cost insurance policy and does not deduct depreciation from the amount received from a claim.'
      },

      {
        header: 'DP-3: ',
        desc:
          'This is the most comprehensive insurance policy and is an open peril policy, which means that it covers all possible perils (with the exception of a small list of perils specifically excluded from the policy).' +
          ' Like the DP-2, it is a Replacement Cost insurance policy and does not deduct depreciation from the amount received from a claim.'
      }
    ]
  };

  const landlordCostFactors = {
    options: [
      {
        desc: 'Type of rental property - condo, single family home, apartment building, etc.'
      },
      {
        desc: 'The geographic location of your property'
      },
      {
        desc: 'Age and condition of your property'
      },
      {
        desc: 'Type and amount of coverage you are purchasing'
      },
      {
        desc: 'Whether or not there is a swimming pool, burglar alarms, or gated access'
      }
    ]
  };
  return (
    <div className="container mt-4">
      <RtrnKnowledgeBase />
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="asH3">Landlord Insurance 101</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 pe-md-4">
          <p>
            Landlord insurance (sometimes referred to as Dwelling Fire) provides
            some of the same benefits as your homeowners policy, but instead
            it's in place for property that you own and rent to others. It's
            insurance that's bought to protect your rental property investments.
          </p>
          <p>
            Landlord insurance rolls multiple forms of coverage into one policy.
            Depending on which policy you choose, coverage usually includes:
          </p>
          <CustomBulletList listOptions={covgForms} />

          <p>
            There are several levels of landlord policies - and the higher the
            number, the better:
          </p>
          <CustomBulletList listOptions={lanlordLevels} />
        </div>

        <div className="col-md-6 ps-md-4">
          <p>
            Landlord insurance is not required by law, but mortgage companies
            will require insurance just like a homeowners policy. Even if you
            don't have a mortgage on the property, if your property sustains
            damage due to events like storms or fire, the cost to repair and
            replace would need to come out of your pocket. In addition, with
            liability lawsuits on the rise, it's an added level of protection
            that will bring peace of mind knowing that you are protected from
            financial loss.
          </p>
          <p>
            The cost of landlord insurance can vary considerably based on a
            number of factors such as:
          </p>
          <CustomBulletList listOptions={landlordCostFactors} />
          <br />

          <PurchasePolicy policyType="Landlord" />
        </div>
      </div>
    </div>
  );
};

export default LandlordIns101;
