import AboutUsHeader from '../AboutUs';
import './AccreditationsAndAwards.scss';
import PageTitle from '@components/form/PageTitle';
import AccreditationsAndAwardsLeftPanel from './AccreditationsAndAwardsLeftPanel';
import AccreditationsAndAwardsRightPanel from './AccreditationsAndAwardsRightPanel';

const AccreditationsAndAwards = () => {
  return (
    <>
      <PageTitle>Our Accreditations & Awards | Stillwater Insurance</PageTitle>
      <AboutUsHeader activeOption="Accreditations and Awards" />
      <div className="container mt-5">
        <div className="row">
          <AccreditationsAndAwardsLeftPanel />
          <AccreditationsAndAwardsRightPanel />
        </div>
      </div>
    </>
  );
};

export default AccreditationsAndAwards;
