import PageTitle from '@components/form/PageTitle';
import CustomParagraphList from '@components/form/paragraph/CustomParagraphList';

const EarthQuakeInsFAQ = () => {
  const earthQuakeParagraphs = {
    title: `Earthquake Insurance FAQ | Stillwater Insurance`,
    header: `Earthquake Insurance FAQ`,
    leftParagraphs: [
      {
        id: 1,
        label: 'Should renters get earthquake insurance?',
        desc: ` Personal property that is broken as the result of an earthquake is not typically covered by your renters policy. If earthquakes are a concern in your area, you should consider purchasing earthquake coverage.`
      },
      {
        id: 2,
        label: 'Is earthquake insurance necessary for my home?',
        desc: ` You should consider coverage if you live in an area that's prone to seismic activity. Earthquake insurance covers damages to your house and your personal belongings, as well as additional living expenses (ALE),
                  which cover the cost to live somewhere else while your home is being repaired.`
      },
      {
        id: 3,
        label: 'What is not covered by my Earthquake insurance policy?',
        desc: ` Although a policy will cover the dwelling itself, earthquake insurance typically does not cover the loss of outdoor structures, landscaping, pools, or fences.`
      }
    ]
  };

  return (
    <>
      <PageTitle>{earthQuakeParagraphs.title}</PageTitle>
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1 className="asH3">{earthQuakeParagraphs.header}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-12 col-lg-8 pr-md-4">
          <CustomParagraphList
            paragraphs={earthQuakeParagraphs.leftParagraphs}
          />
        </div>
      </div>
    </>
  );
};

export default EarthQuakeInsFAQ;
