import {policyReimburses} from './RentersInsProductData';
import CoveragePanel from '@components/panel/covg/CoveragePanel';

const RentersInsCovgs = () => {
  const rentersCovgs1 = [
    {
      header: `Why renters insurance?`,
      paragraphs: [
        `Add it up, and you might have thousands of dollars worth of possessions in your apartment ... maybe even more.`,
        `There's your phone, tablet, computer, TV and other electronics. There's your clothing, furniture and kitchenware.
          Maybe there's a bicycle or other sports gear.`,
        `It all could be worth a lot of money. And it all could be at risk.`,
        `If those items were damaged or lost due to a fire, theft or storm, your landlord's insurance wouldn't cover any of it.`,
        `A Stillwater Renters Insurance policy, however, would.`,
        `It's pretty simple; for just a few dollars a week, you could insure all your possessions. You would have a policy backed by outstanding claims and customer service. 
          And, it would all come from a highly rated company you can believe in, one that would be there for you if and when need it.`
      ]
    },
    {
      header: `Find out how affordable it can be:`,
      headerClass: 'pt-1',
      paragraphs: [
        `The average cost works out to just a few dollars a week. Keep it even lower with discounts for bundling a Stillwater Renters and Auto policy.
          Today, more and more people are renting - and for longer periods of time. Enjoy the flexibility to live life on the go, but don't bend when it comes to insuring your belongings against theft, 
          fire and other forms of damage. For a little bit of money, the peace of mind is priceless.`
      ]
    }
  ];
  const rentersCovgs2 = [
    {
      header: `Customize a policy just for you`,
      headerClass: 'pt-1',
      paragraphs: [
        `A Stillwater Renters policy reimburses you for damaged or stolen possessions, and more.`
      ],
      list: policyReimburses
    },
    {
      paragraphs: [
        `In addition, you can further customize a policy to cover against earthquakes and water backup, provide liability for injuries caused by your pets, 
            cover high value items, protect your identity and more.`,
        `A tip: Coverage applies only to those named on the policy - your roommates' possessions wouldn't be covered unless you specify it. 
          So, be sure to list your roommates on your policy, or encourage them to get their own coverage.`
      ]
    }
  ];

  const impRentsCovgText = [
    `These are generalized coverage descriptions. Our Renters contract includes more detailed definitions.`,
    `Coverages and discounts may not be available in all states or situations.`
  ];
  return (
    <div className="container mt-5 pt-md-5">
      <CoveragePanel
        leftCovgs={rentersCovgs1}
        rightCovgs={rentersCovgs2}
        impCovgText={impRentsCovgText}
      />
    </div>
  );
};

export default RentersInsCovgs;
