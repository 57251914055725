import BgBluePanel from '@components/panel/header/BgBluePanel';
import PageTitle from '@components/form/PageTitle';
import ClaimsRightPanel from './ClaimsRightPanel';
import ClaimsLeftPanel from './ClaimsLeftPanel';

const Claims = () => {
  return (
    <>
      <PageTitle>
        File Your Insurance Claim - Claim Process | Stillwater Insurance Group
      </PageTitle>
      <BgBluePanel headerName="CLAIMS" />
      <div className="container mt-5">
        <div className="row">
          <ClaimsLeftPanel />
          <ClaimsRightPanel />
        </div>
      </div>
    </>
  );
};

export default Claims;
