import {Link} from 'react-router-dom';
import FindAnAgentListItem from '../../FindAnAgentListItem';
import RtrnKnowledgeBase from '../../RtrnKnowledgeBase';

const AutoTheft = () => {
  return (
    <div className="container mt-4">
      <RtrnKnowledgeBase />
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="asH3">
            Theft and What You Can Do to Protect Your Vehicle
          </h1>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-6 pe-md-4">
          <p>
            Since 2020, our nation has seen a dramatic increase in auto and
            catalytic converter thefts, costing both policyholders and insurance
            companies thousands of dollars per claim. Stillwater wants to help
            by providing some tips to help you minimize your risk and hopefully
            avoid becoming a victim of this national crime wave.
          </p>
          <h4>Auto theft</h4>
          <p>
            Most car thefts are perpetrated by organized crime rings. Stolen
            vehicles are dispensed to chop shops within minutes of their theft
            to be cut up and sold piece by piece. And with today's supply chain
            issues, this criminal enterprise is even more profitable.
            <Link
              to="https://www.nicb.org/prevent-fraud-theft/prevent-auto-theft"
              className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover ps-1"
            >
              The National Insurance Crime Bureau (NICB) recommends you use a
              "layered protection" approach
            </Link>{' '}
            to help avoid this happening to your vehicle - the more layers of
            protection you can implement, the more of a challenge it will be to
            steal.
          </p>
          <ul className="custom-bullet square">
            <li>
              <p>
                <strong>LAYER 1:</strong> Always Use Common Sense
              </p>
              <ul>
                <li>Lock your doors.</li>
                <li>Remove your keys from the ignition.</li>
                <li>close your windows completely.</li>
                <li>Park in well-lit areas.</li>
                <li>
                  Don't leave your car running to heat or cool it without using
                  an automatic start device that allows you to do so without
                  leaving your keys in the vehicle and/or doors unlocked.{' '}
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>LAYER 2:</strong> Use a Visible or Audible Device{' '}
              </p>
              <ul>
                <li>Audible alarms</li>
                <li>Steering column collars</li>
                <li>Steering wheel/brake pedal lock</li>
                <li>Brake locks</li>
                <li>Wheel locks</li>
                <li>Theft deterrent decals</li>
                <li>Identification markers in or on vehicle</li>
                <li>Window etching</li>
                <li>Micro Dot marking</li>
              </ul>
            </li>
            <li>
              <p>
                <strong>LAYER 3:</strong> Install a Vehicle Immobilizer{' '}
              </p>
              <ul>
                <li>Smart keys</li>
                <li>Fuse cut-offs </li>
                <li>Kill switches</li>
                <li>Starter,ignition and fuel disablers</li>
                <li>Wireless ignition authentication</li>
              </ul>
            </li>
            <li>
              <p>
                <strong>LAYER 4:</strong> Invest in a Tracking System{' '}
              </p>
              <ul>
                <li>
                  Put your vehicle on the radar screen by installing a tracking
                  system that emits a signal to the police or monitoring service
                  when the vehicle is reported stolen.{' '}
                </li>
              </ul>
            </li>
          </ul>
          <h4>Catalytic converter theft</h4>
          <p>
            You may be asking, "What's a catalytic converter, and why would
            anyone want to steal one?" A catalytic converter is part of a car's
            exhaust system that converts the engine's hazardous exhaust into
            less harmful gasses using the precious metals inside them: platinum,
            palladium and rhodium. The value of these metals is what makes
            catalytic converters so desirable to thieves. Vehicles with higher
            emissions standards have the most valuable catalytic converters
            because they contain more of these precious metals.
          </p>
          <p>
            Besides being very valuable, catalytic converters are also easy to
            steal, as they can be cut off the exhaust system in about 3 minutes
            using a small electric saw. SUVs with higher ground clearance are
            largely a target since their catalytic converters are easier to
            access.
          </p>
        </div>
        <div className="col-md-6 ps-md-4">
          <p>
            Catalytic converter thefts happen in parking lots, driveways, and
            while vehicles are parked on the street. Because it's such a crime
            of opportunity, you could find your vehicle is missing its catalytic
            converter at any time - and it could cost you between $1000 and
            $3000 to replace it!
          </p>

          <p>
            Here are some things you can do to deter a thief from targeting
            yours:
          </p>
          <ul className="custom-bullet square">
            <li>
              <span className="fw-bold">Use the paint and etch technique.</span>{' '}
              Painting your catalytic converter with high-heat fluorescent paint
              and etching your VIN number into the painted surface could deter
              thieves by making the converter traceable and more difficult to
              scrap at a reputable scrap metal shop. Thieves may just move on to
              another vehicle rather than take the risk.
            </li>
            <li>
              <span className="fw-bold">Install an anti-theft device.</span>{' '}
              There are numerous devices available online designed to safeguard
              your catalytic converter and make it more difficult to remove,
              which vary from cage-type enclosures to cables and locks. Some
              mechanics and muffler specialty shops will install them for you;
              call around your local area to find one. There are also a
              multitude of DIY instructions available online.
            </li>
            <li>
              <span className="fw-bold">Turn up your vehicle's alarm.</span> If
              possible, increase your vehicle's alarm sensitivity so that it
              goes off if your vehicle is jostled or jacked up. There are also
              some DIY motion alarms available online.
            </li>
            <li>
              <span className="fw-bold">
                Install motion sensor lights and cameras where you usually park.
              </span>{' '}
            </li>
            <li>
              <span className="fw-bold">
                Park in high-traffic, well-lit areas.
              </span>
            </li>
          </ul>
          <h4>What does your insurance cover when it comes to theft?</h4>
          <p>
            Check your policy or call your insurance company. If you have full
            coverage - which includes liability insurance, plus collision and
            comprehensive coverages that repair or replace your car - your
            policy will cover a theft of your vehicle or a stolen auto part. If
            you have a deductible, you will be required to pay that first. A
            deductible is in your policy agreement and is your share of the
            costs to repair or replace your vehicle. Liability only policies
            will not cover you in the event your vehicle or catalytic converter
            is stolen.
          </p>
          <h4>
            What should you do if your car or catalytic converter is stolen?
          </h4>
          <ul className="custom-bullet square">
            <li>
              Document - note all details including location, time, etc. Take
              pictures of the scene and the damage done to your vehicle, and
              when applicable, file a police report.
            </li>
            <li>Contact your insurance company and report the theft. </li>
            <li>
              Need to make a claim with Stillwater?
              <ul>
                <li>
                  Login to your Stillwater account and click "File a claim"
                </li>
                <li>Login to our mobile app</li>
                <li>Call us at 1-800-220-1351</li>
              </ul>
            </li>
          </ul>

          <br />
          <br />
          <p className="fw-bold">How to purchase a policy from Stillwater:</p>

          <ul className="custom-bullet square">
            <li>
              Online: It's quick and easy. Answer a few questions and you'll
              have a quote in two minutes. You can purchase the policy
              instantly.
            </li>
            <li>
              {' '}
              Call us at (855) 712-4092. Our licensed reps are available Monday
              through Friday from 8am-9pm ET, and 8am-3pm ET on Saturday.
            </li>
            <li>
              {' '}
              <FindAnAgentListItem />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AutoTheft;
