import {useParams} from 'react-router-dom';
import KnowHowHeader from '../KnowHowHeader';
import KnowHowMobileHeader from '../KnowHowMobileHeader';

import PricingAuto from './auto/pricing-auto/PricingAuto';
import MythsBusiness from './bop/myths-business/MythsBusiness';
import GeneralLiability from './bop/general-liability/GeneralLiability';
import BOPPolicy from './bop/bop-policy/BOPPolicy';
import CondoLossAssesmentCovg from './condo/condo-lossassesment-covg/CondoLossAssesmentCovg';
import MythsCondo from './condo/myths-condo/MythsCondo';
import {useMemo} from 'react';
import CondoInsurance from './condo/condo-insurance/CondoInsurance';
import LandlordInsurance from './landlord/landlord-insurance-needs/LandlordInsurance';
import HomeInsurancePricing from './home/ten-factors-pricing-home-insurance/HomeInsurancePricing';
import ActualCashVSReplacementCost from './home/market-value-vs-replacement-cost/ActualCashVSReplacementCost';
import MythsHome from './home/myths-home/MythsHome';
import MythsAuto from './auto/myths-auto/MythsAuto';
import LearnTheDifference from './auto/learn-the-difference/LearnTheDifference';
import LandlordIns101 from './landlord/LandlordIns101';
import MythsLandlord from './landlord/myths-landlord/MythsLandlord';
import RentersInsurance101 from './renters/renters-insurance-101/RentersInsurance';
import PricingRenters from './renters/pricing-renters/PricingRenters';
import MythsRenters from './renters/myths-renters/MythsRenters';
import UmbrellaInsurance101 from './umbrella/umbrella-insurance-101/UmbrellaInsurance101';
import MythsUmbrellaInsurance from './umbrella/myths-umbrella/MythsUmbrellaInsurance';
import UmbrellaInsurance from './umbrella/need-umbrella/UmbrellaInsurance';
import AutoTheft from './claims/auto-theft/AutoTheft';
import CommonQuestions from './claims/five-common-questions/CommonQuestions';
import WaterFraud from './claims/water-fraud/WaterFraud';
import GeneralQuestions from './general-questions/GeneralQuestions';

const KnowHowDetails = () => {
  const params = useParams();
  const paramId = params.knowhowId;
  const renderUI = useMemo(() => {
    switch (paramId) {
      case 'myths-auto':
        return <MythsAuto />;
      case 'learn-the-difference':
        return <LearnTheDifference />;
      case 'pricing-auto':
        return <PricingAuto />;
      case 'myths-business':
        return <MythsBusiness />;
      case 'general-liability':
        return <GeneralLiability />;
      case 'what-is-BOP-policy':
        return <BOPPolicy />;
      case 'condo-loss-assesment-coverage':
        return <CondoLossAssesmentCovg />;
      case 'myths-condo':
        return <MythsCondo />;
      case 'myths-landlord':
        return <MythsLandlord />;
      case 'condo-insurance-101':
        return <CondoInsurance />;
      case 'landlord-insurance-needs':
        return <LandlordInsurance />;
      case 'landlord-insurance-101':
        return <LandlordIns101 />;
      case 'ten-factors-pricing-home-insurance':
        return <HomeInsurancePricing />;
      case 'market-value-vs-replacement-cost':
        return <ActualCashVSReplacementCost />;
      case 'myths-home':
        return <MythsHome />;
      case 'renters-insurance-101':
        return <RentersInsurance101 />;
      case 'pricing-renters':
        return <PricingRenters />;
      case 'myths-renters':
        return <MythsRenters />;
      case 'umbrella-insurance-101':
        return <UmbrellaInsurance101 />;
      case 'myths-umbrella':
        return <MythsUmbrellaInsurance />;
      case 'need-umbrella':
        return <UmbrellaInsurance />;
      case 'claim-autoTheft':
        return <AutoTheft />;
      case 'claim-fiveCommQuestions':
        return <CommonQuestions />;
      case 'claim-waterFraud':
        return <WaterFraud />;
      case 'why-insurance-rates-increase':
        return <GeneralQuestions />;
      default:
        return null;
    }
  }, [paramId]);

  return (
    <>
      <KnowHowHeader activeOption="Knowledge Base" />
      <KnowHowMobileHeader activeLink="/know-how" />
      {renderUI}
    </>
  );
};

export default KnowHowDetails;
