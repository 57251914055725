import PolicyFAQ from '@components/pages/PolicyFAQ';

const AutoInsFAQ = () => {
  const autoFAQData = {
    title: `Auto Insurance FAQ | Stillwater Insurance`,
    header: `Auto Insurance FAQ`,
    leftParagraphs: [
      {
        id: 1,
        label: `If my garage burns down, and my car is in the garage, is my car covered under my homeowners insurance? `,
        desc: `No. You need to have car insurance on your vehicle with comprehensive physical damage coverage in order to be covered. Even 
						if the car is in your garage, it is not covered under your homeowners insurance policy.`
      },
      {
        id: 2,
        label: `How do factors like my driving history and credit score affect my premium? `,
        desc: `Insurance companies use all kinds of data to try and predict how likely you are to get into a car accident.  
						If you have a history of tickets or accidents, you appear more risky to an insurance company, generally resulting in 
						having to pay a higher rate. It might seem a bit strange to correlate a credit score with insurance premium, but the 
						Federal Trade Commission has shown that those with lower credit scores are more likely to file a claim. This could 
						result in a higher premium as well. Some states prohibit the use of credit scores as a rating factor, but it is commonly used in most states.`
      },
      {
        id: 3,
        label: `Does my personal policy cover my rental car while I'm on vacation? `,
        desc: `Most insurance companies do provide the same coverage on your rental car that you have on your personal car. However, even if your 
					company does provide the same coverage, if you only have liability on your personal car, you will also only have liability coverage for 
					anyone or anything that you hit while driving the rental car. You will not have coverage to repair the damage 
					to the rental car. You will need to have comprehensive and collision coverage on your personal car to have this coverage extended 
					to a rental car. The same deductible will apply.`
      },
      {
        id: 4,
        label: `Will my coverage help pay for medical bills after an accident? `,
        desc: `If you are involved in a car accident in a state that does not have no-fault insurance (also called PIP or Personal Injury Protection), 
						you will generally be responsible for paying your medical bills.  However, most companies provide medical payment insurance coverage,
						known as "med pay" coverage which will pay the medical bills of drivers or passengers involved in a car accident with the insured, 
						up to the "med pay" limits defined in the policy. "Med pay" coverage is not always required; if neither you nor the person at fault have 
						"med pay" coverage, you may be responsible for paying the bills out of pocket.`
      }
    ],
    rightParagraphs: [
      {
        id: 5,
        label: `If I get into an accident driving a friend's car, whose insurance do I use?  `,
        desc: `Your friend's insurance policy provides the primary coverage. In this case, since your friend is the registered owner of the car, 
					their car insurance policy will be responsible for damages.`
      },
      {
        id: 6,
        label: `Does the age of the vehicle affect my premium, or just the condition it's in?  `,
        desc: `In most insurance policies, the age of a vehicle is used to calculate your insurance premium.  The condition of the vehicle
						may be used to determine eligibility or the type of coverage that's available to you.`
      },
      {
        id: 7,
        label: `Is auto insurance mandatory? `,
        desc: `Yes, all 50 states require licensed drivers to carry a minimum amount of auto insurance. This minimal amount typically 
						includes just liability insurance. If your vehicle is financed or leased, your lender may require you to have Comprehensive and Collision coverage as well.`
      },
      {
        id: 8,
        label: `Can I buy just liability insurance to save money?  `,
        desc: `Liability insurance covers the damage to other vehicles, property and medical care if you're at fault in an accident. If you only have 
						liability insurance, the cost of your medical bills will have to be paid out of pocket.`
      },
      {
        id: 9,
        label: `Can I put anyone on my policy?   `,
        desc: `You can include members of your household on your policy, but you must all live at the same address.`
      },
      {
        id: 10,
        label: `Do I have to pay more for auto insurance if I'm single?  `,
        desc: `A recent study by the National Institute of Health has shown that single drivers are twice as likely to be in an accident than married 
						drivers and that could result in a higher premium. Married drivers tend to share driving duties, drive less, and drive safer, resulting in fewer claims.`
      },
      {
        id: 11,
        label: `Do I have the right to choose whether or not my vehicle is a total loss? `,
        desc: `The insurance company determines whether or not your vehicle is a total loss.`
      }
    ]
  };
  return <PolicyFAQ faq={autoFAQData} />;
};

export default AutoInsFAQ;
