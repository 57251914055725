export const glossaryData = [
  {
    id: 'A',
    isEnabled: true,
    paragaraphs: [
      {
        id: 1,
        label: `Actual Cash Value (ACV) - `,
        desc: `The replacement cost of an insured item (the amount of money needed to buy a brand new item of same make/model) minus any depreciation due to its age or current market value. For example, say you bought a new car five years ago, and it has since depreciated in value by $10,000. The car is then stolen and declared a total loss by the insurance company. A new model of that car now costs $30,000 (replacement cost). The insurance company will settle your claim based on the stolen car's ACV, or $20,000.`
      },
      {
        id: 2,
        label: `Adjuster -  `,
        desc: `A person who investigates insurance claims, with the goal of determining the insurance company's responsibility under the terms of the policy contract.`
      },
      {
        id: 3,
        label: `Agreed Value - `,
        desc: `With an agreed value policy, the insurance company and policyholder set a predetermined dollar amount upon which to base a claim payout. Agreed value is less common for standard auto policies, but more common for items such as collector cars, boats, or special items on a homeowners policy such as jewelry.`
      },
      {
        id: 4,
        label: `Aftermarket parts - `,
        desc: `Car repair parts that are not made by the vehicle's original manufacturer.`
      },
      {
        id: 5,
        label: `Animal Liability - `,
        desc: `An optional coverage for a homeowners, condominium, or renters policy. If you're legally responsible (liable) for another's injury or property damage caused by your pet, your animal liability would reimburse that person for their medical or repair costs.`
      },
      {
        id: 6,
        label: `Appraisal -  `,
        desc: `A contractual process used to settle a dispute between an insurance company and policyholder regarding the amount or scope of damage to a property.`
      },
      {
        id: 7,
        label: `At Fault - `,
        desc: `Refers to the individual who is responsible (liable) for an accident or other incident in which property is damaged or people are injured.`
      },
      {
        id: 8,
        label: `Auto Insurance - `,
        desc: `A form of insurance designed to provide financial protection against financial loss in the event of a vehicle accident or theft.`
      }
    ]
  },
  {
    id: 'B',
    isEnabled: true,
    paragaraphs: [
      {
        id: 1,
        label: `Best Rating -  `,
        desc: `This refers to insurance company evaluations by the A.M. Best Company. A.M. Best is an independent provider of financial stability ratings focused on the insurance industry.`
      },
      {
        id: 2,
        label: `Bodily Injury (BI) Liability - `,
        desc: `One of the essential coverages on an auto insurance policy; it's required by law in almost every state. If you're responsible (liable) for an accident that injures other drivers, their passengers, or any pedestrians, your BI coverage would reimburse their medical costs. The dollar amount of this reimbursement would not exceed the limits you choose for this coverage when you buy your policy.`
      },
      {
        id: 3,
        label: `Business Interruption Coverage - `,
        desc: `A coverage available on a business owners policy (BOP). If the business is disrupted due to a fire or other incident, the coverage reimburses you for loss of income. It also helps cover expenses related to operating out of a temporary location.`
      },
      {
        id: 4,
        label: `Business Liability - `,
        desc: `A coverage available on a business owners policy (BOP). If the business is held responsible (liable) for injury or property damage caused by trips and falls, product mishaps, faulty installations or other errors, the coverage provides reimbursement for medical treatment, or to replace or repair damaged property.`
      }
    ]
  },
  {
    id: 'C',
    isEnabled: true,
    paragaraphs: [
      {
        id: 1,
        label: `Claim - `,
        desc: `A request made to an insurance company to provide reimbursement for a specific incident covered by a policy, such as damage to a vehicle or home.`
      },
      {
        id: 2,
        label: `Collision - `,
        desc: `An optional coverage on an auto insurance policy. If you're responsible for an accident involving another vehicle, and your vehicle is damaged, the coverage would reimburse you for repair or replacement costs. You'd be required to pay a deductible, which is your share of those costs.`
      },
      {
        id: 3,
        label: `Combined Single Limit (CSL) - `,
        desc: `Auto insurance liability limits may be expressed as a CSL. With a CSL, you have a single limit for all your injury and property damage liabilities in an accident. For example, if your policy refers to liability limits of "300CSL," and you are responsible for causing an accident that injures others and damages their vehicles, your insurance company will pay no more than $300,000 for the total combined medical and repair costs.`
      },
      {
        id: 4,
        label: `Comprehensive - `,
        desc: `An optional coverage on an auto insurance policy. If your vehicle is damaged or destroyed by something other than a collision with another car (for example, a flood, a falling tree, or a collision with a deer), your comprehensive would reimburse your repair or replacement costs. You'd be required to pay a deductible, which is your share of those costs.`
      },
      {
        id: 5,
        label: `Coverage - `,
        desc: `On an insurance policy, a coverage is a mechanism that provides insurance for a specific kind of risk. Most policies consist of a selection of coverages. Some may be required by law, others may be optional.`
      }
    ]
  },
  {
    id: 'D',
    isEnabled: true,
    paragaraphs: [
      {
        id: 1,
        label: `Deductible -  `,
        desc: `Your share of the cost to repair or replace a vehicle or property when you file a claim with your insurance company. For example: say your car insurance policy has a $500 collision deductible. You damage your vehicle, file an insurance claim, and get a repair estimate for $3,000.  The insurance company will pay $2,500 toward the repairs, you are responsible for $500. You choose the amount of your deductible when you buy your policy. Lower deductibles cost more in premium, but provide greater protection.`
      },
      {
        id: 2,
        label: `Declarations Page - `,
        desc: `A document you receive when you buy an insurance policy, summarizing what you've bought. It should include the names of the policyholder and all others covered under the policy, along with selected coverages, limits and deductibles, and the effective date and end date of the policy term.`
      },
      {
        id: 3,
        label: `Dwelling - `,
        desc: `An essential coverage on a homeowners, condo, or landlord policy. If the home is damaged or destroyed by fire, storm, or other event specified in the policy contract, the dwelling coverage would reimburse you for the cost to repair or rebuild the home. That reimbursement would not exceed the limit you choose when you buy the policy. You'd also be required to pay a share of the repair costs in the form of a deductible.`,
        list: [
          {
            desc: `Note: On a condominium policy, dwelling coverage applies only to the parts of the building for which you're responsible. Typically, a condominium association owns and insures the roof, frame and outer walls, windows and foundation. The individual condominium owner owns and insures the inner walls, ceiling, and everything within. Your association agreement should clarify what you do and don't need to include in your dwelling coverage.`
          }
        ]
      }
    ]
  },
  {
    id: 'E',
    isEnabled: true,
    paragaraphs: [
      {
        id: 1,
        label: `Earthquake -  `,
        desc: `An optional coverage for a homeowners, condominium, landlord, or renters insurance policy. It can either be added to one of these policies as an endorsement, or purchased as an additional, separate policy. If your home and/or personal belongings are damaged or destroyed as the direct result of an earthquake, the coverage would reimburse the cost to repair or replace. It would also pay for temporary housing. You'd be required to pay a deductible, which is your share of those costs.`
      },
      {
        id: 2,
        label: `Effective Date - `,
        desc: `The specific date on which an insurance policy goes into effect.`
      },
      {
        id: 3,
        label: `Endorsement - `,
        desc: `A change to an insurance policy, usually involving the addition/expansion or deletion of a coverage. A common example is adding scheduled personal property on a homeowners policy to provide extra coverage for a new, expensive, engagement ring. An endorsement can be made to a policy at any time during the policy term, and the insurance company may adjust your premium (charge you more or less) depending on the change. Endorsements are sometimes referred to as "riders."`
      },
      {
        id: 4,
        label: `Extended Replacement Cost - `,
        desc: `An optional coverage on a homeowners or condo policy. It applies if the home or condo is damaged or destroyed by fire, storm, or other event specified in the policy contract. Extended replacement cost protects the policyholder from increases in the costs of construction supplies and labor that often occur following natural disasters. The coverage temporarily increases the policy's dwelling coverage limits during these times. This ensures the policyholder is fully reimbursed for the cost to repair or rebuild the home, even with the inflated costs.`
      }
    ]
  },
  {
    id: 'F',
    isEnabled: true,
    paragaraphs: [
      {
        id: 1,
        label: `Fair Rental Income - `,
        desc: `A coverage available on a landlord policy. If the property is uninhabitable due to a fire or other incident, it reimburses the property owner for any lost rental income.`
      },
      {
        id: 2,
        label: `Full Coverage - `,
        desc: `This most often refers to an auto insurance policy with high limits of liability, plus comprehensive and collision coverage. The notion of "full coverage" is somewhat vague, as people can have different insurance needs based on their vehicle(s), income, savings, other insurance, tolerance for risk, and other factors.`
      }
    ]
  },
  {
    id: 'G',
    isEnabled: true,
    paragaraphs: [
      {
        id: 1,
        label: `Gap - `,
        desc: `An optional coverage on an auto insurance policy. It applies if you file a comprehensive or collision claim, and your insurance company then declares the car a total loss. Your settlement (the amount of money you can expect to receive from the insurance company) would be based on the actual cash value of the vehicle; not on the amount of money you might owe your bank for the loan or a lease. Having Gap coverage means your insurance company will help pay for that difference. Gap is sometimes referred to as Loan/Lease Payoff coverage.`
      }
    ]
  },
  {
    id: 'H',
    isEnabled: true,
    paragaraphs: [
      {
        id: 1,
        label: `Hurricane Deductible - `,
        desc: `A separate deductible on a homeowners policy that goes into effect under specific circumstances, such as when the National Weather Service declares a hurricane watch or warning.`
      }
    ]
  },
  {
    id: 'I',
    isEnabled: true,
    paragaraphs: [
      {
        id: 1,
        label: `Identity Theft Coverage - `,
        desc: `An optional coverage for a homeowners, condominium, or renters policy. It applies if your personal information is stolen and used for fraudulent purchases or other transactions. With identity theft coverage, your insurance company would provide reimbursement for any financial loss, along with legal and administrative costs related to the restoration of your identity. The dollar amount of this reimbursement would not exceed the limit you choose for this coverage when you buy your policy.`
      }
    ]
  },
  {
    id: 'J',
    isEnabled: false
  },
  {
    id: 'K',
    isEnabled: false
  },
  {
    id: 'L',
    isEnabled: true,
    paragaraphs: [
      {
        id: 1,
        label: `Landlord Liability - `,
        desc: `A coverage available on a landlord policy. If the property owner is held responsible (liable) for an injury on the property, it provides reimbursement for costs related to the injured person's medical treatment.`
      },
      {
        id: 2,
        label: `Lienholder - `,
        desc: `The bank, credit union, or other entity that has a financial interest in an item of property (car or home) until the loan or mortgage is paid off. Because the lienholder has a financial stake in the property, its name will appear on the policy paperwork until the loan is paid off. The lienholder may also require certain coverages, such as Gap coverage on an auto insurance policy.`
      },
      {
        id: 3,
        label: `Limit - `,
        desc: `The maximum amount of money the insurance company will pay to settle a claim. For example, if you have bodily injury liability coverage with a $100,000 per person limit, the insurance company will pay no more than that amount, per injured person. You'd be responsible for any costs beyond that amount. You choose limits for various coverages when you buy a policy. Higher limits cost more in premium, but provide greater protection.`
      },
      {
        id: 4,
        label: `Loss Assessments - `,
        desc: `A coverage available on a condominium policy. It applies if the condominium association levies an assessment for repairs to common property (for example, the roof). Loss assessment coverage helps reimburse the policyholder for the cost of that assessment.`
      },
      {
        id: 5,
        label: `Loss of Use - `,
        desc: `A coverage available on a homeowners, condo or renters policy. If your home or apartment cannot be lived in due to damage from a fire, storm, or other event specified in the policy contract, the coverage would provide reimbursement for living expenses (hotel, meals, etc.) while you're waiting for repairs. That reimbursement would not exceed the limit you choose when you buy the policy.`
      }
    ]
  },
  {
    id: 'M',
    isEnabled: true,
    paragaraphs: [
      {
        id: 1,
        label: `Medical Payments (Condo) - `,
        desc: `A coverage available on a condominium policy. It applies if someone is injured due to something you've done, regardless of your legal responsibility (liability) for the incident. The coverage reimburses the person for their medical expenses.`
      },
      {
        id: 2,
        label: `Medical Payments (Auto) - `,
        desc: `An optional coverage on an auto insurance policy. If you're injured in a car accident, regardless of who is at fault, Medical Payments would help reimburse you and your passengers for hospital bills and funeral expenses. The dollar amount of this reimbursement would not exceed the limits you choose for this coverage when you buy your policy.`
      }
    ]
  },
  {
    id: 'N',
    isEnabled: true,
    paragaraphs: [
      {
        id: 1,
        label: `Named Insured - `,
        desc: `The person or legal entity who buys and "owns" the insurance policy. The named insured is typically the owner of the car or home (for an auto or homeowners insurance policy, respectively), is authorized by the policy contract to make changes to the policy, and is listed on any checks to settle claims.`
      }
    ]
  },
  {
    id: 'O',
    isEnabled: true,
    paragaraphs: [
      {
        id: 1,
        label: `Other Structures - `,
        desc: `A coverage available on a homeowners or landlord policy. If a structure other than the main dwelling-such as a detached garage, shed, or swimming pool-is damaged or destroyed by fire, storm, or other event specified in the policy contract, Other structures coverage reimburses you for the cost to repair or replace. That reimbursement would not exceed the limit you choose when you buy the policy. You'd also be required to pay a share of the repair costs in the form of a deductible.`
      }
    ]
  },
  {
    id: 'P',
    isEnabled: true,
    paragaraphs: [
      {
        id: 1,
        label: `Personal Injury Coverage - `,
        desc: `An optional liability coverage on a homeowners, condominium, or renters insurance policy. It applies if you're sued and found liable for another's personal injury (for example, slander or defamation, false arrest, wrongful eviction, or invasion/violation of privacy). Your personal injury coverage would reimburse you for any damages you owe the other person as a result of the lawsuit, along with any costs you incur related to your own legal defense.`
      },
      {
        id: 2,
        label: `Personal Injury Protection (PIP) coverage - `,
        desc: `A required coverage on an auto insurance policy in states with no-fault insurance laws. If you're involved in a car accident-no matter who is responsible-PIP would reimburse you for costs related to the treatment of injuries. PIP typically covers passengers in your car at the time of the accident too, and may reimburse for lost wages and rehabilitation costs. The dollar amount of the reimbursements would not exceed the limit you choose when you buy your policy.`
      },
      {
        id: 3,
        label: `Personal Liability - `,
        desc: `A coverage available on a homeowners, condo or renters policy. It applies if you are held responsible for someone's accidental injury or property damage while they're on your property. For example: A friend of a friend slips and falls during a party at your apartment and requires medical attention, or an uninsured handyman injures himself while working in your yard. With personal liability coverage, your insurance company would provide reimbursement for any medical costs related to those injuries.`
      },
      {
        id: 4,
        label: `Personal Property - `,
        desc: `A coverage available on a homeowners, condo or renters policy. It applies if items (such as furniture, appliances, clothing, electronics, hobby gear, etc.), are stolen, or damaged or destroyed in a fire, storm, or other event specified in the policy contract. With personal property, your insurance company will provide full or partial reimbursement to repair or replace those items.`
      },
      {
        id: 5,
        label: `Policy Contract - `,
        desc: `A legal agreement between your insurance company and you, detailing both the company's and your responsibilities related to your policy.`
      },
      {
        id: 6,
        label: `Policy Term - `,
        desc: `The length of the insurance contract. Auto and homeowners' policies typically have either 6-month or 12-month terms. As the end of the term approaches, the insurance company may offer to renew the contract for an additional term.`
      },
      {
        id: 7,
        label: `Premium - `,
        desc: `The amount of money your insurance company charges for your insurance policy.`
      },
      {
        id: 8,
        label: `Proof of Insurance / ID Card - `,
        desc: `A document you receive when you buy an auto insurance policy, verifying that you have coverage. Some states' laws require you have this with you while driving. If you're involved in an accident or pulled over for a violation, the officer may ask to see it.`
      },
      {
        id: 9,
        label: `Property Coverage - `,
        desc: `A coverage available on a business owners policy (BOP). If the business is damaged due to a fire or other incident, the coverage pays to repair or replace damaged buildings, furniture, electronics or other equipment.`
      },
      {
        id: 10,
        label: `Property Damage (PD) Liability - `,
        desc: `One of the essential coverages on an auto insurance policy and is required by law in almost every state. If you're responsible (liable) for an accident that damages other peoples' vehicles and/or property, your PD coverage would reimburse them for any repair or replacement costs. The dollar amount of this reimbursement would not exceed the limit you choose for this coverage when you buy your policy.`
      }
    ]
  },
  {
    id: 'Q',
    isEnabled: false
  },
  {
    id: 'R',
    isEnabled: true,
    paragaraphs: [
      {
        id: 1,
        label: `Rental Reimbursement - `,
        desc: `An optional coverage on an auto insurance policy. If your car is in the shop for repairs covered by a comprehensive or collision claim, the coverage would help reimburse you for your rental car costs. The dollar amount of this reimbursement would not exceed the limits you choose for this coverage when you buy your policy.`
      },
      {
        id: 2,
        label: `Replacement Cost - `,
        desc: `The cost to replace an insured item with a new item of the same make and model. On a homeowners policy, insuring at replacement cost (an option that may be offered when you buy your policy) instead of "actual cash value" means you'd receive a settlement amount that would allow you to repair or rebuild your home without having to pay anything out of pocket.`
      }
    ]
  },
  {
    id: 'S',
    isEnabled: true,
    paragaraphs: [
      {
        id: 1,
        label: `Scheduled Personal Property - `,
        desc: `A coverage available on a homeowners or condo policy. This is supplemental insurance for valuable items such as jewelry, luxury watches or furs. The replacement value of these items often exceeds the maximum dollar amount the insurance company would pay under the policy's personal property coverage. This is sometimes referred to as a "floater."`
      },
      {
        id: 2,
        label: `Settlement - `,
        desc: `Refers to the closure of a claim. Once the insurance company receives a claim report, investigates, and determines the claim to be valid, it will calculate and pay a settlement and close the claim.`
      },
      {
        id: 3,
        label: `Special Personal Property - `,
        desc: `A way to "upgrade" a standard homeowners or condominium policy. With a standard policy, the insurance company provides reimbursement to repair or replace property only if damage is due to one of 17 "named perils" stated in the policy contract. These include things such as fire, theft, explosion, and wind or hail. The burden is on the policyholder to prove to the insurance company that the damage is due to one of the named perils. Special Personal Property changes the standard from "named peril" to "all risk," removing that burden of proof and making it much easier for the policyholder to receive reimbursement for a claim.`
      },
      {
        id: 4,
        label: `SR-22 - `,
        desc: `A document your insurance company files with the state to prove you meet the legal requirements for auto insurance. You need to file an SR-22 only if ordered to do so by a court or the state. Such an order is typically as the result of driving without insurance or being convicted of a DUI or other serious infraction. The document may be referred to as an "SR-22 Bond" or a "Certificate of Financial Responsibility."`
      }
    ]
  },
  {
    id: 'T',
    isEnabled: true,
    paragaraphs: [
      {
        id: 1,
        label: `Total Loss - `,
        desc: `When an insured home, vehicle, or other piece of property is either heavily damaged or stolen, the insurance company may declare it a "total loss." When this happens, the company has calculated that the cost to repair or recover the insured item would be greater than the cost to replace it.`
      }
    ]
  },
  {
    id: 'U',
    isEnabled: true,
    paragaraphs: [
      {
        id: 1,
        label: `Umbrella Policy - `,
        desc: `A supplemental policy that provides personal liability coverage at levels beyond what is available with a standard auto or homeowners policy. If you're responsible (liable) for another person's property damage or injury, and your car or home insurance is insufficient to pay for the repair or medical costs, the umbrella policy would cover the difference. The dollar amount of this reimbursement would not exceed the limit you choose for this coverage when you buy your policy.`
      },
      {
        id: 2,
        label: `Underinsured Motorist Bodily Injury (UIMBI) - `,
        desc: `A coverage on an auto insurance policy that's required in some states and optional in others. If you are involved in an accident, where another driver is at fault, and that driver's insurance is inadequate to pay for your medical costs, your UIMBI would cover the difference. The dollar amount of this reimbursement would not exceed the limits you choose for this coverage when you buy your policy.`
      },
      {
        id: 3,
        label: `Underinsured Motorist Property Damage (UIMPD) - `,
        desc: `A coverage on an auto insurance policy that's required in some states and optional in others. If you are involved in an accident, where another driver is at fault, and that driver's insurance is not adequate to cover your repairs, your UMPD would cover the difference. The dollar amount of this reimbursement would not exceed the limits you choose for this coverage when you buy your policy.`
      },
      {
        id: 4,
        label: `Uninsured Motorist Bodily Injury (UMBI) - `,
        desc: `A coverage on an auto insurance policy that's required in some states and optional in others. If you are involved in an accident, where another driver is at fault, and that driver has no insurance, your UMBI would reimburse your medical costs. The dollar amount of this reimbursement would not exceed the limits you choose for this coverage when you buy your policy.`
      },
      {
        id: 5,
        label: `Uninsured Motorist Property Damage (UMPD) - `,
        desc: `A coverage on an auto insurance policy that's required in some states and optional in others. If you are involved in an accident, where another driver is at fault, and that driver has no insurance, your UMPD would reimburse your costs to repair any damage done to your vehicle. The dollar amount of this reimbursement would not exceed the limits you choose for this coverage when you buy your policy.`
      }
    ]
  },
  {
    id: 'V',
    isEnabled: false
  },
  {
    id: 'W',
    isEnabled: true,
    paragaraphs: [
      {
        id: 1,
        label: `Water Backup - `,
        desc: `An optional coverage on a homeowners, condo, landlord, or renters policy. If water from a clogged drain or sump causes damage to your property, your insurance company will provide reimbursement that can be used to repair or replace the damage or remove the water. That reimbursement would not exceed the limit you choose when you buy the policy.`,
        list: [
          {
            desc: `Note that this coverage does not provide reimbursement for damage due to flooding (ocean storm surge or river overflow); that coverage is available only through a policy from the National Flood Insurance Program (NFIP).`
          }
        ]
      }
    ]
  },
  {
    id: 'X',
    isEnabled: false
  },
  {
    id: 'Y',
    isEnabled: false
  },
  {
    id: 'Z',
    isEnabled: false
  }
];
