import {BsStarFill} from 'react-icons/bs';
import {reviewDetails} from '../CustomerFeedbackTypes';

const CustomerReviewQnsAnS = ({
  reviewerDetails
}: {
  reviewerDetails: reviewDetails[];
}) => {
  const renderAns = (answer: string, type: string) => {
    switch (type) {
      case 'YES/NO':
        if (answer == '1') {
          return <span className="fw-bold"> Yes </span>;
        }
        return <span className="fw-bold"> No </span>;
      case 'TRUE/FALSE':
        if (answer == '1') {
          return <span className="fw-bold"> True </span>;
        }
        return <span className="fw-bold"> False </span>;
      case '5 STARS':
        const starList = [];
        for (let i = 0; i < Number(answer); i++) {
          starList.push(<BsStarFill key={i} className="reviewStar" />);
        }
        return starList;
    }
  };

  return (
    <div className="container mt-5 ">
      <div className="row mt-4">
        {reviewerDetails.map((reviewData, index) => (
          <div
            key={index}
            className={`col-md-6 ${index % 2 == 0 ? 'pe-md-5' : 'ps-md-5'}`}
          >
            <p className="mb-1">
              <span className="fw-bold pe-1">
                {reviewData.reviewerFirstName}
              </span>
              <span className="pe-1">from</span>
              <span className="pe-1">{reviewData.reviewerState}</span>
              <span className="pe-1">{reviewData.reviewDateFormatted}</span>
            </p>
            {reviewData.reviewerAnswers &&
              reviewData.reviewerAnswers.map((reviewQuesAns, ansIndex) => (
                <div className="row" key={ansIndex}>
                  <div className="col-4">{reviewQuesAns.name}</div>
                  <div className="col-8">
                    {renderAns(reviewQuesAns.answer, reviewQuesAns.type)}
                  </div>
                </div>
              ))}
            <div className={`row ${reviewData.ourResponse ? '' : 'mb-3'}`}>
              <div className="col-4">Customer Review:</div>
              <div className="col-8 fw-bold">{reviewData.reviewerComments}</div>
            </div>
            {reviewData.ourResponse && (
              <div className="row mb-3">
                <div className="col-4">Stillwater Response:</div>
                <div className="col-8 fw-bold">{reviewData.ourResponse}</div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerReviewQnsAnS;
