import PolicyMyths from '@components/pages/PolicyMyths';

const MythsCondo = () => {
  const paragraphs = [
    `Purchasing a condo comes with many benefits, including amenities, 
				affordability, and less maintenance. And although purchasing a condo insurance policy may be less complex 
				than purchasing home or car insurance, there are still many misconceptions about a 
				condo insurance policy.`,
    `Here are the top five condo insurance myths, debunked:`
  ];
  const condoMythOptions1 = [
    {
      id: 1,
      header: `Myth #1`,
      label: `My condo association already has insurance , so I don't need to buy my own coverage. `,
      desc: ` The condo association insurance policy will only provide protection for the building and 
				common areas. You will need your own condo insurance to protect you, your belongings and 
				any betterments that you made to the interior of the unit. This policy
				includes liability protection and coverage for living expenses if you are forced to 
				relocate due to a catastrophe.`
    },
    {
      id: 2,
      header: `Myth #2`,
      label: `My condo insurance policy will cover everything in my home. `,
      desc: `Many people think 
				that once they purchase condo insurance, everything inside their home is now covered 
				under that policy in case of damage or theft. Standard insurance policies will cover 
				things like your furniture, clothing, televisions and computers, but high value 
				belongings -- such as diamond jewelry, artwork or collectibles -- are subject to a 
				set limit. That means if these items are damaged or stolen, you will only be paid 
				out the limit on your policy (usually between $1,000-$2,000), as opposed to its 
				actual worth. In order to have your valuables protected, they will need to be 
				"scheduled," which means they are covered up to their value. `
    },
    {
      id: 3,
      header: `Myth #3`,
      label: `A burst pipe in the wall is the responsibility of the condo association. `,
      desc: ` This is half true. Because the condo association insurance covers the wall itself, your personal condo insurance company will need to work out with the association's insurer to sort out who will cover what. For instance, the wall damage might be covered under the association's insurance policy, while the damage to your personal goods inside the condo might be covered under your personal condo insurance policy. If you don't have your own condo insurance policy, you would have to pay out of pocket to repair or replace your personal belongings and/or damage to another unit(s).`
    }
  ];

  const condoMythOptions2 = [
    {
      id: 4,
      header: `Myth #4`,
      label: `My condo policy covers all kinds of water damage. `,
      desc: ` Generally speaking, 
				condo insurance will protect you against water damage that is caused by your 
				plumbing and appliances,  heating or air conditioning systems,  and water heaters. 
				A caveat, however, is that the damage cannot be the result of wear and tear.  
				Sewer or drain backups are usually not included in most condo insurance policies, 
				but it's often available as an add-on. You will need a separate policy for 
				protection against flood waters, ground water and pool or lake overflows.`
    },
    {
      id: 5,
      header: `Myth #5`,
      label: `My condo will be rebuilt exactly as it was. `,
      desc: ` This may be true as long as you have informed your personal condo insurance company when you have made upgrades to your condo, such as renovating the kitchen or bathroom or changing the flooring. Once you inform the agent or the company of the changes, your condo insurance company will then make sure that you have the right coverage for your upgrades.`
    },
    {
      id: 6,
      desc: `Condo living can be one of the best decisions you'll ever make. 
				Now that we've busted some common myths, we hope you can feel more 
				comfortable purchasing a condo insurance policy that best meets your needs.`
    }
  ];
  const condoMyths = {
    header: `5 Myths About Condo Insurance`,
    paragraphs: paragraphs,
    mythLeftOptions: condoMythOptions1,
    mythRightOptions: condoMythOptions2,
    policyType: `Condo`
  };
  return <PolicyMyths myths={condoMyths} />;
};

export default MythsCondo;
