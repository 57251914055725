export const learnDiffTypes = {
  header:
    "What's the difference between comprehensive, collision and liability?",
  paragraphTxt:
    ' All 50 states require licensed drivers to carry a minimum amount of auto insurance.' +
    ' This minimal amount typically includes just liability insurance. If your vehicle is' +
    ' financed or leased, your lender may require you to have Comprehensive and Collision' +
    ' coverage too.  These are called "physical damage" coverages. When your policy covers' +
    ' both liability and physical damage this is called a "full coverage" policy. One' +
    ' question that people often ask is, "Should I just get minimum liability insurance' +
    ' (which is cheaper), or do I need a full coverage policy?" The answer depends on your' +
    ' vehicle, how often you drive it, how much money you have saved, and where you live.' +
    ' Understanding the difference between liability and physical damage insurance will' +
    " help you make an informed decision that's right for you. Keep in mind there are other" +
    ' important coverages out there you should consider too, such as Medical Payments/Personal' +
    ' Injury coverage, and Uninsured Motorist coverage.',
  liabilityInsText:
    'covers the damage to other vehicles, property, and' +
    " medical care if you're at fault in an accident. It does not cover damage to your" +
    ' own car or your own medical bills. Having some form of liability insurance is' +
    ' the law in all states, although the amount of coverage can vary. Auto insurance' +
    ' liability limits may be expressed with three numbers, separated by slashes like' +
    ' this : 100/300/100. This is referred to as "split limits."',
  liabilityBoldTxt: 'Liability Insurance ',
  liabilityOptions: [
    {
      listItem:
        'The first number (100) refers to the bodily injury limit per person in a claim.' +
        " If you're at fault for an accident that causes injury to others, your insurance" +
        ' company will pay no more than $100,000 to any individual for medical treatment.'
    },
    {
      listItem:
        'The second number (300) refers to the bodily injury limit for an entire claim.' +
        " If you're at fault in an accident that causes injury to multiple people, your" +
        ' insurance company will pay no more than $300,000 for the combined medical costs.'
    },
    {
      listItem:
        'The third number (100) refers to the property damage limit for an entire claim.' +
        ' In this case, the insurance company will pay no more than $100,000 for any damage' +
        ' you cause to the vehicles or property of others.'
    }
  ],
  coverageParagraph1:
    'In some states, other coverages are required in addition to liability. These may' +
    ' include medical payments/personal injury protection, uninsured motorist coverage,' +
    ' and underinsured motorist coverage.',
  coverageParagraph2:
    'offers protection when your vehicle is damaged.' +
    ' While both Comprehensive and Collision coverage protect your vehicle, the type of' +
    ' damage they cover is very different. Comprehensive insurance covers situations' +
    ' that collision does not, so having both of them keeps you protected in any situation.',
  damageInsTxt: 'Physical Damage Insurance',
  coverageTypes: [
    {
      boldTxt: `Comprehensive `,
      listItem:
        '- An optional coverage on an auto insurance policy.' +
        ' If your vehicle is damaged or destroyed by something other than a collision' +
        ' with another vehicle (for example, a flood, a falling tree, or a collision' +
        ' with a deer), your Comprehensive coverage would reimburse your repair or' +
        ' replacement costs. Comprehensive coverage also reimburses you if your car' +
        " is stolen. In all cases, you'd be required to pay a deductible, which is" +
        ' your share of those costs. '
    },
    {
      boldTxt: `Collision `,
      listItem:
        '- An optional coverage on an auto insurance policy.' +
        " If you're responsible for an accident involving another vehicle, and your" +
        ' vehicle is damaged, this coverage would reimburse you for repair or' +
        " replacement costs for your own vehicle. You'd be required to pay a deductible, which is" +
        ' your share of those costs.'
    }
  ],
  comprehensiveCovgTxt:
    "If you're wondering if you need Comprehensive and Collision coverage, consider the following:",
  coverageOptions: [
    {
      text:
        "How much is your car worth? If it's a newer or expensive car, having these" +
        ' coverages will save you from depleting your savings account in the event of an accident.' +
        ' If you have a loan or lease on a new car, you may want to consider buying Loan/Lease Payoff Coverage (aka "Gap coverage") as well.'
    },
    {
      text:
        'How often and how far do you drive? The more you drive, the more likely you' +
        ' are to be in an accident. The more likely you are to be in an accident, the more you need collision coverage.'
    },
    {
      text:
        "How much money do you currently have in savings? If you're not comfortable covering costly" +
        ' repairs out of your own pocket after a car accident, consider purchasing these coverages.'
    },
    {
      text:
        'Where do you live? Are there frequent car/deer collisions? After a storm, do you count the' +
        " number of neighbors who have trees fallen on their cars? If the answer is yes, it's a good idea" +
        ' to make sure that you are protected with Comprehensive coverage against these circumstances.'
    }
  ],
  liabilityCovgTxt:
    'For most situations, choosing liability and physical damage coverage is the best choice.' +
    ' Although "full coverage" will be more expensive, most people do not have large enough' +
    ' emergency funds or cash reserves to cover the costs associated with an auto accident.'
};
