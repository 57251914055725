import {WfcChat} from '@components/chat/WfcChat';

const LeftContactPanel = () => {
  return (
    <div className="col-md-6">
      <div className="mt-md-2">
        <WfcChat type="customer-service" />
      </div>
      <div className="mt-4">
        <h5 className="fw-bold"> Customer Service</h5>
        <div className="mt-md-2 mb-md-3">
          <p className="contactHeader">Hours:</p>
          <span>M-F: 8am - 9pm ET</span>
          <br />
          <span>Sat: 8am - 3pm ET</span>
        </div>
        <div className="my-3">
          <p className="contactHeader">Phone:</p>
          <span>
            <a
              className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
              href="tel:18008496140"
            >
              (800) 849-6140
            </a>
          </span>
        </div>
        <div className="my-3">
          <p className="contactHeader">Email:</p>
          <span>
            <a className="text-underline" href="mailto:ins@stillwater.com">
              ins@stillwater.com
            </a>
          </span>
        </div>
        <div className="mt-sm-2 mb-sm-3">
          <p className="contactHeader">Fax (General):</p>
          <span>
            <a
              className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
              href="tel:18668776355"
            >
              (866) 877-6355
            </a>
          </span>
        </div>
        <div className="my-3">
          <p className="contactHeader">Fax (Claims):</p>
          <span>
            <a
              className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
              href="tel:18004917683"
            >
              (800) 491-7683
            </a>
          </span>
        </div>
      </div>

      <div className="mt-5">
        <h5 className="fw-bold">Mailing Address</h5>
        <div className="mt-sm-2 mb-sm-3">
          <p className="contactHeader">Correspondence and Payments:</p>
          <span>
            PO Box 45126 <br /> Jacksonville, FL 32232-5126
          </span>
        </div>
      </div>

      <div className="mt-5">
        <h5 className="fw-bold">Become a Stillwater Agent</h5>
        <div className="mt-sm-2 mb-sm-3">
          <p className="contactHeader">Phone:</p>
          <span>
            <a
              className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
              href="tel:18666637129"
            >
              (866) 663-7129
            </a>
          </span>
        </div>
        <div className="my-3">
          <p className="contactHeader">Email:</p>
          <span>
            <a
              className="text-underline"
              href="mailto:agent.appointments@stillwater.com"
            >
              agent.appointments@stillwater.com
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default LeftContactPanel;
