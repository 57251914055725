const CompanyLeftPanel = () => {
  const companyInfo = [
    {
      header: `Stillwater today and tomorrow - Talent, growth and financial stability`,
      paragraphs: [
        `Stillwater is a seasoned mix of talented insurance industry
        professionals. We bring out the best in each other to provide bigger and
        better solutions to our customers' and agents' needs.`,
        `We serve customers in all 50 states, providing a suite of essential
        personal insurance products, and a growing line of commercial products.`,
        ` Our reputation rivals any in the insurance industry. We have earned an
        "A-" rating from AM Best, reflecting our "Excellent" financial strength;
        and "A" rating from Demotech, Inc., reflecting our "Exceptional"
        financial stability; and an "A+" rating from the Better Business Bureau,
        reflecting our commitment to transparency, responsiveness, integrity and
        delivering on the promises we make.`,
        `The vision and passion that fueled our early growth is the core to our
        continued success. We are spun from one of the country's leading
        financial services firms, Fidelity National Financial (FNF). We hire the
        best people and respond quickly and strategically to changes in the
        market. We adapt easily to new technology, and help customers and agents
        use it to their advantage. We deliver innovative products tuned to
        peoples' needs. And, we offer financial stability that gives consumers
        the confidence they've made the right choice of insurer.`,
        `Everything we do is designed to deliver smart, fast and strong services
        that make life easier for our customers. We work hard, every day, to
        earn that respect. In short, we aim to do what's right. Always.`
      ]
    },
    {
      header: `A story of entrepreneurship, vision and customer focus`,
      headerClass: `pt-3`,
      paragraphs: [
        `Stillwater was founded in 2000 as the Fidelity National Property and
        Casualty Insurance Group, a branch of FNF, the country's largest
        provider of commercial and residential title and mortgage services.`,
        ` Since its beginning, Stillwater has been under the direct leadership of
        President and CEO Mark Davey. Through setting up a managing general
        agency (MGA) and purchasing Anza Insurance (to be renamed Fidelity
        National Insurance Company), he formed the sales and underwriting core
        of FNF's insurance division - as well as the "seed" of the group of
        companies that would one day be branded as "Stillwater."`,
        `By 2002, the insurance division of FNF had rapidly expanded its
        geographic footprint and was ready to move beyond the Golden State.
        Fueled by vision and a passion for the industry and its customers, Davey
        and his growing team executed a strategy of both forming and acquiring
        additional companies.`,
        `The strategy helped the division grow its business volume, as well as
        the division's talent, product breadth and technology capabilities - all
        critical elements of a customer-focused insurance provider.`
      ]
    }
  ];
  return (
    <div className="col-md-6 pe-md-4">
      {companyInfo.map((info, infoIndex) => (
        <div key={infoIndex}>
          <h4 className={`fw-bold ${info.headerClass}`}>{info.header}</h4>
          {info.paragraphs.map((text, textIndex) => (
            <p key={textIndex}>{text}</p>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CompanyLeftPanel;
