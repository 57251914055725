import {Link} from 'react-router-dom';
import RtrnKnowledgeBase from '../RtrnKnowledgeBase';

const GeneralQuestions = () => {
  return (
    <div className="container mt-4">
      <RtrnKnowledgeBase />
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="asH3">Why Has My Insurance Rate Increased?</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 pe-md-4">
          <p>
            {' '}
            We know it can be difficult to understand why your rates have gone
            up and we want to help.
          </p>

          <p>
            {' '}
            We can all agree expenses have increased across the board, whether
            groceries, gas, housing, entertainment and yes, insurance. Here we
            will explain several factors affecting insurance rates regardless of
            individual circumstances.
          </p>

          <h4>The Nature of Insurance</h4>
          <p>
            Insurance rates are dependent on risk assessments. When you purchase
            insurance, you pay a premium to transfer the risk of damage to your
            property or person to the insurance company so that the company will
            pay a certain amount to cover damages in the event of an uncertain
            future event. When the risk of future damages increases and insurers
            face greater costs to repair or replace property, the cost of
            insurance increases to compensate.{' '}
          </p>
          <br />

          <h4>Two Major Issues Impacting Insurance Costs Today</h4>

          <p>
            <b>Catastrophic Weather Events </b>
          </p>
          <p>
            The frequency and severity of weather-related catastrophic events
            such as hurricanes, tropical storms, tornadoes, hailstorms,
            wildfires, and flooding have increased in recent years. Such events
            impact property, auto and commercial properties, causing more claims
            to be filed. More claims paid out by insurance carriers translates
            to higher rates for consumers, as insurance companies strive to
            ensure they will have the funds to pay future claims as this
            catastrophic weather trend continues.
          </p>

          <ul className="custom-bullet square">
            <li>
              {' '}
              According to the NOAA National Centers for Environmental
              Information, as of November 2023 the US has had 25 confirmed
              weather disaster events with losses exceeding $1 billion each.
              <sup>1</sup> In comparison, the last 3 years had 60 catastrophic
              events, the entire decade from 2010-2019 had 131 events, and the
              90's had only 57. For more information, visit the
              <Link
                to="https://www.climate.gov/news-features/blogs/beyond-data/2023-historic-year-us-billion-dollar-weather-and-climate-disasters"
                className="ps-1 link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
              >
                National Centers for Environmental Information.
              </Link>
            </li>
          </ul>
          <br />
          <p>
            <b>Inflation and Supply Chain Issues</b>
          </p>
          <p>
            Since 2020, supply chain issues have resulted in higher costs of
            materials and repair delays. In addition, inflation and labor
            shortages in industries needed to repair property such as
            construction, have led to increased demand, higher pricing, and
            lengthy delays. This means higher costs to insurance companies when
            paying claims.
          </p>

          <ul className="custom-bullet square">
            <li>
              {' '}
              Roofing materials pricing increased 14.5% between Oct 2021and
              October 2022. During that same period, lumber products pricing
              increased 6.2%.<sup>2</sup>
            </li>
            <li>
              {' '}
              Vehicle repair costs have increased even more dramatically.
              According to CCC Intelligent Solutions, the cost of auto parts has
              increased anywhere between 15.4% (for rear bumpers) to 56.4% (for
              windshields) when comparing 2019 to 2023 repair costs.<sup>3</sup>
            </li>
          </ul>
          <p>
            As construction costs and auto repair costs rise and extreme weather
            events continue to cause catastrophic damage, insurers are meeting
            their obligations to their policy holders by paying claims. The
            consumers' cost of insurance, your cost, increases as insurers seek
            more rate to cover the higher cost of providing insurance in this
            climate. Consumers usually see rate revisions take effect at the
            renewal of their policy after their state's Department of Insurance
            approves the insurance company's request to increase rates.
            Catastrophic weather, inflation and supply chain issues affect
            insurance companies and consumers regardless of a consumer's claims
            experience, history or insurance bureau score.*
          </p>
          <br />
        </div>
        <div className="col-md-6 ps-md-4">
          <h4>What else can affect your rate?</h4>

          <ul className="custom-bullet square">
            <li>
              {' '}
              <span className="fw-bold">Claims history - </span>
              Though not every claim will affect rates, the frequency and types
              of claims may indirectly affect your insurance rates in the
              future. It is important to discern when to call your insurer with
              a claim by considering the extent of the damage; is there minor
              damage that can be easily remedied or is the damage severe enough
              to require major repair work?
            </li>
            <li>
              {' '}
              <span className="fw-bold">Home improvements - </span>
              the value of your home may have increased.
            </li>
            <li>
              {' '}
              <span className="fw-bold">Newer vehicles - </span>
              more expensive to repair.
            </li>
            <li>
              {' '}
              <span className="fw-bold">Age - </span>
              your age can impact your rate.
            </li>
            <li>
              {' '}
              <span className="fw-bold">Other life events - </span>
              such as adding and removing family members to your household.
            </li>
            <li>
              {' '}
              <span className="fw-bold">Discounts - </span>
              make sure you are getting all the discounts you are eligible for.
            </li>
          </ul>

          <p>
            To read more about what goes into pricing, check out these articles
            for
            <a
              className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
              href="/know-how/pricing-auto"
            >
              {' '}
              auto
            </a>{' '}
            and{' '}
            <a
              className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
              href="/know-how/ten-factors-pricing-home-insurance"
            >
              home
            </a>
            .
          </p>
          <h4>How can I help manage the cost of my insurance?</h4>

          <ul className="custom-bullet square">
            <li>
              {' '}
              <span className="fw-bold">Deductible - </span>
              Increase your deductible to bring down your premium. A deductible
              represents the amount you will contribute towards the repairs
              before your insurance company pays for damages.
            </li>
            <li>
              {' '}
              <span className="fw-bold">Discounts - </span>
              Ask your agent or Stillwater customer service representative to
              make sure all the discounts you are eligible for are applied to
              your policy.
            </li>
          </ul>

          <p>
            The <b>Insurance Bureau Score</b> is one of many factors used by
            insurance carriers to calculate the risk of insuring an individual.
            It indicates how likely someone is to have insurance claims in the
            future and whether the cost of those claims relative to your
            premiums will be higher or lower than average.<sup>4</sup>
          </p>
          <br />

          <p>
            <sup>1</sup>{' '}
            <Link
              to="https://www.climate.gov/news-features/blogs/beyond-data/2023-historic-year-us-billion-dollar-weather-and-climate-disasters"
              className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
            >
              NOAA National Centers for Environmental Information (NCEI)
            </Link>
            , U.S. Billion-Dollar Weather and Climate Disasters, (2022).
            <Link
              to="https://www.climate.gov/news-features/blogs/beyond-data/2023-historic-year-us-billion-dollar-weather-and-climate-disasters"
              className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
            >
              https://www.ncdc.noaa.gov/billions;
            </Link>
          </p>
          <br />

          <p>
            <sup>2</sup> U.S. Bureau of Labor Statistics, Producer Price Index
            by Commodity: Inputs to Industries: Net Inputs to Residential
            Construction, Goods, October 2022;{' '}
          </p>
          <br />

          <p>
            {' '}
            <sup>3</sup> (2023, October) What's Driving the Rise in Auto Costs.
            Best's Review.
            <Link
              to="https://bestsreview.ambest.com/edition/2023/October/"
              className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
            >
              {' '}
              https://bestsreview.ambest.com/edition/2023/october/docs/
            </Link>{' '}
          </p>
          <br />

          <p>
            <sup>4</sup> FICO, Credit Scores vs. Insurance Scores, (2024).
            <Link
              to="https://insurancescores.fico.com/CreditvInsurance"
              className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
            >
              {' '}
              https://insurancescores.fico.com/CreditvInsurance#
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default GeneralQuestions;
