import RtrnKnowledgeBase from '../../RtrnKnowledgeBase';
import FindAnAgentListItem from '../../FindAnAgentListItem';
import CustomBulletList from '../../../../../../components/custom-list/CustomBulletList';

const UmbrellaInsurance101 = () => {
  const umbrellaActivities = {
    options: [
      {
        header: 'Your risk factors ',
        desc:
          '- Do you own a dog or have a swimming pool? Do you spend' +
          ' a lot of time in your car due to your work commute? Do you have a teen driver in your' +
          ' household? If you answered "yes" to any of these questions, you may have an above-average' +
          ' chance of being sued. An Umbrella insurance policy can provide extra protection' +
          ' that you may need.'
      },
      {
        header: 'The value of your assets ',
        desc:
          '- Add together the value of your home, your vehicle(s),' +
          ' your savings and investments. You should set your coverage amount based on the total' +
          ' value of all of your assets.'
      },
      {
        header: 'The potential of future income ',
        desc:
          '- If someone sues you and the judgment' +
          ' exceeds your liability coverage, your future earnings may also be at risk of being' +
          ' garnished to pay off the judgment. To protect your future earnings, multiply your' +
          ' annual income by 5, and add that amount to your asset total.'
      }
    ]
  };
  const insNotCovgOptions = {
    options: [
      {
        desc: 'Injuries to yourself or damage to personal belongings;'
      },
      {
        desc: 'Intentional or criminal acts;'
      },
      {
        desc: 'Your personal business;'
      },
      {
        desc: 'Property damage or injuries that result from an occurrence that is excluded on your policy.'
      }
    ]
  };
  return (
    <div className="container mt-4">
      <RtrnKnowledgeBase />
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="asH3">Umbrella Insurance 101</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 pe-md-4">
          <h5 className="fw-bold"> What is Umbrella Insurance? </h5>
          <p>
            An Umbrella insurance policy is designed to protect you from a
            liability claim above and beyond what your other insurance policies
            cover. It is sometimes referred to as "excess liability" coverage or
            "Personal Liability Umbrella Policy" (PLUP).
          </p>

          <p>
            When you purchase a Home, Auto or watercraft insurance policy, each
            policy includes a certain dollar amount for liability insurance
            coverage. If you have a loss for a dollar amount that exceeds the
            liability limits in your other insurance policies, your Umbrella
            policy would kick in to cover the difference. Without Umbrella
            insurance, you may be required to pay out of pocket for any costs
            that exceed your current liability limits.
          </p>

          <p>
            Since Umbrella insurance does not go into effect until the other
            underlying coverage is exhausted, there are certain limits that must
            first be met before you can purchase a policy. Depending on the
            provider, if you want to purchase a $1,000,000 Umbrella insurance
            policy, you must first have $250,000 liability on your Auto
            insurance policy and $300,000 liability on your Home insurance
            policy with most companies. Umbrella insurance policies are sold in
            increments of $1 million the majority of the time. Some companies
            may require a higher level of underlying coverage if characteristics
            are riskier. For example, newly licensed drivers tend to get into
            more accidents than individuals that have been driving for a long
            time.
          </p>

          <h5 className="fw-bold">
            What does an Umbrella Insurance Policy cover?
          </h5>

          <p>
            <b>Bodily Injury</b>: If you're liable for an accident that injures
            other drivers, their passengers or any pedestrians - and you've
            exhausted the limits on your other insurance policies - your
            Umbrella policy would cover the remaining liability expenses up to
            the limits that you purchased.
          </p>

          <p>
            <b>Property Damage</b>: If you're liable for an accident that
            damages another person's vehicle and/or property, your Umbrella
            policy would cover the remaining balance of money needed to repair
            or replace the damaged property up to the limits that you purchased
          </p>

          <h5 className="fw-bold">
            What's not covered under an Umbrella Insurance Policy?
          </h5>

          <CustomBulletList listOptions={insNotCovgOptions} />
        </div>

        <div className="col-md-6 ps-md-4">
          <h5 className="fw-bold">What terms do I need to understand?</h5>

          <p>
            <b>Umbrella Liability Limit</b> - this is the amount of excess
            liability coverage your Umbrella policy provides. Your umbrella
            coverage pays out only if your underlying policy provides liability
            coverage and the limits of that policy have been exhausted. You are
            also required to satisfy the Self-Insured Retention provision of
            your policy. For more details see below.
          </p>

          <p>
            <b>Self-Insured Retention (SIR)</b> - this is the dollar amount you
            must pay out-of-pocket toward the defense and/or indemnity costs
            associated with a claim before your umbrella policy coverage will
            apply. Once the SIR is reached, the insurance company will make any
            additional payments for defense and indemnity covered by the
            Umbrella policy on your behalf. The SIR does not reduce the coverage
            limit of your policy.{' '}
          </p>

          <h5 className="fw-bold">How much coverage do I need?</h5>

          <p>
            Like all insurance, this is a personal decision based on your
            individual needs. Most Umbrella insurance policies start at $1
            million as a supplemental policy (not available as a stand-alone
            policy). You should consider having enough insurance to cover your
            net worth. Basically, the more assets you have, the more insurance
            you will need. As a rule of thumb, you should consider these three
            things when deciding about how much Umbrella insurance to purchase:
          </p>

          <CustomBulletList listOptions={umbrellaActivities} />

          <p>
            If you have assets that you want to protect, Umbrella insurance is
            well worth the investment. As lawsuits become more and more
            prevalent, failing to protect yourself with this relatively
            inexpensive insurance is a risk you may not want to encounter.
          </p>

          <p className="fw-bold">
            How to purchase an Umbrella policy from Stillwater:
          </p>

          <ul className="custom-bullet square">
            <li>
              {' '}
              Call us at (855) 712-4092. Our licensed reps are available Monday
              through Friday from 8am-9pm ET, and 8am-3pm ET on Saturday.
            </li>

            <li>
              {' '}
              <FindAnAgentListItem />
            </li>
          </ul>
          <br />
        </div>
      </div>
    </div>
  );
};

export default UmbrellaInsurance101;
