import {landlordCardData, landlordInsData} from './LandlordInsPrdtData';
import './LandlordInsPrdt.scss';
import CardPanel from '../../../components/panel/card/CardPanel';
import ProductPanel from '../../../components/panel/product/ProductPanel';
import PageTitle from '@components/form/PageTitle';
import LandlordCovgs from './LandlordCovgs';

const LandlordInsProduct = () => {
  return (
    <>
      <PageTitle>
        Landlord Insurance - Get a Landlord Insurance Quote | Stillwater
        Insurance
      </PageTitle>
      <ProductPanel product={landlordInsData} />
      <CardPanel cardData={landlordCardData} />
      <LandlordCovgs />
    </>
  );
};

export default LandlordInsProduct;
