import {Link} from 'react-router-dom';
import {ICON_LIST} from '../../../../icons';
import ThemeIcon from '../../../../icons/ThemeIcon';

const RtrnKnowledgeBase = () => {
  return (
    <div className="row mb-4">
      <div className="row">
        <div className="ms-2 ps-0">
          <ThemeIcon
            iconName={ICON_LIST.LESSTHAN}
            fill="#003399"
            width="12"
            height="20"
          />
          <Link
            className="ms-2 link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
            to="/know-how"
          >
            Return to Knowledge Base
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RtrnKnowledgeBase;
