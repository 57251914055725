import {baseEnvironment} from './Environment.base';
import {Environment} from './EnvironmentTypes';

const BASE_URL = 'https://training.stillwaterinsurance.com/';

export const environment: Environment = {
  ...baseEnvironment,
  baseUrl: BASE_URL,
  training: true
};
