import {NavLink, useLocation} from 'react-router-dom';
import ProductsDropDown from './productdropdown/ProductsDropDown';
import {useEffect, useState} from 'react';

function TopNavItems() {
  const [isActivePage, setActivePage] = useState({
    product: false,
    aboutus: false,
    knowhow: false
  });
  const location = useLocation(); // Get the current location
  useEffect(() => {
    const aboutUsPaths = [
      '/about-our-company',
      '/about-our-customers',
      '/about-our-leadership',
      '/agent-appointment',
      '/our-accreditations-and-awards',
      '/careers'
    ];
    const knowHowPaths = ['/know-how', '/faqs', '/glossary'];
    const isAboutUsPathIncluded = aboutUsPaths.some(path =>
      location.pathname.includes(path)
    );
    const isKnowHowPathIncluded = knowHowPaths.some(path =>
      location.pathname.includes(path)
    );
    setActivePage(prevRec => ({
      ...prevRec,
      aboutus: isAboutUsPathIncluded,
      product: location.pathname.includes('product'),
      knowhow: isKnowHowPathIncluded
    }));
  }, [location]);

  return (
    <>
      <li className="nav-item nav-knowHow text-nowrap">
        <NavLink
          to="/know-how"
          className={({isActive}) =>
            isActivePage.knowhow || isActive
              ? 'topNav nav-link active'
              : 'nav-link'
          }
        >
          Know How
        </NavLink>
      </li>
      <li className="nav-item dropdown nav-products text-nowrap">
        <NavLink
          className={({isActive}) =>
            ` dropdown-toggle ${isActivePage.product && isActive ? 'nav-link dropDownactive' : 'nav-link'} `
          }
          to="#"
          id="navbarProductsMenuLink"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Products
        </NavLink>
        <ProductsDropDown />
      </li>
      <li className="nav-item nav-claims text-nowrap">
        <NavLink
          className={({isActive}) =>
            isActive ? 'topNav nav-link active' : 'nav-link'
          }
          to="/claims"
        >
          Claims
        </NavLink>
      </li>
      <li className="nav-item nav-agent text-nowrap">
        <NavLink
          className={({isActive}) =>
            isActive ? 'topNav nav-link active' : 'nav-link'
          }
          to="/find-an-agent"
        >
          Find an Agent
        </NavLink>
      </li>
      <li className="nav-item nav-aboutUs text-nowrap">
        <NavLink
          className={({isActive}) =>
            isActivePage.aboutus || isActive
              ? 'topNav nav-link active'
              : 'nav-link'
          }
          to="/about-our-company"
        >
          About Us
        </NavLink>
      </li>
      <li className="nav-item nav-contactUs text-nowrap">
        <NavLink
          className={({isActive}) =>
            isActive ? 'topNav nav-link active' : 'nav-link'
          }
          to="/contact-us"
        >
          Contact Us
        </NavLink>
      </li>
    </>
  );
}

export default TopNavItems;
