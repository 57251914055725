import PolicyMyths from '@components/pages/PolicyMyths';

const MythsHome = () => {
  const homeMyths = {
    header: `5 Myths About Home Insurance`,
    paragraphs: [
      ` Purchasing a home is one of the largest investments you'll make. You've carefully 
					selected the neighborhood, the school system, the number of bedrooms 
					and bathrooms, the yard, and the amount of storage needed. But have you thought about how 
					you are going to protect your investment if the unthinkable happens?`,
      ` Even though home insurance is one of the most common types of insurance, it's often 
					the least understood. Here are the top five Homeowners insurance myths, debunked:`
    ],
    mythLeftOptions: [
      {
        id: 1,
        header: `Myth #1`,
        label: `As long as I have home insurance, I'm covered in case of a flood. `,
        desc: ` This is one 
					of the most common myths about home insurance and often an unpleasant surprise when flood 
					damage actually occurs. Your home insurance policy will usually only cover damage from 
					weather events like tornado, hurricane, ice storm and wildfires. And while there  
					are some instances where water damage may be covered under your insurance policy, 
					damage caused by a  flood is not one of them. You will need a separate flood insurance 
					policy or flood coverage added to your home policy to protect your home in case of a flood.`
      },
      {
        id: 2,
        desc: `Another myth relating to floods is that you don't need a flood insurance policy if you don't 
					live in a high-risk flood zone. But, more than 20% of flood claims come from homes outside of 
					high-risk flood zones, according to the National Flood Insurance Program. You can purchase a 
					flood policy through an insurance agent, or through an insurance company that participates 
					in the National Flood Insurance Program.`
      },
      {
        id: 3,
        header: `Myth #2`,
        label: `My collectibles are covered under my home insurance policy. `,
        desc: `Many people think that once they 
					purchase a home insurance policy that everything inside their home is now covered under that policy in 
					case of damage or theft. Standard insurance policies do cover things like your furniture, clothing, televisions
					 and computers, but high value belongings - such as diamond jewelry, artwork or collectibles - are subject to a set limit. 
					 That means if these items are damaged or stolen, you will only be paid out the limit on your policy (usually between $1,000-$2,000), 
					 as opposed to its actual worth. In order to have your valuables protected, you will need them to be "scheduled," 
					 which means they are covered up their value.`
      },
      {
        id: 4,
        header: `Myth #3`,
        label: `I should insure my home for what I paid for it. `,
        desc: `Failing to have enough insurance
					to cover the cost of rebuilding your home  is one of the costliest mistakes that homeowners 
					can make. The reality is that people don't expect catastrophes to happen to them, 
					and they don't think about what they will need if their home is destroyed in a fire, 
					tornado, or other catastrophic event. With rising labor and supply costs, the cost to 
					rebuild your home could be significantly higher than its purchase price. And if you live 
					in an older home, the rebuild will need to be completed  according to current building 
					codes that could drive the price up even higher. You should review your homeowner's 
					insurance policy on an annual basis, to make sure you have enough coverage to rebuild. 
					The difference in premium in most cases will be minor - but well worth the peace of mind.`
      }
    ],
    mythRightOptions: [
      {
        id: 5,
        header: `Myth #4`,
        label: `Damage from termites and mold is covered. `,
        desc: ` Both termite and mold damage can be 
				very destructive and can leave your home uninhabitable if left undetected and un-addressed.
				But the biggest surprise may come with the fact that most insurance companies won't cover 
				damage by termites and mold. The reason is because both of these infestations are considered 
				maintenance issues - and insurance companies expect you to keep up with the maintenance of
				your home. Early detection and prevention are your best defenses. If you live in an area 
				susceptible to termites, you should have your house regularly checked and sprayed. 
				In addition, you should be on the lookout for mold in your home that may have resulted from a 
				water leak, flooding or high humidity and remediate it to avoid further damage.`
      },
      {
        id: 6,
        header: `Myth #5`,
        label: `My homeowners insurance covers injuries that happen in my home. `,
        desc: ` This myth is 
				half true, but it depends on who was injured. A standard home insurance policy offers 
				medical coverage for people outside your household who have been injured in your home. 
				It does not cover medical costs for those who are insured's under the policy - that should be 
				covered by your health insurance. Liability coverage on a homeowner-s insurance 
				policy will pay medical bills (up to your limits) for people who are accidentally 
				injured while on your property. A standard policy also may include coverage for your 
				relatives who may be held liable for an injury that is sustained off of your property.`
      },
      {
        id: 7,
        desc: `Protecting the major investment that you've made in purchasing your home is one of the best 
				decisions you'll ever make. Now that we've debunked some common myths, we hope you can feel 
				more comfortable purchasing a homeowner's insurance policy that best meets your needs.`
      }
    ],
    policyType: `Home`
  };

  return <PolicyMyths myths={homeMyths} />;
};

export default MythsHome;
