import {ICON_LIST} from '../../../icons';

export const autoProductData = {
  backgroundImgClass: 'bg-auto',
  header: 'GET AN AUTO INSURANCE QUOTE TODAY',
  mobileHeader: 'GET AN AUTO INSURANCE QUOTE TODAY',
  desc: 'Because the road to happiness begins with the right coverage.',
  type: 'Auto'
};

export const autoInsCardPanelInfo = [
  {
    cardClass:
      'card border-transferent border-bottom-0 border-top-0 border-start-0 mb-4',
    image: ICON_LIST.DOLLAR,
    cardHeader: 'What goes into the price of auto insurance?',
    headerClass: 'h-50',
    cardDescription:
      'Insurance companies use all kinds of information to try and predict how likely you are to get in an accident.',
    btnLink: '/know-how/pricing-auto',
    btnText: 'Auto Insurance Pricing'
  },
  {
    cardClass: 'card border-transferent border-0 mb-4',
    image: ICON_LIST.CARCRASH,
    cardHeader:
      "What's the difference between comprehensive, collision and liability?",
    headerClass: 'h-50',
    cardDescription:
      'Knowing the difference can help you make the right purchase.',
    btnLink: '/know-how/learn-the-difference',
    btnText: 'Learn The Difference'
  },

  {
    cardClass:
      'card border-transferent border-bottom-0 border-top-0 border-end-0 mb-4',
    image: ICON_LIST.WANDMAGIC,
    cardHeader: 'Top 5 myths about auto insurance',
    headerClass: 'h-50',
    cardDescription:
      "We're calling out some of the biggest myths about auto insurance to help you feel less intimidated and more informed.",
    btnLink: '/know-how/myths-auto',
    btnText: 'Top 5 Myths about Auto Insurance'
  }
];
