import {reviewData} from './CustomerFeedbackTypes';
import StarRating from './StarRating';
import ReviewBarGraph from './ReviewBarGraph';
import ReviewDisplay from './ReviewDisplay';

const CustomerFeedbackRightPanel = ({review}: {review: reviewData}) => {
  return (
    <div className="col-md-6 ps-md-4">
      <h4 className="mb-3 fw-bolder">
        Overall, how would you rate our handling of your claim?
      </h4>
      <div className="svg-container pb-5">
        <StarRating
          rating={review.starRating}
          outOf={5}
          count={review.reviewCount}
        />
        <ReviewBarGraph reviewPercentage={review.reviewStarPercentageList} />
        <h4 className="fw-bold">
          Based on your experience, would you recommend us to others?
        </h4>
        <ReviewBarGraph reviewPercentage={review.reviewYNPercentageList} />
      </div>
      <div>
        {review.reviewInfo.length > 8 &&
          review.reviewInfo
            .slice(8, 10)
            .map((cusReviewDetails, index) => (
              <ReviewDisplay key={index} customerReview={cusReviewDetails} />
            ))}
      </div>
    </div>
  );
};

export default CustomerFeedbackRightPanel;
