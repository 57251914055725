import PageTitle from '@components/form/PageTitle';
import './KnowHow.scss';
import KnowHowView from './KnowHowView';
import MobileView from './MobileView';

const KnowHow = () => {
  return (
    <>
      <PageTitle>
        Insurance Resources: Articles &amp; Guides | Stillwater Insurance
      </PageTitle>
      <KnowHowView />
      <MobileView />
    </>
  );
};

export default KnowHow;
