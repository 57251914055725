import KnowHowHeader from '../knowledge-base/KnowHowHeader';
import KnowHowMobileHeader from '../knowledge-base/KnowHowMobileHeader';

import './faq.scss';
import FAQDropDown from './FAQDropDown';

const FAQ = () => {
  return (
    <>
      <KnowHowHeader activeOption="FAQs" />
      <KnowHowMobileHeader activeLink="/faqs" />
      <div className="container mt-5">
        <FAQDropDown />
      </div>
    </>
  );
};

export default FAQ;
