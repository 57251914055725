import {AgentValues, mapsLink} from './GoogleMapsServices';

const AgentInfoWindow = ({agentInfo}: {agentInfo: AgentValues}) => {
  return (
    <>
      <div className="text-nowrap">
        <a
          target="_blank"
          href={mapsLink(agentInfo?.address ? agentInfo?.address : null)}
        >
          {' '}
          {agentInfo.address.street} <br />
          {agentInfo?.address.city}, {agentInfo?.address.state}{' '}
          {agentInfo?.address.zip}
        </a>
      </div>
      <hr />
      <div>
        <p>{agentInfo.mailingNameLine1}</p>
        <p>{agentInfo?.mailingNameLine2}</p>
        <p>
          <a href={`tel:${agentInfo?.phoneNo}`}>{agentInfo?.phoneNo}</a>
        </p>
      </div>
    </>
  );
};

export default AgentInfoWindow;
