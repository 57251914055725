import {useState} from 'react';
import {Tab, Tabs} from 'react-bootstrap';
import AutoInsCovgOptions from './AutoInsCovgOptions';

const AutoInsCovgPanel = () => {
  const [key, setKey] = useState('legalCoverages');
  return (
    <>
      <AutoInsCovgOptions />
      <div className="container mt-5">
        <Tabs
          activeKey={key}
          onSelect={(k: any) => setKey(k)}
          className="mb-3 nav-tabs-product "
        >
          <Tab
            eventKey="legalCoverages"
            title="Legally required coverages*"
            tabClassName=" col-12 nav-link-product"
          >
            <ul className="custom-bullet checkmark pt-3">
              <li>
                <span className="fw-bold">Bodily Injury Liability -</span> Pays
                if you cause an accident that injures another driver and/or
                passengers
              </li>
              <li>
                <span className="fw-bold">Property Damage Liability - </span>
                Pays if you cause an accident that damages someone's property
              </li>
            </ul>
          </Tab>
          <Tab
            eventKey="smartCoverages"
            title="Smart coverage options*"
            tabClassName="col-12 nav-link-product "
          >
            <ul className="custom-bullet checkmark pt-3">
              <li>
                <span className="fw-bold">Collision - </span>Pays for physical
                damage to your car when it collides with another object, such as
                a tree or a second vehicle
              </li>
              <li>
                <span className="fw-bold">Comprehensive -</span> Pays if your
                car is stolen, or damaged by something other than a collision
                with another vehicle (examples include fire, flooding or hitting
                an animal)
              </li>
              <li>
                <span className="fw-bold">
                  Uninsured/Underinsured Motorist -
                </span>{' '}
                Pays if you're in an accident caused by someone with little or
                no insurance
              </li>
            </ul>
          </Tab>
          <Tab
            eventKey="addlCoverages"
            title="Additional coverage options to personalize your policy*"
            tabClassName="col-12 nav-link-product"
          >
            <ul className="custom-bullet checkmark pt-3">
              <li>
                <span className="fw-bold"> Rental - </span>Reimburses your
                rental costs if your car is being repaired under a collision or
                comprehensive claim{' '}
              </li>
              <li>
                <span className="fw-bold">Towing - </span>Reimburses you if your
                car needs to be towed to a shop for a covered repair
              </li>
              <li>
                <span className="fw-bold">Medical Payments -</span> Pays for
                necessary medical treatments, and other costs (including
                ambulance transportation, x-rays or nursing care), in the event
                of an accident
              </li>
              <li>
                <span className="fw-bold">Personal Injury Protection -</span>{' '}
                Pays medical bills for any person injured in your car in an
                accident, regardless of who's at fault. (Available only in
                select states)
              </li>
            </ul>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default AutoInsCovgPanel;
