import PurchasePolicy from '../../PurchasePolicy';
import RtrnKnowledgeBase from '../../RtrnKnowledgeBase';

const LandlordInsurance = () => {
  return (
    <div className="container mt-4">
      <RtrnKnowledgeBase />
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="asH3">What Type of Insurance Does a Landlord Need?</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 pe-md-4 pe-lg-5">
          <p>
            Protecting your investment is of the utmost importance when you own
            a property, whether it be the home in which you live or a dwelling
            that you rent out on a short or long-term basis. As a landlord,
            understanding the difference between what kind of insurance is
            needed for both long-term and short-term rentals is essential. In
            the event of a claim, the correct insurance must be in force.
          </p>

          <p>
            In the insurance world, a property that is rented for less than 30
            days at a time is typically considered short-term. These can be
            found on websites like VRBO, Airbnb, Vacasa and more. More and more
            cities are now requiring short-term rental owners to carry a license
            and $1,000,000 in liability insurance as the short-term rental
            market is becoming a big business. If the property is rented for
            more than 30 days (often referred to as a long-term rental), a
            different insurance policy is necessary.
          </p>

          <h4>Long-term rental</h4>
          <p>
            Landlord insurance (sometimes referred to as Dwelling Fire) is your
            best bet to protect your property that you are renting to someone
            for more than 30 days at a time. Landlord insurance includes
            coverage for property damage, liability against any lawsuits, and
            loss of rental income. An important thing to note is that a landlord
            policy does not typically cover short-term rentals.
          </p>
        </div>

        <div className="col-md-6 ps-md-4 ps-lg-5">
          <h4>Short-term rental</h4>
          <p>
            Typically, your personal home insurance policy does not protect you
            when you're renting out your house on a short-term basis, even if
            you only do so occasionally. Before renting your home to anyone, you
            should read your homeowners insurance policy carefully. Most
            homeowners insurance policies include a "business exclusion" clause
            that prohibits you from coverage if you are running a business from
            your home, and many insurance companies consider home-sharing a
            business. This means that if a claim is filed by your renters while
            you are renting your home on a short-term basis, you may be at risk
            of being uninsured and could be held personally responsible to
            settle any financial claims.
          </p>

          <p>
            If you rent your home to short-term guests, you may need to purchase
            commercial insurance coverage, which can be expensive. Another
            option would be to research specialty insurers who are offering
            vacation rental insurance or pay-per-use home share insurance
            coverage for short-term hosts.
          </p>
          <PurchasePolicy policyType="Landlord" />
        </div>
      </div>
    </div>
  );
};

export default LandlordInsurance;
