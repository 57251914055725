import {ProductMenuOptions} from './NavBarTypes';
import {
  getAgentUrl,
  getCSSUrl,
  getQuoteUrl
} from '../../../../environment/Environment';
import HomeUtils from '../../../utils/HomeUtils';
import ThemeIcon from '../../../../icons/ThemeIcon';
import {ICON_LIST} from '../../../../icons';

const MobileTopNavBar = () => {
  return (
    <>
      <div
        className="modal hide fade"
        id="topNavModal"
        tabIndex={-1}
        aria-labelledby="topNavModal"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <button
                type="button"
                className="close border-0 bg-white focus:outline-none"
                data-bs-dismiss="modal"
              >
                <ThemeIcon
                  iconName={ICON_LIST.CLOSE}
                  width="10"
                  height="15"
                  fill="#0099cc"
                />
              </button>
            </div>
            <div className="modal-body">
              <ul className="navbar-nav list-unstyled lh-250 bold">
                <li>
                  <a
                    className="btn btn-outline-skyblue d-block w-100"
                    href={getCSSUrl()}
                  >
                    <span className="ms-2">Customer Log in</span>
                  </a>
                </li>
                <li>
                  <span
                    className="btn btn-outline-skyblue d-block w-100 linkStyle"
                    onClick={HomeUtils.redirectToPaperless}
                  >
                    <span className="ms-2">Go Paperless</span>
                  </span>
                </li>
                <li>
                  <a
                    href={getQuoteUrl()}
                    className="text-orange ms-3 text-decoration-none fw-bolder"
                  >
                    Get a Quote
                  </a>
                </li>
                <li>
                  <hr className="my-1" />
                </li>
                {ProductMenuOptions.map(menu => (
                  <li key={menu.id}>
                    <a
                      href={menu.link}
                      className="ms-3 text-decoration-none fw-bolder"
                    >
                      {menu.name}
                    </a>
                  </li>
                ))}

                <li>
                  <hr className="my-1" />
                </li>
                <li>
                  <a
                    href="/know-how"
                    className="ms-3 text-decoration-none fw-bolder"
                  >
                    Know How to Insurance
                  </a>
                </li>
                <li>
                  <hr className="my-1" />
                </li>
                <li>
                  <a
                    href="/claims"
                    className="ms-3 text-decoration-none fw-bolder"
                  >
                    Claims
                  </a>
                </li>
                <li>
                  <hr className="my-1" />
                </li>
                <li>
                  <a
                    href="/about-our-company"
                    className="ms-3 text-decoration-none fw-bolder"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="/contact-us"
                    className="ms-3 text-decoration-none fw-bolder"
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <a
                    href="/find-an-agent"
                    className="ms-3 text-decoration-none fw-bolder"
                  >
                    Find an Agent
                  </a>
                </li>
                <li className="mt-2">
                  <a
                    className="btn btn-outline-skyblue d-block w-100"
                    href={getAgentUrl()}
                  >
                    <span className="ms-2">Agent Log in</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileTopNavBar;
