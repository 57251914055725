import {
  addCovgOptions,
  insCovgOptions,
  mainCovgOptions,
  smartCovgOptions
} from './HomeInsData';
import CoveragePanel from '@components/panel/covg/CoveragePanel';

const HomeInsCovgOptions = () => {
  const homeCovgs1 = [
    {
      header: `Buying homeowners insurance`,
      paragraphs: [
        `Simply put, a homeowners policy should make you financially "whole" again if your home is damaged or destroyed by 
					events like fire or storm, or is burglarized.`,
        `So, when you buy insurance, look for coverage that:`
      ],
      list: insCovgOptions
    },
    {
      header: `Personalize your coverage* to match your needs`,
      headerClass: 'pt-3',
      paragraphs: [
        `With Stillwater, you can build a policy that'll help you stay  financially whole if the unthinkable happens.`,
        `Your policy's main coverages:`
      ],
      list: mainCovgOptions
    }
  ];
  const homeCovgs2 = [
    {
      header: `Smart coverage options:`,
      list: smartCovgOptions
    },
    {
      header: `Additional coverage options to personalize your policy`,
      headerClass: 'pt-3',
      list: addCovgOptions
    }
  ];

  const impCovgText = [
    `*These are generalized coverage descriptions. Our Homeowners contract includes more detailed definitions.`
  ];
  return (
    <div className="container mt-5 pt-md-5">
      <CoveragePanel
        leftCovgs={homeCovgs1}
        rightCovgs={homeCovgs2}
        impCovgText={impCovgText}
      />
    </div>
  );
};

export default HomeInsCovgOptions;
