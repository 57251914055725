import PolicyFAQ from '@components/pages/PolicyFAQ';

const HomeInsFAQ = () => {
  const homeFAQData = {
    title: `Home Insurance FAQ - Homeowners Insurance Questions | Stillwater Insurance`,
    header: `Homeowners Insurance FAQ`,
    leftParagraphs: [
      {
        id: 1,
        label: `What is the difference between Actual Cash Value and Replacement Cost for my personal belongings? `,
        desc: `Actual Cash Value is the amount of money that the insurance company will pay you to replace your item that has been stolen or damaged. 
                Let's say you purchased a TV for $3,000 but the value of your used TV in today's marketplace is $2,000. If that TV was stolen or damaged, 
                you would receive $2,000, minus your deductible. If you have Replacement Cost Coverage on that same TV, you would receive the full amount 
                of money needed to purchase a new, comparable TV, minus your deductible.`
      },
      {
        id: 2,
        label: `What is a deductible? `,
        desc: `This is the amount of money that you will pay in an insurance claim before the coverage kicks in. Let's say you choose a $1,000 deductible 
                when you purchase your home insurance policy, and you have a claim that will cost $8,000 to repair. You would pay $1,000 of the repair, 
                and your insurance company would pay the remaining $7,000 of the repair. Deductible pricing is based on the concept of "using" your insurance.`
      },
      {
        id: 3,
        label: `What kind of damage is typically excluded from coverage?  `,
        desc: `Typical homeowners policies exclude coverage for general wear and tear, damage resulting from floods and earthquakes, and other 
                specific situations or events. Your insurance company may offer optional coverages that provide protection against these risks.`
      },
      {
        id: 4,
        label: `Am I covered if someone is injured in my home?  `,
        desc: `The typical homeowners policy provides protection for this kind of risk in the form of liability coverage. Coverage amounts and whether 
                the coverage applies per injured person or per occurrence will vary, depending on the insurance company.`
      },
      {
        id: 5,
        label: `Does the age of the home affect my premium, or just the condition it's in?   `,
        desc: `Generally speaking, the older your home, the more expensive it will be to insure. This is primarily due to the risk of loss associated with 
                roof claims and older plumbing.`
      },
      {
        id: 6,
        label: `If a tree from a neighbor's yard falls onto my property, who pays for the damage?  `,
        desc: `In most cases, the damage is covered by your policy, even though the tree belongs to your neighbor.`
      }
    ],
    rightParagraphs: [
      {
        id: 7,
        label: `Will my premium increase if I file a claim?   `,
        desc: `A higher number of claims will usually translate into a higher premium from your insurance company. However, most companies 
            exclude weather-related claims from being counted as a factor in their decision.`
      },
      {
        id: 8,
        label: `Does my coverage apply to other structures on the property (i.e. garage, shed, barn)?  `,
        desc: `Homeowners policies do provide coverage for other structures (typically classified as Coverage B), with a limit usually starting at 10% of the amount of your Coverage A. Most insurance companies allow you to increase Coverage B if you require more coverage to properly protect your structure(s).`
      },
      {
        id: 9,
        label: `Do pets affect my coverage?  `,
        desc: `Owning what is considered to be an "aggressive dog" breed could increase the cost of your home insurance policy due to the prevalence 
                and cost associated with dog bites.`
      },
      {
        id: 10,
        label: `Can your Homeowners insurance be cancelled? `,
        desc: `Homeowners often ask "Can my Homeowners insurance drop me?" Yes, your insurance company can drop you, but it's important to know that 
                being dropped (considered a non-renewal) is different from being canceled. When you are dropped by your insurance provider, your insurance 
                policy is not renewed at its expiration date and you must pursue another provider. You will be informed if you policy is going to be dropped 
                so you have adequate time to shop for new coverage.`
      },
      {
        id: 11,
        label: `Is there anything in my home that would require a separate insurance policy? `,
        desc: `Yes. A homeowners policy may include specific sub-limits for specific items - like firearms, jewelry, etc. - that might need additional 
                amounts of insurance. Additionally, a traditional homeowners policy does not provide coverage in the event of a flood or an earthquake. 
                You would need either a separate insurance policy or need to add coverage for these perils to your homeowners policy.`
      },
      {
        id: 12,
        label: `Is Homeowners insurance required? `,
        desc: `Your state may or may not require homeowners insurance, but your mortgage lender typically will require coverage in order to provide a home loan. 
                Even if you own your home outright and are not required to buy homeowners insurance, it's still a good idea to protect your investment with an 
                insurance policy. That way you can get the financial compensation you need to repair or rebuild after a loss. Not only that, but your home coverage 
                can also help to protect you financially in the event of a liability claim that leads to a lawsuit.`
      }
    ]
  };
  return <PolicyFAQ faq={homeFAQData} />;
};

export default HomeInsFAQ;
