import CustomBulletList from '@components/custom-list/CustomBulletList';
import PurchasePolicy from '../../PurchasePolicy';
import RtrnKnowledgeBase from '../../RtrnKnowledgeBase';

const BOPPolicy = () => {
  const insCovgs = {
    options: [
      {
        header: 'Property -',
        desc: `pays to repair or replace your buildings, furniture, supplies, equipment or computers 
						destroyed or damaged in a fire or other covered loss.`
      },
      {
        header: 'General Business Liability Protection -',
        desc: `pays when your company or workers are responsible for property damage or 
						injuries due to trips and falls, product-related injuries, or other mishaps. This coverage also typically provides for medical 
						payments regardless of fault. And this extends to injuries due to your business operations occurring away from your 
						premises - wherever you conduct business in the U.S.`
      },
      {
        header: 'Business Interruption -',
        desc: `replaces lost income when your business is disrupted due to direct damage. 
						It also pays extra expenses for operating out of a temporary location, and to secure your property.`
      },

      {
        header: 'Optional Coverages and Benefits -',
        desc: `For most businesses, there are up to 25 additional coverages 
						you may choose to either protect your customers or others from common business risks, as well as coverages that 
						respond to additional property risks.`
      }
    ]
  };
  const benefits = {
    options: [
      {
        desc: `Is home-based with no foot traffic, or located outside of your home.`
      },
      {
        desc: `Is in a building you own that is under 35,000 square feet, or less than $6M in replacement value.`
      },
      {
        desc: `Has less than 50 employees and less than $6M in annual sales.`
      }
    ]
  };
  return (
    <div className="container mt-4">
      <RtrnKnowledgeBase />
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="asH3">
            What is a Business Owners Insurance Policy (BOP)?
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 pe-md-4">
          <p>
            {' '}
            Opening your own business is an exciting time. Though with all the
            decisions you need to make to help transform what you've worked so
            hard for into reality, things like choosing the right insurance
            policy can be daunting.
          </p>
          <p>
            {' '}
            One decision you will face is which insurance option is best for
            your business; a Business Owners Policy (BOP) or a Commercial
            Package Policy (CPP)?
          </p>
          <p>
            {' '}
            A BOP is a "package" of essential insurance coverages designed for
            small to medium-sized companies. Whether you own a hair salon, an
            accounting office or a coffee shop, for example, a BOP policy
            combines protection for all major property and liability risks into
            one policy. A typical BOP policy includes:
          </p>
          <CustomBulletList listOptions={insCovgs} />
          <br />
          <p>
            {' '}
            A simple General Liability policy provides only general Business
            Liability Coverage. Businesses shopping for insurance in their early
            stages often buy GL-only policies because they have little property,
            are more concerned with liability risks, or may be asked to, "buy a
            GL" by lessors for the space they rent.
          </p>
        </div>

        <div className="col-md-6 ps-md-4">
          <p>
            BOPs are much more comprehensive, and may only cost a little more.
            In addition to general Business Liability Coverage, they protect
            business property, and business interruption - with coverages
            customized to specific business. BOPs are far more flexible for your
            growing needs and a better value overall.
          </p>
          <p>
            It is important to know BOPs do not cover all risks related to
            running a business. Every business has unique risks depending on
            products they sell, services they provide, or operations they
            perform. BOPs, like GL only policies, do not cover professional
            liability/ professional services (though that option is available
            for a few business types), auto insurance, workers' compensation, or
            health and disability insurance. You will need separate policies to
            cover those matters.
          </p>
          <p>
            <span className="fw-bold">
              What types of businesses could benefit from a BOP?
            </span>{' '}
            A BOP generally makes sense if your business:
          </p>
          <CustomBulletList listOptions={benefits} /> <br />
          <PurchasePolicy policyType="Business" />
        </div>
      </div>
    </div>
  );
};

export default BOPPolicy;
