import {useContext, useEffect, useState} from 'react';
import {ICON_LIST} from '../../../icons';
import ThemeIcon from '../../../icons/ThemeIcon';
import {
  retrieveStateProduct,
  retrieveUnitedStates,
  stateProductTypes
} from './ProductByStateTypes';
import {EVENT_LIST} from '../../../analytics/firebase/EventMap';
import {logFirebaseEvent} from '../../../analytics/firebase/FirebaseAnalytics';
import {getErrorMessage} from '../../../services/commonServices';
import {PageLoaderContext} from '../../../context/PageLoaderProvider';

const ProductAvailableByState = () => {
  const initialProductType = {
    stateCode: '',
    stateName: '',
    auto: '',
    home: '',
    bop: '',
    landlord: '',
    umbrella: '',
    condo: '',
    renters: ''
  };
  const intialProductValues = {
    state: '',
    StateName: '',
    productList: initialProductType,
    stateList: {}
  };

  const [productForm, setProductForm] =
    useState<stateProductTypes>(intialProductValues);
  const {setLoaderActive} = useContext(PageLoaderContext);

  useEffect(() => {
    (async () => {
      setLoaderActive(true);
      const response = await retrieveUnitedStates();
      //console.log("response is" + response);
      setProductForm(prevState => ({
        ...prevState,
        stateList: response
      }));
      setLoaderActive(false);
    })();
  }, []);

  const handleStateChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLoaderActive(true);
    setProductForm(prevState => ({
      ...prevState,
      state: e.target.value
    }));
    try {
      const productResponse = await retrieveStateProduct(e.target.value);
      logFirebaseEvent({
        eventName: EVENT_LIST.PRODUCT_AVAILABLE_BY_STATE_SUCCESS
      });
      //console.log("productResponse"+productResponse);
      setProductForm(prevState => ({
        ...prevState,
        productList: productResponse
      }));
    } catch (error) {
      console.error('Error making the API call:', error);
      logFirebaseEvent({
        eventName: EVENT_LIST.PRODUCT_AVAILABLE_BY_STATE_ERROR,
        message: getErrorMessage(error)
      });
    }
    setLoaderActive(false);
  };

  const renderActiveProduct = (productVal: string) => {
    if (productVal === 'Y') {
      return (
        <ThemeIcon
          iconName={ICON_LIST.CHECKMARK}
          fill="#007bff"
          height="22"
          width="22"
        />
      );
    } else if (productVal === 'P') {
      return (
        <ThemeIcon
          iconName={ICON_LIST.CHECKMARK}
          fill="#ff6600"
          height="22"
          width="22"
        />
      );
    } else if (productVal === 'N') {
      return (
        <ThemeIcon
          iconName={ICON_LIST.CHECKMARK}
          fill="#ddd"
          height="22"
          width="22"
        />
      );
    } else {
      return '';
    }
  };

  return (
    <div className=" mt-4">
      <h5>Product Availibility by state</h5>
      <div className="col-md-6 col-lg-4 ps-0 mt-3">
        <select
          className="faqSelect"
          value={productForm.state}
          onChange={handleStateChange}
        >
          <option value=""> Select a State</option>
          {Object.entries(productForm.stateList).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </select>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-4">
          <div className="row">
            <div className="col-6 mt-3">
              <table className="productsTable">
                <thead></thead>
                <tbody>
                  <tr>
                    <td>Auto</td>
                    <td>{renderActiveProduct(productForm.productList.auto)}</td>
                  </tr>
                  <tr>
                    <td>Business</td>
                    <td>{renderActiveProduct(productForm.productList.bop)}</td>
                  </tr>
                  <tr>
                    <td>Condo</td>
                    <td>
                      {renderActiveProduct(productForm.productList.condo)}
                    </td>
                  </tr>
                  <tr>
                    <td>Home</td>
                    <td>{renderActiveProduct(productForm.productList.home)}</td>
                  </tr>
                  <tr>
                    <td>Landlord</td>
                    <td>
                      {' '}
                      {renderActiveProduct(productForm.productList.landlord)}
                    </td>
                  </tr>
                  <tr>
                    <td>Renters</td>
                    <td>
                      {renderActiveProduct(productForm.productList.renters)}
                    </td>
                  </tr>
                  <tr>
                    <td>Umbrella</td>
                    <td>
                      {renderActiveProduct(productForm.productList.umbrella)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-6 mt-5">
              <p className="mt-5">
                <span>
                  <ThemeIcon
                    iconName={ICON_LIST.CHECKMARK}
                    fill="#007bff"
                    height="15"
                    width="15"
                  />
                </span>
                <span className="font-italic text-14 pe-1">= Available</span>
              </p>
              <p>
                <span>
                  <ThemeIcon
                    iconName={ICON_LIST.CHECKMARK}
                    fill="#ff6600"
                    height="15"
                    width="15"
                  />
                </span>
                <span className="font-italic text-14 pe-1">= Coming soon</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductAvailableByState;
