import {EventListProps} from './FirebaseAnalyticsTypes';

export enum EVENT_LIST {
  FIND_AN_AGENT_SUCCESS = 'FIND_AN_AGENT_SUCCESS',
  FIND_AN_AGENT_ERROR = 'FIND_AN_AGENT_ERROR',
  CONTACT_US_CHAT_SUCCESS = 'CONTACT_US_CHAT_SUCCESS',
  PRODUCT_AVAILABLE_BY_STATE_SUCCESS = 'PRODUCT_AVAILABLE_BY_STATE_SUCCESS',
  PRODUCT_AVAILABLE_BY_STATE_ERROR = 'PRODUCT_AVAILABLE_BY_STATE_ERROR',
  CUSTOMER_REVIEWS = 'CUSTOMER_REVIEWS'
}

export const EVENT_MAP: Record<EVENT_LIST, EventListProps> = {
  [EVENT_LIST.FIND_AN_AGENT_SUCCESS]: {
    feature: 'Find An Agent',
    action: 'Success'
  },
  [EVENT_LIST.FIND_AN_AGENT_ERROR]: {
    feature: 'Find An Agent',
    action: 'Error'
  },
  [EVENT_LIST.CONTACT_US_CHAT_SUCCESS]: {
    feature: 'Contact Us',
    action: 'Success',
    contactMethod: 'chat'
  },
  [EVENT_LIST.PRODUCT_AVAILABLE_BY_STATE_SUCCESS]: {
    feature: 'Agent Appointment',
    action: 'Success'
  },
  [EVENT_LIST.PRODUCT_AVAILABLE_BY_STATE_ERROR]: {
    feature: 'Agent Appointment',
    action: 'Error'
  },
  [EVENT_LIST.CUSTOMER_REVIEWS]: {
    feature: 'Customer Reviews',
    action: 'Success'
  }
};
