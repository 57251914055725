import PolicyFAQ from '@components/pages/PolicyFAQ';

const RentersInsFAQ = () => {
  const rentersFAQData = {
    title: `Renters Insurance FAQ | Stillwater Insurance`,
    header: `Renters Insurance FAQ`,
    leftParagraphs: [
      {
        id: 1,
        label: `If my roommate has Renters insurance, am I covered too? `,
        desc: `You could have three roommates who all have Renters insurance, but that doesn't mean you're also covered. A Renters insurance policy will not cover 
          a policyholder's roommate(s), unless they are specifically listed on the policy. This is because insurance companies need to price the policy appropriately for the 
          risk. If you're not listed on the policy, the insurance company is not aware that you reside at that property and has no obligation to pay for 
          damage or loss to your personal property in the event of a claim. The only way to ensure that you are protected is to purchase a Renters policy 
          yourself, or to make sure that you are listed as a named insured on your roommate's policy.`
      },
      {
        id: 2,
        label: `How  much does Renters insurance cost?  `,
        desc: `According to the National Association of Insurance Commissioners, the average Renters insurance policy only costs $15-$30 per month. This can vary 
          state to state and depends on how many things you have to cover.  Renters insurance is much cheaper than Homeowners insurance and a basic policy 
          normally gives you $30,000 of personal property coverage against theft, fire and natural disasters. The difference between Renters and Homeowners 
          insurance is that renters are just insuring their personal belongings, not the physical building itself. For less than $1 a day, you can have 
          peace of mind in knowing that you're protected.`
      },
      {
        id: 3,
        label: `Does my Renters policy cover my pet?  `,
        desc: `Pets are not considered personal property for the purposes of Renters insurance, so they will not be covered for illness or injury. However, 
          the liability portion of your Renters policy may cover damage or injury caused by your pet.`
      },
      {
        id: 4,
        label: `Are my items still insured if they are stolen from my bag/car outside of the dwelling?  `,
        desc: ` Generally, the policy provides coverage for your personal property while it is anywhere in the world. However, most policies include a 
          sub-limit for personal property that is at another residence or in a storage facility.`
      }
    ],
    rightParagraphs: [
      {
        id: 5,
        label: `Does coverage extend to storage units (either within the property - i.e. basement -  or outside the dwelling entirely)? `,
        desc: `Yes. Coverage generally extends to storage units, although the limit of insurance may be reduced.`
      },
      {
        id: 6,
        label: `Is Personal Liability Insurance included in a Renters policy?* `,
        desc: `Most Renters insurance policies provide coverage for Personal Liability.  As with any other type of policy, the coverage may vary from company to company.`
      },
      {
        id: 7,
        label: `Do I need to get coverage for items shared with roommate(s)? `,
        desc: `Most insurance policies provide coverage for personal property owned by you, therefore if you own it, even partially, then you need coverage for it.  
          Additionally, policies generally exclude coverage for personal property owned by roommate(s) and boarders specifically, unless they are related to you.`
      },
      {
        id: 7,
        label: `Is my property covered if roommate(s) caused the damage? `,
        desc: `(i.e. theft, accidental fire, etc.)? Renter's policies typically provide coverage for theft or fire in the event your property is damaged if the person 
          responsible for the fire is not an insured on the policy.`
      },
      {
        id: 8,
        label: `Do I need to update my policy if I change roommates/apartments?  `,
        desc: ` Yes. Anytime you move locations, you need to update your policy.  Policy language includes information regarding the residence premises, and you don't want 
          to be in a claim situation and find out you do not have coverage at your current residence premises.`
      }
    ]
  };
  return <PolicyFAQ faq={rentersFAQData} />;
};

export default RentersInsFAQ;
