import PolicyMyths from '@components/pages/PolicyMyths';

const MythsUmbrellaInsurance = () => {
  const umbrellaMyths = {
    header: `3 Myths About Umbrella Insurance`,
    paragraphs: [
      `You've heard the old saying, "When it rains, it pours." Just like you need an actual umbrella
				 to protect you during a downpour, you may need an Umbrella insurance policy to protect your assets 
				 if you are the target of a large liability claim. Think of it as an extra layer of protection that will help 
				 pay what you owe if you are sued and the damages exceed the liability limits of your Home, 
				 Auto or other insurance policies.`,
      `Umbrella insurance is often misunderstood, so here are a few myths that need to be debunked:`
    ],
    mythLeftOptions: [
      {
        id: 1,
        header: `Myth #1`,
        label: `Umbrella insurance is too expensive. `,
        desc: `Fact check: Umbrella insurance is affordable. According to the Insurance Information Institute, an Umbrella policy with $1,000,000 in coverage costs about $150 to $300 per year. That's somewhere between 40 and 80 cents a day. For less than a cup of coffee, you can protect your assets.`
      },
      {
        id: 2,
        header: `Myth #2`,
        label: `Umbrella insurance is just for the wealthy. `,
        desc: `Fact check: Not anymore. As lawsuits become more and more prevalent for everything from a dog bite which causes permanent scarring and injury to an accident resulting from underage drinking at a party, more and more people are purchasing an Umbrella policy to protect themselves from an unforeseen event. Every day, courts around the country are awarding hundreds of millions of dollars in damages that can financially cripple the negligent party. Unexpected events can happen to anyone, and your future financial stability could be in jeopardy.

`
      },
      {
        id: 3,
        header: `Myth #3`,
        label: `I already have Home and Auto liability insurance. I don't need more. `,
        desc: `Fact check: 
				Depending on your situation, your traditional Home and Auto liability insurance may not 
				be enough to cover your assets if you are sued. Let's say you have $300,000 in liability 
				coverage on your Home insurance policy. If a neighbor is injured in your swimming pool 
				and sues you for $1,000,000, you would be responsible for the remaining $700,000 from 
				that judgement. Your checking and savings accounts and future income are considered assets; 
				your savings accounts could be depleted, and your future wages could be garnished to settle 
				your financial obligation.`
      }
    ],
    mythRightOptions: [
      {
        id: 4,
        desc: `The bottom line: Purchasing an Umbrella policy can get you one step closer to having 
				peace of mind knowing that your financial future is secure.`
      }
    ],
    policyType: `Umbrella`
  };
  return <PolicyMyths myths={umbrellaMyths} />;
};

export default MythsUmbrellaInsurance;
