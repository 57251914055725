import {glossaryData} from './GlossaryTypes';
import CustomParagraphList from '@components/form/paragraph/CustomParagraphList';

const GlossaryBody = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="d-none d-md-block">
          <h1 className="asH3">Insurance Term Glossary</h1>
        </div>
        <div className=" col-sm-12 col-2 sticky-top sticky-offset order-2 order-sm-1">
          <ul className="nav glossary-nav">
            {glossaryData.map(glossaryNavItem =>
              glossaryNavItem.isEnabled ? (
                <li className="nav-item" key={glossaryNavItem.id}>
                  <a
                    className="nav-link glossary-tab"
                    href={`#${glossaryNavItem.id}`}
                  >
                    {glossaryNavItem.id}
                  </a>
                </li>
              ) : (
                <li className="nav-item" key={glossaryNavItem.id}>
                  <a
                    className="nav-link glossary-tab-disabled"
                    tabIndex={-1}
                    aria-disabled="true"
                    href="#"
                  >
                    {glossaryNavItem.id}
                  </a>
                </li>
              )
            )}
          </ul>
        </div>
        <div className="col-sm-12 col-10 order-1 order-sm-2">
          <div className="row mt-3 ms-2">
            <div className="col-12">
              <h5 className="fw-bold">
                We want you to be an informed customer-one who feels confident
                about their purchase of a Stillwater policy.
              </h5>
            </div>
            <div className="col-md-6 pe-md-4">
              <p>
                We also know that insurance policies contain a lot of
                unfamiliar, often confusing terminology. This glossary is about
                bridging that gap. It includes insurance lingo related to
                coverages, processes, and the workings of a policy. And, it aims
                to deliver simplified definitions and basic examples to help you
                understand what you're buying.
              </p>
            </div>
            <div className="col-md-6 ps-md-4">
              <p>
                Know that these definitions are not a substitute for the policy
                contract, which spells out in detail what you can expect from
                Stillwater. We encourage you to review your contract (which we
                send after a policy is bought), and contact us if you have any
                questions.
              </p>
            </div>
            <hr className="col-12" />
            <p>
              <strong>100/300/100 - </strong>Auto insurance liability limits may
              be expressed with three numbers separated by slashes. This is
              referred to as "split limits."
            </p>
            <ul className="ps-5">
              <li>
                The first number (100) refers to the bodily injury limit per
                person in a claim. If you're at fault for an accident that
                causes injury to others, your insurance company will pay no more
                than $100,000 to any individual for medical treatment.
              </li>
              <li>
                The second number (300) refers to the bodily injury limit for an
                entire claim. So, if you're at fault in an accident that causes
                injury to multiple people, your insurance company will pay no
                more than $300,000 for the combined medical costs.
              </li>
              <li>
                The third number (100) refers to the property damage limit for
                an entire claim. In this case, the insurance company will pay no
                more than $100,000 for any damage you cause to others' vehicles
                or property.
              </li>
            </ul>
          </div>

          <div className="row ms-2 ms-md-0">
            {glossaryData.map(
              glossaryNavItem =>
                glossaryNavItem.isEnabled &&
                glossaryNavItem.paragaraphs && (
                  <div
                    key={glossaryNavItem.id}
                    id={glossaryNavItem.id}
                    className="col-md-12 glossary-item"
                  >
                    <CustomParagraphList
                      paragraphs={glossaryNavItem.paragaraphs}
                    />
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlossaryBody;
