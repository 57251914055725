import {Container} from 'react-bootstrap';
import {BsStar, BsStarFill, BsStarHalf} from 'react-icons/bs';
import {Link} from 'react-router-dom';

const StarRating = ({
  rating,
  outOf = 5,
  count
}: {
  rating: number;
  outOf: number;
  count: number;
}) => {
  const renderStarReview = () => {
    const starList = [];
    // Create star elements based on the rating
    for (let i = 1; i <= outOf; i++) {
      if (i <= rating) {
        // Full star
        starList.push(<BsStarFill key={i} className="star" />);
      } else if (i - rating < 1) {
        // Half star if the rating is not a whole number
        starList.push(<BsStarHalf key={i} className="star" />);
      } else {
        // Empty star
        starList.push(<BsStar key={i} className="star" />);
      }
    }
    return starList;
  };

  return (
    <Container>
      {renderStarReview()}
      <span className="ratingFontSize ps-2 pe-2">{rating} out of 5 stars</span>
      <span className="pe-1">(</span>
      <Link
        className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
        to="/customer-reviews"
      >
        {count} responses
      </Link>
      <span className="ps-1">)</span>
    </Container>
  );
};

export default StarRating;
