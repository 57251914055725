import {useContext, useEffect, useState} from 'react';
import {PageLoaderContext} from '../../../context/PageLoaderProvider';
import AboutUsHeader from '../AboutUs';
import './CustomerFeedback.scss';
import {
  convertStarPercentageMap,
  convertYNPercentageMap,
  retrieveDisplayreviewDetails,
  retrieveReviewPercentage,
  retrieveTotalReviewCount,
  reviewData
} from './CustomerFeedbackTypes';
import ReviewDisplay from './ReviewDisplay';
import CustomerFeedbackRightPanel from './CustomerFeedbackRightPanel';
import PageTitle from '@components/form/PageTitle';

const CustomerFeedback = () => {
  const {setLoaderActive} = useContext(PageLoaderContext);
  const [review, setStarReview] = useState<reviewData>({
    reviewCount: 0,
    reviewStarPercentageList: [],
    reviewYNPercentageList: [],
    reviewInfo: [],
    starRating: 0
  });
  useEffect(() => {
    (async () => {
      setLoaderActive(true);
      const count = await retrieveTotalReviewCount();
      const percentage = await retrieveReviewPercentage();
      const displayReviews = await retrieveDisplayreviewDetails();
      // console.log("count is" + count);
      //console.log("percentage is" + percentage);
      //console.log("displayReviews are" + displayReviews);
      setStarReview(prevState => ({
        ...prevState,
        reviewStarPercentageList: convertStarPercentageMap(percentage),
        reviewYNPercentageList: convertYNPercentageMap(percentage),
        starRating: percentage.starRating,
        reviewCount: count,
        reviewInfo: displayReviews
      }));
      setLoaderActive(false);
    })();
  }, []);
  return (
    <>
      <PageTitle>
        Customer Reviews & Testimonials | Stillwater Insurance
      </PageTitle>
      <AboutUsHeader activeOption="Customer Feedback" />
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6 pe-md-4">
            <h1 className="asH4 fw-bold">Customer reviews and testimonials</h1>
            <p>
              After every claim, we ask our customers for feedback about their
              experience so that we can celebrate when we get it right - and
              improve our processes when we don't. This is a summary of
              responses from our actual customers.
            </p>
            <div>
              {review.reviewInfo.length > 6 &&
                review.reviewInfo
                  .slice(0, 8)
                  .map((cusReviewDetails, index) => (
                    <ReviewDisplay
                      key={index}
                      customerReview={cusReviewDetails}
                    />
                  ))}
            </div>
          </div>
          <CustomerFeedbackRightPanel review={review} />
        </div>
      </div>
    </>
  );
};

export default CustomerFeedback;
