import '../Welcome.scss';
import WelcomeImage from './welcomeimage/WelcomeImage';
import Products from './products/Products';

import LocalStorage from '../../../utils/LocalStorage';
import BottomPanel from '../bottomPanel/BottomPanel';
import PaperLess from './paperless/PaperLess';

import {cardInfo} from '../WelcomeTypes';
import CardPanel from '../../../components/panel/card/CardPanel';

export default function Welcome() {
  const showView = LocalStorage.get(LocalStorage.STORE_KEYS.SESSION_SHOW_VIEW)
    ? LocalStorage.get(LocalStorage.STORE_KEYS.SESSION_SHOW_VIEW)
    : 'welcome';

  return (
    <>
      <div className="jumbotron jumbotron-fluid text-white bg-main py-0 welcome">
        <div className="container">
          <div className="row">
            <WelcomeImage />
            {showView == 'welcome' && <Products />}
            {showView == 'paperless' && <PaperLess />}
          </div>
        </div>
      </div>
      <CardPanel cardData={cardInfo} />
      <BottomPanel />
    </>
  );
}
