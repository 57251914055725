export const firebaseConfigQUA = {
  apiKey: 'AIzaSyANs9Nek3MFT9PJmy-zRmGRuURoDf6JD-E',
  authDomain: 'qua---ga4.firebaseapp.com',
  projectId: 'qua---ga4',
  storageBucket: 'qua---ga4.appspot.com',
  messagingSenderId: '41071579436',
  appId: '1:41071579436:web:098a0109e9d584a0b05c89',
  measurementId: 'G-4B08T19K3W'
};

export const firebaseConfigPRD = {
  apiKey: 'AIzaSyAQMRS35FIHv8UFxR5l7iiqY2VhNJFgS3Y',
  authDomain: 'stillwater-insurance.firebaseapp.com',
  projectId: 'stillwater-insurance',
  storageBucket: 'stillwater-insurance.appspot.com',
  messagingSenderId: '55140168230',
  appId: '1:55140168230:web:3b4b084979d9c1c7fb6ce7',
  measurementId: 'G-07B3NJM2WZ'
};
