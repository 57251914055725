import {ICON_LIST} from '../../../icons';

export const umbrellaInsData = {
  backgroundImgClass: 'bg-umbrella',
  header: 'GET AN UMBRELLA INSURANCE QUOTE TODAY',
  mobileHeader: 'GET AN UMBRELLA INSURANCE QUOTE TODAY',
  desc: "Because with the right amount of additional coverage, it's sunny days ahead.",
  type: 'Umbrella'
};

export const umbrellaInsCardPanelInfo = [
  {
    cardClass:
      'card border-transferent border-bottom-0 border-top-0 border-start-0 mb-4',
    image: ICON_LIST.SHIELDCHECK,
    cardHeader: 'Do I need umbrella insurance?',
    headerClass: 'h-50',
    cardDescription: `A commonly asked question when considering purchasing umbrella insurance is "Do I really need it?"`,
    btnLink: '/know-how/need-umbrella',
    btnText: 'Do I need an Umbrella Policy?'
  },
  {
    cardClass: 'card border-transferent border-0 mb-4',
    image: ICON_LIST.BOOK,
    cardHeader: 'Umbrella Insurance 101',
    headerClass: 'h-50',
    cardDescription:
      'An umbrella insurance policy is designed to protect you from a liability claim above and beyond what your other insurance policies cover.',
    btnLink: '/know-how/umbrella-insurance-101',
    btnText: 'Umbrella Insurance 101'
  },

  {
    cardClass:
      'card border-transferent border-bottom-0 border-top-0 border-end-0 mb-4',
    image: ICON_LIST.WANDMAGIC,
    cardHeader: 'Top 3 myths about umbrella insurance',
    headerClass: 'h-50',
    cardDescription:
      'An extra layer of protection that will help pay what you owe if you are sued and the damages exceed the liability limits of your home, auto or other insurance policies.',
    btnLink: '/know-how/myths-umbrella',
    btnText: 'Top 3 myths about umbrella insurance'
  }
];
