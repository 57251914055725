import PageTitle from '@components/form/PageTitle';
import AboutUsHeader from '../AboutUs';
import {leaderShipData} from './LeadershipTypes';

const OurLeadership = () => {
  return (
    <>
      <PageTitle>Leadership Team | Stillwater Insurance</PageTitle>
      <AboutUsHeader activeOption="Our Leadership" />
      <div className="container">
        <div className="row">
          {leaderShipData
            .sort((a, b) => a.sequence - b.sequence)
            .map(leader => (
              <div key={leader.sequence} className="col-lg-4 col-md-6">
                <div className="card no-border mb-4">
                  <p className="image mt-4">
                    <img
                      src={leader.imageName}
                      className="img-fluid border-pacificblue"
                      alt={leader.alt}
                      width="328"
                      height="328"
                    />
                  </p>
                  <div className="card-body ps-0 pt-2">
                    <span className="card-title">
                      <span className="text-14 fw-bolder text-darkblue style-worksans">
                        {leader.name}
                      </span>
                      <br />
                      <span className="text-14 bold">{leader.title}</span>
                    </span>
                    <p className="card-text mr-xl-5 mt-2 text-14 light">
                      {' '}
                      {leader.text}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
export default OurLeadership;
