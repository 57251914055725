import FindAnAgentListItem from '../../FindAnAgentListItem';
import RtrnKnowledgeBase from '../../RtrnKnowledgeBase';

const CommonQuestions = () => {
  return (
    <div className="container mt-4">
      <RtrnKnowledgeBase />
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="asH3">
            5 Commonly Asked Questions about Filing a Claim
          </h1>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-6 pe-md-4">
          <p>
            Any event that results in having to file an insurance claim can be
            stressful. Whatever type of claim you face, the circumstances will
            require you to know what to do and when. The last thing you want is
            to have unanswered questions preventing you from taking the best
            course of action. Here we will address the most commonly asked
            Claims questions, so that when the time comes, you will know exactly
            what to do and what to expect.
          </p>
          <h4>Do I need to file a claim immediately?</h4>
          <p>
            When faced with a circumstance that results in damage to people or
            property, your first priority is always to get safe. You are also
            obligated to take steps to stop or minimize the damage to your
            property, if at all possible. It is important to document the scene
            with photos and videos that can be used to investigate your claim
            later on. If emergency personnel are on the scene, listen to their
            instructions and collect the police report to document who was on
            the scene.
          </p>
          <p>
            <strong>
              What are the benefits of filing your claim immediately?
            </strong>
          </p>
          <ol>
            <li>
              The sooner you file, the sooner the investigation can be
              completed, and repairs can begin.{' '}
            </li>
            <li>
              You will get expert guidance on what to do and how to mitigate
              damages. Once you report a loss, a claim examiner will call you as
              quickly as warranted by your situation and provide further loss
              specific instructions.{' '}
            </li>
          </ol>
          <h4>What happens once I file a claim?</h4>
          <p>
            Once you file a claim - known as the First Notice of Loss - a claim
            examiner will be assigned to you. This examiner will contact you and
            deploy a strategy to handle the claim depending on the severity,
            which may include emergency services like board-up, water mitigation
            or tree removal. The examiner will review documentation and
            information you provide to determine what is needed. Often the
            examiner will send an adjuster or contractor from Stillwater's
            approved contractor network to determine the value of the loss.
          </p>
          <p>
            <strong>
              Should you ever move forward with repairs prior to speaking with
              your claim examiner?
            </strong>
          </p>
          <p>
            We know how important it is to get your property restored as soon as
            possible; however, moving forward on repair work without your claim
            examiner's determination could put you at risk of not having your
            claim fully covered. Work with your examiner throughout the claims
            investigation, and provide any documentation requested of you so
            that the value of the loss can be determined quickly. The examiner
            will take your information, estimates and market rate into
            consideration when determining the value of a claim.
          </p>
          <h4>
            What is a deductible, and what do I need to consider when filing a
            claim?{' '}
          </h4>
          <p>
            A deductible is the amount a policyholder pays out of pocket before
            an insurance provider will pay any expenses. People often wonder if
            they should file a claim if they have a deductible that exceeds the
            value of their loss. The good news is you don't have to figure that
            out on your own; Stillwater's claim examiner's role is to help you
            sort through your coverages and make decisions. If you report a
            claim, and it ends up your loss value is less than your deductible,
            the claim will only show up in your history with a $0 payout
            associated with it.
          </p>
        </div>
        <div className="col-md-6 ps-md-4">
          <h4>How will my claim payout be determined?</h4>
          <p>
            You will be paid the fair amount to make the repairs necessary to
            restore your property to its prior condition, minus any applicable
            deductible, depreciation or other offsets that may be included in
            your policy. For example, you may have a sublimit for jewelry in
            your homeowner's policy, in which case your payout for jewelry will
            not exceed that amount. Sometimes depreciation may be applied to a
            claim payout; this is when your insurance company holds back a
            portion of the money for damaged items based on their age or
            condition. This money is released to you once you have paid to
            repair or replace the items.
          </p>
          <p>
            Your contract contains all the details you need to know about your
            coverage and rights. The claim examiner assigned to you will provide
            explanation of the insurance contract and how your claim payout will
            be determined. If you have a lienholder on your property or vehicle,
            the claims check may have the lienholder as the payee. Often the
            lienholder will want to inspect the property to ensure the repairs
            have been made. You will have to contact your lienholder for
            instructions on how to process the check.
          </p>

          <h4>Will my rate go up if I file a claim?</h4>
          <p>
            Many factors contribute to the rate you pay for your insurance
            policy, which are reevaluated upon the renewal of your policy.
            Though not every claim will result in an increase to your rate at
            renewal, the frequency and types of claims may affect your insurance
            rates in the future.{' '}
            <span className="fw-bold">Important to know:</span> You could put
            yourself at risk by NOT filing a claim; for example, if you are
            involved in an accident with anyone else that you have not reported,
            and that person decides to sue you months later, it will be more
            difficult for your insurance carrier to represent you. You purchase
            insurance to protect yourself and to recover from a loss - use it!
          </p>

          <p>
            At Stillwater, our policyholders are so satisfied with our handling
            of their claims that 9 out of 10 say they will recommend us to their
            friends and family. Stillwater's around-the-clock claims reporting
            ensures that in the event of a claim, you will receive fast,
            knowledgeable and empathetic claims handling by professionals
            committed to the highest standards.
          </p>

          <br />
          <br />
          <p className="fw-bold">How to purchase a policy from Stillwater:</p>

          <ul className="custom-bullet square">
            <li>
              Online: It's quick and easy. Answer a few questions and you'll
              have a quote in two minutes. You can purchase the policy
              instantly.
            </li>
            <li>
              {' '}
              Call us at (855) 712-4092. Our licensed reps are available Monday
              through Friday from 8am-9pm ET, and 8am-3pm ET on Saturday.
            </li>
            <li>
              <FindAnAgentListItem />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CommonQuestions;
