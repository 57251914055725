import {getBaseUrl} from '../../environment/Environment';

const MobileApp = ({mobileAppClass}: {mobileAppClass: string}) => {
  const appStoreImg = () => {
    return (
      getBaseUrl() + 'static5/resources/common/img/ad-mobile-apple-normal.png'
    );
  };
  const googlePlay = () => {
    return (
      getBaseUrl() + 'static5/resources/common/img/ad-mobile-google-normal.png'
    );
  };
  return (
    <div className={mobileAppClass}>
      <h5 className="fw-bold">Download the mobile app now</h5>
      <div>
        <a
          href="https://play.google.com/store/apps/details?id=com.stillwater.insurance"
          target="_blank"
        >
          <img
            src={googlePlay()}
            alt="Stillwater Mobile APP Ad Google"
            width="120"
            height="40"
          />
        </a>
        <a
          href="https://itunes.apple.com/us/app/APPNAME/id1434850228"
          target="_blank"
        >
          <img
            src={appStoreImg()}
            alt="Stillwater Mobile APP Ad Apple"
            width="120"
            height="40"
          />
        </a>
      </div>
    </div>
  );
};

export default MobileApp;
