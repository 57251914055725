export interface Environment {
  readonly dev?: boolean;
  readonly int?: boolean;
  readonly local?: boolean;
  readonly prod?: boolean;
  readonly staging?: boolean;
  readonly training?: boolean;
  readonly base?: boolean;
  readonly baseUrl: string;
  readonly reqTimeout: number;
  readonly googleMapApiKey: string;
  readonly firebaseConfig: FirebaseConfig;
}

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export enum ENV_TYPES {
  DEV = 'dev',
  INT = 'int',
  LOCAL = 'local',
  PROD = 'prod',
  STAGING = 'staging',
  TRAINING = 'training'
}
