import {ICON_LIST} from '../../../icons';

export const homeProductData = {
  backgroundImgClass: 'bg-home',
  header: 'GET A HOME INSURANCE QUOTE TODAY',
  mobileHeader: 'GET A HOME INSURANCE QUOTE TODAY',
  desc: 'And start protecting the place that you and your loved ones call home.',
  type: 'Home'
};

export const homInsCardPanelInfo = [
  {
    cardClass:
      'card border-transferent border-bottom-0 border-top-0 border-start-0 mb-4',
    image: ICON_LIST.HOME,
    cardHeader: 'Ten factors of pricing home insurance',
    headerClass: 'h-50',
    cardDescription:
      'Key factors that determine how insurance companies calculate your home insurance premium.',
    btnLink: '/know-how/ten-factors-pricing-home-insurance',
    btnText: '10 factors of pricing home insurance'
  },
  {
    cardClass: 'card border-transferent border-0 mb-4',
    image: ICON_LIST.DOLLAR,
    cardHeader:
      "What's the difference between market value and replacement cost?",
    headerClass: 'h-50',
    cardDescription:
      "Have you ever considered exactly how insurance companies will determine what amount of money you'll be reimbursed?",
    btnLink: '/know-how/ten-factors-pricing-home-insurance',
    btnText: 'Know The Difference'
  },

  {
    cardClass:
      'card border-transferent border-bottom-0 border-top-0 border-end-0 mb-4',
    image: ICON_LIST.WANDMAGIC,
    cardHeader: 'Top 5 myths about home insurance',
    headerClass: 'h-50',
    cardDescription:
      'Have you thought about how you are going to protect your investment if the unthinkable happens?',
    btnLink: '/know-how/myths-home',
    btnText: 'Top 5 Home Insurance Myths'
  }
];

export const insCovgOptions = {
  options: [
    {
      desc: "Pays claims based on the cost to rebuild your home. That can be more than your home's market value."
    },
    {
      desc: "Pays the replacement cost for your home's contents, not a depreciated value based in the age or condition."
    },
    {
      desc: 'Reflects your very specific needs. Maybe you want coverage for the jewelry your grandmother handed down to you, identify theft protection, or coverage for your seasonal residence.'
    },
    {
      desc: 'Offers deductible options and discounts that save you money.'
    }
  ]
};

export const mainCovgOptions = {
  options: [
    {
      header: 'Dwelling - ',
      desc: "Pays to repair or rebuild your home and attached structures, up to chosen coverage limits, if it's damaged or destroyed by fire or storms."
    },
    {
      header: 'Other Structures - ',
      desc: 'Similar to Dwelling Coverage, but for unattached structures'
    },
    {
      header: 'Personal Property - ',
      desc: 'Pays for damage to or theft of most personal items, including appliances, furniture, electronics and clothing'
    },
    {
      header: 'Medical Payments - ',
      desc: 'Pays for medical expenses for bodily injury to guests while on your property regardless of fault'
    },
    {
      header: 'Personal Liability - ',
      desc: 'Pays for expenses associated with bodily injury or property damage to others due to negligence on your part both on or off your property'
    },
    {
      header: 'Loss of Use - ',
      desc: 'Pays the bills or expenses you have as a result of losing the use or your residence'
    }
  ]
};

export const smartCovgOptions = {
  options: [
    {
      header: 'Extended Replacement Cost - ',
      desc: 'After a catastrophic event like a hurricane or tornado, the cost to repair or rebuild homes can spike due to a shortage of building materials. In that situation, this coverage ensures that the additional cost to repair or rebuild your home is covered.'
    },
    {
      header: 'Personal Property Replacement Cost - ',
      desc: 'Get paid the full replacement cost -rather than a depreciated value- for most stolen or damaged personal items.Similar to Dwelling Coverage, but for unattached structures'
    }
  ]
};

export const addCovgOptions = {
  options: [
    {
      desc: 'Animal Liability'
    },
    {
      desc: 'Identity Theft Expense Recoupment'
    },
    {
      desc: 'Personal Injury'
    },
    {
      desc: 'Scheduled Personal Property'
    },
    {
      desc: 'Special Personal Property'
    },
    {
      desc: 'Water Back-Up'
    }
  ]
};
