import LocalStorage from '../../utils/LocalStorage';

class HomeUtils {
  static redirectToHome(): void {
    LocalStorage.set(LocalStorage.STORE_KEYS.SESSION_SHOW_VIEW, 'welcome');
    window.location.href = '/';
  }
  static redirectToPaperless(): void {
    LocalStorage.set(LocalStorage.STORE_KEYS.SESSION_SHOW_VIEW, 'paperless');
    window.location.href = '/';
  }
}

export default HomeUtils;
