import {useMemo} from 'react';
import HomeInsProduct from './home-insurance/HomeInsProduct';
import {useParams} from 'react-router-dom';

import BusinessInsuranceProduct from './business-insurance/BusinessInsuranceProduct';
import CondoInsProduct from './condo-insurance/CondoInsProduct';
import AutoInsProduct from './auto-insurance/AutoInsProduct';
import UmbrellaInsProduct from './umbrella-insurance/UmbrellaInsProduct';
import RentersInsProduct from './renters-insurance/RentersInsProduct';
import LandlordInsProduct from './land-lord-insurance/LandlordInsProduct';

const InsuranceProduct = () => {
  const params = useParams();
  const paramId = params.productItem;
  const renderInsProduct = useMemo(() => {
    switch (paramId) {
      case 'home-insurance':
        return <HomeInsProduct />;
      case 'business-insurance':
        return <BusinessInsuranceProduct />;
      case 'renters-insurance':
        return <RentersInsProduct />;
      case 'landlord-insurance':
        return <LandlordInsProduct />;
      case 'condo-insurance':
        return <CondoInsProduct />;
      case 'umbrella-insurance':
        return <UmbrellaInsProduct />;
      case 'auto-insurance':
        return <AutoInsProduct />;
      default:
        return null;
    }
  }, [paramId]);
  return <>{renderInsProduct}</>;
};

export default InsuranceProduct;
