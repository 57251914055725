import {useContext, useEffect, useState} from 'react';
import {StateValues} from '../../WelcomeTypes';

import StringUtils from '../../../../utils/StringUtils';
import StartQuote from '../../../../components/form/StartQuote';
import ReactSelect, {components} from 'react-select';
import {ProductOptions} from '../../WelcomeTypes';
import ThemeIcon from '../../../../icons/ThemeIcon';
import {ProductTypeContext} from '../../../../context/StartQuoteProductProvider';
import {getRetrieveQuoteUrl} from '../../../../environment/Environment';
import Product1 from './Product1';
import {colourStyles, InActiveButtons} from './ProductTypes';
import Product2 from './Product2';

const Products = () => {
  const {setSelectedProductTyp} = useContext(ProductTypeContext);

  const [state, setState] = useState<StateValues>({
    btnType: 'HOME',
    panelType: 'products1',
    selectedProductVal: 'home'
  });

  useEffect(() => {
    setSelectedProductTyp(state.selectedProductVal);
  }, [state.selectedProductVal]);

  const updateActiveBtn = (val: string) => {
    setState(prevState => ({
      ...prevState,
      btnType: val,
      selectedProductVal: StringUtils.toLowerCase(val)
    }));

    //console.log(state.btnType);
  };

  const updatePanel = (val: string) => {
    if (val == 'products1') {
      setState(prevState => ({
        ...prevState,
        btnType: 'HOME',
        panelType: val
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        btnType: '',
        panelType: val
      }));
    }
  };
  const handleChange = (val?: any) => {
    //console.log(val);
    // console.log(val?.value);
    setState(prevState => ({
      ...prevState,
      selectedProduct: val,
      selectedProductVal: val?.value
    }));
  };
  const Option = (props: any) => (
    <components.Option {...props} className="country-option">
      <ThemeIcon
        iconName={props.data.image}
        fill="#0099cc"
        width="20"
        height="20"
      />
      <span className="ms-2">{props.data.label}</span>
    </components.Option>
  );

  const SingleValue = (props: any) => (
    <components.SingleValue {...props} className="singleProductVal">
      <ThemeIcon
        iconName={state.selectedProduct.image}
        fill="#ffffff"
        width="20"
        height="20"
      />
      <span className="ms-2">{state.selectedProduct.label}</span>
    </components.SingleValue>
  );

  return (
    <div className="col-xl-7">
      <h1 className="asH2 mt-4 m-md-3 boldest">
        GET A HOME, AUTO, RENTERS, CONDO OR BUSINESS QUOTE TODAY.
      </h1>
      <div className="d-md-none pt-2 pb-1">
        <ReactSelect
          placeholder="Select a Product..."
          value={state.selectedProduct}
          options={ProductOptions}
          onChange={handleChange}
          className="productSelect"
          styles={colourStyles}
          components={{
            Option,
            SingleValue
          }}
        />
      </div>
      <p className="m-md-3 d-none d-lg-block">
        Getting a quote for your home, auto or business should not be painful.
        Got two minutes? That's all you need to see if we're a right fit for
        you.
      </p>
      {state.panelType == 'products1' && (
        <Product1
          btnType={state.btnType}
          updatePanel={updatePanel}
          updateActiveBtn={updateActiveBtn}
        />
      )}
      {state.panelType == 'products2' && (
        <Product2
          btnType={state.btnType}
          updatePanel={updatePanel}
          updateActiveBtn={updateActiveBtn}
        />
      )}
      {state.btnType &&
        (InActiveButtons.indexOf(state.btnType) !== -1 ? (
          <div className="inActiveBtn productdiv-hght">
            <div id="productsTextDiv" className="row">
              <p className="ps-4 ms-5">
                To best assist you with our{' '}
                {StringUtils.toLowerCase(state.btnType)} insurance product,{' '}
                <br /> please contact our specialist at{' '}
                <span className="fw-bold text-nowrap">(855) 712-4092</span>
              </p>
            </div>
          </div>
        ) : (
          <StartQuote page="start" />
        ))}
      <div className={`col-md-12  py-3  col-lg-10 text-center text-underline`}>
        <a href={getRetrieveQuoteUrl()} className="text-white">
          <br />
          Retrieve a saved quote or enter mailing reference number
        </a>
      </div>
    </div>
  );
};
export default Products;
