export const purchasePolicy = {
  purchaseTxt: ' How to purchase an Auto policy from Stillwater:',
  purchaseOptions: [
    {
      txt:
        "Online: It's quick and easy. We ask a few questions and you'll" +
        ' have a quote in two minutes. You can purchase the policy' +
        ' instantly.'
    },
    {
      type: 'Umbrella',
      txt:
        'Call us at (855) 712-4092. Our licensed reps are available Monday' +
        ' through Friday from 8am-9pm ET, and 8am-3pm ET on Saturday.'
    }
  ]
};
