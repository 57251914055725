import {getBaseUrl} from '../../environment/Environment';
import {ServiceErrorResponseType} from '../../services/BaseMapper';
import ServiceRequest from '../../services/ServiceRequest';

export interface mapCoOrdinatesValues {
  lat: number;
  lng: number;
  agent: AgentValues;
}

export interface AgentValues {
  id?: string;
  fullName?: string;
  mailingNameLine1?: string;
  mailingNameLine2?: string;
  generalCode?: string;
  subCode?: string;
  producerCode?: string;
  email: string;
  address: Address;
  phoneNo?: string;
  emailAddress?: string;
  searchPriority: number;
}

export interface Address {
  buildingNumber: String;
  street: string;
  unit: string;
  city: string;
  state: string;
  zip: string;
}

const initialAgentStateAddressValues = {
  id: '',
  buildingNumber: '',
  street: '',
  unit: '',
  city: '',
  state: '',
  zip: ''
};

export const initialAgentStateValues = {
  fullName: '',
  mailingNameLine1: '',
  mailingNameLine2: '',
  generalCode: '',
  subCode: '',
  producerCode: '',
  email: '',
  address: initialAgentStateAddressValues,
  phoneNo: '',
  emailAddress: '',
  searchPriority: 0
};

export const mapsLink = (agentAddress: null | Address) => {
  if (agentAddress) {
    return (
      'http://maps.google.com?q=' +
      encodeURIComponent(
        (agentAddress.street ? agentAddress.street : '') +
          ' ' +
          (agentAddress.city ? agentAddress.city : '') +
          ', ' +
          (agentAddress.state ? agentAddress.state : '') +
          ' ' +
          (agentAddress.zip ? agentAddress.zip : '')
      ).replace(/'/g, '')
    );
  }
};

export interface params {
  zip: string;
}

export type ResponseMapper = AgentValues[] & ServiceErrorResponseType;

const retrieveAgentsUrl = getBaseUrl() + 'Common/search/agents';

export const retrieveAgents = async (
  values: params
): Promise<ResponseMapper> => {
  return await ServiceRequest.get(retrieveAgentsUrl, {
    params: values
  });
};

const getAlphabeticId = (index: number) => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return alphabet[index % alphabet.length];
};

export const addingIdToAgents = async (
  agents: AgentValues[]
): Promise<AgentValues[]> => {
  agents.map((agent, agentIndex) => (agent.id = getAlphabeticId(agentIndex)));
  return await agents;
};

export const getAddressString = (addressVal: Address) => {
  return (
    (addressVal.street || '') +
    (addressVal?.unit ? `, ${addressVal?.unit}` : '') +
    ', ' +
    (addressVal?.city || '') +
    ', ' +
    (addressVal?.state || '') +
    ', ' +
    (addressVal?.zip || '')
  );
};

export const retrieveAgentAddressLocations = async (
  addressVal: string,
  agentInfo: AgentValues
): Promise<mapCoOrdinatesValues> => {
  return new Promise((resolve, reject) => {
    new google.maps.Geocoder().geocode(
      {address: addressVal},
      (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results && results?.length > 0) {
            const geoCodeRes = results[0].geometry.location;
            resolve({
              lat: geoCodeRes.lat(),
              lng: geoCodeRes.lng(),
              agent: agentInfo
            });
          } else {
            reject(new Error('Geocode is not successful'));
          }
        }
      }
    );
  });
};
