import {useEffect, useState} from 'react';
import {navItems} from './AppNavBarTypes';

const MobileNavBar = ({
  items,
  activeOption,
  navClass
}: {
  items: navItems[];
  activeOption: string;
  navClass: string;
}) => {
  const [selectval, setSelectVal] = useState('');
  useEffect(() => {
    setSelectVal(activeOption);
  }, []);
  const handleChangeEvent = (e: React.ChangeEvent<HTMLSelectElement>) => {
    window.location.href = e.currentTarget.value;
  };
  return (
    <div className="container-fluid bg-darkblue">
      <div className={`d-block  ${navClass}`}>
        <select
          className="form-control"
          value={selectval}
          onChange={handleChangeEvent}
        >
          {items.map(item => (
            <option key={item.id} value={item.link}>
              {item.desc}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default MobileNavBar;
