import {Spinner} from 'react-bootstrap';

const PageLoader = () => {
  return (
    <div className="d-flex justify-content-center mt-5">
      <Spinner animation="border" role="status" variant="secondary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default PageLoader;
