export const automythTypes = {
  header: '5 Myths About Auto Insurance',
  paragraphs: [
    'Purchasing auto insurance can be confusing. Figuring out how much' +
      ' coverage you need, what company is best for you, and how much you' +
      " should pay intimidates people of all ages. We're calling out some of" +
      ' the biggest myths about auto insurance to help you feel less' +
      ' intimidated and more informed.'
  ],
  mythLeftOptions: [
    {
      id: 1,
      header: 'Myth #1',
      label: 'You only need minimum coverage. ',
      desc: `It's true that every state has its own minimum requirements for auto insurance. You're only legally required to carry the bare minimum car insurance for the state that you reside in. However, while minimum coverage is usually the cheapest, it typically only includes bodily injury and property damage liability. Liability refers to what you owe others when you hurt them or their property in an accident you cause. That means if you cause an accident, you may have to pay out of pocket for your own car repairs or your own injuries. That's why purchasing more coverage than just the state minimum is a good idea.`
    },
    {
      id: 2,
      header: 'Myth #2',
      label: 'Comprehensive coverage covers everything. ',
      desc:
        'The word "comprehensive" can be confusing because it implies that it' +
        ' should cover everything. But, in reality, Comprehensive coverage is' +
        ' for when your car is damaged by certain reasons other than a car' +
        ' accident - like when you hit a deer or hail pummels your car parked' +
        ' in the driveway. Comprehensive coverage will also reimburse you the' +
        " market value of your car if it's stolen."
    },
    {
      id: 3,
      header: 'Myth #3',
      label: 'A red car costs more to insure. ',
      desc:
        "This myth has been around forever and it's hard to pinpoint the" +
        ' origin. Some believe that red cars are more likely to be pulled over' +
        ' for speeding, resulting in insurance companies using car color to' +
        ' calculate your rate. The truth is that while insurance companies use' +
        ' things such as year, make, model, body type, and engine size to' +
        ' calculate the rate, the color of your car is not considered as a' +
        " factor. In fact, most insurance companies don't even ask for the" +
        ' color of your car when you apply for a quote.'
    }
  ],
  mythRightOptions: [
    {
      id: 4,
      header: 'Myth #4',
      label: 'Men always pay more for insurance than women. ',
      desc: `Some people believe that the origin of this myth is that insurance companies see men as riskier drivers than women. Although this may be true for certain age groups (like young men), it's not necessarily consistent across other age groups. In some states, insurance companies are not allowed to use gender as a pricing factor.`
    },
    {
      id: 5,
      header: 'Myth #5',
      label:
        'A friend who borrows your car and crashes it has to pay for the damages. ',
      desc:
        'This is false. As the registered owner of the car, you are' +
        ' responsible for damages caused by your automobile, even when your' +
        ' car is used by someone else with your permission. The only way that' +
        " your friend's insurance company will pay is if the damages exceed" +
        ' your limits. If your friend was injured and has personal injury' +
        ' protection (PIP) on their personal auto policy, they would use their' +
        ' own policy to cover those costs.'
    },
    {
      id: 6,
      desc:
        "The bottom line: you shouldn't let misconceptions about auto" +
        ' insurance stop you from getting the right coverage for you and your' +
        ' vehicle.'
    }
  ],

  policyType: 'Auto'
};
