import HomeUtils from '@components/utils/HomeUtils';
import {
  getAgentUrl,
  getCSSUrl,
  getQuoteUrl
} from '../../../../environment/Environment';
import HeaderLogo from './headerlogo/HeaderLogo';
import TopNavItems from './TopNavItems';

function TopMediumNavBar() {
  return (
    <div className="d-none d-md-block d-xl-none">
      <nav className="navbar navbar-expand-md navbar-light pt-5">
        <HeaderLogo />
        <div
          className="collapse navbar-collapse w-100 flex-md-column"
          id="navbarCollapse"
        >
          <ul className="navbar-nav ms-auto">
            <li className="nav-item nav-getQuote">
              <a className="nav-link " href={getQuoteUrl()}>
                <span className="text-orange">Get a Quote </span>
              </a>
            </li>
            <li className="nav-item text-nowrap">
              <a className="nav-link" href={getCSSUrl()}>
                Customer Log in
              </a>
            </li>
            <li className="nav-item text-nowrap">
              <a className="nav-link" href={getAgentUrl()}>
                Agent Log in
              </a>
            </li>
          </ul>
          <ul className="navbar-nav ms-auto">
            <li
              className="nav-item nav-getQuote"
              onClick={() => HomeUtils.redirectToPaperless()}
            >
              <span className="nav-link linkStyle">Go Paperless</span>
            </li>
          </ul>
        </div>
      </nav>
      <nav className="navbar navbar-expand-md  navbar-light">
        <div className="navbar-collapse collapse">
          <ul className="navbar-nav ms-auto">
            <TopNavItems />
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default TopMediumNavBar;
