import {Col, Container, ProgressBar, Row} from 'react-bootstrap';
import {reviewPercentageMap} from './CustomerFeedbackTypes';

const ReviewBarGraph = ({
  reviewPercentage
}: {
  reviewPercentage: reviewPercentageMap[];
}) => {
  return (
    <Container className="pt-4">
      {reviewPercentage.map((item, index) => (
        <Row key={index} className="mb-3 gx-3">
          <Col xs={1}>
            <span className="ratingFontSize">{item.label}</span>
          </Col>
          <Col xs={9}>
            <ProgressBar
              now={item.value}
              className="barHeight custom-progress-bar"
            />
          </Col>
          <Col xs={1}>
            {' '}
            <span className="ratingFontSize">{`${item.value}%`}</span>{' '}
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default ReviewBarGraph;
