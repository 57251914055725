import {environment as DevEnvironment} from './Environment.dev';
import {environment as IntEnvironment} from './Environment.int';
import {environment as LocalEnvironment} from './Environment.local';
import {environment as ProdEnvironment} from './Environment.prod';
import {environment as StagingEnvironment} from './Environment.staging';
import {environment as TrainingEnvironment} from './Environment.training';
import {Environment, ENV_TYPES, FirebaseConfig} from './EnvironmentTypes';

function getEnviromentType(): ENV_TYPES {
  if (process.env.NODE_ENV === 'development') {
    return ENV_TYPES.LOCAL;
  }
  const hostName: string = window.location.hostname;
  if (hostName.indexOf('qua.stillwaterinsurance') !== -1) {
    return ENV_TYPES.DEV;
  } else if (hostName.indexOf('staging.stillwaterinsurance') !== -1) {
    return ENV_TYPES.STAGING;
  } else if (hostName.indexOf('training.stillwaterinsurance') !== -1) {
    return ENV_TYPES.TRAINING;
  } else if (hostName.indexOf('int.stillwaterinsurance') !== -1) {
    return ENV_TYPES.INT;
  } else {
    return ENV_TYPES.PROD;
  }
}

const ENV_SPECS = {
  [ENV_TYPES.DEV]: DevEnvironment,
  [ENV_TYPES.INT]: IntEnvironment,
  [ENV_TYPES.LOCAL]: LocalEnvironment,
  [ENV_TYPES.PROD]: ProdEnvironment,
  [ENV_TYPES.STAGING]: StagingEnvironment,
  [ENV_TYPES.TRAINING]: TrainingEnvironment
};

export const getEnvironment = (): Environment => {
  const env = getEnviromentType();
  return ENV_SPECS[env];
};

export const getEnvironmentByMode = (mode: ENV_TYPES): Environment => {
  return ENV_SPECS[mode];
};

export const getBaseUrl = (): string => {
  const envObj = getEnvironment();
  return envObj['baseUrl'];
};

export const getAgentUrl = (): string => {
  const envObj = getEnvironment();
  return envObj['baseUrl'] + 'SalesPortal/';
};

export const getSecurePolicyPdf = (): string => {
  const envObj = getEnvironment();
  return envObj['baseUrl'] + 'edocs/view/security-policy.pdf';
};

export const getPrivatePolicyPdf = (): string => {
  const envObj = getEnvironment();
  return envObj['baseUrl'] + 'edocs/view/privacy-policy.pdf';
};

export const getQuoteUrl = (): string => {
  const envObj = getEnvironment();
  return envObj['baseUrl'] + 'quoting/';
};

export const getCSSUrl = (): string => {
  const envObj = getEnvironment();
  return envObj['baseUrl'] + 'ClientSelfService/';
};

export const getRegistrationUrl = (): string => {
  const envObj = getEnvironment();
  return envObj['baseUrl'] + 'ClientSelfService/signUpPage';
};

export const getRetrieveQuoteUrl = (): string => {
  const envObj = getEnvironment();
  return envObj['baseUrl'] + 'PublicQuoting/main/landingPage';
};

export const getFirebaseConfig = (): FirebaseConfig => {
  const envObj = getEnvironment();
  return envObj['firebaseConfig'];
};
