import PolicyInsuranceBtn from '@modules/knowhow/knowledge-base/PolicyInsuranceBtn';
import FindAnAgentListItem from '../../FindAnAgentListItem';
import RtrnKnowledgeBase from '../../RtrnKnowledgeBase';

const WaterFraud = () => {
  return (
    <div className="container mt-4">
      <RtrnKnowledgeBase />
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="asH3">
            How to Fight Back Against Water Mitigation Contractor Fraud
          </h1>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-6 pe-md-4">
          <p>
            As a homeowner, you know that there are many scenarios that can
            leave you with a damp mess to contend with, from leaks and
            malfunctioning appliances to storm damage and burst pipes. One of
            the primary concerns and responsibilities in these water-related
            emergencies is mitigation, a temporary step to clean, dry, and
            secure property that has been affected by water damage. And in many
            cases, it is necessary to hire an expert water mitigation contractor
            to help prevent further damage to your property.
          </p>

          <p>
            In these stressful emergency situations, it is easy to get taken
            advantage of by questionable contractors who do not have your best
            interest at heart when offering to simply get the job done as
            quickly as possible. That's why it's important for homeowners to
            know how to spot and fight against fraud when using an emergency
            service such as a water mitigation contractor.
          </p>

          <p>
            Keep the following tips in mind when interacting with your
            contractors so that you're equipped to combat questionable practices
            and ensure a smooth process in mitigating damage to your property -
            saving you both time and money in the long run.
          </p>

          <h4>Observe and document: before and after work is done</h4>
          <p>
            Keen observation and thorough documentation on the work contractors
            are performing is key to identifying potential fraud or questionable
            practices, while also giving you the necessary tools to fight back
            in case fraud does occur.
          </p>

          <ul className="custom-bullet square">
            <li>Immediately request copies of any signed documentation.</li>
            <li>
              Take photographs and retain video footage of vendors and equipment
              entering or exiting your property.
            </li>
            <li>
              Take photographs during pack out services, such as inside of boxes
              and where personal property is stored, especially if stored
              off-site.
            </li>
            <li>
              Immediately alert your adjuster to questionable activity or
              concerns about your contractor or other involved water mitigation
              vendors.
            </li>
            <li>
              Retain records of any communications with vendors, including text
              messages, call logs and emails.
            </li>
            <li>If possible, be present when vendors are completing work.</li>
          </ul>
          <h4>Recognize equipment commonly used by contractors</h4>
          <p>
            Familiarizing yourself with the equipment that water mitigation
            contractors commonly use can help you identify if your contractor is
            taking the necessary steps to mitigate damage and whether you are
            being billed appropriately. Examples include:
          </p>
          <ul className="custom-bullet square">
            <li>
              Negative scrubber - removes potentially dangerous particles from
              the air through a series of filters.
            </li>
            <li>
              Dehumidifier - takes moisture out of the air to help eliminate
              musty odors and mildew.
            </li>
            <li>
              Air Mover - facilitates drying by increasing the evaporation rate.
            </li>
          </ul>
        </div>
        <div className="col-md-6 ps-md-4">
          <h4>Be aware of trends in common repair scams</h4>
          <p>
            Being aware of common and trending scams can help you identify
            suspicious behaviors before they result in wasted time and money.
            Inform your adjuster immediately if you have concerns about your
            contractor. Here's what to look for:
          </p>

          <ul className="custom-bullet square">
            <li>
              Companies referring other service providers that are owned by the
              referring company.
            </li>
            <li>
              Contractors charging for services not rendered, equipment not
              used, or staff/technicians that were not present.
            </li>
            <li>
              Contractors submitting significantly inflated bills to the
              insurance carrier.
            </li>
            <li>
              Companies charging for travel time for unnecessary off-site
              storage out of state.
            </li>
            <li>Contractors using sub-contractors who are not licensed.</li>
            <li>
              Pack out companies charging for resetting personal property after
              repairs, yet no reset is completed.
            </li>
          </ul>
          <br />

          <p>
            There's no doubt that experiencing water damage to your property can
            be a stressful event. Your adjuster is a valuable resource to answer
            questions and assist you in determining if contractor work is
            appropriate for your claim. Don't let potential fraud cause any more
            headaches by being prepared and vigilant.
          </p>
          <p>
            You can report insurance fraud suspicions to the National Insurance
            Crime Bureau, a non-profit organization that partners with insurance
            companies and law enforcement agencies to help identify, detect, and
            prosecute insurance criminals: 800-835-6422. Or you can anonymously
            report your insurance fraud suspicions to Stillwater directly. Our
            Stillwater Fraud Hotline is 866-877-3697 or via email at
            reportfraud@stillwater.com.
          </p>
          <br />

          <br />
          <p className="fw-bold">
            How to purchase a Homeowners policy from Stillwater:
          </p>
          <ul className="custom-bullet square">
            <li>
              Online: It's quick and easy. Answer a few questions and you'll
              have a quote in two minutes. You can purchase the policy
              instantly.
            </li>
            <li>
              {' '}
              Call us at (855) 712-4092. Our licensed reps are available Monday
              through Friday from 8am-9pm ET, and 8am-3pm ET on Saturday.
            </li>
            <li>
              <FindAnAgentListItem />
            </li>
          </ul>
          <br />
          <PolicyInsuranceBtn policyType="Home" />
        </div>
      </div>
    </div>
  );
};

export default WaterFraud;
