import {baseEnvironment} from './Environment.base';
import {Environment} from './EnvironmentTypes';

const BASE_URL = 'https://stillwaterinsurance.com/';

export const environment: Environment = {
  ...baseEnvironment,
  prod: true,
  baseUrl: BASE_URL,
  googleMapApiKey: 'AIzaSyB_EUqma3S60TJNxIFRvZHshsCIgte1EIw'
};
