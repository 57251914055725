import {AppErrorServiceInitiator, ServiceMessage} from './BaseMapper';

export class AppError {
  messageMain: string;
  messageMainType: string;
  messageSuccess: boolean;
  messageList: Array<ServiceMessage>;

  constructor(message: AppErrorServiceInitiator) {
    this.messageMain = message.messageList[0].description;
    this.messageMainType = message.messageList[0].type;
    this.messageSuccess = message.success;
    this.messageList = message.messageList;
  }
}
