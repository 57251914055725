import {mobilePanel} from './KnowHowTypes';
import {Link} from 'react-router-dom';

const KnowHowMobilePanel = () => {
  return (
    <div className="d-block d-md-none">
      <div className="container-fluid">
        <h1 className="asH4 mt-2 fw-bolder">Know How to Insurance Articles</h1>
        {mobilePanel.map((panel, index) => (
          <div key={index} className="card  border-0 border-bottom  px-0 mt-3">
            <div className="card-header bg-darkblue py-2">
              <h6 className="text-white m-0">{panel.name}</h6>
            </div>
            <ul className="list-group list-group-flush text-14 text-lightblue">
              {panel.items.map((panelItem, panelIndex) => (
                <li
                  key={panelIndex}
                  className="list-group-item bg-whitesmoke pt-3 pb-3"
                >
                  <Link className="text-decoration-none" to={panelItem.link}>
                    {panelItem.desc}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KnowHowMobilePanel;
