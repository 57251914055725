import {useContext, useState} from 'react';
import {
  getLineOfBusiness,
  startQuoteService
} from '../../services/StartQuoteServices';
import ProductUnavailableModal from '../../modules/common/modals/ProductUnavailableModal';
import {PageLoaderContext} from '../../context/PageLoaderProvider';
import ThemeIcon from '../../icons/ThemeIcon';
import {ICON_LIST} from '../../icons';
import {ProductTypeContext} from '../../context/StartQuoteProductProvider';
import {getBaseUrl, getQuoteUrl} from '../../environment/Environment';

const StartQuote = ({page}: {page: string}) => {
  const {setLoaderActive} = useContext(PageLoaderContext);
  const {selectedProductTyp} = useContext(ProductTypeContext);

  const [formData, setFormData] = useState<QtStateValues>({
    zipCode: '',
    isDialogOpen: false,
    pageVal: page
  });

  interface QtStateValues {
    zipCode: string;
    isDialogOpen: boolean;
    pageVal?: string;
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const result = e.currentTarget.value.replace(/\D/g, '');
    setFormData({
      ...formData,
      zipCode: result
    });
  };
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      submitForm(e);
    }
  };

  const handleRemoveLastChar = (str: String) => {
    return str.substring(0, str.length - 1);
  };

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoaderActive(true);
    try {
      if (formData.pageVal === 'start' && formData.zipCode == '') {
        window.location.href = getQuoteUrl();
      } else if (formData.zipCode !== '') {
        const response = await startQuoteService({
          zip5: formData.zipCode,
          lob: getLineOfBusiness(selectedProductTyp)
        });
        if (response.data) {
          if (
            response.data.redirectUrl &&
            response.data.redirectUrl == handleRemoveLastChar(getBaseUrl())
          ) {
            setFormData({
              ...formData,
              isDialogOpen: true
            });
          } else {
            window.location.href = response.data.redirectUrl;
          }
        }
      }
    } catch (err) {
      setFormData({
        ...formData,
        isDialogOpen: true
      });
    }
    setLoaderActive(false);
  };

  const closeModal = () => {
    setFormData({
      ...formData,
      isDialogOpen: false
    });
  };

  return (
    <>
      <ProductUnavailableModal
        open={formData.isDialogOpen}
        close={closeModal}
        dialogClass={
          formData.pageVal === 'start'
            ? 'product-home-modal-dialog'
            : 'product-modal-dialog'
        }
      />
      <form id="getQuoteForm" onSubmit={submitForm} autoComplete="off">
        {formData.pageVal === 'start' ? (
          <div className="row g-0">
            <div className="col-md-5 mt-2 ms-md-3 mt-sm-0 text-center pe-md-0">
              <textarea
                id="zipcode"
                name="zipCode"
                className="input-custom-lg pt-2 pt-sm-2 mb-2 pt-md-3 pb-3 pb-xl-2 w-100 input-textarea"
                maxLength={5}
                placeholder="Select your product above and enter your ZIP code here."
                inputMode="numeric"
                onChange={handleInputChange}
                value={formData.zipCode}
                onKeyDown={handleKeyDown}
              ></textarea>
            </div>
            <div className="col-md-5 text-center ps-md-0">
              <button
                className="btn btn-orange-lg text-white py-3 w-100 getQuoteBtn"
                type="submit"
              >
                <span className="clipBoard">
                  <ThemeIcon
                    iconName={ICON_LIST.CLIPBORADCHECK}
                    width="20"
                    height="25"
                    fill="white"
                  />
                </span>
                <span className="ps-2">Get a Quote Today</span>
              </button>
            </div>
            <div className="col-lg-2 d-none"></div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-6">
              <input
                type="text"
                className="input-custom-lg py-3 w-100"
                id="zipcode"
                placeholder="Enter Your ZIP Code"
                maxLength={5}
                value={formData.zipCode}
                inputMode="numeric"
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="col-md-6 ps-md-0">
              <button
                className="btn btn-orange-lg text-white mt-2 mt-sm-0 py-3 w-100"
                type="submit"
              >
                Get a Quote in minutes
              </button>
            </div>
          </div>
        )}
      </form>
    </>
  );
};
export default StartQuote;
