import KnowHowHeader from '../knowledge-base/KnowHowHeader';
import KnowHowMobileHeader from '../knowledge-base/KnowHowMobileHeader';
import './glossary.scss';
import GlossaryBody from './GlossaryBody';
import PageTitle from '@components/form/PageTitle';

const Glossary = () => {
  return (
    <>
      <PageTitle>Insurance Term Glossary | Stillwater Insurance</PageTitle>
      <KnowHowHeader activeOption="Glossary" />
      <KnowHowMobileHeader activeLink="/glossary" />
      <GlossaryBody />
    </>
  );
};

export default Glossary;
