import {ICON_LIST} from '../../icons';

export const ProductsData = [
  {
    image:
      'data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAeCAYAAABJ/8wUAAACOUlEQVRYhe2WO2hUQRSGv92sImiaBBSRYGIpGoQQX1iJWlqINhrtbERT2CmKjWhjF+xEIRKwUARBLMRSIja+sbAINolBEfERFM3+MnpGJzdzN7v3sVj4w2G4M+fx3bnzuEjKYwckTUqakjSUJ1ceiHOar/PtBKlKuhEgnJF0Oni+KalWNkivpGdB0WPB2JGg/4WkNWWB7JD02Qp9kbQz4rNd0ifzmZG0q2iQo8Hbuhnpa+C7WtKTwH+4KJCRxPfvaCKmIul6EHcxD8gySXeDZFl2xNkg/p6kzlZB1kmaCJIcygDhbSjI81pSf7MgeyTNWuC0pM05ILxttEPPqS5p70IgJwP6cUnLC4Dw1i3pfpD/VBrIaODktK9AiHC2Q435sSrQCzwADvJb09Z2Uby6EjX2Aw+BPgfyGNgEfAe2ArfMqVoCiM95G9gCfAMGgadu4DLwEugHxoGeEgCS6rGvsN5qX6kBx828OtoA4mu8AtaSMv1qA8i8GmWsg0yqZQjqtnW1MngRmc0Ck8Bh4H0rSbOAuFW+ewGfS8CdskF+WPsWGAYqZm52LgArbGZiqhQJUrf2A3AtMXbCQOqRuIbKs1gXRfoWZ032z+ya/yBJNQJ5l9I/ZW1sB/i+Ny3mjO4an2wAmIiMD1obuwp83zZbzD6X3+IDiRp/i/76O5qrR8CGNPJAM8DSRN9HoLOJ2Od28/5RbEauAquAJSk3sTsj3H9E8gxxGrOfHRfrPrs/+r25w/ArMDonCvgJVSVMDdvoN+8AAAAASUVORK5CYII=',
    buttonName: 'HOME',
    height: '30',
    width: '34',
    x: '3',
    activeClass: 'carousel-selected text-white',
    nonActiveClass: 'btn-carousel text-white'
  },
  {
    image:
      'data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAeCAYAAABe3VzdAAADiklEQVRYhbWYW6hVVRSGv+052vHaSTRD1EzUlAIjqRBNzKMm5SVEtF56yBexwDAK7EEoq7ceitIHJV+FvIGKLwpHsQshPqlwSikxtcxU1KN2vPwy9Z8xXera6+y99oDJ3PM2xr/+NeYYY+2KJArKeGAZ0AYMBXoUPWi5BZwG9gBrgcOFTgWABdpSlS/vFbFdhMGFwPfJeCfQAdzsJoNNwFhgTjL3FrCxHgZ7S+oyd52SphZkPK9NlnTZOm9K6pu3v5qyt5MXu7AEcLHNS/QuqQfgLis5UyK42E5Z9+68fc1+01OBd4DHMx4w2/3WbvpbEdkCvOuosCt4W3LmH2AD0B5QzipwP9sawODLBey+Hm7xcWAEcAb41U9yFXgBeAz4ExjeAAaD/A6MBC4AvwC9gQowBhgCnCRBuzbzhH97fp2kJkl9Sm7NktbYxtmM7a8jqMDgz8BLZjAw2Qp8BSz2U56tIWsUlZBdBnnvJmC5cZwAngAOBIDhguz1puCcjwK9GgSomlwHzieXdUbMJF8AK4BHEgUhe/zmjKEGsHjL/hYyzGhgUbLW5bf4UfreLyUxb0IDbm219qykv5KsdU+gfj+5LJMTRWMlfSppvaQPJA0uAcggSSusc7WkccnaiwmOD1OAWzzZkWye9oC4FPLygjrAzZd0tUqcPey57WEc/Sr63in3laSC+QSYB3wD9AQ2O0Z2V54HtgEtwBrrXOW1TYmPRwx3L6pRbzPq/R63eHwuw8BMz/9UA3v7fPa1zHz0u/4et3u8I83FUSru/3P8CzHqM2AfcAMY5fUhNTAYQ0fIHNPhju0p1hUySWcGAw9i8IcqPhjljRoYnJujb0ayb28eg6m0uwJ+E3jK8yHCbw8RvgYGw7mJ9r0nPfeHK+qOhx3KArySGYdAvboGMA+Tg2550pmuZQGOAz7PzCmp1Sr3+Uj3JU9fmH8mD+Aw4OM6AZQqWYAh717yU8kxq8VrF5P8WY/EvD7AOq65RZv9UlzxR5P7/cBMB8mweSnwpUPMcy6FygA4EDhqOyFYf2u9XS7/2yKmCLCn+1aXPNc9juw1u/otSzoTUvpkLmer+14pwB+BV4EJTkdHgP7AEq8fKRFclEO2txIYbBd62qEoYvo/UIcP9GM5gXRKA8qrSTn2jkvql1YzobVK+k7SSUkXnYf3SHqlgTVg+KcifBf/a5vhW3mDpIF31iVuA1VfLMqZE4R2AAAAAElFTkSuQmCC',
    buttonName: 'AUTO',
    height: '30',
    width: '40',
    x: undefined,
    activeClass: 'carousel-selected text-white',
    nonActiveClass: 'btn-carousel text-white'
  },
  {
    image:
      'data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAeCAYAAAAy2w7YAAACFElEQVRIibWWu2tUQRTGf5usJkE3CGIaQSTBF4GAVUBrtbKUlPlPBEmTOk3QJkWwsLRTMKl2YTepbBTdNK4xKWwM2VzNc+Ss54bZITOzk71+cO89556Z8zFzHjMYY+QZNMYsG2MyUxx+G2NeG2PKwlGSFzABbPAPuxSDinq5A3wtq3JBv2vAdEFENeBB7ntAfx473yLQ5XPAcThYIFGXr7J/XAcSu0cqt4E3wKE1d8aKxQrQ9HrSrLuludZQPX8WnRycsmyTju2VM7em/++K7m5dDMOWfThlYozowNEzj4y1pWciFqN5YBUoAXvAJ8v2ReN3WSIArPdDtA289dhOgA+R+aeIbd0zoKrF9x64ZtmuAu/UVtUM9CK2oqfAQ0sfB36qfBN4Ytlamv7nWlGoU5QSxkZX1AAeq1Mp2B+WbUuapRasJEM96ClSsP08XQUbW5F03hu6oj/ApmO/Dozoir6fUXeniMXouZ5TTXV027JNKHFTx7wIOYoRjTn6FY+Mk/rJRBcd/cgjC4ZCjmIxegnsqNzWtpNDtmwOGFXdW0O9ENUDaZtpDHtCjMjFLHBf/30Elv4X0YK1VVkKUerB17LkbykTU4lOPHLPRG39xq5bKUS5rz2baMQh9sHu2G73dpH76twt5EosR/A94JIafgUmu92gl7GSNJ+FqHP51ju3dIJQhUtjzS8h0nBDN6F9bbIVgL/ITXJb1qmIAAAAAABJRU5ErkJggg==',
    buttonName: 'RENTERS',
    height: '30',
    width: '26',
    x: '7',
    activeClass: 'carousel-selected text-white',
    nonActiveClass: 'btn-carousel text-white'
  },
  {
    image:
      'data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAeCAYAAABJ/8wUAAACr0lEQVRYhdWX34tNURTHP3fuzAPGGFM8oKFh1NX8BTwqKTNFXpQXD5IHD148eFCU8Uh44T/QlEIhv/LgRwqTGjEMJj9KU2bmIpQZs7SudThnOfece47rxrdOd6/9XXvvdddea+29CyIyAcwH3gPNwBwag0/ANDAPKOvCZTNEO2aAD0DhL5siQCvQZPIkIqJfp/zAY5Mb8Q3Zml26VnOK5UVglVmvnnpk/ybAQqDL2qPAmBuvY9uAzzZ2uop3SPPINolio+Pvh9gHjut1Y7c7PuKRphgLw2hzcmuC7Lm5KXIEaYZ8c/JMgpzExckRpMXIOWAp0GL7fNXx+4C11r7uuGtAv5UDjY0ziSv9L1nTDey37PliHngT4ncAvda+AJwIcYuAg+YR3eIDwJO8HtnlIn+r44dD3Ijjtrixu/8kazzvK26hRi5OTlzI452TJ5w8XqXtOWLmiiAtRk4Bg7bPH4Gnju+zrFK8ctxlYGWosvqxmQzR3B9O4Mdj/nkYIynz12xIGrS+LLb912yayjtRWoxUw2rgIvDVDrsX1r4ErGmUIQPALWC9VcyHwJB5Yx1wEzidedaMlfWG6Y2JyOYYfpOIvDWd21kqaxZDjpjOoIi0JOgVReSu6R6v1ZBCxRroBF5ahpRiHNcdSr9lpjsL2AAssH6tE+ctVZcAr62/VCXzdDt7gOWVGKvRI3uMP2pyu4g8l98xKiIdpnPY2L31OPQCXAGOASdN7rcrol4LzlpfnwXrIWCn6RYtk+oerMF3x/R7Qn0l67uX5xqQt44U7TdcwKYclwl5DQmQdPo21JB6QP4VQyqe1DqiJ+QK69TTVt/APxVioDr6PNVY0KtBEBuagXrk67Uw7dmqXlDdIJ6eqSGTQHvGR7gWLTVA3yqBV9VANUxP5Nk1zPHrEQ7l7zoZ9v6eNJRiAAAAAElFTkSuQmCC',
    buttonName: 'CONDO',
    height: '30',
    width: '34',
    x: '3',
    activeClass: 'carousel-selected text-white',
    nonActiveClass: 'btn-carousel text-white'
  },
  {
    image:
      'data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAeCAYAAACrI9dtAAABXElEQVRYhe1X0XGDMAx96WWAZgNGIBvQDdigHaGZoGzQEdJO0GxAN2g2SDoBbOCc7qTEKDZ1Tez88O4EHNbpPWxLFjDGuKw2xnQmLTrmueJf0EXhEcAPgEIPJMARwBpAb4d+cPC8siByXAFYJLAVxy+Ybwg1dYW1bI1naW9lDfN0zHuOqwm27HhILEjswHxbn6jK2tbODZjAaouzcolqebDNJMjLKwO24jKzqFKv0OjaZrTBXh7Ngow2yHpkLAF/2XlyqKJLSd/rypoZdJKUREmivgDUdxSjsZOzr+KBZwAvPGubyKDv/MUbjhPi+wHgk999L+WB7yKut979F7IF9gExxPfX9nUdyHfHLCoUS48f9TlNZMzJzeGYqLepwWPhE9UHpLMPJRfCm4siQU+RMVurtERhzr5QzKJCMYsKhS4JR75TSl/9z+eCnqndhKJpg9qQkNZHWpdLxwvgBGOPVxEItdRRAAAAAElFTkSuQmCC',
    buttonName: 'BUSINESS',
    height: '30',
    width: '37',
    x: '1',
    activeClass: 'carousel-selected text-white px-1',
    nonActiveClass: 'btn-carousel text-white px-1'
  },
  {
    image:
      'data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAABt0lEQVRIib1Wi03DMBA9oQ6QDegG9QaEDcIEDROUEbIBYoLABC0TFCZomYB0gnSDQ5beRRfXre18eJJlJznfy/P5ziZmHtNyZq6Z+ZcvsWfmkpkzn/+hpAaOY9AyczEFcQlngi3eLfE9w0q8Onb1GOJcOTrg+ZZ9BsIL8hTSTCk4XIsdml3aSv1YpciLVGL58zZAqhVqlb35KWoFZUCpOK/UChlnxco7ikMBq4aI3m/MMOjfiKhCT5h/VnPXi0jiFfqvgN0R/cbp5f0nEb0QUR671JKzVcJe8KVRF7JYxSl4hjIDpTs19yyDOYgJZDvP+6UMYjfXN/pVwC6EHN+b2LosadAOKLG6bSXuqaRmBKkutzd39ZSkGcosI0Oulsy5SDtfc5O6R2hXbt10srm3J6IMOfeoqo4PYm8r2o/6fo8dLOljfT31Kt8IpcZRcw217zRbJCotlYqNsreHwYOyO2IFdrpa9ZCo1L1nDd4Di8SYCuzxdoKiGHuvYkHMn4vi0F0rSrGOiy+ma/VsPDaDEDqdlqqwazRzEwtsTD8wbv6T+BRx7RlMzFM6DsFeBELpYNNsUrVERH/UE0oW1UaMDwAAAABJRU5ErkJggg==',
    buttonName: 'LANDLORD',
    height: '30',
    width: '30',
    x: '5',
    activeClass: 'carousel-selected text-white px-1',
    nonActiveClass: 'btn-carousel text-white px-1'
  }
];

export const xmlIns = {
  name: 'http://www.w3.org/2000/svg',
  link: 'http://www.w3.org/1999/xlink'
};

export const Products2Data = [
  {
    image:
      'data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAeCAYAAAA/xX6fAAABr0lEQVRIibWX4XHDIAyF1U5AN/AIbFB3g4zgETJCNvAIGcEj+DpB0gnSDZwNXo8cOK8yGEgc7vgREO+zhEBEANT0DsCIexv9WLFGiVED4ADggnS7eJvmGWAL4BhBuDHre2q+LQUaHyLtjfu99/OxNfvEmk6v4bD1ACa1aACwq9ijndpjeM0+hDskQtLgwZ5yoBMajIbgya63aHrDzc9b+xCRq7ymGRGZnPI7yW8Jsx6y0GagkW1aJyInEbnEtBloN8A52DHh8Qw8b+Qhw1wIv2guaJ8d8Fd/xUawM80H7asD/vgfny+CsfZ3uDND4zPY+/NjV84iXxpTwtaQzS4MzgNkmBMqgYXrbnYoDA50d5YIlsIW2jGBJiNcA2v4HtXlKdx3fSZ0pbCQB8F2UQ8PCS9TFSUHY+8OMaAhD4aIQFcB472bOPvXMi9WeG1h+eLM/PfIihmP9GWPFOGGIjXq+dyCU2VBNn7NIpRrQFG3TymUYUjt85pAVwHVsOTjOPfV+gzG3pstbcGUe4mX7ItVB37wEEupX3xcapKBxXVzc0XJVZvyzjP9Zyb5rF90QP4AHmZgKuB6K8kAAAAASUVORK5CYII=',
    buttonName: 'UMBRELLA',
    height: '30',
    width: '28',
    x: '6',
    activeClass: 'btn-carousel bg-transparent text-white umbBtnWidth',
    nonActiveClass: 'btn-carousel text-white umbBtnWidth'
  },
  {
    image:
      'data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAeCAYAAABJ/8wUAAAB2UlEQVRYhb1XgXHCMAz8Y4KMkBFgAzNB0w2yQWEC2gnSDUInKBskG4QNYIOwgXtu5UMVjpGd0r/TOZwd6/P6yAHW2jnR2CuaOXvl3lhYawd7i4Hm/oXI0lo7MgodhcdIax5KpBYa8HI0Yq6OPIiRyqWQaMVThxLVQq02sMarZ1KJSD+c7ki/pDUe0jdZREJ+0JixiPgmmUjMD9oI+SaJiMYPKQYf7S2iRFL9oA3pm6giuX7QRsw34PJxDJHNK9bEctSSHbn2RFp7iy6yEVctp4t2gXztAkCFH5wBbHEfBVvhrjsAS8V9ElvK6VA5ImsABwArAEfFBnsajxS5ZI6U8/DNQchmmFGnpDVM0oq9DdoyBfvIIkPSnsLhCcAzgMvMMmURcfigsSYS67lkconsmdFeqN6zyOQScXijsabkksyneMMeRqSksSAyEGRKUkZFJoeIT7BjyXs2z8m48jSPILIBMAAw9Pt9ov/Ipncfyj4iD6yT7ANsHf8GCfUW1ffIFBH5/yV0Khtx1Ls9yr9uaF7ens6Ii5h/Jd+UNLcln5wDewWhJeIbmGFvi8dGGHdF3kmClkjPnm4n5nhzu6So8AuRAy0G6RHtfRxRj8jaT8GVQyqWWo5rLgBf0dij98lpuKsAAAAASUVORK5CYII=',
    buttonName: 'EARTHQUAKE',
    height: '30',
    width: '34',
    x: '3',
    activeClass: 'btn-carousel bg-transparent text-white landLordBtnWidth',
    nonActiveClass: 'btn-carousel text-white landLordBtnWidth'
  }
];

export interface ProductFields {
  image: any;
  buttonName: string;
  height?: string;
  width?: string;
  x?: string;
  activeClass?: string;
  nonActiveClass?: string;
  btnWidth?: string;
}

export interface ProductOption {
  value: string;
  label: string;
  image: any;
}

export const products = [
  {name: 'Home Insurance', value: 'home', image: ICON_LIST.HOME},
  {name: 'Auto Insurance', value: 'auto', image: ICON_LIST.AUTO},
  {name: 'Renters Insurance', value: 'renters', image: ICON_LIST.BUILDING},
  {name: 'Codo Insurance', value: 'condo', image: ICON_LIST.BUILDING},
  {name: 'Business Insurance', value: 'bop', image: ICON_LIST.BUSINESS}
];

export const ProductOptions = products.map(product => ({
  value: product.value,
  label: product.name,
  image: product.image
}));

export interface StateValues {
  btnType: string;
  panelType: string;
  selectedProduct?: any;
  selectedProductVal: string;
}

export const productImage = (image = 'transperant') => ({
  alignItems: 'center',
  display: 'flex',
  borderStyle: 'none',
  color: 'white',

  ':before': {
    backgroundImage: 'url(' + image + ')',
    backgroundolor: 'rgba(255, 102, 0, 1)',
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 20,
    width: 20
  }
});

export const GradeInfo = [
  {
    intialText: 'Better Business Bureau ',
    boldText: 'A+',
    lastText: ' Rated Business'
  },
  {
    intialText: 'AM Best ',
    boldText: 'A-',
    lastText: ' Rated Financial Strength: Excellent *'
  },
  {
    intialText: 'Demotech, Inc ',
    boldText: 'A',
    lastText: ' Rated Financial Stability: Exceptional *'
  },
  {
    intialText: 'Jacksonville Business Journal: Best Place to Work'
  },
  {
    intialText: 'Better Business Bureau: Torch Award for Ethics'
  }
];

export interface GradeInfoFields {
  intialText: string;
  boldText?: string;
  lastText?: string;
}

export const cardInfo = [
  {
    cardClass:
      'card border-transferent border-bottom-0 border-top-0 border-start-0 mb-4',
    image: ICON_LIST.HEART,
    alt: 'heart symbol icon',
    cardHeader: 'Our Customers love us',
    cardDescription:
      "We're kind of a big deal with our customers. They are not shy in letting us know when we get right, and " +
      'we are not shy in letting everyone in on the love. We even share the feedback from the very rare occasions when we ' +
      "don't get it right. See for yourself by clicking below.",
    btnLink: '/about-our-customers',
    btnText: "Read what they're saying about us"
  },
  {
    cardClass: 'card border-transferent border-0 mb-4',
    image: ICON_LIST.LIGHTBULB,
    alt: 'light bulb on icon',
    cardHeader: 'Know your policy',
    cardDescription:
      'Insurance is complicated. We know - we work with it every day. So here are a few tips on how to understand the ' +
      "basics and make sure you are comfortable with your decision. This information is free. (You're welcome.)",
    btnLink: '/know-how',
    btnText: 'Know How'
  },

  {
    cardClass:
      'card border-transferent border-bottom-0 border-top-0 border-end-0 mb-4 card-d-md-none',
    image: ICON_LIST.USERGROUP,
    alt: 'group user symbol icon',
    cardHeader: 'Join our Team!',
    cardDescription:
      "We're looking for people who want to join our collaborative team and grow in their career. We offer casual " +
      'work environment, health, vision and dental benefits, 401K and gym/fitness center reimbursement.',
    btnLink:
      'https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=669ce1b1-0baa-4717-afd9-6652f4141f3d',
    btnText: 'Find a Career'
  }
];

export const leftArrow = {
  image:
    'data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAeCAYAAAAhDE4sAAAAtElEQVRIiWP4//+/wP///8//h4D5////ZyAHMyAZ8p8Swxj+Ywf7oS4lyaB+HIadJ8UwGKOAUsOQOQmUGIYuQLZh2ATxGaZAikH4DHv///9/A1IMAuEAqEaiDCMUGwbEGkZMGiHKMGJTLkHDSMlP+AwLIMUgmGH3sRnGxEAtQCWvOdA1sKkS/VRJkAnUyCJUybRklUlUMQTdIKoUtQ2UGPKf2tURNrCenAqSalU25Y2I//8ZAKDIwicPQYdOAAAAAElFTkSuQmCC'
};
export const rightArrow = {
  image:
    'data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAeCAYAAAAhDE4sAAAAs0lEQVRIia3VWw3DMAyF4WoIAmEQCmEQCmEQBmEMBmEQBmEQAmEQCuGfqslSH5zUXo6l8xZ9UpyLJ+DfPPlVBcooYlWzQAFeOJVFqocADwVy39aMIldbJ0GOoDDSg2ZgjSItqIWsLcSDesjc66cE2UNDiEGXUcQgD/lkkC2nSVXAotqarNnS45deyAi2ZCDZo7WcFd+IRfKxpbDoE+hhtwx0hIXH0R57e1IWsgyPbA+rQPkC2PXCJz4rVBcAAAAASUVORK5CYII='
};
