import PolicyFAQ from '@components/pages/PolicyFAQ';

const CondoInsFAQ = () => {
  const condoFAQData = {
    title: `Does condo insurance cover personal injury?  `,
    header: `Condo Insurance FAQ`,
    leftParagraphs: [
      {
        id: 1,
        label: `Does condo insurance cover personal injury? `,
        desc: `Yes. Condo insurance protects you from liability claims and lawsuits if a guest gets injured in your home. However, you typically don't need liability protection for claims involving building common areas - if a visitor slips in the pool area or falls down in the lobby entrance, your HOA insurance will typically kick in.`
      },
      {
        id: 2,
        label: `What does my Condo Policy cover as opposed to my Home Owners Association policy?  `,
        desc: `Condo insurance covers the inside of your condo while the external areas like the pool, hot tub, elevators and the building itself is covered by the Home Owners Association insurance policy.`
      },
      {
        id: 3,
        label: `Does my condo insurance cover theft?  `,
        desc: ` Yes. The Personal Property coverage on your Condo Policy is designed to cover theft of personal belongings.`
      },
      {
        id: 4,
        label: `Is condo insurance cheaper than home insurance? `,
        desc: `Yes. Condo insurance protects the interior of the unit as opposed to the external structure. This accounts for the typically significant cost difference between a Condo Policy 
							and a Homeowners policy, since the latter protects the entire structure.`
      },
      {
        id: 5,
        label: `Do I need loss assessment Coverage? `,
        desc: `Condo insurance provides an option to purchase this valuable coverage. Loss Assessment Coverage protects you if your HOA surpasses the limits of its master policy in the event of a 
							catastrophe like a hailstorm. In this instance, each unit member may need to contribute funds to make up the difference between the coverage limits that the HOA policy has, and 
							the actual cost to repair/replace. If you have purchased loss assessment coverage, this sum may be partially or totally covered by your insurance company and not something you  
							would have to pay out-of-pocket.`
      }
    ],
    rightParagraphs: [
      {
        id: 6,
        label: `What's an "all-in" condo master policy?  `,
        desc: `Your condo association has a Home Owners Association master policy that covers the exterior of your complex and sometimes include coverage for the interior of your unit as 
							well. An "all-in" condo master policy means that fixtures in your condo, such as appliances, wiring, plumbing and carpets are covered under the master policy. It does not cover personal property.`
      },
      {
        id: 7,
        label: `What's a "bare walls-in and wall studs in" condo master policy?  `,
        desc: `Your condo association has a Home Owners Association master policy that covers the exterior of your complex and the bare structure of your unity. It will not provide coverage for your condo's interior contents - including bathroom and kitchen fixtures and countertops - in addition to your personal property.`
      }
    ]
  };
  return <PolicyFAQ faq={condoFAQData} />;
};

export default CondoInsFAQ;
