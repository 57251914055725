import {
  getAgentUrl,
  getCSSUrl,
  getQuoteUrl
} from '../../../../../environment/Environment';
import HomeUtils from '../../../../utils/HomeUtils';
import leaf from '@images/icons/svg/leaf.svg';

function NavQuoteItems() {
  return (
    <>
      <li className="nav-item nav-getQuote">
        <a
          className="nav-link  text-orange border-lightgray"
          href={getQuoteUrl()}
        >
          <div className="text-orange">
            Get<span className="ms-1">a</span>
            <span className="ms-1">Quote</span>
          </div>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link " href={getCSSUrl()} id="customerLogIn">
          Customer
          <br />
          Log<span className="ms-1">in</span>
        </a>
      </li>
      <li className="nav-item ">
        <span
          className="nav-link linkStyle"
          id="goPaperless"
          onClick={() => HomeUtils.redirectToPaperless()}
        >
          <img className="leaf-btn-bg" src={leaf} alt="" />
          <span className="leaf-btn-content">
            Go
            <br />
            Paperless
          </span>
        </span>
      </li>
      <li className="nav-item">
        <a
          className="nav-link text-nowrap"
          href={getAgentUrl()}
          id="agentLogin"
        >
          Agent
          <br />
          Log in
        </a>
      </li>
    </>
  );
}

export default NavQuoteItems;
