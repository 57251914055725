import {useMemo, useState} from 'react';
import {FaqStateValueTypes} from './FAQTypes';
import AutoInsFAQ from './auto/AutoInsFAQ';
import BusinessInsFAQ from './bop/BusinessInsFAQ';
import CondoInsFAQ from './condo/CondoInsFAQ';

import HomeInsFAQ from './home/HomeInsFAQ';

import RentersInsFAQ from './renters/RentersInsFAQ';

import UmbrellaInsFAQ from './umbrella/UmbrellaInsFAQ';
import EarthQuakeInsFAQ from './earth-quake/EarthQuakeInsFAQ';
import LandlordInsFAQ from './land-lord/LandlordInsFAQ';

const FAQDropDown = () => {
  const [faqState, setFaqState] = useState<FaqStateValueTypes>({
    policyType: 'autoFaq'
  });
  const changeFaq = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFaqState(prevState => ({
      ...prevState,
      policyType: e.target.value
    }));
  };
  const renderFaq = useMemo(() => {
    const value = faqState.policyType;
    switch (value) {
      case 'autoFaq':
        return <AutoInsFAQ />;
      case 'bopFaq':
        return <BusinessInsFAQ />;
      case 'condoFaq':
        return <CondoInsFAQ />;
      case 'eqFaq':
        return <EarthQuakeInsFAQ />;
      case 'homeFaq':
        return <HomeInsFAQ />;
      case 'landlordFaq':
        return <LandlordInsFAQ />;
      case 'rentersFaq':
        return <RentersInsFAQ />;
      case 'umbrellaFaq':
        return <UmbrellaInsFAQ />;
      default:
        return <AutoInsFAQ />;
    }
  }, [faqState.policyType]);
  return (
    <>
      <div className="row mb-3">
        <div className="col-md-6 col-lg-4 col-xl-3">
          <select
            className="faqSelect"
            value={faqState.policyType}
            onChange={changeFaq}
          >
            <option value="autoFaq">Auto Insurance</option>
            <option value="bopFaq">Business Insurance</option>
            <option value="condoFaq">Condo Insurance</option>
            <option value="eqFaq">Earthquake Insurance</option>
            <option value="homeFaq">Homeowners Insurance</option>
            <option value="landlordFaq">Landlord Insurance</option>
            <option value="rentersFaq">Renters Insurance</option>
            <option value="umbrellaFaq">Umbrella Insurance</option>
          </select>
        </div>
      </div>
      {renderFaq}
    </>
  );
};

export default FAQDropDown;
