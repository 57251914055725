import {getBaseUrl} from '../../../../environment/Environment';
import {ServiceErrorResponseType} from '../../../../services/BaseMapper';
import ServiceRequest from '../../../../services/ServiceRequest';
import {reviewDetails} from '../CustomerFeedbackTypes';

const reviewListPerPageUrl = getBaseUrl() + 'Common/reviews/customer/list';

export interface reviewParams {
  pageNo: number;
  reviewsPerPage: number;
}

export const retrieveReviewsPerPage = async (
  values: reviewParams
): Promise<reviewDetails[] & ServiceErrorResponseType> => {
  return await ServiceRequest.get(reviewListPerPageUrl, {
    params: values
  });
};
