import {useContext, useEffect, useState} from 'react';
import {
  retrieveTotalReviewCount,
  reviewDetails
} from '../CustomerFeedbackTypes';
import BgBluePanel from '@components/panel/header/BgBluePanel';
import {retrieveReviewsPerPage} from './CustomerReviewServices';
import {Pagination} from 'react-bootstrap';
import CustomerReviewQnsAnS from './CustomerReviewQnsAnS';
import {PageLoaderContext} from '../../../../context/PageLoaderProvider';
import {logFirebaseEvent} from '../../../../analytics/firebase/FirebaseAnalytics';
import {EVENT_LIST} from '../../../../analytics/firebase/EventMap';

const CustomerReviews = () => {
  const {setLoaderActive} = useContext(PageLoaderContext);

  interface customerReviewFormType {
    data: reviewDetails[];
    limit: number;
    activePage: number;
    totalPages: number;
  }
  const [reviewForm, setReviewForm] = useState<customerReviewFormType>({
    data: [],
    limit: 10,
    activePage: 1,
    totalPages: 0
  });

  useEffect(() => {
    setLoaderActive(true);
    (async () => {
      const response = await retrieveReviewsPerPage({
        pageNo: 1,
        reviewsPerPage: reviewForm.limit
      });
      const totalReviews = await retrieveTotalReviewCount();
      setReviewForm(prevState => ({
        ...prevState,
        data: response,
        totalPages: Math.ceil(totalReviews / 10)
      }));
    })();
    setLoaderActive(false);
  }, [reviewForm.limit]);

  const handlePageChange = async (pageNumber: number) => {
    setLoaderActive(true);
    const response = await retrieveReviewsPerPage({
      pageNo: pageNumber,
      reviewsPerPage: reviewForm.limit
    });
    logFirebaseEvent({
      eventName: EVENT_LIST.CUSTOMER_REVIEWS,
      message: 'Customer Reviews for page ' + pageNumber
    });
    setReviewForm(prevState => ({
      ...prevState,
      data: response,
      activePage: pageNumber
    }));
    setLoaderActive(false);
  };
  const renderPaginationItems = () => {
    const items = [];
    items.push(
      <Pagination.Item
        key={1}
        active={reviewForm.activePage == 1}
        onClick={() => handlePageChange(1)}
      >
        {1}
      </Pagination.Item>
    );
    if (reviewForm.activePage > 3) {
      items.push(<Pagination.Ellipsis key="elipses1" />);
    }
    for (
      let num = Math.max(2, reviewForm.activePage - 2);
      num <= Math.min(reviewForm.totalPages - 1, reviewForm.activePage + 2);
      num++
    ) {
      items.push(
        <Pagination.Item
          key={num}
          active={num === reviewForm.activePage}
          onClick={() => handlePageChange(num)}
        >
          {num}
        </Pagination.Item>
      );
    }
    if (reviewForm.activePage < reviewForm.totalPages - 2) {
      items.push(<Pagination.Ellipsis key="elipses2" />);
    }
    items.push(
      <Pagination.Item
        key={reviewForm.totalPages}
        active={reviewForm.activePage == reviewForm.totalPages}
        onClick={() => handlePageChange(reviewForm.totalPages)}
      >
        {reviewForm.totalPages}
      </Pagination.Item>
    );

    return items;
  };
  return (
    <>
      <BgBluePanel headerName="REVIEWS" />
      {reviewForm.data.length > 0 && (
        <>
          <CustomerReviewQnsAnS reviewerDetails={reviewForm.data} />
          <Pagination className="mt-4 justify-content-center text-center">
            <Pagination.First
              onClick={() => handlePageChange(1)}
              disabled={reviewForm.activePage == 1}
            />
            <Pagination.Prev
              onClick={() =>
                reviewForm.activePage > 1 &&
                handlePageChange(reviewForm.activePage - 1)
              }
              disabled={reviewForm.activePage == 1}
            />
            {renderPaginationItems()}
            <Pagination.Next
              onClick={() =>
                reviewForm.activePage < reviewForm.totalPages &&
                handlePageChange(reviewForm.activePage + 1)
              }
              disabled={reviewForm.activePage == reviewForm.totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageChange(reviewForm.totalPages)}
              key="last"
              disabled={reviewForm.activePage == reviewForm.totalPages}
            />
          </Pagination>
        </>
      )}
    </>
  );
};

export default CustomerReviews;
