import CustomBulletList from '../../../../../../components/custom-list/CustomBulletList';
import PurchasePolicy from '../../PurchasePolicy';
import RtrnKnowledgeBase from '../../RtrnKnowledgeBase';

const CondoLossAssesmentCovg = () => {
  const liabilityInsCovOptions = {
    options: [
      {
        header: 'Someone has been hurt in a common area.',
        newLine: 'Y',
        desc:
          'Whether you personally know this individual or not, the fact that they were injured on the condo' +
          " property may result in an extra strain on your personal finances. You'd probably assume that the" +
          " medical costs associated with the injury would be covered under the HOA's insurance policy, but" +
          ' the injury bill could exceed the HOA liability limit. In this case, because the injury happened' +
          ' in a common area, you have partial responsibility for making up the difference between the actual' +
          ' medical bills and the HOA liability limit. That means you and your fellow condo dwellers could' +
          ' be assessed. Condo loss assessment coverage will help you avoid paying out of pocket if this situation arises.'
      },
      {
        header:
          'There has been a major catastrophe - like an earthquake, tornado or hurricane - that has damaged the outside of the building.',
        newLine: 'Y',
        desc:
          'Often times, storm events like this come with a high deductible that must first' +
          ' be paid before repair of the damages can begin. And some HOA master policies dictate that the deductible is the' +
          ' responsibility of the condo owners. Contributing to this extra charge could be a strain on your personal finances' +
          " if you didn't have condo loss assessment coverage. Associations could charge a special assessment to cover this" +
          ' master policy deductible cost.'
      },
      {
        header:
          'The shared property inside the building has been damaged by a fire, and the cost to repair has exceeded the HOA policy limits.',
        newLine: 'Y',
        desc:
          'Since you and your fellow residents jointly own the common property, you could be asked to shoulder part of' +
          " the cost to repair. Even if you do not use the shared property that's inside of the building" +
          ' (including elevators, fitness room equipment, hot tub, etc.), you could still be found responsible' +
          ' for a portion of the cost.'
      }
    ]
  };
  const lossCovgInsProducts = {
    options: [
      {
        desc:
          'To start, you should carefully review your HOA master policy to determine how much insurance coverage your' +
          " HOA has, and if they have special deductibles for certain hazards. You should also read your HOA's bylaws to" +
          ' determine how they handle master policy deductibles. Understanding what your HOA will cover in the event of' +
          ' a claim will help you make an informed decision on how much loss assessment coverage you should purchase' +
          ' from your personal condo insurance company to protect your financial earnings.'
      },
      {
        desc:
          'Carefully review your own personal condo insurance policy to make sure you understand the causes of loss' +
          ' for which you are insured. For instance, your HOA may levy an assessment against you for earthquake damage.' +
          ' In order for the loss assessment coverage to kick in, you need to make sure that your personal insurance' +
          ' policy covers earthquake, or you may not have coverage. In addition, special assessments for maintenance' +
          ' repairs are typically not covered.'
      },
      {
        desc:
          'Another data point to consider is how many people are part of your HOA. The more units in your community,' +
          ' the more the assessment will be spread out among their owners, which will reduce your individual financial' +
          ' contribution.'
      },
      {
        desc:
          'And, finally, inventory how many amenities you have available in your complex that could contribute to injury.' +
          ' Pools, grill areas and workout facilities are all common places where an injury can occur. The more of these' +
          ' amenities that your complex offers, the more loss assessment coverage you should consider purchasing.'
      }
    ]
  };

  return (
    <div className="container mt-4">
      <RtrnKnowledgeBase />
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="asH3">What is Condo Loss Assessment Coverage?</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 pe-md-4 pe-lg-5">
          <p>
            You've just moved into your new condo. You're loving the amenities,
            including the pool, fitness center, hot tub and tennis courts.
            You've purchased a personal condo insurance policy to protect your
            belongings, and you're confident that your Homeowners Association
            (HOA) will not only cover the maintenance and upkeep of these
            amenities but will also cover the claim costs associated with major
            and minor damage to common areas.
          </p>

          <p>
            Here's where you may be surprised. While your HOA maintains its own
            policy (called a "master policy"), something could still happen
            where the HOA assesses the community of owners, and the condo owners
            could be responsible for the costs. That's where loss assessment
            coverage comes in; it can save the day... and your bank account.
          </p>

          <p>
            Loss assessment coverage is an add-on to your condo insurance policy
            that comes in handy when the community of owners is responsible for
            unexpected damages. Here are a few scenarios that could cause a loss
            assessment to be activated:
          </p>
          <CustomBulletList listOptions={liabilityInsCovOptions} />
        </div>

        <div className="col-md-6 ps-md-4 pe-lg-5">
          <p>
            One of the most frequently asked questions when it comes to loss
            assessment coverage is, "How much do I need?" Like most insurance
            products, this decision can vary based on personal situations. A
            good rule of thumb is to consider the following;
          </p>
          <CustomBulletList listOptions={lossCovgInsProducts} />
          <p>
            Most personal condo insurance policies include assessed losses up to
            a certain limit - usually $1,000. For just $25 to $50 year, you can
            purchase more coverage to ensure that you aren't financially
            strapped if the unforeseen happens and your condo association levies
            an assessment. It's a small price to pay for peace of mind.
          </p>
          <br />
          <PurchasePolicy policyType="Condo" />
        </div>
      </div>
    </div>
  );
};

export default CondoLossAssesmentCovg;
