import CustomBulletList from '@components/custom-list/CustomBulletList';
import {benefits, condoCovgs1, condoCovgs2} from './CondoInsProductData';

const CondoInsCovgs = () => {
  return (
    <div className="container mt-5 pt-md-5">
      <div className="row">
        <div className="col-md-6 pe-md-4">
          <h5 className="fw-bold"> Protect your condo lifestyle</h5>
          <p>
            {' '}
            Condo living offers some unbeatable benefits: efficiency, easy
            maintenance, independence and more.
          </p>
          <p>
            Be confident you're protecting those benefits with a Stillwater
            Condo policy.
          </p>
          <CustomBulletList listOptions={benefits} />
          <p className="pt-1">
            Your condo is more than just a place to live. It's a lifestyle.
            Protect it with Stillwater.
          </p>

          <h5 className="fw-bold pt-2">The basics of condo insurance</h5>
          <p>Insuring a condo actually means thinking about two policies.</p>
          <p>
            First, there's your homeowners association's policy. It usually
            covers spaces with shared responsibility: for instance, the lobby,
            pool and pool house, plus the exterior walls and roof. Your
            association fee helps cover the cost of this policy.
          </p>
          <p>
            Then, there's your policy - the one for which you're shopping. It
            needs to cover not only your belongings, but the spaces for which
            you have sole responsibility. That usually includes your unit's
            interior walls, floors, ceilings and everything within them. It also
            needs to protect you if you're liable for another's injury or
            property damage.
          </p>
          <p>A Stillwater Condo policy offers this - and much more.</p>

          <h5 className="fw-bold pt-2"> Where to start</h5>
          <p>
            A smart first step is to check with your homeowners association. Its
            bylaws should outline exactly what the association policy covers,
            and what your policy needs to cover.
          </p>

          <h5 className="fw-bold pt-2">
            {' '}
            Cover your belongings, and spaces that are your responsibility
          </h5>
          <p>
            A Stillwater Condo policy picks up where your homeowners
            association's insurance leaves off.{' '}
          </p>
          <p>Your policy's main coverages* include:</p>
          <CustomBulletList listOptions={condoCovgs1} />
        </div>
        <div className="col-md-6 ps-md-4">
          <CustomBulletList listOptions={condoCovgs2} />
          <h5 className="fw-bold pt-2">
            Customize a policy to match specific needs
          </h5>

          <p>
            You can add options like Animal Liability, Identity Theft
            Protection, Personal Injury Coverage, Special Personal Property
            Coverage, Water Backup and Earthquake.
          </p>

          <h5 className="fw-bold pt-2">
            Choose a deductible that fits your budget
          </h5>
          <p>
            The deductible-which you choose when you buy a policy-is your
            dollar-amount "share" of a claim settlement. A high deductible means
            you get a lower rate, and have a higher share if you file a claim. A
            low deductible means a higher rate, and a lower share.
          </p>
          <h5 className="fw-bold pt-2">Save money with discounts</h5>
          <p>
            The sense of peace that comes with a Stillwater Condo policy is
            available at an affordable rate... and with discounts that will help
            you save.
          </p>
          <ul className="custom-bullet square">
            <li>Age of Insured</li>
            <li>Automatic Sprinkler</li>
            <li>Claim-Free</li>
            <li>Auto-Home</li>
          </ul>
          <p>
            You could save hundreds by combining discounts. And, you can take
            advantage of flexible payment options that help you keep your budget
            on track.
          </p>

          <p>
            Whether it's your full-time residence or your weekend getaway,
            condos offer an easy, low maintenance lifestyle. You should expect
            the same from your coverage. That's why we offer great rates to
            protect your condo and the belongings inside. It's easy to grab a
            quote, sit back and enjoy your home.
          </p>
          <p className="fst-italic text-14 pt-1">
            *These are generalized coverage descriptions. Our Condo contract
            includes more detailed definitions.
            <br />
            ** Varies by condo association
          </p>
        </div>
      </div>
    </div>
  );
};

export default CondoInsCovgs;
