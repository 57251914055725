import {createContext, ReactNode, useState} from 'react';

export interface PageLoaderProps {
  children: ReactNode;
}

const PageLoaderContext = createContext(null as any);
const PageLoaderProvider = (props: PageLoaderProps) => {
  const [loaderActive, setLoaderActive] = useState(false);

  const loaderContext = {loaderActive, setLoaderActive};

  return (
    <PageLoaderContext.Provider value={loaderContext}>
      {props.children}
    </PageLoaderContext.Provider>
  );
};

export {PageLoaderContext, PageLoaderProvider};
