import React, {useEffect, useState} from 'react';
import PageLoader from './loader/page-loader/PageLoader';
import {RootContextProvider} from './context/RootContextProvider';
import FullScreenLoader from './loader/page-loader/FullScreenLoader';
import {HelmetProvider} from 'react-helmet-async';
import {Layout} from './components/layout/Layout';
import {HOME_ROUTES} from './routes/HomeRoutesTypes';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom';

const App = () => {
  const [isAppReady, setAppReady] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setAppReady(true);
    }, 100);
  }, [isAppReady]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />}>
        {HOME_ROUTES.map((routes, index) => {
          const {Component} = routes;
          return (
            <Route key={index} path={routes.route} element={<Component />} />
          );
        })}
      </Route>
    )
  );

  return (
    <React.Suspense fallback={<PageLoader />}>
      {!isAppReady ? (
        <PageLoader />
      ) : (
        <RootContextProvider>
          <FullScreenLoader />
          <HelmetProvider>
            <RouterProvider router={router} fallbackElement={<PageLoader />} />
          </HelmetProvider>
        </RootContextProvider>
      )}
    </React.Suspense>
  );
};

export default App;
