import {ProductFields, xmlIns} from '../../WelcomeTypes';

export default function Product({
  product,
  updateBtn,
  activeButton
}: {
  product: ProductFields;
  updateBtn: () => void;
  activeButton: string;
}) {
  return (
    <li className="list-inline-item me-1">
      <button
        onClick={updateBtn}
        className={`text-white ${activeButton == product.buttonName ? product.activeClass : product.nonActiveClass}`}
      >
        <svg
          xmlns={xmlIns.name}
          xmlnsXlink={xmlIns.link}
          width="40"
          height="30"
          viewBox="0 0 40 30"
        >
          <image
            x={product.x}
            width={product.width}
            height={product.height}
            xlinkHref={product.image}
          />
        </svg>
        <p className="mt-2 mb-0">{product.buttonName}</p>
      </button>
    </li>
  );
}
