export const ProductMenuOptions = [
  {
    name: 'Home Insurance',
    link: '/product/home-insurance',
    id: 1
  },
  {
    name: 'Renters Insurance',
    link: '/product/renters-insurance',
    id: 3
  },

  {
    name: 'Auto Insurance',
    link: '/product/auto-insurance',
    id: 7
  },
  {
    name: 'Business Insurance',
    link: '/product/business-insurance',
    id: 2
  },
  {
    name: 'Landlord Insurance',
    link: '/product/landlord-insurance',
    id: 4
  },
  {
    name: 'Condo Insurance',
    link: '/product/condo-insurance',
    id: 5
  },
  {
    name: 'Umbrella Insurance',
    link: '/product/umbrella-insurance',
    id: 6
  }
];
