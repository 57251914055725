import {listOfOptions} from './CustomBulletTypes';

const CustomBulletList = ({listOptions}: {listOptions: listOfOptions}) => {
  return (
    <>
      <ul className="custom-bullet square ${listOptions.class}">
        {listOptions.options.map((opt, optIndex) => (
          <li key={optIndex}>
            <span className="fw-bold pe-1">{opt.header}</span>
            {opt.newLine == 'Y' && <br />}
            {opt.desc}
            {opt.nextParagraph && (
              <>
                <br />
                <br />
              </>
            )}{' '}
            {opt.nextParagraph}
            {opt.lastParagraph && (
              <>
                <br />
                <br />
              </>
            )}{' '}
            {opt.lastParagraph}
          </li>
        ))}
      </ul>
    </>
  );
};

export default CustomBulletList;
