import stillwaterCloudImg from '@images/word_cloud.png';

const StillwaterCultureCloud = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-7 ps-md-0">
          <h4 className="fw-bold text-center">The Stillwater Culture Cloud</h4>
          <p className="text-center">
            The words used by your employees to describe their work environment
            can reveal key insights into your company's culture and brand. The
            graphic below shows the words most frequently used by Stillwater
            employees in response to the survey question: "What three words best
            describe your work environment?"
          </p>

          <div className="w-100">
            <img
              src={stillwaterCloudImg}
              className="img-fluid"
              alt="Picture of cloud with words about what people say about Stillwater work environment"
            />
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
};

export default StillwaterCultureCloud;
