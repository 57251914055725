import CoveragePanel from '@components/panel/covg/CoveragePanel';

const UmbrellaInsCovg = () => {
  const oustStandingCovgs = {
    options: [
      {
        desc: `Choose a level of protection that's right for you-Umbrella limits range from $1 million to $5 million`
      },
      {
        desc: `Supplement virtually any other carrier's* underlying auto, home, renters, recreational vehicle or watercraft policy. Few other Umbrella policies offer this flexibility`
      },
      {
        desc: `Keep the cost of coverage low-we require lower limits for the underlying policy than many of our competitors, helping you save overall on your insurance`
      },
      {
        desc: `Cover yourself; all members of your household (including new drivers); and anyone with permission to use your vehicles, provided they use the vehicle within the scope of that permission`
      },
      {
        desc: `Cover pools and other home features that may pose a risk to small children.`
      },
      {
        desc: `Have added confidence when you rent a car or home while traveling abroad-Stillwater Umbrella provides worldwide coverage**`
      }
    ]
  };

  const umbrellaCovgs1 = [
    {
      header: `Why Umbrella insurance?`,
      paragraphs: [
        `Umbrella is an additional-often essential-layer of personal liability protection for you and your family. It supplements your auto, home, renters, recreational vehicle or watercraft insurance.`,
        `Chances are, you have better things to think about than insurance and what your policy does or does not cover. An affordable and flexible Stillwater Umbrella policy gives you added peace of mind.`
      ]
    },
    {
      header: `How it works:`,
      headerClass: `pt-3`,
      paragraphs: [
        `Say, for example, your teenager is at-fault for a car accident that injures another person. And, say that person sues, and the settlement exceeds the liability limits of your auto policy.`,
        `Or say you're responsible for an accident that totals a car like a Mercedes or BMW. The replacement cost for luxury cars like this can be more than $100K and can exceed your liability limits.`,
        `In either case, your Umbrella policy could pay the difference (up to your Umbrella policy's limit). Without Umbrella, the court could seize your home and other assets to help pay the claim.`,
        `A rule of thumb: if the value of your assets-your home and other property, your possessions, your savings and investments-exceeds the liability coverage limits on your vehicle and homeowners policies, you should consider Umbrella.`,
        `Your Stillwater agent can help you decide if this additional coverage is right for you.`
      ]
    }
  ];

  const umbrellaCovgs2 = [
    {
      header: `Outstanding coverage, flexibility and value`,
      paragraphs: [`Get the peace of mind you want at an affordable price`],
      list: oustStandingCovgs
    },
    {
      paragraphs: [
        `To best assist you with our umbrella insurance product, please contact our specialist at (855) 712-4092. `
      ]
    }
  ];

  const umbrellaCovgText = [
    `*Carriers rated B+ or better by A.M. Best only.`,
    `**Subject to the territory restrictions of the underlying policies`
  ];

  return (
    <div className="container mt-5 pt-md-5">
      <div className="row">
        <CoveragePanel
          leftCovgs={umbrellaCovgs1}
          rightCovgs={umbrellaCovgs2}
          impCovgText={umbrellaCovgText}
        />
      </div>
    </div>
  );
};

export default UmbrellaInsCovg;
