import steveImg from '@images/employee/steve_jensen.jpg';
import monaImg from '@images/employee/mona-sanders.jpg';
import ciaraImg from '@images/employee/ciara-williams.jpg';
import stephenImg from '@images/employee/stephen-west.jpg';
import gloriaImg from '@images/employee/gloria-camarena.jpg';
import lukeImg from '@images/employee/curt-czajkowski.jpg';
import johnImg from '@images/employee/john-dole.jpg';
import hayleyImg from '@images/employee/hayley-seyler.jpg';
import kyleImg from '@images/employee/kyle-rhodes.png';
import andreaImg from '@images/employee/andrea-lomax.jpg';
import jakeImg from '@images/employee/jake-tuttle.jpg';

export const offers = {
  options: [
    {
      desc: 'Casual work environment'
    },
    {
      desc: 'Medical, dental, vision and life insurance benefits to keep you and your family protected'
    },
    {
      desc: '401(K) plans to help you plan for your retirement'
    },
    {
      desc: 'Gym/Fitness Center reimbursement'
    },
    {
      desc: 'Generous Paid Time Off (PTO) program to help you maintain your work/life balance'
    }
  ]
};

export const empFeedbackOptions = [
  {
    left: {
      image: steveImg,
      desc: `"I work for a company that values my personal efforts and contributions.
							 I receive great support from leadership and peers in other departments as well. If you like to take
							  ownership and responsibility in your role, Stillwater will be a great fit for you."`,
      signature: `- Steve Jensen, Regional Sales Manager`
    },
    right: {
      image: monaImg,
      desc: `"This is a friendly place to work with helpful co-workers. The potential for growth is high and encouraged.
							 I also really enjoy the collaboration between the different departments."`,
      signature: `-  Mona Sanders, Cash Receipts Representative`
    }
  },
  {
    left: {
      image: ciaraImg,
      desc: `"The work environment at Stillwater is friendly and comfortable. There is also opportunity for growth if you work hard.
							  Everyone here is always more than happy to help you get better at your job."`,
      signature: `- Ciara Williams, Administrative Support III`
    },
    right: {
      image: stephenImg,
      desc: `"Stillwater has a friendly atmosphere. From Customer Service, to Claims to IT, everyone gets along and
							 works well together. The company does a good job of building an environment for happy employees."`,
      signature: `- Stephen West, Lead Software Engineer`
    }
  },
  {
    left: {
      image: gloriaImg,
      desc: `"The thing I like best about Stillwater is the professional work environment. I'm surrounded by very knowledgeable insurance professionals."`,
      signature: `- Gloria Camarena, Customer Service Representative II`
    },
    right: {
      image: lukeImg,
      desc: `"We work hard to continually improve our process in order to strengthen our company to be both competitive and financially strong. 
							 It's so rewarding to see how what I do here can make a difference. And the best part is that my team and 
							 coworkers are all working towards this common goal."`,
      signature: `- Luke Czajkowski, Product Analyst`
    }
  },
  {
    left: {
      image: johnImg,
      desc: `"There is lots of room for advancement here. If you know what you're doing and come in with a strong work ethic,
							 you can make a career here at Stillwater. Our culture is laid back and low stress and we all pull together
							 to make sure we are successful as a company."`,
      signature: `- John Dole, Senior Network Engineer`
    },
    right: {
      image: hayleyImg,
      desc: `"I really appreciate the work-life balance that Stillwater gives me and it's the best company I've worked for. 
							The environment is laid back, calm and friendly and everyone is great to work with."`,
      signature: `-  Hayley Seyler, Administrative Support III`
    }
  },
  {
    left: {
      image: kyleImg,
      desc: `"This is the best company I have ever worked for. I have great co-workers who truly care about the
							 success of our company, as well as each other. If you put in the effort and always do the right thing,
							 you'll be recognized and rewarded"`,
      signature: `- Kyle Rhodes, Business Output Manager`
    },
    right: {
      image: andreaImg,
      desc: `"Working in IT, I really enjoy helping employees face to face with their computer or technology troubles.
							 We have a friendly, stress-free environment that I look forward to coming to."`,
      signature: `- Andrea Lomax, System Administator`
    }
  },

  {
    left: {
      image: jakeImg,
      desc: `"We sometimes wear a lot of hats at Stillwater and that's the fun part. 
							 We partner with other teams and decision makers beyond our core roles to help
							 problem solve and take on new challenges. This gives employees the chance to
							 make a big impact."`,
      signature: `- Jake Tuttle, Product Manager`
    }
  }
];

export interface feedback {
  left?: empFeedbackDesc;
  right?: empFeedbackDesc;
}

export interface empFeedbackDesc {
  image: string;
  desc: string;
  signature: string;
}
