import axios from 'axios';
import {
  ServiceRequestUrl,
  ServiceRequestGetConfig,
  ServiceRequestPostConfig
} from './BaseMapper';
import ServiceRequestUtils, {
  DEFAULT_MESSAGE_BEHVIOUR
} from './ServiceRequestUtils';

// Wrapper between our services and axios - on how to handle the request!
class ServiceRequest {
  static async get(
    url: ServiceRequestUrl,
    config?: ServiceRequestGetConfig
  ): Promise<any> {
    if (!config) {
      config = <ServiceRequestGetConfig>{};
    }
    if (!config.messageBehaviour) {
      config.messageBehaviour = DEFAULT_MESSAGE_BEHVIOUR.get;
    }
    try {
      const response = await axios.get(url, {
        params: config.params ? config.params : undefined
      });
      return response.data;
    } catch (err: any) {
      ServiceRequestUtils.genericErrorHandler(err);
    }
  }

  static async post(
    url: ServiceRequestUrl,
    config: ServiceRequestPostConfig
  ): Promise<any> {
    if (!config.messageBehaviour) {
      config.messageBehaviour = DEFAULT_MESSAGE_BEHVIOUR.post;
    }
    try {
      const requestConfig = config?.params ? {params: config.params} : {};

      const response = await axios.post(url, config.payload, requestConfig);

      return response.data;
    } catch (err: any) {
      ServiceRequestUtils.genericErrorHandler(err);
    }
  }
}
export default ServiceRequest;
