export enum ROUTES {
  ROOT = '',
  ROOT_DEFAULT = '/',
  WELCOME = 'welcome',
  CONTACT_US = 'contact-us',
  ABOUTOURFAMILY = 'about-our-company',
  KNOWHOW = 'know-how',
  CLAIMS = 'claims',
  FINDANAGENT = 'find-an-agent',
  KNOWHOWDETAILS = 'know-how/:knowhowId',
  FAQS = 'faqs',
  GLOSSARY = 'glossary',
  INSURANCEPRODUCT = 'product/:productItem',
  OURCOMPANY = 'about-our-company',
  CUSTOMERFEEDBACK = 'about-our-customers',
  OURLEADERSHIP = 'about-our-leadership',
  AGENTAPPOINTMENT = 'agent-appointment',
  OURAWARDS = 'our-accreditations-and-awards',
  CAREERS = 'careers',
  CUSTOMERREVIEWS = 'customer-reviews',
  PRIVACYPOLICY = 'privacy-policy'
}
