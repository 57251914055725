import KnowHow from '@modules/knowhow/knowledge-base/KnowHow';
import Claims from '@modules/claims/Claims';
import FindAnAgent from '@modules/findanagent/FindAnAgent';
import ContactUs from '@modules/contactus/ContactUs';
import Welcome from '@modules/welcome/topPanel/Welcome';
import {ROUTES} from './RouteConstants';
import KnowHowDetails from '@modules/knowhow/knowledge-base/knowhow-details/KnowHowDetails';
import FAQ from '@modules/knowhow/faq/FAQ';
import Glossary from '@modules/knowhow/glossary/Glossary';
import InsuranceProduct from '@modules/products/InsuranceProduct';
import AboutOurCompany from '@modules/aboutus/company/AboutOurCompany';
import CustomerFeedback from '@modules/aboutus/customer/CustomerFeedback';
import OurLeadership from '@modules/aboutus/leadership/OurLeadership';
import AgentAppointment from '@modules/aboutus/agent/AgentAppointment';
import AccreditationsAndAwards from '@modules/aboutus/awards/AccreditationsAndAwards';
import Careers from '@modules/aboutus/careers/Careers';
import CustomerReviews from '@modules/aboutus/customer/reviews/CustomerReviews';
import PrivacyPolicy from '@modules/privacy-policy/PrivacyPolicy';

export const HOME_ROUTES: Array<RouteInfo> = [
  {
    route: ROUTES.ROOT,
    Component: Welcome
  },
  {
    route: ROUTES.KNOWHOW,
    Component: KnowHow
  },

  {
    route: ROUTES.CLAIMS,
    Component: Claims
  },

  {
    route: ROUTES.FINDANAGENT,
    Component: FindAnAgent
  },

  {
    route: ROUTES.OURCOMPANY,
    Component: AboutOurCompany
  },
  {
    route: ROUTES.CONTACT_US,
    Component: ContactUs
  },
  {
    route: ROUTES.KNOWHOWDETAILS,
    Component: KnowHowDetails
  },
  {
    route: ROUTES.FAQS,
    Component: FAQ
  },
  {
    route: ROUTES.GLOSSARY,
    Component: Glossary
  },
  {
    route: ROUTES.INSURANCEPRODUCT,
    Component: InsuranceProduct
  },
  {
    route: ROUTES.CUSTOMERFEEDBACK,
    Component: CustomerFeedback
  },
  {
    route: ROUTES.OURLEADERSHIP,
    Component: OurLeadership
  },
  {
    route: ROUTES.AGENTAPPOINTMENT,
    Component: AgentAppointment
  },
  {
    route: ROUTES.OURAWARDS,
    Component: AccreditationsAndAwards
  },
  {
    route: ROUTES.CAREERS,
    Component: Careers
  },
  {
    route: ROUTES.CUSTOMERREVIEWS,
    Component: CustomerReviews
  },
  {
    route: ROUTES.PRIVACYPOLICY,
    Component: PrivacyPolicy
  }
];

export interface RouteInfo {
  route: string;
  Component: any;
  hasSubRoutes?: boolean;
  isIndex?: boolean;
}
