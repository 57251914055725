import {ParagraphProps} from './ParagraphTextTypes';

const ParagraphText = ({label, children}: ParagraphProps) => {
  return (
    <p>
      <span className="fw-bold">{label}</span>
      {children}
    </p>
  );
};

export default ParagraphText;
