import {essentialCovgOptions} from './BusinessInsProductData';
import CoveragePanel from '@components/panel/covg/CoveragePanel';

const BusinessInsCovgs = () => {
  const bopCovgs1 = [
    {
      header: `One insurance package for the coverage you need`,
      paragraphs: [
        `A Stillwater Business Owners Policy (BOP) is a package of essential
            coverages for small to medium sized companies like yours. It insures
            your buildings, contents or equipment and provides basic business
            liability insurance. It even helps you maintain your income if a
            fire or other incident interrupts your business.`,
        `Our BOP product is designed to be easy to buy and easy to own.
            Pricing is affordable and policy wording and coverage options are
            clear and understandable. And it keeps protecting your business as
            it grows.`,
        `Simply put, it's a perfect first step to insure your company.`,
        `Depending on your type of business, you may want to consider
            additional coverage for vehicles or professional liability. Your
            Stillwater agent can help identify your needs and customize a
            coverage that's right for you.`,
        `A Stillwater BOP makes insurance easy, and helps you be confident
            you have quality coverage.`
      ]
    },
    {
      header: `Essential coverages`,
      paragraphs: [`BOP are built on these main coverages*:`],
      list: essentialCovgOptions
    }
  ];

  const bopCovgs2 = [
    {
      header: `Designed and priced competitively for small businesses`,
      paragraphs: [
        `We focus on insuring businesses like yours, and welcome both new and
            established ventures. So, your Stillwater rate will be
            affordable-with coverages tailored to your specific business needs.
            Multiple deductible, coverage limits, and payment plan options help
            you settle on a price that's right for your finances.`,
        `Want to amplify the savings? Bundle with a Stillwater Homeowners,
            Auto or Umbrella policy for a Multi Policy Discount. You can also
            earn discounts for paying in full, years in business, having good
            credit and being claim-free.`,
        `And, recognizing you have plenty on your plate already, we've made
            our BOP easy to use. You can shop and buy online or through an
            agent, and get your policy paperwork electronically.`,
        `And, recognizing you have plenty on your plate already, we've made
            our BOP easy to use. You can shop and buy online or through an
            agent, and get your policy paperwork electronically.`
      ]
    },
    {
      paragraphs: [
        `Stillwater offers a convenient, comprehensive BOP quickly and
            easily. In just four minutes, you can customize your liability and
            property damage policy to protect your business' physical and
            financial assets. Designed for a variety of risks, our BOP insurance
            provides coverage for businesses with as many as 50 employees,
            whether it's an established business or new venture. With
            Stillwater, it's one and done with coverage that never stops taking
            care of business.`
      ]
    }
  ];
  const bopCoverageText = [
    `*These are generalized coverage descriptions. Our policy contract
            includes more detailed definitions.`
  ];
  return (
    <div className="container mt-5 pt-md-5">
      <CoveragePanel
        leftCovgs={bopCovgs1}
        rightCovgs={bopCovgs2}
        impCovgText={bopCoverageText}
      />
    </div>
  );
};

export default BusinessInsCovgs;
