import PolicyMyths from '@components/pages/PolicyMyths';

const MythsRenters = () => {
  const rentersMyths = {
    header: `5 Myths About Renters Insurance`,
    paragraphs: [
      ` When looking for a place to rent, there are several factors to take into consideration 
				before making a decision. Top of the list is location, price, neighbors, the landlord, 
				amenities, pet policy and the lease. Renters insurance rarely makes the list, 
				even though it can come in handy when you least expect it and protect you 
				when some event damages or destroys your belongings. According to the 
				Insurance Information Institute, only 37 percent of renters buy Renters insurance. 
				The big question is "Why so few?" It seems that a lot of people have the perception that 
				insurance is too complicated and have developed misconceptions about how 
				Renters insurance works. Here are the top five Renters insurance myths, debunked.`
    ],
    mythLeftOptions: [
      {
        id: 1,
        header: `Myth #1`,
        label: `I can't afford Renters insurance. `,
        desc: `According to the National Association 
				of Insurance Commissioners, the average Renters insurance policy only costs 
				$15-$30 per month. This can vary state to state and depends on how many things 
				you have to cover. Renters insurance is much cheaper than Homeowners insurance 
				and a basic policy normally gives you $30,000 of personal property coverage 
				against theft, fire and natural disasters. The difference between Renters 
				and Homeowners insurance is that renters are just insuring their personal 
				belongings, not the physical building itself. For less than $1 a day, 
				you can have peace of mind in knowing that you're protected.`
      },
      {
        id: 2,
        header: `Myth #2`,
        label: `My stuff isn't worth insuring. `,
        desc: `Take a hard look at everything you own from furniture, 
				household goods, electronics, clothing, appliances, rugs, bedding, shoes - everything. You'll be 
				surprised if you add it all up. Even if it isn't a high dollar amount, do you have enough money to 
				replace everything in a catastrophic event like a fire? According to US News, the average two-bedroom 
				apartment in the US has about $30,000 worth of electronics, clothing, jewelry, bikes and more. Even if 
				you think that you don't own enough items of value to purchase a Renters policy, you need to think beyond 
				your apartment. In addition to personal property, a Renters policy also provides liability insurance and 
				temporary/additional living expenses. Liability covers the cost if someone gets hurt on the property or 
				you are responsible for damage to the property of other tenants in the complex, depending on the circumstances 
				and who is at fault (either you or your landlord).  Temporary/additional living expenses reimburse you if 
				something happens to your rental and you have to temporarily move to a hotel.  For less money than what 
				you would spend on a coffee every day, Renters insurance is worth it.`
      },
      {
        id: 3,
        header: `Myth #3`,
        label: `My landlord has insurance, so I don't need any. `,
        desc: `Many renters think 
				they don't need insurance because they don't actually own the place. 
				They may think they're covered under their landlord's insurance policy, 
				but that's incorrect.  When you rent an apartment or house, the landlord 
				will have an insurance policy that protects the actual dwelling 
				(walls, roof, etc.) and will be responsible if a visitor gets injured 
				on the steps for example. However, if you have a party and you or a 
				visitor gets injured or your personal property is damaged, 
				you could be held responsible.`
      }
    ],
    mythRightOptions: [
      {
        id: 4,
        header: `Myth #4`,
        label: `My room mate has insurance, so I'm covered. `,
        desc: `You could have 
				three room mates who all have Renters insurance, but that doesn't mean
				you're covered too. A Renters insurance policy will not cover a policyholder's 
				roommate(s), unless they are specifically listed on the policy. This is because insurance 
				companies need to price the policy appropriately for the risk. If you're not 
				listed on the policy, the insurance company is not aware that you reside at 
				that property and has no obligation to pay for damage or loss to your 
				personal property in the event of a claim. The only way to ensure that 
				you are protected is to purchase a Renters policy yourself, or to make sure 
				that you are listed as a named insured on your roommate's policy.`
      },
      {
        id: 5,
        header: `Myth #5`,
        label: `Nothing bad is going to happen to me.  `,
        desc: ` We hope that's true. 
				We all know people who've said, "I don't need health insurance, because 
				I never get sick," or "I'm single, I don't need life insurance." 
				A Renters insurance policy covers several perils that people don't 
				usually think of, like fire, lightning, smoke, vandalism, theft and 
				power surges. If you live by yourself, you can control a lot of 
				the risks. When living with other people, or in an apartment structure, 
				you don't know if the neighbors upstairs left something cooking and left 
				for the day, or if the water pipes within the walls are old and ready to burst.`
      },
      {
        id: 6,
        desc: `The bottom line: you shouldn't let misconceptions about Renters insurance 
				stop you from making sure you and the things you treasure are  protected.`
      }
    ],
    policyType: `Renters`
  };
  return <PolicyMyths myths={rentersMyths} />;
};

export default MythsRenters;
