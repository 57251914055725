import {reviewDetails} from './CustomerFeedbackTypes';

const ReviewDisplay = ({customerReview}: {customerReview: reviewDetails}) => {
  return (
    <>
      <p>
        <span className="fw-bold">
          {customerReview.reviewerFirstName.trim()},{' '}
          {customerReview.reviewerState.trim()},{' '}
          {customerReview.reviewDateFormatted}
        </span>
      </p>
      <p>{customerReview.reviewerComments}</p>
    </>
  );
};

export default ReviewDisplay;
