import CustomBulletList from '../../../../../../components/custom-list/CustomBulletList';
import PurchasePolicy from '../../PurchasePolicy';
import RtrnKnowledgeBase from '../../RtrnKnowledgeBase';

const GeneralLiability = () => {
  const liabilityInsCovOptions = {
    options: [
      {
        header: 'Third-Party Bodily Injury:',
        desc:
          'When a person is injured while on your' +
          ' business property, they may make a bodily injury claim against your business.' +
          ' For example, a customer stops by your business and slips and falls in your' +
          ' parking lot and breaks their arm.'
      },
      {
        header: 'Third-Party Property Damage:',
        desc:
          'When you or one of your employees damages a' +
          " third party's property, it will help cover the cost of the claim made against" +
          ' your business. For example, a customer tears their clothing on your door, or' +
          " an employee drops an item on a customer's car and damages it."
      },
      {
        header: 'Advertising Injury:',
        desc:
          'Covers the cost of a claim made against your business' +
          ' because of its advertising practices. For example, a competitor sues you for copyright' +
          ' infringement because your logo looks similar to theirs.'
      },
      {
        header: 'Reputation Harm:',
        desc:
          'Other businesses may make a claim your business has harmed' +
          ' their reputation. For example,  one of your sales reps makes a claim to a customer about' +
          " your competitor that is not true and can't be verified."
      }
    ]
  };

  const bopCovOptions = {
    options: [
      {
        header: 'General Liability Insurance:',
        desc:
          'This is the same protection as a standalone General' +
          " Liability policy outlined above. If you purchase a BOP, you don't need a separate general" +
          ' liability policy.'
      },
      {
        header: 'Property Insurance and Contents:',
        desc:
          'This covers damages to the building and property' +
          ' such as supplies, inventory, appliances and furniture  owned by the company.'
      },
      {
        header: 'Business Income Coverage:',
        desc:
          'This covers the loss of income that results' +
          ' when business is interrupted due to fire or other catastrophes.'
      },
      {
        header: 'Cyber Protection:',
        desc:
          "This covers your company's legal responsibility" +
          ' for the harm caused to others, as well as damages your business suffers from' +
          ' various cyber-attacks against your business.  This is available as an' +
          ' endorsement to the basic BOP policy.'
      }
    ]
  };

  return (
    <div className="container mt-4">
      <RtrnKnowledgeBase />
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="asH3">
            General Liability Insurance vs. Business Owner's Policy
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <p>
            {' '}
            When shopping for small business insurance, it's important to
            understand the difference between General Liability Insurance (GL)
            and a Business Owner's Policy (BOP). Knowing what each policy covers
            will help you make the best financial decision on how to protect
            your business.
          </p>
          <br />

          <p>
            <b>General Liability Insurance</b> protects you and your business
            from "general" claims involving bodily injuries and property damage.
            In many cases, General Liability Insurance is a requirement for
            financial lenders, landlords or licensing boards. If a third party
            is injured while doing business with you, your General Liability
            Insurance will protect you from having to pay out of pocket for the
            injured party's medical and repair bills, plus your legal fees if
            they sue. But as the word "liability" implies, it typically only
            covers the cost of damage your business causes to others.
          </p>
          <br />

          <p>General Liability Insurance covers:</p>
          <CustomBulletList listOptions={liabilityInsCovOptions} />
          <br />

          <p>
            General Liability Insurance does not cover work place injuries that
            happen to employees or other people on your payroll. And GL does not
            cover professional liability/professional services. This is
            generally covered by Workers' Compensation. It also does not cover
            the cost of damages to your own business property. Typically, it
            only helps cover the costs of damage your business causes to others.
            Commercial Property Insurance is designed to help cover the cost of
            damage done to your business' property and must be purchased
            separately.
          </p>
        </div>
        <div className="col-md-6">
          <p>
            <b>A Business Owners Policy (BOP)</b> is designed for small-or
            medium-sized businesses and bundles General Liability Insurance and
            Commercial Property Insurance into one product. A BOP policy
            includes the same liability protection as a General Liability
            Insurance policy; however, it also offers coverage to protect your
            company property, assets owned and used by your business and lost
            income due to business interruption related to those losses. BOPs
            are a good option for small businesses looking to bundle more
            complete insurance, and for savings, as it's often cheaper to
            purchase a BOP policy than to buy each policy separately.
          </p>
          <br />

          <p>BOP covers:</p>
          <CustomBulletList listOptions={bopCovOptions} />
          <p>
            BOP does not provide auto insurance, workers compensation or
            disability insurance. You'll need separate insurance policies to
            cover these for your business. Also, Professional Services Liability
            Coverage is not included, unless selected where available.
          </p>

          <p>
            Both General Liability Insurance and BOP protect your business from
            liability. But if you're looking for more coverage and want to save
            money on small business insurance, a BOP policy is a smart choice.
            Many insurance carriers offer BOP at a discounted rate - costing
            less than what you would spend on a standalone policy. As a business
            owner, you can benefit by protecting yourself from unexpected
            financial losses in one convenient package designed specifically for
            small-and mid-sized businesses that offers a broad range of
            coverage.
          </p>

          <PurchasePolicy policyType="Business" />
        </div>
      </div>
    </div>
  );
};

export default GeneralLiability;
