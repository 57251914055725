import {rentersInsCardPanelInfo, rentersInsData} from './RentersInsProductData';
import './RentersInsPrdt.scss';
import ProductPanel from '@components/panel/product/ProductPanel';
import CardPanel from '@components/panel/card/CardPanel';
import PageTitle from '@components/form/PageTitle';
import RentersInsCovgs from './RentersInsCovgs';

const RentersInsProduct = () => {
  return (
    <>
      <PageTitle>
        Renters Insurance: Get a Renters Insurance Quote | Stillwater Insurance
      </PageTitle>
      <ProductPanel product={rentersInsData} />
      <CardPanel cardData={rentersInsCardPanelInfo} />
      <RentersInsCovgs />
    </>
  );
};

export default RentersInsProduct;
