import {useMemo} from 'react';
import {getQuoteUrl} from '../../../environment/Environment';

const PolicyInsuranceBtn = ({policyType}: {policyType: String}) => {
  const btnTxt = useMemo(() => {
    if (policyType === 'Auto') {
      return 'Get an ' + policyType + ' Insurance Quote Today';
    } else {
      return 'Get a ' + policyType + ' Insurance Quote Today';
    }
  }, [policyType]);
  return (
    <div className="float-end pt-2">
      <a
        className="btn-blue-lg text-white text-decoration-none"
        href={getQuoteUrl()}
      >
        {btnTxt}
      </a>
    </div>
  );
};

export default PolicyInsuranceBtn;
