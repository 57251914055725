import {CustomParagraphProps} from './CustomParagraphTypes';
import ParagraphText from './ParagraphText';

const CustomParagraphList = ({
  paragraphs
}: {
  paragraphs: CustomParagraphProps[];
}) => {
  return (
    <>
      {paragraphs.map(text => (
        <div key={text.id}>
          <ParagraphText label={text.label && text.label}>
            <>{text.desc && text.desc}</>
          </ParagraphText>
          {text.list && (
            <ul>
              {text.list.map((option, index) => (
                <li key={index}>
                  {option.label && <i>{option.label}</i>} {option.desc}
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </>
  );
};

export default CustomParagraphList;
