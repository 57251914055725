interface AccreditationAndAwards {
  header?: string;
  subHeader?: string;
  headerClass?: string;
  desc?: string;
  image?: string;
  imgClass?: string;
  imgDesc?: string;
}

const AccrediationsAndAwardsPanel = ({
  accreditationAndAwards
}: {
  accreditationAndAwards: AccreditationAndAwards[];
}) => {
  return (
    <>
      {accreditationAndAwards.map((awards, awardIndex) => (
        <div key={awardIndex}>
          {awards.header && (
            <h4 className={`fw-bold ${awards.headerClass}`}>{awards.header}</h4>
          )}
          {awards.subHeader && <h5 className="pt-3">{awards.subHeader}</h5>}
          <p>{awards.desc}</p>
          {awards.image && (
            <div className="text-center">
              <img
                src={awards.image}
                className={awards.imgClass}
                alt={awards.imgDesc}
              />
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default AccrediationsAndAwardsPanel;
