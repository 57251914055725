import {useContext} from 'react';
import {PageLoaderContext} from '../../context/PageLoaderProvider';
import {Spinner} from 'react-bootstrap';

const FullScreenLoader = () => {
  const {loaderActive} = useContext(PageLoaderContext);
  return (
    <>
      {loaderActive && (
        <div className="spinner-overlay">
          <Spinner animation="border" role="status" variant="secondary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
};

export default FullScreenLoader;
