import MobileNavBar from '../../../components/navs/MobileNavBar';
import {navItems} from './KnowHowTypes';

interface faqProps {
  activeLink: string;
}
const KnowHowMobileHeader = (props: faqProps) => {
  return (
    <>
      <div className="jumbotron jumbotron-fluid bg-white mb-0 pb-0 mt-0 mt-md-2 pt-0 pt-md-2">
        <div className="container">
          <div className="row d-block d-md-none">
            <div className="knowhow-pb svg-container">
              <svg
                viewBox="0 20 600 200"
                preserveAspectRatio="xMidYMin slice"
                className="svg-content"
              >
                <g>
                  <rect x="0" y="20" width="800" height="200" fill="none" />
                  <rect x="0" y="20" width="400" height="200" fill="none" />
                  <rect x="250" y="20" width="400" height="200" fill="none" />
                  <text x="20" y="90" className="knowHowHeader">
                    KNOW HOW TO
                  </text>
                  <text x="20" y="150" className="insuranceHeader">
                    INSURANCE
                  </text>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <MobileNavBar
        items={navItems}
        activeOption={props.activeLink}
        navClass="d-md-none"
      />
    </>
  );
};

export default KnowHowMobileHeader;
