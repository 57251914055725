import {GradeInfoFields} from '../../WelcomeTypes';

const Grade = ({gradeInfo}: {gradeInfo: GradeInfoFields}) => {
  return (
    <li className="my-3 font-weight-normal">
      <p>
        {gradeInfo.intialText}
        {gradeInfo.boldText && (
          <span className="bold">"{gradeInfo.boldText}"</span>
        )}{' '}
        {gradeInfo.lastText}
      </p>
    </li>
  );
};

export default Grade;
