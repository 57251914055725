import {leftArrow, ProductsData, xmlIns} from '@modules/welcome/WelcomeTypes';
import Product from './Product';

const Product1 = ({
  btnType,
  updatePanel,
  updateActiveBtn
}: {
  btnType: string;
  updatePanel: (val: string) => void;
  updateActiveBtn: (val: string) => void;
}) => {
  return (
    <div className="row ms-1 d-none d-md-block">
      <ul className="list-inline">
        {ProductsData.map(product => (
          <Product
            key={product.buttonName}
            product={product}
            updateBtn={() => updateActiveBtn(product.buttonName)}
            activeButton={btnType}
          />
        ))}
        <li className="list-inline-item ms-2 me-0">
          <button
            id="p-next"
            className="text-white btn-next "
            onClick={() => updatePanel('products2')}
          >
            <span>
              <svg
                xmlns={xmlIns.name}
                xmlnsXlink={xmlIns.link}
                width="40"
                height="30"
                viewBox="0 0 40 30"
              >
                <image
                  x="1"
                  width="18"
                  height="30"
                  xlinkHref={leftArrow.image}
                />
              </svg>
            </span>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Product1;
