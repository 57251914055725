import PolicyFAQ from '@components/pages/PolicyFAQ';

const UmbrellaInsFAQ = () => {
  const umbrellaFAQData = {
    title: `Umbrella Insurance FAQ - Common Questions Explained | Stillwater Insurance`,
    header: `Umbrella Insurance FAQ`,
    leftParagraphs: [
      {
        id: 1,
        label: `What is Umbrella Insurance?  `,
        desc: ` An Umbrella insurance policy is designed to protect you from a liability claim above and beyond what your other insurance policies cover. It is sometimes referred to as "excess liability" coverage.`
      },
      {
        id: 2,
        label: `How much Umbrella insurance coverage do I need? `,
        desc: `Like all insurance, this is a personal decision based on your individual needs and risk tolerance. If you have a teen driver in your household or own a swimming pool, you may have an above average 
                        chance of being sued. You should have enough insurance to cover your net worth. To determine your net worth,  you should add together the value of  your home, your vehicle(s) and your savings, 
                        including investments. Also, as part of your evaluation, you should consider your future earnings, because if someone sues you and the judgment exceeds your liability coverage, this income could 
                        be garnished to pay off the judgment. To protect your future earnings, multiply your annual income by 5, and add that amount to your asset total.`
      },
      {
        id: 3,
        label: `Does a personal Umbrella policy cover my whole family?  `,
        desc: `A personal Umbrella policy may extend to other members of the policyholder's household (such as a spouse or children), as long as they are listed on the policy. Some exclusions may apply 
                        though, so you should check your policy or discuss it with your insurance agent.`
      },
      {
        id: 4,
        label: `What's not covered under an Umbrella insurance policy?  `,
        desc: `Most Umbrella insurance policies do not cover injuries to yourself, damage to personal belongings, intentional or criminal acts, your personal business, or property damage or injuries 
                        that result from an occurrence that is excluded on your policy.`
      }
    ],
    rightParagraphs: [
      {
        id: 5,
        label: `When does an Umbrella policy kick in? `,
        desc: `When you purchase a home, auto or watercraft insurance policy, each policy contains a certain dollar amount for liability insurance coverage. If you are sued for a dollar amount 
                        that exceeds the liability limit in your other insurance policies, your Umbrella policy would kick in to cover the difference. Without Umbrella insurance, you would have to pay 
                        out of pocket for any costs that exceed your current liability limits.`
      },
      {
        id: 6,
        label: `Is Umbrella insurance just for the wealthy? `,
        desc: `As lawsuits become more and more prevalent for everything from a dog bite that causes permanent scaring and injury, to an accident resulting from underage drinking at a party, more 
                        and more people are purchasing an Umbrella policy to protect themselves from an unforeseen event. Every day, courts around the country are awarding hundreds of millions of dollars in 
                        damages that can financially cripple the negligent party. Unexpected events can happen to anyone, and your future financial stability could be in jeopardy.`
      },
      {
        id: 7,
        label: `Is Umbrella insurance expensive? `,
        desc: `No, Umbrella insurance is affordable. According to the Insurance Information Institute, an Umbrella policy with $1,000,000 in coverage costs about $150 to $300 per year. 
                        That's somewhere between 40 and 80 cents a day. For less than a cup of coffee, you can protect your assets.`
      }
    ]
  };
  return <PolicyFAQ faq={umbrellaFAQData} />;
};

export default UmbrellaInsFAQ;
