import {useState} from 'react';
import AboutUsHeader from '../AboutUs';
import groupImg from '@images/group_photo.jpg';
import ProductAvailableByState from './ProductAvailableByState';
import './ProductAvailableByState.scss';
import PageTitle from '@components/form/PageTitle';

const AgentAppointment = () => {
  const [showPrdtAvailable, setShowPrdtAvailable] = useState(false);

  return (
    <>
      <PageTitle>
        Apply to Become a Stillwater Insurance Agent | Stillwater Insurance
      </PageTitle>
      <AboutUsHeader activeOption="Agent Appointment" />
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-8">
            <h1 className="asH4">
              Apply to become a Stillwater appointed agent
            </h1>
            <p>
              Interested in becoming a Stillwater appointed agent? Email us at{' '}
              <a
                className="text-underline"
                href="mailto:agent.appointments@stillwater.com"
              >
                agent.appointments@stillwater.com
              </a>{' '}
              and tell us about your agency. We'll need to know your agency's
              physical address, how to contact you, and what products you are
              interested in, as well as anything else you'd like to add. Then we
              will contact you to see if we would be a good fit for your agency.
            </p>
            {!showPrdtAvailable && (
              <>
                <button
                  className="fw-bold text-underline border-0 bg-transparent linkStyle"
                  onClick={() => setShowPrdtAvailable(true)}
                >
                  Check out Stillwater product availability by state.
                </button>
                <br />
              </>
            )}
          </div>
        </div>
        {showPrdtAvailable && <ProductAvailableByState />}
        <div className="row d-none d-md-block">
          <img
            src={groupImg}
            className="w-100 img-fluid"
            alt="Picture of people"
          />
        </div>
      </div>
    </>
  );
};

export default AgentAppointment;
