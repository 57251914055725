import {useContext, useEffect} from 'react';
import StartQuote from '../../form/StartQuote';
import {productTypes} from './ProductPanelTypes';
import {ProductTypeContext} from '../../../context/StartQuoteProductProvider';

const ProductPanel = ({product}: {product: productTypes}) => {
  const {setSelectedProductTyp} = useContext(ProductTypeContext);

  useEffect(() => {
    setSelectedProductTyp(product.type);
  }, [product.type]);

  return (
    <div
      className={`jumbotron container-fluid text-white ${product.backgroundImgClass}`}
    >
      <div className="container">
        <div className="row">
          <div
            className={`${product.type == 'Umbrella' ? 'col-md-8' : 'col-lg-6 col-md-11'}`}
          >
            <h1 className="asH1 d-none d-md-block">{product.header}</h1>
            <p className="asH1 d-md-none">
              {product.mobileHeader} <br /> {product.mobileHeader2}
            </p>
            {product.type == 'Umbrella' && (
              <p className="d-block d-md-none text-24">
                To best assist you with our umbrella insurance product, please
                contact our specialist at
                <a
                  className="text-white link-underline link-underline-opacity-0 link-underline-opacity-100-hover ps-2"
                  href="tel:18557124092"
                >
                  (855) 712-4092.
                </a>
              </p>
            )}
            <p
              className={`text-24 d-none d-md-block d-lg-block ${product.type == 'Umbrella' ? 'pb-lg-5 pb-md-3' : ''}`}
            >
              {product.desc}
            </p>
            {product.type != 'Umbrella' && (
              <>
                <StartQuote page="product" />{' '}
                <div className="mb-lg-3 mb-md-5" />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPanel;
