import {ICON_LIST, ICON_MAP} from './IconMap';

interface IconComponentProps {
  iconName: ICON_LIST;
  clickable?: boolean;
  width?: string | number;
  height?: string | number;
  fill?: string;
  cursor?: string;
  onClick?: () => any;
}

const ThemeIcon = (props: IconComponentProps): JSX.Element => {
  const {
    iconName,
    height = '28px',
    width = '30px',
    fill,
    clickable = false,
    cursor,
    onClick
  } = props;

  const Icon = ICON_MAP[iconName];

  const svgProps = {
    width: typeof width === 'number' ? `${width}px` : width,
    height: typeof height === 'number' ? `${height}px` : height,
    fill: fill
  };

  const handleClick = (): void => {
    if (clickable && onClick) {
      onClick();
    }
  };

  return (
    <Icon
      width={svgProps.width}
      height={svgProps.height}
      fill={svgProps.fill}
      onClick={handleClick}
      style={{cursor: cursor || 'inherit', position: 'relative'}}
    />
  );
};

export default ThemeIcon;
