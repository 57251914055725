import HomeUtils from '../../../../components/utils/HomeUtils';
import {
  getCSSUrl,
  getRegistrationUrl
} from '../../../../environment/Environment';
import {ICON_LIST} from '../../../../icons';
import ThemeIcon from '../../../../icons/ThemeIcon';
import leafImg from '@images/icons/svg/leaf.svg';

const PaperLess = () => {
  const paperlessList = [
    {
      name: 'NEW CUSTOMERS',
      class: 'bold mt-3 mb-2 pt-2',
      text: 'Start your quote and choose to opt-in for Paperless',
      type: 'customer',
      id: 1,
      iconColor: 'white',
      iconWidth: '30',
      iconheight: '30'
    },
    {
      name: 'NEED TO REGISTER?',
      class: 'mt-3 mb-2 bold pt-2',
      text: 'Use an existing policy to register your account',
      type: 'register',
      id: 2,
      iconColor: 'white',
      iconWidth: '30',
      iconheight: '30'
    },
    {
      name: 'ALREADY REGISTERED',
      class: 'mt-3 mb-2 bold pt-2',
      text: 'Login and activate paperless in Account setting',
      type: 'registered',
      id: 3,
      iconColor: 'white',
      iconWidth: '30',
      iconheight: '30'
    }
  ];
  function handlePaperless(type: String) {
    if (type === 'customer') {
      HomeUtils.redirectToHome();
    } else if (type === 'register') {
      window.location.href = getRegistrationUrl();
    } else {
      window.location.href = getCSSUrl() + 'setting';
    }
  }
  return (
    <div className="col-xl-7 leaf-backGround paperLess">
      <img className="leaf-bg" src={leafImg} alt="" />
      <div className="paperless-content">
        <div className="row mt-4 ">
          <div className="col-10 col-sm-11">
            <h1 className="asH2 bold">ELIMINATE UNWANTED PAPER</h1>{' '}
          </div>
          <div className="col-1 col-sm-1" onClick={HomeUtils.redirectToHome}>
            <ThemeIcon
              iconName={ICON_LIST.CLOSE}
              fill="white"
              width="25"
              height="25"
              onClick={HomeUtils.redirectToHome}
            />
          </div>
        </div>
        <div className="my-3">
          <p>
            By going paperless, you can significantly cut down the amount of
            mail we send you. Instead, you will be able to view your policy
            documents anytime, anywhere on our secure site and download and
            print those documents at your convenience.{' '}
          </p>
          <p>
            In addition to helping to reduce waste, you will also be reducing
            the time it takes to access your information. You will avoid any
            mail delays and receive instant email notifications when new
            documents are ready to view.{' '}
          </p>
        </div>
        <div className="mb-3 row">
          {paperlessList.map(paperless => (
            <div
              key={paperless.id}
              className="col-md linkStyle"
              onClick={() => handlePaperless(paperless.type)}
            >
              <div className="text-white container paperlessBlock">
                <p className={paperless.class}>{paperless.name}</p>
                <div className="row">
                  {paperless.type == 'customer' ? (
                    <>
                      <div className="col-1 g-0 my-2 me-lg-1 me-md-1 me-sm-2 ">
                        <ThemeIcon
                          iconName={ICON_LIST.ANGLELEFT}
                          height={paperless.iconheight}
                          width={paperless.iconWidth}
                          fill={paperless.iconColor}
                        />
                      </div>
                      <div className="col-10 text-left ms-1">
                        <p>{paperless.text}</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-10 text-left">{paperless.text}</div>
                      <div className="col-1 my-1 float-end">
                        <ThemeIcon
                          iconName={ICON_LIST.ANGLERIGHT}
                          height={paperless.iconheight}
                          width={paperless.iconWidth}
                          fill={paperless.iconColor}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-4">
          <p>
            Thank you for considering going paperless and helping Stillwater
            keep our rates lower by reducing costs.
          </p>
        </div>
      </div>
    </div>
  );
};
export default PaperLess;
