import {landlordCovgOptions} from './LandlordInsPrdtData';
import CoveragePanel from '@components/panel/covg/CoveragePanel';

const LandlordCovgs = () => {
  const landlordCovgs1 = [
    {
      header: `Protection for your rental property investment`,
      paragraphs: [
        `Stillwater Landlord Insurance is ideal for owners of non-commercial rental homes. Providing some of the same benefits as a homeowners policy, it's primarily for property you own and rent to others.`
      ]
    },
    {
      header: `Why Landlord insurance?`,
      headerClass: 'pt-1',
      paragraphs: [
        `Say, for instance, your rental property is damaged or destroyed by a fire. A Landlord policy would pay to repair or replace the things you own: the house, any damaged outbuildings, and any major appliances. 
          (Your tenants would have coverage for their personal belongings under their renters policies.)`,
        `A Landlord policy also provides Liability coverage. This would protect you if, for instance, a contractor were to sue after being injured on your property.`,
        `Stillwater's Landlord Insurance provides wide-ranging protection, combined with affordable rates and discounts to help you save. Your agent can help you build a policy customized to your needs.`
      ]
    },
    {
      header: `Save with affordable rates and deep discounts`,
      paragraphs: [
        `It's easy to earn a Multi-Policy Discount. Just bundle a Landlord policy with any other Stillwater product, such as an Auto, Homeowners, Earthquake or Umbrella policy. You can also receive our Professional 
          Property Management Discount for being a National Association of Residential Property Managers-certified owner.`
      ]
    }
  ];
  const landlordCovgs2 = [
    {
      header: `Get the protection you need ... and the confidence you want`,
      headerClass: 'pt-1',
      paragraphs: [
        `Stillwater Landlord Insurance gives you confidence as a property owner. It provides confidence that your investment is protected should the unthinkable happen.`
      ],
      list: landlordCovgOptions
    }
  ];

  const impLandlordCovgText = [
    `These are generalized coverage descriptions. Our Landlord contract includes more detailed definitions.`,
    `Coverages and discounts may not be available in all states or situations.`
  ];

  return (
    <div className="container mt-5 pt-md-5">
      <CoveragePanel
        leftCovgs={landlordCovgs1}
        rightCovgs={landlordCovgs2}
        impCovgText={impLandlordCovgText}
      />
    </div>
  );
};

export default LandlordCovgs;
