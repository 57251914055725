import torchAwards from '@images/torch_awards.jpg';
import bestPlaceToWorkAward from '@images/best_place_to_work_award.png';
import AccrediationsAndAwardsPanel from './AccrediationsAndAwardsPanel';

const AccreditationsAndAwardsRightPanel = () => {
  const accreditationRightPnlData = [
    {
      header: `BBB Torch Award for Ethics (2017)`,
      desc: `This award recognizes trustworthy organizations that demonstrate best practices, leadership, social responsibility and high standards of organizational ethics that benefit their customers, employees, vendors and 
					local communities. Being awarded this honor supports our mission of always doing the right thing, even when nobody is looking.`,
      headerClass: 'pb-3',
      image: torchAwards,
      imgClass: `torchAwards`,
      imgDesc: `Torch awards`
    },
    {
      header: `Jacksonville Business Journal "Best Place to Work" Winner (2019)`,
      desc: `This award recognizes companies that go above and beyond the norm to foster an enjoyable and meaningful work environment for their employees. 
				  Out of hundreds of nominated North Florida businesses, we were #7 in the top 10 large companies (100-249 employees) category. Based off anonymous 
					responses gathered from company employees via an online survey, the words most often used to describe the working environment at Stillwater 
					include "friendly", "challenging", "professional", "family", "teamwork" and "caring".`,
      image: bestPlaceToWorkAward,
      headerClass: 'pt-3',
      imgClass: `bestPlaceToWorkAwards`,
      imgDesc: `Best Place to Work Award`
    }
  ];
  return (
    <div className="col-md-6 ps-md-4">
      <AccrediationsAndAwardsPanel
        accreditationAndAwards={accreditationRightPnlData}
      />
    </div>
  );
};

export default AccreditationsAndAwardsRightPanel;
