import PolicyMyths from '@components/pages/PolicyMyths';

const MythsLandlord = () => {
  const landlordMyths = {
    header: `Top 5 Myths About Landlord Insurance`,
    paragraphs: [
      ` As a landlord, renting property that you own can be stressful when trying to protect it... especially 
				when you're not actually living in it.  There are several myths about landlord insurance that can add 
				to your stress level and cause even more confusion.
				Here are the top 5 myths debunked to help you better protect your investment:`
    ],
    mythLeftOptions: [
      {
        id: 1,
        header: `Myth #1`,
        label: `As long as I have landlord insurance on my rental property, my tenants don't need renters insurance. `,
        desc: ` While it's great that you have insurance to protect your rental property, your tenants still need insurance to protect
				   their personal belongings that they bring with them. And an interesting data point is that tenants who purchase insurance
				   for their own belongings are more likely to help safeguard your property. There is no federal or state law that requires
				   tenants to have renter's insurance, but as a landlord, it's within your legal rights to require this as part of the
				   rental agreement.`
      },

      {
        id: 2,
        header: `Myth #2`,
        label: `My personal homeowners insurance will cover my rental property. `,
        desc: `In most cases, your personal homeowners insurance policy only covers owner-occupied homes. That means 
				  it only covers the home in which you personally live in. Once you turn your property into a full-time 
				  rental property, you need a landlord insurance policy to protect your investment.`
      },
      {
        id: 3,
        header: `Myth #3`,
        label: `My property is insured, even if my tenants take a long vacation. `,
        desc: `Many landlord insurance policies impose restrictions on just how long a property can stay vacant while 
				still remaining covered. The reality is that unoccupied homes pose a greater risk to burglary and vandalism. 
				Your tenants should notify you in advance if they are planning on an extended vacation where the home will 
				be unoccupied. You should check with your insurance company to confirm that you are covered, or if you 
				need to consider purchasing extended coverage.`
      }
    ],
    mythRightOptions: [
      {
        id: 4,
        header: `Myth #4`,
        label: `If my tenants stop paying rent for any reason, my landlord insurance policy will cover it. `,
        desc: ` A landlord policy usually covers loss of rental income/rental default but only when the dwelling is unfit to live in due to a covered event.`
      },
      {
        id: 5,
        header: `Myth #5`,
        label: ` I'm renting my home to a family member, so I don't need landlord insurance. `,
        desc: `Renting to a family member may help you feel more secure that you won't experience legal claims or loss of rental income. 
				  But the structure still needs to be protected against situations that are out of your control. If you value your investment 
				  property, you'd be wise to make sure it's properly insured.`
      },
      {
        id: 6,
        desc: `Owning investment property can be financially satisfying, but also challenging as you deal with maintaining the property, fixing and 
				   repairing damage, and having bad tenants. Understanding how landlord insurance works - and purchasing a policy that protects your 
				   investment - provides one less thing to worry about.`
      }
    ],
    policyType: `Landlord`
  };
  return <PolicyMyths myths={landlordMyths} />;
};

export default MythsLandlord;
