import BgBluePanel from '@components/panel/header/BgBluePanel';
import {useContext, useState} from 'react';

import GoogleMapsAGentsView from './GoogleMapsAgentsView';
import {
  addingIdToAgents,
  AgentValues,
  getAddressString,
  initialAgentStateValues,
  mapCoOrdinatesValues,
  mapsLink,
  retrieveAgents
} from './GoogleMapsServices';
import {GoogleMapLoadScriptContext} from '../../context/GoogleMapLoadScripProvider';
import LoadGoogleMapScript from '@components/google/maps/LoadGoogleMapScript';
import {PageLoaderContext} from '../../context/PageLoaderProvider';
import {logFirebaseEvent} from '../../analytics/firebase/FirebaseAnalytics';
import {EVENT_LIST} from '../../analytics/firebase/EventMap';
import {getErrorMessage} from '../../services/commonServices';
import PageTitle from '@components/form/PageTitle';

const FindAnAgent = () => {
  const {isGoogleLoadedScript} = useContext(GoogleMapLoadScriptContext);
  const {setLoaderActive} = useContext(PageLoaderContext);

  const [agentFormData, setAgentFormData] = useState<AgentStateValues>({
    zipCode: '',
    errorMessage: ''
  });

  const [listOfAgents, setListOfAgents] = useState<AgentValues[]>([]);
  const [mapCoordinates, setMapCoordnates] = useState<mapCoOrdinatesValues[]>(
    []
  );

  interface AgentStateValues {
    zipCode: string;
    errorMessage: string;
  }

  const handleZipCodeChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const result = e.currentTarget.value.replace(/\D/g, '');
    setAgentFormData({
      ...agentFormData,
      zipCode: result
    });
  };
  const clearDivOnSubmit = () => {
    setMapCoordnates([]);
    setListOfAgents([]);
    setAgentFormData({
      ...agentFormData,
      errorMessage: ''
    });
  };
  const submitAgentForm = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoaderActive(true);
    clearDivOnSubmit();
    const values = {zip: agentFormData.zipCode};
    try {
      const response = await retrieveAgents(values);
      logFirebaseEvent({
        eventName: EVENT_LIST.FIND_AN_AGENT_SUCCESS,
        message: `Number of Agents: ${response.length}`
      });
      // console.log("response is" + response);
      if (response.length > 0) {
        const agentsRes = await addingIdToAgents(response);
        setListOfAgents(agentsRes);
        agentsRes.map(async (res: AgentValues) => {
          const addressString = getAddressString(res.address);
          await retrieveAgentAddressLocations(addressString, res);
        });
      } else {
        setAgentFormData({
          ...agentFormData,
          errorMessage: 'No Agents found near ' + agentFormData.zipCode
        });
      }
    } catch (err) {
      console.log('error is' + err);
      logFirebaseEvent({
        eventName: EVENT_LIST.FIND_AN_AGENT_ERROR,
        message: getErrorMessage(err)
      });
      setAgentFormData({
        ...agentFormData,
        errorMessage: 'No Agents found near' + agentFormData.zipCode
      });
    } finally {
      setLoaderActive(false);
      console.log('mapCoordinates is' + mapCoordinates);
    }
  };

  const retrieveAgentAddressLocations = async (
    addressVal: string,
    agentInfo: AgentValues
  ) => {
    try {
      await new google.maps.Geocoder().geocode(
        {address: addressVal},
        (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results && results?.length > 0) {
              const geoCodeRes = results[0].geometry.location;
              const resCoOrds = {
                lat: geoCodeRes.lat(),
                lng: geoCodeRes.lng(),
                agent: agentInfo
              };
              setMapCoordnates(prevCoOrds => [...prevCoOrds, resCoOrds]);
            }
          }
        }
      );
    } catch (e) {
      // handle error
    }
  };

  return (
    <>
      <PageTitle>Find an Insurance Agent | Stillwater Insurance</PageTitle>
      {!isGoogleLoadedScript && <LoadGoogleMapScript />}
      <BgBluePanel headerName="FIND AN AGENT" />
      <div className="container pt-5">
        <div className="row">
          <div className="col-sm-12 col-md-4 col-lg-3">
            <form onSubmit={submitAgentForm}>
              <div className="row">
                <div className="col-12">
                  <p>
                    To find a Stillwater agent near you enter your zip code and
                    click submit.
                  </p>
                </div>
              </div>
              {(!(listOfAgents?.length > 0) ||
                !(mapCoordinates.length > 0)) && (
                <div className="row  text-danger">
                  <div className="col-12">{agentFormData.errorMessage}</div>
                </div>
              )}
              <div className="row">
                <div className="col-12">
                  <input
                    type="text"
                    id="zipcode"
                    className="input-line"
                    placeholder="Enter your ZIP code"
                    maxLength={5}
                    value={agentFormData.zipCode}
                    inputMode="numeric"
                    onChange={handleZipCodeChange}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-blue-lg text-white mt-3 px-4 float-end"
                    disabled={
                      !agentFormData.zipCode || agentFormData.zipCode.length < 5
                    }
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-sm-12 col-md-8 col-lg-9 mt-2 mt-md-0">
            <div className="row">
              {listOfAgents?.length > 0 && mapCoordinates.length > 0 && (
                <>
                  <div className="col-sm-12 col-md-7">
                    <GoogleMapsAGentsView
                      locations={mapCoordinates}
                      firstCord={
                        mapCoordinates.length > 0
                          ? {
                              lat: mapCoordinates[0].lat,
                              lng: mapCoordinates[0].lng,
                              agent: mapCoordinates[0].agent
                            }
                          : {lat: 0, lng: 0, agent: initialAgentStateValues}
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-5 g-md-0">
                    <ul className=" agentDataHeight overflow-scroll list-unstyled">
                      {listOfAgents.map(agentData => (
                        <li key={agentData.id}>
                          {agentData.id}
                          <span className="fw-bold">)</span>
                          <br />
                          {agentData.mailingNameLine1}
                          <br />
                          <a
                            target="_blank"
                            href={mapsLink(
                              agentData?.address ? agentData?.address : null
                            )}
                          >
                            {' '}
                            {agentData.address.street} <br />
                            {agentData?.address.city},{' '}
                            {agentData?.address.state} {agentData?.address.zip}
                          </a>
                          <br />
                          <a
                            className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
                            href={`tel:${agentData.phoneNo ? agentData.phoneNo.trim() : ''}`}
                          >
                            {agentData.phoneNo}
                          </a>
                          <hr />
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FindAnAgent;
