import {baseEnvironment} from './Environment.base';
import {Environment} from './EnvironmentTypes';

const BASE_URL = 'https://int.stillwaterinsurance.com/';

export const environment: Environment = {
  ...baseEnvironment,
  baseUrl: BASE_URL,
  int: true
};
