import axios from 'axios';
import {AppError} from './AppErrorService';
import {MessageBehaviour, ServiceMessage} from './BaseMapper';

export type RequestTypes = 'get' | 'post' | 'put' | 'delete';
export const NON_ERROR_CODES = ['GETSSN'];
export const DEFAULT_MESSAGE_BEHVIOUR: Record<RequestTypes, MessageBehaviour> =
  {
    get: 'toaster',
    post: 'throw',
    put: 'throw',
    delete: 'throw'
  };

class ServiceRequestUtils {
  static genericErrorHandler(error: any): void {
    if (axios.isAxiosError(error)) {
      const {response}: any = error;
      const {
        data: {message}
      } = response;
      const {messageList, success} = message;
      throw new AppError({messageList, success});
    } else if (error instanceof AppError) {
      throw error;
    } else {
      // TODO - Need to resolve this
      const messageList: ServiceMessage[] = [];
      throw new AppError({messageList, success: false});
    }
  }
}

export default ServiceRequestUtils;
