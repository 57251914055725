import {analytics} from './Firebase';
import {logEvent as logWebEvent} from 'firebase/analytics';
import {EventProps, FirebaseEventProps} from './FirebaseAnalyticsTypes';
import {EVENT_MAP} from './EventMap';

async function firebaseEvent(props: FirebaseEventProps) {
  const {feature, action, message, contactMethod} = props;

  const formattedFeature = feature.toLowerCase().replace(/ /g, '_');

  let eventParams: FirebaseEventProps = {
    feature: feature,
    action: action,
    message: message,
    contactMethod: contactMethod ? contactMethod : undefined
  };
  if (action === 'Success' || action === 'Error') {
    eventParams = {
      ...eventParams
    };
  }
  const an = await analytics();
  logWebEvent(an, formattedFeature, eventParams);
}

export function logFirebaseEvent(props: EventProps): void {
  const {eventName, message} = props;
  const {feature, action, contactMethod} = EVENT_MAP[eventName];
  firebaseEvent({
    feature: feature,
    action: action,
    message: message ? message : '',
    contactMethod: contactMethod
  });
}
