import {ReactComponent as CheckMark} from '../assets/images/icons/svg/check-solid.svg';
import {ReactComponent as leftAngleIcon} from '../assets/images/icons/svg/angle-left.svg';

import {ReactComponent as rightAngleIcon} from '../assets/images/icons/svg/angle-right.svg';
import {ReactComponent as closeIcon} from '../assets/images/icons/svg/close.svg';
import {ReactComponent as facebookIcon} from '../assets/images/icons/svg/facebook-square.svg';
import {ReactComponent as linkedinIcon} from '../assets/images/icons/svg/linkedin.svg';
import {ReactComponent as homeIcon} from '../assets/images/icons/svg/home.svg';
import {ReactComponent as hotelIcon} from '../assets/images/icons/svg/hotel.svg';
import {ReactComponent as carIcon} from '../assets/images/icons/svg/car-alt.svg';
import {ReactComponent as buildingIcon} from '../assets/images/icons/svg/building.svg';
import {ReactComponent as businessIcon} from '../assets/images/icons/svg/store-alt.svg';
import {ReactComponent as lightbulbIcon} from '../assets/images/icons/svg/lightbulb-on.svg';
import {ReactComponent as heartIcon} from '../assets/images/icons/svg/heart.svg';
import {ReactComponent as usergroupIcon} from '../assets/images/icons/svg/user-group.svg';
import {ReactComponent as keyIcon} from '../assets/images/icons/svg/key.svg';
import {ReactComponent as clipboardlistcheckIcon} from '../assets/images/icons/svg/clipboard-list-check.svg';
import {ReactComponent as shieldcheckIcon} from '../assets/images/icons/svg/shield-check.svg';
import {ReactComponent as lessthanIcon} from '../assets/images/icons/svg/less-than.svg';
import {ReactComponent as squareIcon} from '../assets/images/icons/svg/less-than.svg';
import {ReactComponent as clipboardCheckIcon} from '../assets/images/icons/svg/clipboard-check.svg';
import {ReactComponent as dollarIcon} from '../assets/images/icons/svg/dollar-sign.svg';
import {ReactComponent as wandMagicIcon} from '../assets/images/icons/svg/wand-magic.svg';
import {ReactComponent as carCrashIcon} from '../assets/images/icons/svg/car-crash.svg';
import {ReactComponent as bookIcon} from '../assets/images/icons/svg/book.svg';
import {ReactComponent as laptopIcon} from '../assets/images/icons/svg/laptop.svg';
import {ReactComponent as phoneIcon} from '../assets/images/icons/svg/phone-rotary.svg';
import {ReactComponent as mobileIcon} from '../assets/images/icons/svg/mobile-screen.svg';

export enum ICON_LIST {
  CHECKMARK = 'CHECKMARK',
  CLOSE = 'CLOSE',
  ANGLELEFT = 'ANGLELEFT',
  ANGLERIGHT = 'ANGLERIGHT',
  FACE_BOOK = 'FACEBOOK',
  LINKED_IN = 'LINKEDIN',
  HOME = 'HOME',
  AUTO = 'CAR',
  BUILDING = 'BUILDING',
  BUSINESS = 'BUSINESS',
  LIGHTBULB = 'LIGHTBULB',
  HEART = 'HEART',
  USERGROUP = 'USERGROUP',
  HOTEL = 'HOTEL',
  KEY = 'KEY',
  CLIPBOARDLIST = 'CLIPBOARDLIST',
  SHIELDCHECK = 'SHIELDCHECK',
  LESSTHAN = 'LESSTHAN',
  SQUARE = 'SQUARE',
  CLIPBORADCHECK = 'CLIPBORADCHECK',
  DOLLAR = 'dollar',
  WANDMAGIC = 'wandmagic',
  CARCRASH = 'carcrash',
  BOOK = 'book',
  LAPTOP = 'laptop',
  MOBILE = 'mobile',
  PHONE = 'phone'
}
type IconMap = Record<ICON_LIST, React.FC<React.SVGProps<SVGSVGElement>>>;
export const ICON_MAP: IconMap = {
  [ICON_LIST.CHECKMARK]: CheckMark,
  [ICON_LIST.ANGLELEFT]: leftAngleIcon,
  [ICON_LIST.ANGLERIGHT]: rightAngleIcon,
  [ICON_LIST.CLOSE]: closeIcon,
  [ICON_LIST.FACE_BOOK]: facebookIcon,
  [ICON_LIST.LINKED_IN]: linkedinIcon,
  [ICON_LIST.HOME]: homeIcon,
  [ICON_LIST.AUTO]: carIcon,
  [ICON_LIST.BUILDING]: buildingIcon,
  [ICON_LIST.BUSINESS]: businessIcon,
  [ICON_LIST.LIGHTBULB]: lightbulbIcon,
  [ICON_LIST.HEART]: heartIcon,
  [ICON_LIST.USERGROUP]: usergroupIcon,
  [ICON_LIST.HOTEL]: hotelIcon,
  [ICON_LIST.KEY]: keyIcon,
  [ICON_LIST.CLIPBOARDLIST]: clipboardlistcheckIcon,
  [ICON_LIST.SHIELDCHECK]: shieldcheckIcon,
  [ICON_LIST.LESSTHAN]: lessthanIcon,
  [ICON_LIST.SQUARE]: squareIcon,
  [ICON_LIST.CLIPBORADCHECK]: clipboardCheckIcon,
  [ICON_LIST.DOLLAR]: dollarIcon,
  [ICON_LIST.WANDMAGIC]: wandMagicIcon,
  [ICON_LIST.CARCRASH]: carCrashIcon,
  [ICON_LIST.BOOK]: bookIcon,
  [ICON_LIST.LAPTOP]: laptopIcon,
  [ICON_LIST.MOBILE]: mobileIcon,
  [ICON_LIST.PHONE]: phoneIcon
};
