import {ReactNode} from 'react';
import {Helmet} from 'react-helmet-async';

export interface PageTitleProps {
  children: ReactNode;
}

const PageTitle = ({children}: PageTitleProps) => {
  return (
    <Helmet>
      <title>{children}</title>
    </Helmet>
  );
};

export default PageTitle;
