import {WfcChat} from '@components/chat/WfcChat';
import {ICON_LIST} from '../../icons';
import ThemeIcon from '../../icons/ThemeIcon';
import MobileApp from '@components/form/MobileApp';
import {getCSSUrl} from '../../environment/Environment';

const ClaimsLeftPanel = () => {
  return (
    <div className="col-md-6 pe-md-4">
      <h5 className="fw-bold"> Need to make a claim?</h5>
      <p>
        The most efficient way to report any type of claim (loss) is through
        your Self Service portal or mobile app.
      </p>
      <p className="fw-bold">File any type of claim quickly and easily by:</p>
      <br />

      <div className="fw-bold">
        <ThemeIcon
          iconName={ICON_LIST.LAPTOP}
          fill="#0099cc"
          width="22"
          height="16"
        />{' '}
        <a
          className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover fw-bold ps-1"
          href={getCSSUrl()}
        >
          Logging in to your Self Service account
        </a>
      </div>
      <ul className="custom-bullet custom-bullet-margin-left checkmark">
        <li>Select "Claims"</li>
        <li>Click "Report a Claim"</li>
      </ul>

      <div className="fw-bold pb-4">
        <ThemeIcon
          iconName={ICON_LIST.MOBILE}
          fill="#0099cc"
          width="11"
          height="16"
        />
        <span className="ps-1">
          Logging in to your Stillwater Mobile Account
        </span>
      </div>

      <>
        <div className="pb-4">
          <ThemeIcon
            iconName={ICON_LIST.PHONE}
            fill="#0099cc"
            width="18.29"
            height="16"
          />
          <span className="fw-bold ps-1">By Phone:</span> 1-800-220-1351 (follow
          voice prompts)
        </div>
        <p className="bold">
          If you call to report a claim have the following information ready:
        </p>
        <ul className="custom-bullet custom-bullet-margin-left checkmark">
          <li>Policy #</li>
          <li>Best contact number</li>
          <li>Date of Loss</li>
          <li>Location of Loss</li>
          <li>Description of event/cause of damage</li>
        </ul>
        <p className="text-14 fst-italic">
          *After hours, please note the service used to take the report may not
          be able to verify how coverage will apply. An examiner will review the
          claim report and follow up with you.
        </p>{' '}
        <br />
      </>

      <div>
        <h5 className="fw-bold">
          Need a letter showing claims history (Letter of Experience)?
        </h5>
        <p className="bold">Option 1 Self Service</p>
        <ul className="custom-bullet custom-bullet-margin-left circle">
          <li>Log in to your Self Service account.</li>
          <li>Select the policy you want to view.</li>
          <li>Select Docs & Notes. </li>
          <li>Select Prepare Letter of Experience (Claims History). </li>
          <li>Print or email the PDF document for your use.</li>
        </ul>

        <p className="bold">Option 2 Submit a Request</p>
        <ul className="custom-bullet custom-bullet-margin-left circle">
          <li>
            Email{' '}
            <a className="text-underline" href="mailto:loe@stillwater.com">
              loe@stillwater.com
            </a>
            . Include your policy number in your request.{' '}
          </li>
        </ul>
      </div>
      <div>
        <h5 className="fw-bold"> Want to check the status of your claim?</h5>
        <WfcChat type="claims" />
      </div>
      <br />
      <div>
        <h5 className="fw-bold">Emergency Roadside Assistance</h5>
        <span>
          <a
            className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
            href="tel:18002201351"
          >
            (800) 220-1351
          </a>
        </span>
        <br />
        Code 23324, Plan DV16A
      </div>

      <br />
      <MobileApp mobileAppClass="mt-md-2" />
    </div>
  );
};

export default ClaimsLeftPanel;
