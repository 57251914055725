import ParagraphText from '../../../../../../components/form/paragraph/ParagraphText';
import PurchasePolicy from '../../PurchasePolicy';
import RtrnKnowledgeBase from '../../RtrnKnowledgeBase';

const HomeInsurancePricing = () => {
  return (
    <div className="container mt-4">
      <RtrnKnowledgeBase />
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="asH3">Ten Factors of Pricing Home Insurance</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 pe-md-4 pe-lg-5">
          <p>
            Through the process of buying your first house, you've made it
            through the world of understanding mortgage rates, property taxes,
            home inspections, closing costs and escrow. Next up is understanding
            Homeowners insurance, and what you can do to fit the premium into
            your budget.
          </p>

          <p>
            Here are some key factors that determine how insurance companies
            calculate your Homeowners insurance premium:
          </p>
          <ParagraphText label="1. Location">
            <>
              {' '}
              - Where your house is located is one of the major factors that
              insurance companies take into consideration when determining your
              premium. The closer you are to a large body of water (like an
              ocean), the more likely you are to sustain damage from an event
              like a hurricane. For that reason, insurance companies will charge
              more. Another factor that insurance companies consider is how far
              you are located from a fire station. The farther you are, the
              greater the risk of your house burning down before the fire
              department can arrive. This increases your risk and, in turn,
              increases your premium.
            </>
          </ParagraphText>

          <p>
            <span className="fw-bold">2. Your deductible</span> - When you
            choose the amount of your deductible when you buy your policy, you
            are determining the amount of money you will pay in an insurance
            claim before your coverage kicks in. Deductible pricing is based on
            the concept of "using" your insurance. Customers with low
            deductibles traditionally make the purchasing decision assuming they
            will use their insurance frequently, which is why they select a low
            deductible. Customers with high deductibles enjoy a lower premium,
            but they will have to pay more out of pocket in the event of a
            claim. It's important to note that when you file a claim, the amount
            of your deductible is subtracted from the amount of money the
            insurance company will reimburse you. The lower your deductible, the
            higher your premium will be.
          </p>

          <p>
            <span className="fw-bold">3. Credit history</span> - Insurance
            companies determine your insurance premium based on how risky they
            determine you to be. Research from the Federal Trade Commission,
            among others, has shown that people with poor credit history tend to
            file more claims, so having a poor credit history may increase your
            premium. The states of California, Hawaii, Maryland and
            Massachusetts prohibit the use of credit scores as an insurance
            rating factor, but credit is commonly used in determining premiums
            almost everywhere else.
          </p>

          <p>
            <span className="fw-bold">4. Claims history</span> - When you file a
            claim with an insurance company, your carrier could report it via
            C.L.U.E. (Comprehensive Loss Underwriting Exchange) Personal
            Property report. A higher number of claims that are included in this
            report for you will usually translate into a higher premium from
            your insurance company.
          </p>

          <p>
            <span className="fw-bold">5. Breed of dog</span> - Owning what is
            considered to be an "aggressive" dog breed could increase the cost
            of your homeowners insurance policy due to the prevalence of dog
            bites. About 4.5 million dog bites occur annually in the US. The
            list of aggressive dog breeds may vary by insurance company, so it's
            important to check with your insurer. Some insurance companies
            consider aggressive dogs to be an ineligible risk, which means they
            won't even issue you a policy.
          </p>
        </div>

        <div className="col-md-6 ps-md-4 pe-lg-5">
          <p>
            <span className="fw-bold">6. Marital status</span> - Married couples
            historically file fewer claims than singles and are viewed as "less
            risky" by insurance companies. This lower propensity of risk may
            decrease your premium.
          </p>

          <p>
            <span className="fw-bold">7. Age of home</span> - Generally
            speaking, the older your home, the more expensive it will be to
            insure. This is primarily due to the risk of loss associated with
            roof claims and older plumbing.
          </p>

          <p>
            <span className="fw-bold">8. Swimming pool</span> - Having a
            swimming pool could increase your home insurance premium due to the
            increased liability associated with pool-related incidents.
          </p>

          <p>
            <span className="fw-bold">9. Roof condition</span> - The older the
            roof, the more likely it is to leak and not hold up during a storm.
            That means you are at a higher risk to file a claim, and insurance
            companies may charge you a higher premium to cover that expense.
          </p>

          <p>
            <span className="fw-bold">
              10. Actual Cash Value vs Replacement Cost Coverage on personal
              contents
            </span>{' '}
            - Most people purchase insurance never expecting to have to use it,
            but in the event of a claim, having the right coverage is paramount.
            It's true that choosing Actual Cash Value Coverage might save you
            some money up-front, but Replacement Cost Coverage is ultimately the
            better choice because it provides enough coverage to replace the
            full value of your belongings should the worst happen.
          </p>

          <br />
          <p>
            Insurance companies use a variety of information to determine how to
            assess and price a policy. Although there is some commonality in the
            key factors that influence the rate, it pays to shop around to find
            the rate, coverages, and company that is right for you.
          </p>
          <br />
          <PurchasePolicy policyType="Home" />
        </div>
      </div>
    </div>
  );
};

export default HomeInsurancePricing;
