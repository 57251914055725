import PolicyFAQ from '@components/pages/PolicyFAQ';

const LandlordInsFAQ = () => {
  const landlordFAQData = {
    title: `Landlord Insurance FAQ | Stillwater Insurance`,
    header: `Landlord Insurance FAQ`,
    leftParagraphs: [
      {
        id: 1,
        label: `Do I have to purchase Landlord insurance on the rental property I own? `,
        desc: `Landlord insurance is not required by law, but mortgage companies will require insurance just like a home policy. 
                    Even if you don't have a mortgage on the property, if your property sustains damage due to events like storms or fire, 
                    the cost to repair and replace would need to come out of your pocket. In addition, with liability lawsuits on the rise, 
                    it's an added level of protection that will bring peace of mind knowing that you are protected from financial loss.`
      },
      {
        id: 2,
        label: `What kind of coverages do I get with a Landlord insurance policy? `,
        desc: `Coverage on a Landlord insurance policy usually includes property damage, liability against tenant lawsuits, and loss of 
                    rental income. In addition, there are several additional coverages you can purchase such as personal property replacement cost, 
                    identity theft protection and monitoring, and water back up and sump overflow.`
      },
      {
        id: 3,
        label: `What determines the cost of a Landlord insurance policy? `,
        desc: `The cost of Landlord insurance can vary greatly based on a number of factor such as: type of rental property (condo, single family home, etc.); 
                    geographic location of the property, age and condition of the property; type and amount of coverage you are purchasing**; 
                    and whether or not there is a swimming pool, burglar alarms, or gated access.`
      }
    ],
    rightParagraphs: [
      {
        id: 4,
        label: `Do my tenants need rental insurance if I have Landlord insurance?   `,
        desc: `Yes. Your tenants need insurance to protect their personal belongings that they bring with them when they move into your rental property. Your insurance covers the property, liability against lawsuits, and loss of rental income.`
      },
      {
        id: 5,
        label: `Can landlords require Renters insurance?  `,
        desc: `Although there is no federal or state law requiring tenants to have Renters insurance, as a landlord it is fully within your legal rights 
                    to require this as part of the rental agreement. Stipulated in a rental agreement, this requirement must apply to every tenant. A landlord 
                    can also require that a tenant have a specific amount of insurance, provide proof of coverage and require that the tenant maintain the 
                    policy for the duration of the lease.`
      },
      {
        id: 6,
        label: `Does a Landlord policy pay for accidental damage to rental property? `,
        desc: `A Landlord policy will include accidental damage caused by tenants, theft of your property by tenants, malicious damage caused by 
                    tenants, and loss of rent due to tenants leaving without paying.`
      }
    ]
  };
  return <PolicyFAQ faq={landlordFAQData} />;
};

export default LandlordInsFAQ;
