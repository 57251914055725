import PurchasePolicy from '../../PurchasePolicy';
import RtrnKnowledgeBase from '../../RtrnKnowledgeBase';

const CondoInsurance = () => {
  return (
    <div className="container mt-4">
      <RtrnKnowledgeBase />
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="asH3">Condo Insurance 101</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 pe-md-4">
          <p>
            Unlike homeowners, condo dwellers don't own the building they live
            in or the land it sits on. But they do need protection for what is
            inside their specific unit. Buying insurance can often be more
            difficult and confusing for a condo than a house as you try to
            figure out what you're financially responsible for.
          </p>
          <h5 className="fw-bold"> What does it cover?</h5>
          <p>
            Condo insurance covers the inside of your condo while the external
            areas are typically covered by your homeowners association. It also
            protects you against liability claims if you are found to be
            responsible for another person's injury or property damage, or if
            you have legal fees due to a lawsuit.
          </p>
          <p>
            Condo insurance typically covers what the condo association's master
            policy does not. A condo association master policy can be one of two
            types:
          </p>
          <ol>
            <li className="ms-0">
              "All in" condo master policy - Also known as a single unit master
              policy, this covers fixtures in your condo, such as appliances,
              wiring, plumbing and carpets. It does not cover your personal
              property or any upgrades you have made to your unit.
            </li>
            <li>
              "Studs in "- Covers only the bare structure. You will need to
              insure all of your condo's interior contents - including bathroom
              and kitchen fixtures and countertops - in addition to your
              personal property with your condo policy.
            </li>
          </ol>

          <h5 className="fw-bold">How does it work?</h5>
          <p>
            While a master policy covers damage to your condo building, grounds
            and other external features, your personal condo policy needs to
            cover your personal possessions, appliances and, sometimes,
            installed fixtures and other attached features in the unit.
          </p>
          <p>
            If you need to make a claim (i.e. burglary or fire), you need to
            file with your insurance company and also contact your condo
            association so they can file with their insurance company. For
            instance, if the burglary resulted in a broken door or window, the
            master policy would typically cover this, depending on the
            deductible and your association's declarations and agreements.
          </p>

          <h5 className="fw-bold">Why is it important?</h5>
          <p>
            In addition to protecting your possessions if they are damaged or
            stolen, condo insurance also protects you from liability claims and
            lawsuits if a guest gets injured in your home. It also covers
            expenses related to living elsewhere - such as a hotel - if you
            can't live in your condo due to damage covered by your policy. Condo
            insurance also provides an option to purchase the valuable loss
            assessment coverage, which protects you in the event you are
            assessed due to a covered loss. In this instance, each unit member
            may need to contribute funds to make up the difference. If you have
            loss assessment coverage, this sum may be partially or totally
            covered.
          </p>
        </div>

        <div className="col-md-6 ps-md-4">
          <h5 className="fw-bold">How much does it cost?</h5>
          <p>
            Cost varies, but on average coverage ranges from $150 to $400 per
            year. Cost is affected by where you live, the construction materials
            used for your condo, the value of your personal property and the
            amount of coverage you purchase to cover your personal property and
            any alterations you made to your unit.
          </p>

          <h5 className="fw-bold">How much should I buy?</h5>
          <p>
            This decision may be more complicated than buying regular home
            insurance because of the different types of HOA policies and
            regulations. First, review your condo association policy to see what
            type of policy they have. If it's "bare walls-in and wall studs-in",
            you will need to purchase more coverage to cover the interior
            fixtures and countertops in addition to your personal possessions.
          </p>
          <p>
            In addition, it's important to understand what you would need to
            replace. Perform an inventory of all of your personal items to
            figure out how much it would cost to replace your belongings after
            an event like a burglary or a fire. Check to see if any one item in
            your personal property inventory exceeds the "per-item" limit. One
            good estimate is to assume $40,000 in personal property for the
            first 1,000 square feet of your condo, and then add $5,000 for each
            additional 500 square feet. For liability, if your assets are more
            than $500K, you may want to look at an umbrella policy. Don't skimp;
            for a little money, you can be properly protected.
          </p>
          <PurchasePolicy policyType="Condo" />
        </div>
      </div>
    </div>
  );
};

export default CondoInsurance;
