import homeArrow from '@images/home_arrow.png';
import manWithTie from '/src/assets/images/man_with_tie.png';
import {useTranslation} from 'react-i18next';

const WelcomeImage = () => {
  const {t} = useTranslation();
  return (
    <div className="col-xl-5  d-none d-xl-block">
      <div>
        <svg
          width="500"
          height="450"
          className="welComeImageDisplay"
          aria-labelledby="title"
        >
          <title id="title" lang="en">
            Know how to Insurance
          </title>
          <defs>
            <clipPath id="rectView">
              <rect
                x="0"
                y="10"
                height="500"
                width="400"
                className="rectViewStroke"
              />
            </clipPath>
            <pattern
              id="bgpattern"
              height="100%"
              width="100%"
              patternContentUnits="objectBoundingBox"
            >
              <image
                height="1"
                width="1"
                preserveAspectRatio="none"
                xlinkHref={homeArrow}
              />
            </pattern>
          </defs>
          <polygon
            fill="url(#bgpattern)"
            points="0,0 450,0 500,225 450,450 0,450"
          />
          <text x="20" y="70" className="knowHow">
            {t('APP.KNOW_HOW_HEADER')}
          </text>
          <text x="20" y="120" className="insurance">
            {t('APP.INSURANCE_HEADER')}
          </text>
          <image
            y="70"
            width="400"
            height="85%"
            xlinkHref={manWithTie}
            clipPath={'url(#rectView)'}
          />
        </svg>
      </div>
    </div>
  );
};

export default WelcomeImage;
