import AppNavBar from '../../../components/navs/AppNavBar';
import {navItems} from './KnowHowTypes';
import {useTranslation} from 'react-i18next';
import thumbsUp from '@images/thumbs_up_know_how.png';

const KnowHowHeader = ({activeOption}: {activeOption: string}) => {
  const {t} = useTranslation();
  return (
    <>
      <div className="jumbotron jumbotron-fluid bg-white mb-0 pb-0 mt-0 mt-md-2 pt-0 pt-md-2">
        <div className="container">
          <div className="row">
            <div className="d-none d-lg-block col-lg-1 col-xl-2"></div>
            <div className="d-none d-md-block col-md-8">
              <svg width="740" height="230" aria-labelledby="title">
                <title id="title" lang="en">
                  Know how to Insurance
                </title>
                <defs>
                  <clipPath id="rectView">
                    <rect
                      x="450"
                      y="0"
                      height="250"
                      width="300"
                      className="rectViewStroke"
                    />
                  </clipPath>
                </defs>
                <rect width="300" height="230" fill="none" />
                <text x="20" y="90" className="knowHowHeader">
                  {t('APP.KNOW_HOW_HEADER')}
                </text>
                <text x="20" y="150" className="insuranceHeader">
                  {t('APP.INSURANCE_HEADER')}
                </text>
                <image
                  x="480"
                  y="0"
                  height="100%"
                  xlinkHref={thumbsUp}
                  clipPath="url(#rectView)"
                />
              </svg>
            </div>
            <div className="d-none d-md-block col-md-1 col-xl-2"></div>
          </div>
        </div>
      </div>
      <AppNavBar
        items={navItems}
        activeLink={activeOption}
        itemClass="mx-5 "
        divClass="d-md-block"
      />
    </>
  );
};

export default KnowHowHeader;
