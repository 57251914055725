import {ICON_LIST} from '../../../icons';

export const condoProductData = {
  backgroundImgClass: 'bg-condo',
  header: 'GET A CONDO INSURANCE QUOTE TODAY...',
  mobileHeader: 'GET A CONDO INSURANCE QUOTE TODAY..',
  desc: 'and get wall to wall coverage.',
  type: 'Condo'
};
export const condoInsCardPanelInfo = [
  {
    cardClass:
      'card border-transferent border-bottom-0 border-top-0 border-start-0 mb-4',
    image: ICON_LIST.WANDMAGIC,
    cardHeader: 'Top five myths about condo insurance',
    headerClass: 'h-50',
    cardDescription: `Purchasing a condo insurance policy may be less complex than purchasing home or car insurance, but there are still many misconceptions 
						about a condo insurance policy.`,
    btnLink: '/know-how/myths-condo',
    btnText: 'Top 5 Condo Insurance Myths'
  },
  {
    cardClass: 'card border-transferent border-0 mb-4',
    image: ICON_LIST.HOTEL,
    cardHeader: 'What is Condo Loss Assessment coverage?',
    headerClass: 'h-50',
    cardDescription:
      'Loss Assessment Coverage is an add-on to your condo insurance policy that comes in handy when the community of owners are responsible for unexpected damages.',
    btnLink: '/know-how/condo-loss-assesment-coverage',
    btnText: 'Condo Loss Assessment Coverage'
  },

  {
    cardClass:
      'card border-transferent border-bottom-0 border-top-0 border-end-0 mb-4 ',
    image: ICON_LIST.HOTEL,
    cardHeader: 'Condo Insurance 101',
    headerClass: 'h-50',
    cardDescription:
      "Unlike homeowners, condo dwellers don't own the building they will live in or the land it sits on. But they do need protection for what's inside their specific unit.",
    btnLink: '/know-how/condo-insurance-101',
    btnText: 'Condo Insurance 101'
  }
];

export const benefits = {
  options: [
    {
      desc: `It ensures you'll be "whole" again if the interior of your condo is damaged or your personal belongings are stolen.`
    },
    {
      desc: `It offers an opportunity to customize coverage - so you get exactly the policy you need.`
    },
    {
      desc: `And, it's affordable, with a variety of discounts and flexible payment options to help you keep your budget on track.`
    },
    {
      desc: `Offers deductible options and discounts that save you money.`
    }
  ]
};

export const condoCovgs1 = {
  options: [
    {
      header: 'Personal Property - ',
      desc: `Pays for damage to or theft of your personal items, including appliances, furniture, 
                    electronics and clothing. Enhance it with Personal Property Replacement Cost. 
                    You'll get paid the full cost of an item - not a depreciated value - if it needs to be replaced.`
    },
    {
      header: 'Dwelling - ',
      desc: `Covers fire or storm damage to interior walls and floors - the parts of your condo not covered under the association's policy**`
    },
    {
      header: 'Loss of use - ',
      desc: `Provides living expenses if your condo is not fit to live in due to a covered claim.`
    }
  ]
};

export const condoCovgs2 = {
  options: [
    {
      header: 'Personal Liability - ',
      desc: `Protects you if you're liable for another person's property damage or injury`
    },
    {
      header: 'Medical Payments - ',
      desc: `Pays medical expenses incurred by another person because of something you've done, regardless of your legal liability`
    },
    {
      header: 'Loss Assessment - ',
      desc: `Covers common loss assessments levied by your association (to repair a damaged roof, for instance)`
    }
  ]
};
