import {firebaseConfigQUA} from '../analytics/firebase/FirebaseUtils';
import {Environment} from './EnvironmentTypes';

const BASE_URL = 'https://stillwaterinsurance.com/';

export const baseEnvironment: Environment = {
  base: true,
  baseUrl: BASE_URL,
  reqTimeout: 245000,
  googleMapApiKey: 'AIzaSyCJ76ZpeMJPlKSoFei_qbnEv786U0 - heeY',
  firebaseConfig: firebaseConfigQUA
};
