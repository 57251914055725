import PolicyFAQ from '@components/pages/PolicyFAQ';

const BusinessInsFAQ = () => {
  const businessFAQData = {
    title: `Business Insurance FAQ | Stillwater Insurance`,
    header: `Business Insurance FAQ`,
    leftParagraphs: [
      {
        id: 1,
        label: `What is the difference between General Liability (GL) and Business Owners Policy (BOP)? `,
        desc: `General Liability protects you and your business from general claims involving bodily injury and property damage. It does
			not cover damages to your business's property.  A Business Owners Policy, meanwhile, bundles General Liability and Commercial
			Property into one product. In addition to providing liability protection, it also covers damage to your building and 
			supplies, covers the loss of income if your business is interrupted due to a catastrophe, and also offers cyber protection.`
      },
      {
        id: 2,
        label: `What basic insurance do I need for my small business? `,
        list: [
          {desc: `Property Insurance`},
          {desc: `Liability Insurance`},
          {desc: `Business Vehicle Insurance`},
          {desc: `Workers Compensation Insurance`},
          {
            desc: `Errors and Omissions Insurance (E&O)/Professional Liability Insurance`
          },
          {desc: `Employment Practices Liability Insurance (EPLI)`},
          {desc: `Directors and Officers Liability Insurance (D&O)`},
          {desc: `Business Identity Theft Insurance`}
        ]
      },
      {
        id: 3,
        label: `What is the difference between general and professional liability insurance for my business?  `,
        desc: `General liability protects you against claims for bodily injury and property damage, such as someone tripping over a cord in 
							the office and falling. Professional liability is insurance that protects you and your staff against negligence claims
							relating to your delivery of professional services; for example, offering what someone perceived as bad advice.`
      },
      {
        id: 4,
        label: `When should I renew my business insurance?  `,
        desc: ` At the end of the coverage`
      },
      {
        id: 5,
        label: `Does business insurance cover loss of wages?  `,
        desc: `Most standard business insurance
						policies cover only loss or damage to tangible items -- your equipment and inventory and your warehouse, office or 
						store -- and not lost profits if your business cannot operate. To get business interruption coverage added to your 
						business policy, you-ll need to document your current net income.`
      },
      {
        id: 6,
        label: `Is BOP the same thing as Commercial Package Policy (CPP)?  `,
        desc: `No.A BOP policy is designed for small-to medium-sized companies. It combines protection for all major property and liability
							risks into one policy. CPP policies are intended for larger companies with greater, more complex risks that require more unique solutions, services, more 
							underwriting and substantially different pricing.`
      }
    ],
    rightParagraphs: [
      {
        id: 7,
        label: `What business insurance is required by law? `,
        desc: `Business insurance is required by law, but only under certain conditions. The following business insurance is required by law if it is applicable to your situation: Unemployment insurance: Applies to a business that has employees and may be obligated to pay unemployment insurance taxes under prescribed conditions; if these conditions are applicable to your business, then you must register your business with the state work force's agency.`,
        list: [
          {
            label: `Workers compensation insurance: `,
            desc: `If your business has employees, you are most likely legally obligated to carry workers'
							compensation insurance, either on a self-insured basis or through a commercial insurance carrier or a state worker's 
							compensation program. Workers compensation laws vary by state. Professional liability insurance: Some states require 
							specified professionals to carry insurance against professional liability.`
          },
          {
            label: `Disability insurance: `,
            desc: `Several states require that a business have partial wage replacement insurance coverage for 
							employees eligible for non-work related injury or illness. These states include California, Hawaii, New Jersey, New York, 
							Puerto Rico and Rhode Island.`
          }
        ]
      },
      {
        id: 8,
        label: `How do I figure out how much business insurance I need? `,
        desc: `The cost of business insurance varies. 
					A number of factors affect how much business insurance costs, because it depends on the type of business and the types of coverage appropriate 
					for that commercial operation. Cost also depends on the size of the business. A small, home-based business can often be adequately insured 
					for $500 per year, while insurance for a large company with many employees and a wide range of business risks could $500,000 per year.`
      },
      {
        id: 9,
        desc: `The costs of business insurance can be reduced with effective risk management practices, and by comparing costs from several different 
					insurance carriers. An independent agent in the Trusted Choice network who specializes in commercial insurance can help with this process, 
					and can manage a company's complete business insurance portfolio through one office.`
      },
      {
        id: 10,
        label: `Is a BOP policy right for me? `,
        desc: `A BOP policy makes sense if your business is located outside of your home, (in-home is acceptable with no foot-traffic) is located in a building 
							you own under 35,000 square feet, has less than 50 employees and less than $6 million in annual sales.`
      },
      {
        id: 11,
        label: `What's not covered under a BOP policy? `,
        desc: `A BOP policy does not cover professional liability/professional services (though that is available for a few business types), workers' compensation, 
							disability, or health insurance. These will need to be purchased separately.`
      }
    ]
  };
  return <PolicyFAQ faq={businessFAQData} />;
};

export default BusinessInsFAQ;
