import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const ScrollToTopOfUI = ({excludePaths = []}: {excludePaths?: string[]}) => {
  const location = useLocation(); // Get the current location
  useEffect(() => {
    // Scroll to the top of the page
    if (!excludePaths.includes(location.pathname)) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }, [location, excludePaths]); // Trigger whenever the UI changes

  return null; // This component doesn't render anything
};

export default ScrollToTopOfUI;
