import ScrollToTopOfUI from '@components/scroll/ScrollToTopOfUI';
import {Footer} from './footer/Footer';
import {Header} from './header/Header';
import {Section} from './section/Section';

export const Layout = () => {
  return (
    <>
      <ScrollToTopOfUI excludePaths={['/glossary']} />
      <Header></Header>
      <Section></Section>
      <Footer></Footer>
    </>
  );
};
