import BgBluePanel from '@components/panel/header/BgBluePanel';
import './ContactUs.scss';
import LeftContactPanel from './LeftContactPanel';
import RightContactPanel from './RightContactPanel';
import PageTitle from '@components/form/PageTitle';

const ContactUs = () => {
  return (
    <>
      <PageTitle>
        Contact Us - Stillwater Insurance Phone Number | Stillwater Insurance
      </PageTitle>
      <BgBluePanel headerName="CONTACT US" />
      <div className="container mt-3">
        <div className="row">
          <LeftContactPanel />
          <RightContactPanel />
        </div>
      </div>
    </>
  );
};

export default ContactUs;
