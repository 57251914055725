import {Modal} from 'react-bootstrap';
import {ICON_LIST} from '../../../icons';
import ThemeIcon from '../../../icons/ThemeIcon';

const ProductUnavailableModal = ({
  open,
  close,
  dialogClass
}: {
  open: boolean;
  close: () => void;
  dialogClass?: string;
}) => {
  return (
    <Modal
      backdrop="static"
      centered={false}
      show={open}
      onHide={close}
      dialogClassName={dialogClass}
    >
      <div className="modal-body home-modal-text">
        <div className="float-end">
          <button
            type="button"
            className=" top-2 right-2 border-0 bg-white focus:outline-none"
            onClick={close}
          >
            <ThemeIcon
              iconName={ICON_LIST.CLOSE}
              width="10"
              height="15"
              onClick={close}
            />
          </button>
        </div>
        <div className="pt-3">
          We do not currently offer the selected product in this zip code. Thank
          you for inquiring with the Stillwater Insurance Group.
        </div>
      </div>
    </Modal>
  );
};

export default ProductUnavailableModal;
