import PageTitle from '@components/form/PageTitle';
import CustomParagraphList from '@components/form/paragraph/CustomParagraphList';
import {CustomParagraphProps} from '@components/form/paragraph/CustomParagraphTypes';

interface FaqProps {
  title: string;
  header: string;
  leftParagraphs: CustomParagraphProps[];
  rightParagraphs?: CustomParagraphProps[];
}

const PolicyFAQ = ({faq}: {faq: FaqProps}) => {
  return (
    <>
      <PageTitle>{faq.title}</PageTitle>
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1 className="asH3">{faq.header}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 pe-md-4 pe-lg-5">
          <CustomParagraphList paragraphs={faq.leftParagraphs} />
        </div>
        {faq.rightParagraphs && (
          <div className="col-sm-6 pe-md-4 pe-lg-5">
            <CustomParagraphList paragraphs={faq.rightParagraphs} />
          </div>
        )}
      </div>
    </>
  );
};

export default PolicyFAQ;
