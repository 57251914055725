import {Link} from 'react-router-dom';
import {GradeInfo} from '../WelcomeTypes';
import Grade from './grade/Grade';

const BottomPanel = () => {
  return (
    <div className="jumbotron my-5 bg-info bg-grade">
      <div className="container">
        <div className="row">
          <p className="grade-h2 my-0">
            WHEN IT COMES TO PROTECTING YOUR ASSETS,
          </p>
          <p className="grade-h1 text-white my-0">WE MAKE THE GRADE.</p>
        </div>
        <div className="row">
          <div className="col-md-5">
            <ul className="custom-bullet checkmark-white text-white style-worksans">
              {GradeInfo.map(grade => (
                <Grade key={grade.intialText} gradeInfo={grade} />
              ))}
            </ul>
          </div>
          <div className="col-md-7 mt-4">
            <Link
              to="/our-accreditations-and-awards"
              className="btn btn-white btn-ratings border-black px-5 py-3 text-smaltblue font-weight-normal text-14 style-worksans"
            >
              Check Out Our Rankings
            </Link>
          </div>
        </div>
        <br />
        <div className="row text-white font-weight-normal text-12 style-worksans">
          <span>
            *This rating is granted to Stillwater Insurance Company and
            Stillwater Property and Casualty Insurance Company.
          </span>
        </div>
      </div>
    </div>
  );
};

export default BottomPanel;
