import {EVENT_LIST} from '../../analytics/firebase/EventMap';
import {logFirebaseEvent} from '../../analytics/firebase/FirebaseAnalytics';
import {getBaseUrl} from '../../environment/Environment';

import {Helmet} from 'react-helmet-async';

export interface WfcChatProps {
  type: 'customer-service' | 'claims';
}

export function WfcChat({type}: WfcChatProps) {
  if (type === 'claims') return <WfcChatClaims />;
  else return <WfcChatCustomerService />;
}

declare global {
  // implementation in static5/wfcchat.js
  const wfcChat: {
    initClaimsSession(): void;
    initNonClaimsSession(): void;
  };
}

const WfcChatClaims = () => {
  const onSelectClaimsChat = () => {
    logFirebaseEvent({eventName: EVENT_LIST.CONTACT_US_CHAT_SUCCESS});
    wfcChat.initClaimsSession();
  };

  return (
    <div>
      <Helmet>
        <script src={getBaseUrl() + 'static5/resources/common/js/wfcchat.js'} />
      </Helmet>
      <a
        onClick={() => {
          onSelectClaimsChat();
        }}
      >
        <img
          src={
            getBaseUrl() + 'static5/resources/common/img/chatlaunch-claims.png'
          }
          alt="Claims Online Chat"
        />
      </a>
    </div>
  );
};

const WfcChatCustomerService = () => {
  const onSelectGeneralInquiriesChat = () => {
    logFirebaseEvent({eventName: EVENT_LIST.CONTACT_US_CHAT_SUCCESS});

    wfcChat.initNonClaimsSession();
  };

  return (
    <div>
      <Helmet>
        <script src={getBaseUrl() + 'static5/resources/common/js/wfcchat.js'} />
      </Helmet>
      <a
        onClick={() => {
          onSelectGeneralInquiriesChat();
        }}
      >
        <img
          src={getBaseUrl() + 'static5/resources/common/img/chatlaunch.png'}
          alt="Customer Service Online Chat"
        />
      </a>
    </div>
  );
};
