import {
  ProductFields,
  Products2Data,
  rightArrow,
  xmlIns
} from '@modules/welcome/WelcomeTypes';
import Product from './Product';
import {InActiveButtons} from './ProductTypes';

const Product2 = ({
  btnType,
  updatePanel,
  updateActiveBtn
}: {
  btnType: string;
  updatePanel: (val: string) => void;
  updateActiveBtn: (val: string) => void;
}) => {
  return (
    <>
      <div className={`row ms-2 d-none d-md-block`}>
        <ul className="list-inline">
          <li className="list-inline-item">
            <button
              id="p-back"
              className="text-white btn-prev"
              onClick={() => updatePanel('products1')}
            >
              <span>
                <svg
                  xmlns={xmlIns.name}
                  xmlnsXlink={xmlIns.link}
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                >
                  <image
                    data-name="angle left"
                    x="1"
                    width="18"
                    height="30"
                    xlinkHref={rightArrow.image}
                  />
                </svg>
              </span>
            </button>
          </li>
          {Products2Data.map((product: ProductFields) => (
            <Product
              key={product.buttonName}
              product={product}
              updateBtn={() => updateActiveBtn(product.buttonName)}
              activeButton={btnType}
            />
          ))}
        </ul>
      </div>
      {InActiveButtons.indexOf(btnType) == -1 && (
        <div id="emptydiv" className="productdiv-hght"></div>
      )}
    </>
  );
};

export default Product2;
