import {createContext, ReactNode, useState} from 'react';

export interface GoogleMapLoadProps {
  children: ReactNode;
}

const GoogleMapLoadScriptContext = createContext(null as any);

const GoogleMapLoadScripProvider = ({children}: GoogleMapLoadProps) => {
  const [isGoogleLoadedScript, setGoogleLoadedScript] =
    useState<boolean>(false);
  return (
    <GoogleMapLoadScriptContext.Provider
      value={{isGoogleLoadedScript, setGoogleLoadedScript}}
    >
      {children}
    </GoogleMapLoadScriptContext.Provider>
  );
};

export {GoogleMapLoadScriptContext, GoogleMapLoadScripProvider};
