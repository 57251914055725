import {ICON_LIST} from '../../../icons';

export const businessInsData = {
  backgroundImgClass: 'bg-business',
  header: 'GET A BUSINESS INSURANCE QUOTE TODAY',
  mobileHeader: 'GET',
  mobileHeader2: 'A BUSINESS INSURANCE QUOTE TODAY',
  desc: 'Go from zero to protected in 4 minutes flat.',
  type: 'Business'
};
export const businessInsCardPanelInfo = [
  {
    cardClass:
      'card border-transferent border-bottom-0 border-top-0 border-start-0 mb-4',
    image: ICON_LIST.BUSINESS,
    cardHeader: 'What is BOP policy?',
    headerClass: 'h-25',
    cardDescription:
      'A Business Owners Policy (BOP) is a "package" of essential insurance coverages designed for small to medium-sized companies.',
    btnLink: '/know-how/what-is-BOP-policy',
    btnText: 'Find out what a BOP Policy is'
  },
  {
    cardClass: 'card border-transferent border-0 mb-4',
    image: ICON_LIST.WANDMAGIC,
    cardHeader: 'Top 5 myths about Business Insurance..',
    headerClass: 'h-25',
    cardDescription:
      'Being your own boss comes with all kinds of perks, like getting to work on something you are passionate about and working on your own terms. But it also comes with worry over risks associated with owning your own business.',
    btnLink: '/know-how/myths-business',
    btnText: 'Top 5 myths about Business Insurance'
  },

  {
    cardClass:
      'card border-transferent border-bottom-0 border-top-0 border-end-0 mb-4',
    image: ICON_LIST.BUSINESS,
    cardHeader: 'General Liability vs. BOP',
    headerClass: 'h-25',
    cardDescription:
      "It's important to understand the difference between General Liability Insurance and a Business Owner's Policy (BOP).",
    btnLink: '/know-how/general-liability',
    btnText: 'Discover the difference'
  }
];

export const essentialCovgOptions = {
  options: [
    {
      header: 'Property coverage - ',
      desc: `Pays to repair or replace your buildings, furniture, equipment or computers destroyed or damaged in a fire or other incident.`
    },
    {
      header: 'Business income coverage - ',
      desc: `Reimburses loss of income if your business is disrupted due to a fire or other incident. Also pays extra expenses related to operating out of a temporary location.`
    },
    {
      header: 'Liability coverage - ',
      desc: `Pays if your company is responsible for injury or property damage caused by trips and falls, product mishaps, faulty installations or other errors.`
    }
  ]
};
