import ThemeIcon from '../../../icons/ThemeIcon';
import {panelOptions} from './KnowHowTypes';
import {useNavigate} from 'react-router-dom';

const KnowHowBody = () => {
  const navigate = useNavigate();
  return (
    <div className="d-none d-md-block">
      <div className="container">
        <h4 className="my-3 fw-bolder">Know How to Insurance Articles</h4>
        <div className="row">
          {panelOptions.map((option, optionIndex) => (
            <div key={optionIndex} className="col-lg-6 px-0">
              <div className="card border-0 ">
                <div className="card-header py-2 ms-3 me-1 rounded-0 bg-darkblue">
                  <h6 className="text-white m-0">{option.name}</h6>
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    {option.items.map((item, itemIndex) => (
                      <div
                        key={itemIndex}
                        className="col-md-4 d-flex pe-0 test"
                        onClick={() => navigate(`${item.link}`)}
                      >
                        <div className="card rounded-0 border-blue my-2 know-how-card">
                          <p className="image mt-3 text-center">
                            <ThemeIcon
                              iconName={option.image}
                              fill="#003295"
                              width={option.width}
                              height={option.height}
                            />
                          </p>
                          <div
                            className={`card-body ${option.titleClass} text-center h-100`}
                          >
                            <h6 className="card-title text-14 blue">
                              {item.title}
                            </h6>
                            <p
                              className={`card-text text-12 light ${option.textClass}`}
                            >
                              {item.text}
                            </p>
                          </div>
                          <div className="card-footer text-center border-0 bg-transparent">
                            <span
                              className="text-darkblue bold"
                              onClick={() => navigate(`${item.link}`)}
                            >
                              Learn More
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KnowHowBody;
