import {empFeedbackDesc} from './CareerTypes';

const RightFeedbackPanel = ({feedback}: {feedback: empFeedbackDesc}) => {
  return (
    <div className="row">
      <div className="offset-md-3 col-md-7 ps-md-0">
        <h5 className="careerTxt">{feedback.desc}</h5>
        <p className="mb-5 mb-lg-0 empttSignature">{feedback.signature}</p>
      </div>
      <div className="col-md-2 pe-md-0 order-first order-md-2">
        <div>
          <img
            src={feedback.image}
            className="img-fluid border-pacificblue"
            alt={feedback.signature}
          />
        </div>
        <br />
      </div>
    </div>
  );
};

export default RightFeedbackPanel;
