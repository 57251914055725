import {umbrellaInsCardPanelInfo, umbrellaInsData} from './UmbrellaInsPrdtData';
import './UmbrellaInsPrdt.scss';
import ProductPanel from '../../../components/panel/product/ProductPanel';
import CardPanel from '../../../components/panel/card/CardPanel';
import PageTitle from '@components/form/PageTitle';
import UmbrellaInsCovg from './UmbrellaInsCovg';

const UmbrellaInsProduct = () => {
  return (
    <>
      <PageTitle>
        Umbrella Insurance: Get an Umbrella Insurance Quote | Stillwater
        Insurance
      </PageTitle>
      <ProductPanel product={umbrellaInsData} />
      <CardPanel cardData={umbrellaInsCardPanelInfo} />
      <UmbrellaInsCovg />
    </>
  );
};

export default UmbrellaInsProduct;
