import {ICON_LIST} from '../../../icons';

export const rentersInsData = {
  backgroundImgClass: 'bg-renters',
  header: 'GET A RENTERS INSURANCE QUOTE TODAY',
  mobileHeader: 'GET A RENTERS INSURANCE QUOTE TODAY',
  desc: "And start protecting what's yours.",
  type: 'Renters'
};

export const rentersInsCardPanelInfo = [
  {
    cardClass:
      'card border-transferent border-bottom-0 border-top-0 border-start-0 mb-4',
    image: ICON_LIST.BUILDING,
    cardHeader: 'Renters insurance 101',
    headerClass: 'h-50',
    cardDescription: `Do you really need Renters insurance? The short answer is yes - simply because stuff happens that is completely out of your control.`,
    btnLink: '/know-how/renters-insurance-101',
    btnText: 'Renters Insurance 101'
  },
  {
    cardClass: 'card border-transferent border-0 mb-4',
    image: ICON_LIST.DOLLAR,
    cardHeader: 'What goes into the price of renters insurance?',
    headerClass: 'h-50',
    cardDescription:
      'When shopping for a Renters insurance policy that covers your needs, you should be aware of several factors that affect the price insurance companies charge.',
    btnLink: '/know-how/pricing-renters',
    btnText: 'Renters Insurance Pricing'
  },

  {
    cardClass:
      'card border-transferent border-bottom-0 border-top-0 border-end-0 mb-4',
    image: ICON_LIST.WANDMAGIC,
    cardHeader: 'Top 5 myths about renters insurance',
    headerClass: 'h-50',
    cardDescription:
      'When looking for a place to rent, there are several factors to take into consideration before making a decision.',
    btnLink: '/know-how/myths-renters',
    btnText: 'Top 5 Renters Insurance Myths'
  }
];

export const policyReimburses = {
  options: [
    {
      desc: 'It covers losses caused by theft, fire, smoke, storm damage, water overflow and electrical surges'
    },
    {
      desc: 'It provides living expenses if your property is not fit to live in due to a covered loss.'
    },
    {
      desc: `It includes both Personal Liability (protects you if you cause another's injury or property damage),
              and Medical Coverage (pays medical expenses related to a covered incident)`
    }
  ]
};
