import PolicyMyths from '@components/pages/PolicyMyths';

const MythsBusiness = () => {
  const paragraphs = [
    ` Being your own boss comes with all kinds of perks, like getting to work on 
				something you are passionate about and working on your own terms. But it also 
				comes with worry over risks associated with owning your own business.`,
    ` One way to mitigate that risk is by purchasing a Business Owners Policy (BOP) 
				which combines protection for all major property and liability risks into one policy. 
				However, many small business owners neglect this necessary safeguard because 
				they don't understand how a BOP policy works, or they assume they don't need it.`,
    ` To help you tell fact from fiction and feel more comfortable about your decision, 
				here are five BOP myths, debunked:`
  ];
  const bopLeftMyths = [
    {
      id: 1,
      header: `Myth #1`,
      label: `My business is not big enough to purchase insurance. `,
      desc: ` A BOP policy, 
				like all insurance policies, is there to protect you when the unexpected happens. 
				In addition to protecting your assets, a BOP policy also reimburses you for loss 
				of income if your business is disrupted, and it provides General Business Liability 
				protection if your company is responsible for injury or property damage. 
				The reality is, the smaller your business, the bigger the impact one of these events 
				can have on your bottom line. This is peace of mind to protect your livelihood and 
				all that you've built. `
    },
    {
      id: 2,
      header: `Myth #2`,
      label: `My homeowners insurance covers my home-based business. `,
      desc: `False. Your homeowners insurance
				provides you coverage in your home as a resident, not as a place of business. And if 
				you don't disclose  to your home insurance company that you are running a business out of 
				your home, your home insurance provider could void your policy altogether. You should 
				alert your home insurance carrier if you are running a business out of your home to 
				obtain the appropriate coverage. Keep in mind some carriers are not able to provide 
				home-based business insurance coverage, so you should be prepared to find a new 
				company if that is the case.`
    },
    {
      id: 3,
      header: `Myth #3`,
      label: `I'm covered for all risks with my General Liability Insurance. `,
      desc: `False. General 
				Liability Insurance does not cover workplace injuries that happen to employees or other 
				people on your payroll; this is generally covered by Workers' Compensation. It also does 
				not cover the cost of damages to your own business property. Typically, it only helps 
				cover the costs of damage your business causes to others who are not yourself or your employees.`
    }
  ];

  const bopRightMyths = [
    {
      id: 4,
      header: `Myth #4`,
      label: `My business is too small to worry about a cyber-attack. `,
      desc: ` False.  According to a 
				recent study cited by the U.S. House Small Business Subcommittee on Health and Technology, 
				nearly 20% of all cyber-attacks hit small businesses with 250 or fewer employees. And to make
				 matters worse, roughly 60% of small businesses close within six months of a cyber-attack.
				 Further, as larger companies invest more to fight these threats, cyber criminals are likely
				 to turn to businesses that may be more susceptible.`
    },
    {
      id: 5,
      header: `Myth #5`,
      label: `My business is a corporation, and that protects me from liability. `,
      desc: ` False. Being a 
				corporation only protects the individual investors, owners and officers from personal 
				liability for actions and debts of the corporation for corporate purposes. There are laws 
				that allow the "corporate veil of protection" to be removed under certain circumstances, 
				which, in this case, can make the owner personally liable. Laws on this vary from state 
				to state, but the smaller you are, the more likely this could occur.`
    },
    {
      id: 6,
      desc: `The bottom line: you should not let misconceptions about BOP insurance stop you from making sure your livelihood is protected.`
    }
  ];

  const bopMyths = {
    header: `5 Myths About Business Insurance`,
    paragraphs: paragraphs,
    mythLeftOptions: bopLeftMyths,
    mythRightOptions: bopRightMyths,
    policyType: `Business`
  };
  return <PolicyMyths myths={bopMyths} />;
};

export default MythsBusiness;
