export const getErrorMessage = (error: any): string => {
  if (typeof error === 'string') return error;
  else if (error instanceof Error) return error.message;
  else if (
    typeof error === 'object' &&
    Array.isArray(error.errors) &&
    error.errors.length > 0 &&
    typeof error.errors[0].errorDescription === 'string'
  )
    return error.errors[0].errorDescription;
  else return JSON.stringify(error);
};
