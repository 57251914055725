import {ICON_LIST} from '../../../icons';

export const landlordInsData = {
  backgroundImgClass: 'bg-landlord',
  header: 'GET A LANDLORD INSURANCE QUOTE TODAY',
  mobileHeader: 'GET A LANDLORD INSURANCE QUOTE TODAY',
  desc: 'And start protecting your tenant-occupied property.',
  type: 'Landlord'
};

export const landlordCardData = [
  {
    cardClass:
      'card border-transferent border-bottom-0 border-top-0 border-start-0 mb-4',
    image: ICON_LIST.KEY,
    cardHeader: 'Landlord Insurance 101',
    headerClass: 'h-50',
    cardDescription:
      'Landlord insurance rolls multiple forms of coverage into one policy.',
    btnLink: '/know-how/landlord-insurance-101',
    btnText: 'Landlord Insurance 101'
  },
  {
    cardClass: 'card border-transferent border-0 mb-4',
    image: ICON_LIST.KEY,
    cardHeader: 'What type of insurance does a landlord need?',
    headerClass: 'h-50',
    cardDescription:
      'As a landlord, understanding the difference between what kind of insurance is needed for both long-term and short-term rentals is essential.',
    btnLink: '/know-how/landlord-insurance-needs',
    btnText: 'Landlord insurance needs'
  },

  {
    cardClass:
      'card border-transferent border-bottom-0 border-top-0 border-end-0 mb-4',
    image: ICON_LIST.WANDMAGIC,
    cardHeader: 'Top 5 myths about landlord insurance',
    headerClass: 'h-50',
    cardDescription:
      'There are several myths about landlord insurance that can add to your stress level and cause even more confusion.',
    btnLink: '/know-how/myths-landlord',
    btnText: 'Top 5 Landlord insurance myths'
  }
];
export const landlordCovgOptions = {
  options: [
    {
      desc: `Cover your house, garage, shed and other structures. At a minimum, the policy protects against the most common perils of fire, collapse, explosion, hail, lightning, smoke, vandalism and wind`
    },
    {
      desc: `Get Fair Rental Income Coverage. This pays your rental income if the property is uninhabitable due to a covered loss`
    },
    {
      desc: `Get Liability Coverage. This protects you if you're deemed responsible for an injury on your property`
    },
    {
      desc: `Cover many rental homes considered "ineligible risks" on standard homeowners contracts due to being tenant occupied`
    },
    {
      desc: `Insure properties owned by corporations, LLC's, trusts and associations`
    }
  ]
};
