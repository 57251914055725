import {getBaseUrl} from '../../../environment/Environment';
import {ServiceErrorResponseType} from '../../../services/BaseMapper';
import ServiceRequest from '../../../services/ServiceRequest';

const reviewPercentageUrl = getBaseUrl() + 'Common/reviews/customer/percentage';
const reviewCountUrl = getBaseUrl() + 'Common/reviews/customer/published/count';
const reviewListUrl = getBaseUrl() + 'Common/reviews/customer/display/list';

export const initialReviewPercentage = {
  fiveStar: 0,
  fourStar: 0,
  threeStar: 0,
  twoStar: 0,
  oneStar: 0,
  yesQuesTyp: 0,
  noQuesTyp: 0,
  total: 0,
  recommended: 0
};

export interface reviewData {
  reviewCount: number;
  reviewStarPercentageList: reviewPercentageMap[];
  reviewYNPercentageList: reviewPercentageMap[];
  reviewInfo: reviewDetails[];
  starRating: number;
}

export interface reviewerAnswer {
  name: string;
  type: string;
  answer: string;
}

export interface reviewDetails {
  id: number;
  reviewDate: string;
  reviewerFirstName: string;
  reviewerState: string;
  reviewerComments: string;
  reviewerAnswers?: reviewerAnswer[];
  ourResponse?: string;
  reviewDateFormatted?: string;
}
export interface reviewPercentage {
  fiveStar: number;
  fourStar: number;
  threeStar: number;
  twoStar: number;
  oneStar: number;
  yesQuesTyp: number;
  noQuesTyp: number;
  total: number;
  recommended: number;
  starRating: number;
}
export interface reviewPercentageMap {
  label: string;
  value: number;
}
export const convertStarPercentageMap = (response: reviewPercentage) => {
  const list = [];
  if (response.fiveStar) {
    list.push({
      label: '5 Star',
      value: response.fiveStar
    });
  }
  if (response.fourStar) {
    list.push({
      label: '4 Star',
      value: response.fourStar
    });
  }
  if (response.threeStar) {
    list.push({
      label: '3 Star',
      value: response.threeStar
    });
  }
  if (response.twoStar) {
    list.push({
      label: '2 Star',
      value: response.twoStar
    });
  }
  if (response.oneStar) {
    list.push({
      label: '1 Star',
      value: response.oneStar
    });
  }
  return list;
};

export const convertYNPercentageMap = (response: reviewPercentage) => {
  const list = [];
  if (response.yesQuesTyp) {
    list.push({
      label: 'Yes',
      value: response.yesQuesTyp
    });
  }
  if (response.noQuesTyp) {
    list.push({
      label: 'No',
      value: response.noQuesTyp
    });
  }
  return list;
};

export type ResponseMapper = reviewPercentage & ServiceErrorResponseType;

export const retrieveReviewPercentage = async (): Promise<ResponseMapper> => {
  return await ServiceRequest.get(reviewPercentageUrl);
};

export const retrieveTotalReviewCount = async (): Promise<
  number & ServiceErrorResponseType
> => {
  return await ServiceRequest.get(reviewCountUrl);
};

export const retrieveDisplayreviewDetails = async (): Promise<
  reviewDetails[] & ServiceErrorResponseType
> => {
  return await ServiceRequest.get(reviewListUrl);
};
