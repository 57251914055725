import CustomBulletList from '../../../../../../components/custom-list/CustomBulletList';
import FindAnAgentListItem from '../../FindAnAgentListItem';
import RtrnKnowledgeBase from '../../RtrnKnowledgeBase';

const UmbrellaInsurance = () => {
  const umbrellaCovgOptions = {
    options: [
      {
        desc:
          'Serve on the board of a non-profit or coach youth sports? Corporate board of directors' +
          ' are usually protected from personal liability, but those working for charities or' +
          " non-profits often don't get the same protection."
      },
      {
        desc:
          " Regularly post reviews of products and businesses on social media? It's no secret" +
          ' that libel and defamation verdicts are on the rise, and lawsuits could result from something you post.'
      },
      {
        desc:
          'Participate in skiing, surfing or hunting? Participation in a sport where you' +
          ' could accidentally  injure another person increases your likelihood of being sued.'
      },
      {
        desc:
          'Host large parties? The more people you have in your home at one time,' +
          ' the higher the likelihood of injury that could result in you being held liable.'
      }
    ]
  };
  return (
    <div className="container mt-4">
      <RtrnKnowledgeBase />
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="asH3">Do I Need Umbrella Insurance?</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 pe-md-4">
          <p>
            {' '}
            A commonly asked question when considering purchasing Umbrella
            insurance is, "Do I really need it?" The answer to that questions
            depends on two things: (1) Do you own anything of value; and (2) How
            much risk do you have in your life?
          </p>

          <p>
            {' '}
            Some people assume that they have enough liability protection from
            their Auto or Home insurance policies, but that's not easy to
            predict. Umbrella insurance is a secondary type of insurance that
            will cover your liability legal fees and judgment expense if your
            primary coverage is exhausted.{' '}
          </p>

          <p>
            {' '}
            If you don't own a home, have little or no net worth and have a
            low-risk lifestyle, you may not need Umbrella insurance. However,
            most people should consider buying a policy, especially if you can
            answer "yes" to any of the below questions.
          </p>
          <br />

          <h4 className="bold">
            The top 10 signs you may need an Umbrella insurance policy:
          </h4>
          <ol>
            <li>
              {' '}
              Are you a homeowner? It's easy to be held liable for something
              that happens on your property, which is why Umbrella insurance is
              so popular.
            </li>

            <li>
              {' '}
              Do you have a pool, hot tub or trampoline? Unfortunately, these
              types of things that bring such comfort to homeowners are
              statistically likely sources of injury and lawsuits.
            </li>

            <li>
              {' '}
              Do you own a dog? If your dog happens to bite someone - even if
              it's away from your home - the lawsuit could exceed the liability
              coverage you currently have on your Homeowners insurance policy.
            </li>

            <li>
              {' '}
              Are you a parent of a young driver? It's been well documented that
              teenage drivers are involved in more severe crashes due to
              distractions and lack of experience.
            </li>

            <li>
              {' '}
              Do you own vacant land that the public can access? If someone gets
              hurt on your land - even if they are uninvited - they can sue for
              damages. This becomes even riskier if you lease the land out for
              hunting.
            </li>

            <li>
              {' '}
              Do you have a long commute for work or drive during rush hour on a
              regular basis? The more time you spend in a car, the higher the
              risk of getting into an accident.
            </li>

            <li>
              {' '}
              Do you own rental property? The more tenants you have, the higher
              your lawsuit risk.
            </li>

            <li>
              {' '}
              Do you have significant savings or assets? Without an Umbrella
              insurance policy, these may be at risk if the judgment against you
              exceeds your current liability insurance coverage.
            </li>

            <li>
              {' '}
              Do you want to protect future earnings? You may think that you
              don't need an Umbrella insurance policy because your current net
              worth is low. What several people fail to realize is that your
              future earnings are also at risk of being garnished if you are
              sued.
            </li>

            <li>
              {' '}
              Do you own recreational vehicles? The more time you spend on these
              vehicles, the higher the risk of having an accident.
            </li>
          </ol>
        </div>
        <div className="col-md-6 ps-md-4">
          <p>
            Additionally, if you engage in any of these activities, you may need
            an Umbrella insurance policy:
          </p>
          <CustomBulletList listOptions={umbrellaCovgOptions} />
          <br />

          <p>
            It's not too difficult to make the case that just about anyone can
            benefit from Umbrella insurance. A large lawsuit can wipe out not
            only your current savings, but also what you stand to earn in the
            future.
          </p>
          <p>
            Like an umbrella on a sunny day, an Umbrella insurance policy is
            something you don't expect to use. But when it rains, you will
            definitely be happy that you have it. For a few hundred dollars a
            year, you can have the peace of mind when the unforeseen happens.
          </p>
          <br />

          <p className="fw-bold">
            How to purchase an Umbrella policy from Stillwater:
          </p>

          <ul className="custom-bullet square">
            <li>
              {' '}
              Call us at (855) 712-4092. Our licensed reps are available Monday
              through Friday from 8am-9pm ET, and 8am-3pm ET on Saturday.
            </li>

            <li>
              {' '}
              <FindAnAgentListItem />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UmbrellaInsurance;
