import stillwater_insr_services from '@images/stillwater_insr_services_inc.png';
import stillwater_prop_casuality_comp from '@images/stillwater_prop_casuality_comp.png';
import stillwater_ins_company from '@images/stillwater_ins_company.png';
import video_thumb_nail from '@images/video_thumb_nail.png';
const CompanyRightPanel = () => {
  const companyInfo = [
    {
      header: `Establishing a brand`,
      paragraphs: [
        `In 2005, the catastrophic hurricanes Katrina, Rita and Wilma brought with them an aftermath of long-lasting changes for not only the Gulf region, but also the 
					entire property and casualty insurance industry.`,
        `The severe weather and high storm activity that followed over the next few years was an abrupt reminder of the risks borne by an insurance provider. Affirming its true focus on title 
					and mortgage services, FNF made a strategic decision to exit the property and casualty insurance market.`,
        `Not wanting to abandon what he and his employees had built - or the customers they served - Davey organized a group of investors to make a bid. On May 1, 2012, they bought 85 
					percent of the outstanding shares of FNF's P&C operations. Another investor group bought the flood operations. The remaining 15 percent of FNF's P&C operations were purchased on June 16, 2016.`,
        `Backed with capital and more than 250 experienced and passionate team members, FNF's former P&C division was rebranded as the Stillwater Insurance Group.`
      ]
    },
    {
      header: `Our Companies`,
      paragraphs: [
        `The Stillwater Insurance Group is made up of three separate companies: Stillwater Insurance Company; Stillwater Insurance Services, Inc; and Stillwater Property and Casualty Insurance Company. 
					We have offices in Jacksonville FL; Jericho NY; and Omaha NE. Our home office address is 6800 Southpoint Parkway, Suite 700 Jacksonville, FL 32216.`
      ]
    }
  ];
  return (
    <div className="col-md-6 ps-md-4">
      {companyInfo.map((info, infoIndex) => (
        <div key={infoIndex}>
          <h4 className="fw-bold">{info.header}</h4>
          {info.paragraphs.map((text, textIndex) => (
            <p key={textIndex}>{text}</p>
          ))}
        </div>
      ))}
      <div className="pt-3 pb-3">
        <img
          src={stillwater_ins_company}
          className="img-fluid"
          alt="SIC-logo"
        />
        <img
          src={stillwater_insr_services}
          className="img-fluid"
          alt="SIS-logo"
        />
        <img
          src={stillwater_prop_casuality_comp}
          className="img-fluid"
          alt="SPAC-logo"
        />
      </div>
      <video preload="none" poster={video_thumb_nail} controls>
        <source
          src="/public/videos/The-Stillwater-Story-shortversion.mp4"
          type="video/mp4"
          media="all and (max-width: 480px)"
        />
        <source
          src="/public/videos/The-Stillwater-Story.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  );
};
export default CompanyRightPanel;
