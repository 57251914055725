import HomeUtils from '../../../../utils/HomeUtils';
import logo from '../../../../../assets/images/logo-header-50h.png';

function HeaderLogo() {
  return (
    <>
      <span className="navbar-brand me-0 me-sm-2 linkStyle">
        <img
          src={logo}
          className="img-responsive me-xl-2"
          alt="Stillwater logo"
          onClick={HomeUtils.redirectToHome}
        />
      </span>
    </>
  );
}

export default HeaderLogo;
