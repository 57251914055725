import KnowHowMobileHeader from './KnowHowMobileHeader';
import KnowHowMobilePanel from './KnowHowMobilePanel';

const MobileView = () => {
  return (
    <>
      <KnowHowMobileHeader activeLink="/know-how" />
      <KnowHowMobilePanel />
    </>
  );
};

export default MobileView;
