import ParagraphText from '@components/form/paragraph/ParagraphText';
import PurchasePolicy from '@modules/knowhow/knowledge-base/knowhow-details/PurchasePolicy';
import RtrnKnowledgeBase from '@modules/knowhow/knowledge-base/knowhow-details/RtrnKnowledgeBase';

interface MythProps {
  header: string;
  paragraphs: string[];
  mythLeftOptions: MythOptionsProps[];
  mythRightOptions: MythOptionsProps[];
  policyType: string;
}

interface MythOptionsProps {
  id: number;
  header?: string;
  label?: string;
  desc: string;
}
const PolicyMyths = ({myths}: {myths: MythProps}) => {
  return (
    <div className="container mt-4">
      <RtrnKnowledgeBase />
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="asH3">{myths.header}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 pe-md-4">
          {myths.paragraphs.map((pargraph, paragraphindex) => (
            <p key={paragraphindex}>{pargraph}</p>
          ))}

          {myths.mythLeftOptions.map(myth => (
            <div className="pt-2" key={myth.id}>
              {myth.header && <h4>{myth.header}</h4>}
              <ParagraphText label={myth.label}>
                <>{myth.desc}</>
              </ParagraphText>
            </div>
          ))}
        </div>
        <div className="col-md-6 ps-md-4">
          {myths.mythRightOptions.map(myth => (
            <div className="pb-2" key={myth.id}>
              {myth.header && <h4>{myth.header}</h4>}
              <ParagraphText label={myth.label}>
                <>{myth.desc}</>
              </ParagraphText>
            </div>
          ))}
          <PurchasePolicy policyType={myths.policyType} />
        </div>
      </div>
    </div>
  );
};

export default PolicyMyths;
