import KnowHowBody from './KnowHowBody';
import KnowHowHeader from './KnowHowHeader';

const KnowHowView = () => {
  return (
    <>
      <KnowHowHeader activeOption="Knowledge Base" />
      <KnowHowBody />
    </>
  );
};

export default KnowHowView;
