import {autoInsCardPanelInfo, autoProductData} from './AutoInsPrdtData';
import './AutoInsPrdt.scss';
import ProductPanel from '../../../components/panel/product/ProductPanel';
import CardPanel from '../../../components/panel/card/CardPanel';
import AutoInsCovgPanel from './AutoInsCovgPanel';
const AutoInsProduct = () => {
  return (
    <>
      <ProductPanel product={autoProductData} />
      <CardPanel cardData={autoInsCardPanelInfo} />
      <AutoInsCovgPanel />
    </>
  );
};

export default AutoInsProduct;
