import {getBaseUrl} from '../environment/Environment';
import StringUtils from '../utils/StringUtils';
import {ServiceErrorResponseType, ServiceResponseType} from './BaseMapper';
import ServiceRequest from './ServiceRequest';

const startQuoteUrl =
  getBaseUrl() + 'quoting-service/static/quote/quoteStartUrl';

export interface StartQuoteResponse {
  redirectUrl: string;
}

export interface StartQuotePayload {
  zip5: string;
  lob: string;
  cname?: string;
  clientId?: string;
}

export type StartQuoteResponseMapper = ServiceResponseType<StartQuoteResponse> &
  ServiceErrorResponseType;

export const getLineOfBusiness = (selectedProductTyp: string) => {
  if (StringUtils.toLowerCase(selectedProductTyp) == 'business') {
    return 'bop';
  } else if (StringUtils.toLowerCase(selectedProductTyp) == 'landlord') {
    return 'DwFire';
  }
  return selectedProductTyp;
};

export const startQuoteService = async (
  values: StartQuotePayload
): Promise<StartQuoteResponseMapper> => {
  return await ServiceRequest.post(startQuoteUrl, {
    payload: values
  });
};
