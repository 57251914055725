import {homeProductData, homInsCardPanelInfo} from './HomeInsData';
import './homeInsProduct.scss';
import ProductPanel from '../../../components/panel/product/ProductPanel';
import CardPanel from '../../../components/panel/card/CardPanel';
import PageTitle from '@components/form/PageTitle';
import HomeInsCovgPanel from './HomeInsCovgPanel';

const HomeInsProduct = () => {
  return (
    <>
      <PageTitle>
        Home Insurance: Get a Home Insurance Quote | Stillwater Insurance
      </PageTitle>
      <ProductPanel product={homeProductData} />
      <CardPanel cardData={homInsCardPanelInfo} />
      <HomeInsCovgPanel />
    </>
  );
};

export default HomeInsProduct;
