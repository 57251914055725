import {memo, useContext, useEffect} from 'react';
import {GoogleMapLoadScriptContext} from '../../../context/GoogleMapLoadScripProvider';
import {useJsApiLoader} from '@react-google-maps/api';
import {getEnvironment} from '../../../environment/Environment';

const LoadGoogleMapScript = (): JSX.Element => {
  const env = getEnvironment();
  const {setGoogleLoadedScript} = useContext(GoogleMapLoadScriptContext);
  const {isLoaded} = useJsApiLoader({
    googleMapsApiKey: env.googleMapApiKey
  });
  useEffect(() => {
    {
      setGoogleLoadedScript(true);
    }
  }, [isLoaded]);
  return <></>;
};

export default memo(LoadGoogleMapScript);
