import {ICON_LIST} from '../../../icons';

export const navItems = [
  {
    id: 'sub-nav-kbase',
    desc: 'Knowledge Base',
    link: '/know-how'
  },
  {
    id: 'sub-nav-faqs',
    desc: 'FAQs',
    link: '/faqs'
  },
  {
    id: 'sub-nav-glossary',
    desc: 'Glossary',
    link: '/glossary'
  }
];

export interface mobilePanelOptions {
  name: string;
  items: [
    {
      desc: string;
      link: string;
    }
  ];
}

export const mobilePanel = [
  {
    name: 'Auto Insurance',
    items: [
      {
        desc: 'Top 5 myths about Auto Insurance',
        link: '/know-how/myths-auto'
      },
      {
        desc: 'What goes into the price of Auto Insurance?',
        link: '/know-how/pricing-auto'
      },
      {
        desc: "What's the difference between comprehensive, collision, and liability?",
        link: '/know-how/learn-the-difference'
      }
    ]
  },
  {
    name: 'Business Insurance',
    items: [
      {
        desc: 'Top 5 myths about Business Insurance',
        link: '/know-how/myths-business'
      },
      {
        desc: 'General Liability vs. BOP',
        link: '/know-how/general-liability'
      },
      {
        desc: 'What is BOP policy?',
        link: '/know-how/what-is-BOP-policy'
      }
    ]
  },
  {
    name: 'Condo Insurance',
    items: [
      {
        desc: 'What is condo loss assessment coverage?',
        link: '/know-how/condo-loss-assesment-coverage'
      },
      {
        desc: 'Top 5 myths about Condo Insurance',
        link: '/know-how/myths-condo'
      },
      {
        desc: 'Condo Insurance 101',
        link: '/know-how/condo-insurance-101'
      }
    ]
  },
  {
    name: 'Home Insurance',
    items: [
      {
        desc: 'Ten factors of pricing home insurance',
        link: '/know-how/ten-factors-pricing-home-insurance'
      },
      {
        desc: "What's the difference between actual cash value and replacement cost?",
        link: '/know-how/market-value-vs-replacement-cost'
      },
      {
        desc: 'Top 5 myths about home insurance',
        link: '/know-how/myths-home'
      }
    ]
  },
  {
    name: 'Landlord Insurance',
    items: [
      {
        desc: 'What type of insurance does a landlord need?',
        link: '/know-how/landlord-insurance-needs'
      },
      {
        desc: 'Top 5 myths about Landlord Insurance',
        link: '/know-how/myths-landlord'
      },
      {
        desc: 'Landlord Insurance 101',
        link: '/know-how/landlord-insurance-101'
      }
    ]
  },
  {
    name: 'Renters Insurance',
    items: [
      {
        desc: 'Renters Insurance 101',
        link: '/know-how/renters-insurance-101'
      },
      {
        desc: 'What goes into the price of Renters Insurance?',
        link: '/know-how/pricing-renters'
      },
      {
        desc: 'Top 5 myths about Renters Insurance',
        link: '/know-how/myths-renters'
      }
    ]
  },
  {
    name: 'Umbrella Insurance',
    items: [
      {
        desc: 'Do I need Umbrella Insurance?',
        link: '/know-how/need-umbrella'
      },
      {
        desc: 'Top 3 myths about Umbrella Insurance',
        link: '/know-how/myths-umbrella'
      },
      {
        desc: 'Umbrella Insurance 101',
        link: '/know-how/umbrella-insurance-101'
      }
    ]
  },
  {
    name: 'Claims',
    items: [
      {
        desc: 'How to combat water mitigation fraud',
        link: '/know-how/claim-waterFraud'
      },
      {
        desc: 'Five commonly asked questions about filing a claim',
        link: '/know-how/claim-fiveCommQuestions'
      },
      {
        desc: 'Auto theft: how to minimize your risk',
        link: '/know-how/claim-autoTheft'
      }
    ]
  },
  {
    name: 'General Insurance Questions',
    items: [
      {
        desc: 'Why Insurance Rates Increase',
        link: '/know-how/claim-waterFraud'
      }
    ]
  }
];

export const panelOptions = [
  {
    name: 'Auto Insurance',
    image: ICON_LIST.AUTO,
    titleClass: 'pt-2 pb-3 pt-md-4',
    textClass: 'pt-md-3',
    width: '35',
    height: '38',
    items: [
      {
        title:
          "What's the difference between comprehensive, collision, and liability?",
        text: 'Knowing the difference can help you make the right purchase.',
        link: '/know-how/learn-the-difference'
      },
      {
        title: 'Top 5 myths about Auto Insurance',
        text: "We're calling out some of the biggest myths about auto insurance to help you feel less intimidated and more informed.",
        link: '/know-how/myths-auto'
      },
      {
        title: 'What goes into the price of Auto Insurance?',
        text: 'Insurance companies use all kinds of information to try and predict how likely you are to get in an accident.',
        link: '/know-how/pricing-auto'
      }
    ]
  },
  {
    name: 'Business Insurance',
    image: ICON_LIST.BUSINESS,
    titleClass: 'pt-md-3 pb-md-3 pt-lg-5 pb-xl-4 pt-xl-4',
    textClass: 'pt-md-1',
    width: '38',
    height: '45',
    items: [
      {
        title: 'Top 5 myths about Business Insurance',
        text: 'Being your own boss comes with all kinds of perks, like getting to work on something you are passionate about and working ...',
        link: '/know-how/myths-business'
      },
      {
        title: 'General liability vs. BOP',
        text: "It's important to understand the difference between General Liability Insurance and a Business Owner's Policy (BOP).",
        link: '/know-how/general-liability'
      },
      {
        title: 'What is BOP policy?',
        text: 'A Business Owners Policy (BOP) is a package of essential insurance coverages designed for small to medium-sized companies.',
        link: '/know-how/what-is-BOP-policy'
      }
    ]
  },
  {
    name: 'Condo Insurance',
    image: ICON_LIST.HOTEL,
    titleClass: 'pt-3 pb-4',
    width: '32',
    height: '35',
    items: [
      {
        title: 'What is condo loss assessment coverage?',
        text: 'Loss assessment coverage is an add-on to your condo insurance policy that comes in handy when the community of owners are responsible for unexpected damages.',
        link: '/know-how/condo-loss-assesment-coverage'
      },
      {
        title: 'Top 5 myths about Condo Insurance',
        text: 'Purchasing a condo insurance policy may be less complex than purchasing home or car insurance, there are still many...',
        link: '/know-how/myths-condo'
      },
      {
        title: 'Condo Insurance 101',
        text: "Unlike homeowners, condo dwellers don't own the building they will live in or the land it sits on.  But they do need protection for what's inside their specific unit.",
        link: '/know-how/condo-insurance-101'
      }
    ]
  },
  {
    name: 'Home Insurance',
    image: ICON_LIST.HOME,
    titleClass: 'pt-md-3 pb-md-3 pt-lg-4 pb-lg-4 pt-xl-3 pb-xl-3',
    textClass: 'pt-md-2',
    width: '36',
    height: '32',
    items: [
      {
        title: 'Ten factors of pricing Home Insurance',
        text: 'Key factors that determine how insurance companies calculate your home insurance premium',
        link: '/know-how/ten-factors-pricing-home-insurance'
      },
      {
        title:
          "What's the difference between actual cash value and replacement cost?",
        text: '50% of homeowners don\'t understand what "actual cash value" or "replacement cost" means',
        link: '/know-how/market-value-vs-replacement-cost'
      },
      {
        title: 'Top 5 myths about Home Insurance',
        text: 'Have you thought about how you are going to protect your investment if the unthinkable happens?',
        link: '/know-how/myths-home'
      }
    ]
  },
  {
    name: 'Landlord Insurance',
    image: ICON_LIST.KEY,
    titleClass: 'pt-3 pb-4',
    width: '28',
    height: '28',
    items: [
      {
        title: 'What type of insurance does a landlord need?',
        text: 'As a landlord, understanding the difference between what kind of insurance is needed for both long-term and short-term rentals is essential.',
        link: '/know-how/landlord-insurance-needs'
      },
      {
        title: 'Top 5 myths about Landlord Insurance',
        text: 'There are several myths about landlord insurance that can add to your stress level and cause even more confusion.',
        link: '/know-how/myths-landlord'
      },
      {
        title: 'Landlord Insurance 101',
        text: 'Landlord insurance rolls multiple forms of coverage into one policy.',
        link: '/know-how/landlord-insurance-101'
      }
    ]
  },
  {
    name: 'Renters Insurance',
    image: ICON_LIST.BUILDING,
    titleClass: 'pt-2 pb-2',
    textClass: 'pt-2',
    width: '28',
    height: '28',
    items: [
      {
        title: 'Renters Insurance 101',
        text: 'Do you really need Renters insurance? The short answer is yes - simply because stuff happens that is completely out of your control.',
        link: '/know-how/renters-insurance-101'
      },
      {
        title: 'What goes into the price of Renters Insurance?',
        text: 'When shopping for a Renters insurance policy that covers your needs, you should be aware of several factors that affect the price insurance companies charge.',
        link: '/know-how/pricing-renters'
      },
      {
        title: 'Top 5 myths about Renters Insurance',
        text: 'When looking for a place to rent, there are several factors to take into consideration before making a decision.',
        link: '/know-how/myths-renters'
      }
    ]
  },
  {
    name: 'Umbrella Insurance',
    image: ICON_LIST.SHIELDCHECK,
    titleClass: 'pt-2 pb-1',
    width: '28',
    height: '33',
    items: [
      {
        title: 'Do I need Umbrella Insurance?',
        text: 'A commonly asked question when considering purchasing umbrella insurance is "Do I really need it?"',
        link: '/know-how/need-umbrella'
      },
      {
        title: 'Top 3 myths about Umbrella Insurance',
        text: 'An extra layer of protection that will help pay what you owe if you are sued and the damages exceed the liability limits of your home, auto or other insurance policies.',
        link: '/know-how/myths-umbrella'
      },
      {
        title: 'Umbrella Insurance 101',
        text: 'An umbrella insurance policy is designed to protect you from a liability claim above and beyond what your other insurance policies cover.',
        link: '/know-how/umbrella-insurance-101'
      }
    ]
  },
  {
    name: 'Claims',
    image: ICON_LIST.CLIPBOARDLIST,
    titleClass: ' pt-md-2 pb-md-3 pt-lg-3 pb-lg-4 pt-xl-2 pb-xl-3',
    width: '25',
    height: '33',
    items: [
      {
        title: 'How to combat water mitigation fraud',
        text: 'What to watch for when dealing with water mitigation contractors in water-related emergencies.',
        link: '/know-how/claim-waterFraud'
      },
      {
        title: 'Five commonly asked questions about filing a claim',
        text: 'Filing a claim can be stressful. When that time comes, know what to do and what to expect. ',
        link: '/know-how/claim-fiveCommQuestions'
      },
      {
        title: 'Auto theft: how to minimize your risk',
        text: "Auto and catalytic converter thefts are on the rise. Here's some tips on how to avoid becoming a victim of this national crime wave.",
        link: '/know-how/claim-autoTheft'
      }
    ]
  },
  {
    name: 'General Insurance Questions',
    image: ICON_LIST.LIGHTBULB,
    width: '28',
    height: '28',
    items: [
      {
        title: 'Why Insurance Rates Increase?',
        text: 'Learn about the factors affecting insurance rates nationwide.',
        link: '/know-how/why-insurance-rates-increase'
      }
    ]
  }
];

export interface Items {
  header: string;
  desc: string;
}
