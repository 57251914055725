import PageTitle from '@components/form/PageTitle';
import BgBluePanel from '@components/panel/header/BgBluePanel';

const PrivacyPolicy = () => {
  const privacyPolicyDetails = [
    {
      header: 'Privacy Policy',
      paragraphs: [
        `Stillwater Insurance is dedicated to respecting and protecting the privacy of those who visit 
                            and transact with us. When you quote or purchase a policy from us, we collect information about you
                            and the property you are insuring. This Privacy Statement explains our privacy practices,
                            including what non-public personal information (NPI) we collect, how we use it, to whom it is
                            disclosed, and how to access your personal information that we have collected.`,
        `We may amend this Privacy Statement from time to time consistent with applicable privacy laws. Each 
                            affiliated company of the Duval Holdings, Inc. Group follows the privacy practices described in this 
                            Privacy Statement. Depending on the business they perform, these affiliates may also share information 
                            as described below.`
      ]
    },
    {
      header: 'Privacy Assurance',
      paragraphs: [
        `We do not sell your personal information to anyone.`,
        `We require our employees to protect your personal information and keep it confidential.`,
        `We do not share your information with non-affiliate companies for their promotional use.`,
        `We require persons or organizations that represent or assist us in servicing your policy and`,
        `claims to keep your information confidential.`,
        `You may review and correct your information.`
      ]
    },
    {
      header:
        'We may collect Personal Information about you from the following sources:',
      paragraphs: [
        `We may collect Personal Information about you from the following sources:`,
        `Information we receive from you on applications or other forms we receive from you or your authorized representative, such as your name, address, social security number, tax identification number, asset information and income information;`,
        `Information about your transactions with us or the services being performed by us, our affiliates or others, such as information concerning your policy, premiums, payment history, information about your home or other real property, information from lenders and other third parties involved in such a transaction, credit card numbers and payment histories;`,
        `Information we receive from consumer or other reporting agencies;`,
        `Information we receive from you through your interaction with our mobile app, such as how often you use the application, events that occur within the application, aggregated usage, performance data, and where the application is downloaded from;`,
        `Information we receive from you through our internet web site(s), such as your name, address, email address, Internet Protocol address, the web site links you used to get to our site(s) and the pages viewed while at our site(s).`
      ]
    },
    {
      header: 'How we use your personal information: ',
      paragraphs: [
        `We use the personal information we collect to sell, underwrite and rate, service and administer
						insurance; to handle claims; to create and market products and services; to prevent and detect
						fraud; to satisfy legal or regulatory requirements; and for other business purposes and as
						otherwise allowed by law.`
      ]
    },
    {
      header:
        'Protection of the Confidentiality and Security of Your Personal Information',
      paragraphs: [
        `We maintain physical, electronic and procedural safeguards designed to protect your Personal
						Information from unauthorized access or intrusion. We limit access to the Personal Information
						to those employees who need such access in connection with providing products or services to
						you or for other legitimate business purposes.`
      ]
    },
    {
      header: 'Sharing of Personal Information',
      paragraphs: [
        `We do not disclose Personal Information about our customers or former customers to anyone,
						except as permitted by law. We do not share information we collect from consumer or credit
						reporting agencies with our affiliates or others without your consent, unless such disclosure is
						otherwise permitted by law.`,
        `We maintain physical, electronic and procedural safeguards designed to protect your Personal
						Information from unauthorized access or intrusion. We limit access to the Personal Information
						to those employees who need such access in connection with providing products or services to
						you or for other legitimate business purposes. Consistent with applicable law, we may
						nevertheless provide all or some of the Personal Information described above (excluding
						information we receive from consumer or other credit reporting agencies) to the following
						individuals and companies:`,
        `To our affiliates, such as insurance companies and agents, to provide you with services you have requested or as otherwise permitted under applicable law;`,
        `To insurance agents, brokers, representatives, support organizations or others;`,
        `To the extent necessary to provide you with services you have requested, so long as the above
						entities and persons agree not to further disclose your Personal Information except to the extent
						necessary to carry out the requested service on your behalf;`,
        `To enable us to detect or prevent criminal activity, fraud, material misrepresentation or
						nondisclosure in connection with an insurance transaction; and in connection with performing an
						insurance transaction for you;`,
        `To third-party contractors or service providers for the purpose of determining your eligibility for an insurance benefit or payment and/or provide you with services you have requested;`,
        `To an insurance regulatory authority or a law enforcement or other governmental authority.`
      ]
    },
    {
      header: 'Sharing of Personal Information with Service Providers',
      paragraphs: [
        `Where permitted by law, we may also disclose Application or Transactional Information to
						service providers that helps us market our products.`
      ]
    },
    {
      header: 'Use of cookies and other similar tracking technologies',
      paragraphs: [
        `We, or our third-party marketing partners (such as Google Analytics), use cookies, remarketing
						and other technology to help us provide users with a better service and a more customized web
						experience. The website may also use web beacons (also called "clear GIFs" or "pixel tags") in
						conjunction with cookies. If you prefer, you can choose to not accept cookies by changing the
						settings on your web browser. Many commercial internet sites use cookies. A cookie enables the
						site to label you as a particular user, but it does not identify you by name or address unless you
						have provided us with such information or set up preferences in your browser to do so
						automatically.`
      ]
    },
    {
      header:
        'Right to Opt Out of Information Sharing with Non-affiliated Parties Other Than As Permitted By Law',
      paragraphs: [
        `We will not share your NPI with other companies for their independent marketing purposes
						without your consent other than as permitted by law. There is no need to opt out or tell us not to
						do this.`
      ]
    },
    {
      header:
        'Right to Access Your Personal Information and Ability To Correct Errors Or Request Changes Or Deletion(s)',
      paragraphs: [
        `As required by applicable law, we will afford you the right to access your Personal Information 
						under certain circumstances to find out to whom your Personal Information has been disclosed and to 
						request correction or deletion of your Personal Information. Where permitted by law, we may charge a 
						reasonable fee to cover the costs incurred in responding to such requests. Requests must be made in 
						writing to the following address: Stillwater Privacy Policy, Privacy Compliance Officer Duval 
						Holdings, Inc. P.O. Box 45126 Jacksonville, FL 32232-5126, or email to
						Stillwater at `
      ],
      link: 'sigroup@stillwater.com'
    }
  ];
  return (
    <div>
      <PageTitle>Privacy Policy | Stillwater Insurance</PageTitle>
      <BgBluePanel headerName="PRIVACY POLICY" />
      <div className="container mt-3">
        <div className="row">
          {privacyPolicyDetails.map((policy, policyIndex) => (
            <div className="col-md-12" key={policyIndex}>
              <div className="mt-4">
                <h5 className="fw-bold">{policy.header}</h5>
                <div className="my-3">
                  {policy.paragraphs.map((text, paragraphIndex) => (
                    <p className="pt-2" key={paragraphIndex}>
                      {text}{' '}
                      <span>
                        {policy.link && (
                          <span>
                            <a
                              className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
                              href="mailto:sigroup@stillwater.com"
                            >
                              {policy.link}
                            </a>
                            . In addition, you can call us at (800) 849-6140 or
                            visit Stillwater.com to view our privacy policy.
                          </span>
                        )}
                      </span>
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
