import CoveragePanel from '@components/panel/covg/CoveragePanel';

const AutoInsCovgOptions = () => {
  const covgOptions = {
    options: [
      {
        desc: `Offers coverage limits that protect your auto and assets if you're at fault in an accident`
      },
      {
        desc: `Customizes a policy to fit your needs, with coverage options like collision and comprehensive, uninsured/underinsured motorist, towing and rental`
      },
      {
        desc: `Recognizes your loyalty with a discount for "bundling" your auto and home policies`
      },
      {
        desc: `Rewards you for things like being a good driver, buying a car with safety and theft-prevention technologies, and having a good student on the policy`
      },
      {
        desc: `Has representatives ready to help you get back on the road as quickly as possible after a claim`
      },
      {
        desc: `Has a solid reputation and high ratings by independent evaluators`
      }
    ]
  };

  const swDiscounts = {
    options: [
      {
        desc: `Accident/Violation Free`
      },
      {
        desc: `Good Driver`
      },
      {
        desc: `Good Student`
      },
      {
        desc: `Multi-Car`
      },
      {
        desc: `Multi-Policy`
      },
      {
        desc: `Paid-in-Full`
      },
      {
        desc: `Paperless`
      },
      {
        desc: `Theft Protection and Safety Device`
      }
    ]
  };

  const autoCovgs1 = [
    {
      header: `Buying auto insurance`,
      paragraphs: [
        `Stillwater offers more than just competitive rates. We offer quality, value and outstanding service.	`,
        `When you buy auto insurance, look for a provider that:`
      ],
      list: covgOptions
    },
    {
      paragraphs: [
        `With the exception of your home, your car may be the most valuable thing you own. It's an investment you rely on every day to commute, shuffle kids, 
          run errands and have fun. Protecting that investment with a Stillwater Auto policy helps you do those things confidently.`
      ]
    }
  ];

  const autoCovgs2 = [
    {
      header: `Get rewarded with money-saving discounts`,
      paragraphs: [
        `Stillwater's already-low rates get even better with discounts. Here are just a few we offer:`
      ],
      list: swDiscounts
    },
    {
      header: `Choose a deductible that fits your budget`,
      headerClass: `pt-1`,
      paragraphs: [
        `The deductible - which you choose when you buy a policy - is the amount you have to pay before the insurance company starts to pay for a covered loss. 
          Generally speaking, the higher the deductible you choose, the lower your premium.`
      ]
    }
  ];

  const autoCovgText = [
    `*These are generalized coverage descriptions. Our Auto contract includes more detailed definitions.`,
    `Coverages and discounts may not be available in all states or situations.`
  ];
  return (
    <div className="container mt-5 pt-5">
      <CoveragePanel
        leftCovgs={autoCovgs1}
        rightCovgs={autoCovgs2}
        impCovgText={autoCovgText}
      />
    </div>
  );
};

export default AutoInsCovgOptions;
