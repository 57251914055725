import {Container} from 'react-bootstrap';
import TopLargeNavBar from './navBar/TopLargeNavBar';
import TopMediumNavBar from './navBar/TopMediumNavBar';
import MobileTopNavBar from './navBar/MobileTopNavBar';

export const Header = () => {
  return (
    <>
      <Container>
        <TopLargeNavBar />
        <TopMediumNavBar />
      </Container>
      <MobileTopNavBar />
    </>
  );
};
