import CustomBulletList from '@components/custom-list/CustomBulletList';
import AboutUsHeader from '../AboutUs';
import {empFeedbackOptions, feedback, offers} from './CareerTypes';
import LeftFeedbackPanel from './LeftFeedbackPanel';
import RightFeedbackPanel from './RightFeedbackPanel';
import {useEffect, useState} from 'react';
import StillwaterCultureCloud from './StillwaterCultureCloud';
import PageTitle from '@components/form/PageTitle';
import './careers.scss';

const Careers = () => {
  const [options, setOptions] = useState<feedback[]>([]);

  const getNextOptions = (startIndex: number) => {
    const nextOptions = empFeedbackOptions.slice(startIndex, startIndex + 2);
    // Wrap around if we reach the end of the options array
    if (nextOptions.length < 2) {
      return [
        ...nextOptions,
        ...empFeedbackOptions.slice(0, 2 - nextOptions.length)
      ];
    }
    return nextOptions;
  };

  useEffect(() => {
    // Get the current index from localStorage, or default to 0
    const currentIndex = parseInt(
      localStorage.getItem('currentIndex') || '0',
      10
    );

    // Get the next 2 options
    const newOptions = getNextOptions(currentIndex);
    setOptions(newOptions);

    // Update the currentIndex for the next refresh (loop back if needed)
    const nextIndex = (currentIndex + 2) % empFeedbackOptions.length;
    localStorage.setItem('currentIndex', nextIndex.toString());
  }, []);

  return (
    <>
      <PageTitle>
        Stillwater Career Opportunities - Insurance Jobs | Stillwater Insurance
      </PageTitle>
      <AboutUsHeader activeOption="Careers" />
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-6 pe-md-5">
            <h1 className="asH4 fw-bold">The Stillwater Principle</h1>
            <p>
              We don't want anyone to just "come to work" at Stillwater. We're
              looking for people to join our collaborative team and grow in
              their career. We're everything you'd expect from an insurance
              company, but the real reasons we love to call Stillwater home are
              the things you don't expect from a job. You don't have to take our
              word for it - here are some recent employee testimonials.
            </p>
            <br />
            <div>
              <a
                className="btn btn-blue-lg text-white"
                href="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=669ce1b1-0baa-4717-afd9-6652f4141f3d"
              >
                Search Jobs
              </a>
            </div>
          </div>
          <div className="col-md-6 ps-md-5 mt-5 mt-md-0">
            <h4 className="fw-bold">Stillwater Offers:</h4>
            <CustomBulletList listOptions={offers} />
          </div>
        </div>
      </div>
      <div className="container">
        {options.map((option, index) => (
          <div key={index}>
            {option.left && <LeftFeedbackPanel feedback={option.left} />}
            {option.right && <RightFeedbackPanel feedback={option.right} />}
          </div>
        ))}
      </div>
      <StillwaterCultureCloud />
    </>
  );
};

export default Careers;
