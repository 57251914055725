import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader
} from '@react-google-maps/api';
import {getEnvironment} from '../../environment/Environment';
import {
  AgentValues,
  initialAgentStateValues,
  mapCoOrdinatesValues
} from './GoogleMapsServices';
import {useState} from 'react';

import AgentInfoWindow from './AgentInfoWindow';

const GoogleMapsAGentsView = ({
  locations,
  firstCord
}: {
  locations: mapCoOrdinatesValues[];
  firstCord: mapCoOrdinatesValues;
}) => {
  const env = getEnvironment();

  const {isLoaded} = useJsApiLoader({
    googleMapsApiKey: env.googleMapApiKey
  });

  interface markerValues {
    markerPosition: null | google.maps.LatLngLiteral;
    markerInfo: AgentValues;
  }
  const [selectedMarker, setSelectedMarker] = useState<markerValues>({
    markerPosition: null,
    markerInfo: initialAgentStateValues
  });

  return (
    <>
      {locations != null && locations.length > 0 && (
        <>
          {!isLoaded ? (
            <h6>Loading...</h6>
          ) : (
            <GoogleMap
              center={firstCord} // Center map at first location
              zoom={11.5}
              mapContainerStyle={{width: '100%', height: '35rem'}}
            >
              {locations.map((location, index) => (
                <Marker
                  label={`${location.agent.id}`}
                  key={index}
                  position={{lat: location.lat, lng: location.lng}}
                  onClick={() =>
                    setSelectedMarker({
                      markerInfo: location.agent,
                      markerPosition: {lat: location.lat, lng: location.lng}
                    })
                  }
                />
              ))}
              {selectedMarker.markerPosition && (
                <InfoWindow
                  position={selectedMarker.markerPosition}
                  onCloseClick={() =>
                    setSelectedMarker({...selectedMarker, markerPosition: null})
                  }
                >
                  <AgentInfoWindow agentInfo={selectedMarker.markerInfo} />
                </InfoWindow>
              )}
            </GoogleMap>
          )}
        </>
      )}
    </>
  );
};

export default GoogleMapsAGentsView;
