import {Link} from 'react-router-dom';
import {navItems} from './AppNavBarTypes';

const AppNavBar = ({
  items,
  activeLink,
  divClass,
  itemClass
}: {
  items: navItems[];
  activeLink: string;
  divClass: string;
  itemClass: string;
}) => {
  return (
    <>
      <div className="container-fluid bg-darkblue">
        <div className={`d-none ${divClass}`}>
          <ul className="nav nav-tabs justify-content-center">
            {items.map(item => (
              <li key={item.id} className={`nav-item ${itemClass} text-white`}>
                <Link
                  className={`nav-link bg-darkblue no-border mb-0 text-white fw-bold ${
                    item.desc === activeLink ? 'active' : ''
                  }`}
                  id={item.id}
                  to={item.link}
                >
                  {item.desc}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default AppNavBar;
