export const aboutUsNavItems = [
  {
    id: 'sub-nav-company',
    desc: 'Our Company',
    link: '/about-our-company'
  },
  {
    id: 'sub-nav-customer',
    desc: 'Customer Feedback',
    link: '/about-our-customers'
  },
  // {
  //   id: 'sub-nav-leadership',
  //   desc: 'Our Leadership',
  //   link: '/about-our-leadership'
  // },
  {
    id: 'sub-nav-partners',
    desc: 'Agent Appointment',
    link: '/agent-appointment'
  },
  {
    id: 'sub-nav-awards',
    desc: 'Accreditations and Awards',
    link: '/our-accreditations-and-awards'
  },
  {
    id: 'sub-nav-careers',
    desc: 'Careers',
    link: '/careers'
  }
];
