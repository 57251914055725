import AppNavBar from '@components/navs/AppNavBar';
import {aboutUsNavItems} from './AboutUsTypes';
import MobileNavBar from '@components/navs/MobileNavBar';

const AboutUsNavbar = (props: {activeOption: string}) => {
  const activeMobileOption = aboutUsNavItems.filter(item =>
    item.desc.includes(props.activeOption)
  )[0];
  return (
    <>
      <AppNavBar
        items={aboutUsNavItems}
        activeLink={props.activeOption}
        itemClass="mx-lg-1  mx-3 "
        divClass="d-lg-block"
      />
      <MobileNavBar
        items={aboutUsNavItems}
        activeOption={activeMobileOption.link}
        navClass="d-lg-none"
      />
    </>
  );
};

export default AboutUsNavbar;
