import {ProductOption} from '@modules/welcome/WelcomeTypes';
import {StylesConfig} from 'react-select';

export const InActiveButtons = ['UMBRELLA', 'EARTHQUAKE'];

export const colourStyles: StylesConfig<ProductOption> = {
  control: styles => ({
    ...styles,
    backgroundColor: 'rgba(255, 102, 0, 1)',
    color: '#ffffff',
    border: '1px #ffffff solid'
  }),
  placeholder: styles => ({...styles, color: '#ffffff'})
};
