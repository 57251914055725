import {Link} from 'react-router-dom';

const FindAnAgentListItem = () => {
  return (
    <div>
      Visit a local independent agent.
      <Link
        className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover ps-1"
        to="/find-an-agent"
        role="button"
      >
        Click here
      </Link>
      <span className="ps-1">to find an agent near you.</span>
    </div>
  );
};

export default FindAnAgentListItem;
