import PurchasePolicy from '../../PurchasePolicy';
import RtrnKnowledgeBase from '../../RtrnKnowledgeBase';

const RentersInsurance101 = () => {
  return (
    <div className="container mt-4">
      <RtrnKnowledgeBase />
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="asH3">Renters Insurance 101</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 pe-md-4">
          <p>
            <span className="fw-bold">
              Do you really need Renters insurance?{' '}
            </span>
            The short answer is yes - simply because stuff happens that is
            completely out of your control. Someone steals your laptop, you're
            hosting a party and someone falls and is injured, or your apartment
            is damaged by fire and you need temporary housing - you would be
            financially responsible for all of these scenarios if you didn't
            have a Renters insurance policy. When you rent an apartment or a
            house, the landlord will have an insurance policy that protects the
            actual dwelling ( walls, roof, etc.), but you are responsible if
            you, or a visitor get injured, or your personal property is damaged
            due to a number of causes. Purchasing a Renters policy creates a
            contract between you and an insurance company, where the insurance
            company will reimburse you for your loss in the event of a claim.
          </p>

          <p>
            <span className="fw-bold">How much coverage should you have? </span>
            To make sure you have enough insurance to protect your personal
            property, look at everything you own - mattress, electronics,
            dishes, furniture, jewelry, and even socks, and make an inventory of
            their value. Then ask yourself, "Do I have enough money to replace
            everything if I lost it?" The average renter in a two- bedroom
            apartment has about $30,000 worth of personal items.
          </p>

          <p>
            <span className="fw-bold">
              What's my landlord responsible for?{' '}
            </span>
            Your landlord will have separate property insurance that could vary
            depending on the company they are insured with, but generally
            speaking that insurance policy only covers the building itself. Your
            landlord is not responsible for your personal property if it's
            destroyed or stolen. That's what a Renters insurance policy is for.
          </p>

          <p>
            <span className="fw-bold">What's covered? </span>There are three
            components to a Renters policy: personal property, loss of use and
            liability.
          </p>
          <br />

          <ul className="custom-bullet square">
            <li>
              {' '}
              Personal property refers to your belongings. Your policy covers
              your belongings from things like power surges, fire, vandalism,
              theft and other events out of your control.{' '}
            </li>

            <li>
              {' '}
              Loss of Use covers the cost of the hotel, food and other expenses
              that you incur in case you can't live in your apartment due to a
              covered event.{' '}
            </li>

            <li>
              {' '}
              Liability pays for bodily injury and property damage caused by
              your negligence, such as when your dog gets loose and bites the
              mailman, or your bathtub overflows and damages the apartment
              below.
            </li>
          </ul>
          <br />

          <p>
            <span className="fw-bold">How much does it cost? </span>Price varies
            by state, liability limit, and the amount of personal property that
            you want to cover. A typical Renters insurance policy has a $30,000
            limit for personal property, and a $100,000 limit for liability. The
            cost of a basic Renters insurance policy is $15-$30 per month.
            That's a small price to pay for the peace of mind you'll have
            knowing you're covered.
          </p>
        </div>

        <div className="col-md-6 ps-md-4">
          <p>
            There are several contributing factors that will impact the price of
            Renters insurance, including:
          </p>

          <ul className="custom-bullet square">
            <li>
              {' '}
              Location - your zip code is an important factor in determining the
              cost of Renters insurance.
            </li>

            <li>
              {' '}
              Type of residence - the larger the building and number of units
              can determine the amount of your premium.{' '}
            </li>

            <li>
              {' '}
              In some states, your insurance score could also factor into the
              cost of insurance.
            </li>

            <li> Owning a dog could result in a higher rate.</li>

            <li>
              {' '}
              The amount of coverages that you select will determine what your
              rate is. A higher deductible means a lower rate.{' '}
            </li>

            <li>
              {' '}
              Bundling your Renters policy with your Auto insurance policy can
              save you money.
            </li>
          </ul>
          <br />

          <p>
            <span className="fw-bold">
              How do you choose the right deductible?{' '}
            </span>
            Your deductible is the amount of money that you would pay if you
            have a claim. That means if you have a $500 deductible and have a
            $3,000 claim, you would pay $500 and the insurance company would pay
            $2,500. When choosing your deductible, consider how much you can
            afford to pay out of pocket if you have a claim. Your deductible
            should never be higher than that amount, because if something
            happens, that's the portion for which you will be responsible.
          </p>

          <PurchasePolicy policyType="Renters" />
        </div>
      </div>
    </div>
  );
};

export default RentersInsurance101;
