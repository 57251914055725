import {empFeedbackDesc} from './CareerTypes';

const LeftFeedbackPanel = ({feedback}: {feedback: empFeedbackDesc}) => {
  return (
    <div className="row">
      <div className="col-md-2 pe-md-2">
        <div className="pb-3">
          <img
            src={feedback.image}
            className="img-fluid border-pacificblue"
            alt={feedback.signature}
          />
        </div>
      </div>
      <div className="col-md-7 ps-sm-3 ps-xl-0">
        <h5 className="careerTxt">{feedback.desc}</h5>
        <p className="mb-5 mb-lg-0">{feedback.signature}</p>
      </div>
    </div>
  );
};

export default LeftFeedbackPanel;
