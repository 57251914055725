import {getBaseUrl} from '../../../environment/Environment';
import {ServiceErrorResponseType} from '../../../services/BaseMapper';
import ServiceRequest from '../../../services/ServiceRequest';

export interface stateProductTypes {
  state: string;
  StateName: string;
  productList: productType;
  stateList: {[key: string]: string};
}

export interface productType {
  stateCode: string;
  stateName: string;
  auto: string;
  home: string;
  bop: string;
  landlord: string;
  umbrella: string;
  condo: string;
  renters: string;
}

export type ResponseMapper = {[key: string]: string} & ServiceErrorResponseType;
export type ProductResponseMapper = productType & ServiceErrorResponseType;

const unitedStatesUrl = getBaseUrl() + 'Common/states';
const productByStatesUrl = getBaseUrl() + 'Common/products';

export const retrieveUnitedStates = async (): Promise<ResponseMapper> => {
  return await ServiceRequest.get(unitedStatesUrl);
};

export const retrieveStateProduct = async (
  stateCodeVal: string
): Promise<ProductResponseMapper> => {
  return await ServiceRequest.get(productByStatesUrl, {
    params: {stateCode: stateCodeVal}
  });
};
