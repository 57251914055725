import PageTitle from '@components/form/PageTitle';
import AboutUsHeader from '../AboutUs';
import CompanyLeftPanel from './CompanyLeftPanel';
import CompanyRightPanel from './CompanyRightPanel';

const AboutOurCompany = () => {
  return (
    <>
      <PageTitle>
        About Us - Stillwater History | Stillwater Insurance
      </PageTitle>
      <AboutUsHeader activeOption="Our Company" />
      <div className="container mt-5">
        <div className="row">
          <CompanyLeftPanel />
          <CompanyRightPanel />
        </div>
      </div>
    </>
  );
};

export default AboutOurCompany;
