import ThemeIcon from '../../../icons/ThemeIcon';
import {ICON_LIST} from '../../../icons';
import BPTW from '../../../assets/images/bptw-logo-2019.png';
import VerticaBbar from '../../../assets/images/vertical_gradient_bar.png';
import SWVerticalLogo from '../../../assets/images/sw_vertical_logo.png';
import NorthSeal from '../../../assets/images/NortonSeal.png';
import AuthorizeNet from '../../../assets/images/authorize_net.png';
import HorizontalBar from '../../../assets/images/horizontial_gradient_bar.png';
import {
  getPrivatePolicyPdf,
  getSecurePolicyPdf
} from '../../../environment/Environment';

export const Footer = () => {
  const footerLogos = [
    {
      imageName: BPTW,
      className: 'ms-4',
      style: '12%',
      alt: 'best place to work logo',
      id: 1
    },
    {
      imageName: VerticaBbar,
      className: 'img-fluid mx-4',
      alt: 'vertical bar',
      id: 2
    },
    {
      imageName: SWVerticalLogo,
      style: '15%',
      alt: 'Stillwater logo',
      id: 3
    },
    {
      imageName: VerticaBbar,
      className: 'img-fluid mx-4',
      alt: 'vertical bar',
      id: 4
    },
    {
      imageName: NorthSeal,
      className: 'me-3',
      style: '10%',
      alt: 'Norton seal',
      id: 5
    },
    {
      imageName: AuthorizeNet,
      className: 'me-3',
      style: '6%',
      alt: 'Authorize net logo',
      id: 6
    }
  ];

  const horizantalBar = {
    name: HorizontalBar,
    class: 'img-fluid',
    alt: 'horizontal bar'
  };
  return (
    <div>
      <footer className="page-footer font-small mt-5 pt-5">
        <div className="container text-center">
          <div className="row">
            <div className="row m-5">
              <img
                src={horizantalBar.name}
                className={horizantalBar.class}
                alt={horizantalBar.alt}
              />
            </div>
            <div className="row">
              <div className="col-12">
                {footerLogos.map(logo => (
                  <img
                    key={logo.id}
                    src={logo.imageName}
                    className={logo.className}
                    style={{maxWidth: `${logo.style}`}}
                    alt={logo.alt}
                  />
                ))}
              </div>
            </div>
            <div className="row m-5">
              <img
                src={horizantalBar.name}
                className={horizantalBar.class}
                alt={horizantalBar.alt}
              />
            </div>
            <div className="col-sm-12">
              <a
                rel="noreferrer nofollow"
                href="https://www.facebook.com/Stillwater.Insurance.Company/"
              >
                <ThemeIcon
                  iconName={ICON_LIST.FACE_BOOK}
                  fill="#003399"
                  height="35"
                  width="30"
                />
              </a>
              <span className="ms-3">
                <a href="https://www.linkedin.com/company/stillwater-insurance-group">
                  <ThemeIcon
                    iconName={ICON_LIST.LINKED_IN}
                    fill="#003399"
                    height="35"
                    width="30"
                  />
                </a>
              </span>
            </div>
          </div>
          <div className="text-center mt-3 mb-md-5">
            <p className="text-14">
              &copy; <span className="pe-1">{new Date().getFullYear()}</span>
              Stillwater Insurance Group. All Rights Reserved.
              <span className="d-block d-md-none">{` `}</span>
              <a
                href={getPrivatePolicyPdf()}
                className="text-underline"
                target="_blank"
              >
                Privacy Policy
              </a>
              <span className="spacer-horizontal-5"> | </span>
              <a
                href={getSecurePolicyPdf()}
                className="text-underline"
                target="_blank"
              >
                Security Policy
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};
