import MarkDaveyImg from '@images/leadership/Mark-Davey.jpg';
import JuliaEdmonstonImg from '@images/leadership/Julia-Edmonston.jpg';
import DanDuffyImg from '@images/leadership/Dan-Duffy.jpg';
import MikeWhatleyImg from '@images/leadership/Mike-Whatley.jpg';
import RameshMugalamImg from '@images/leadership/Ramesh-Mugalam.jpg';
import DawnBostonImg from '@images/leadership/Dawn-Boston.jpg';
import AngelaVaughnImg from '@images/leadership/Angela-Vaughn.jpg';
import DebbieCollazoImg from '@images/leadership/Debbie-Collazo.jpg';
import CindyMcDonaldImg from '@images/leadership/Cindy-McDonald.jpg';
import LarryMortensenImg from '@images/leadership/Larry-Mortensen.jpg';
import KevinKarlImg from '@images/leadership/Kevin-Karl.jpg';
import MarcCrawfordImg from '@images/leadership/Marc-Crawford.jpg';
import JerryReichImg from '@images/leadership/Jerry-Reich.png';
import DeanDreherImg from '@images/leadership/Dean-Dreher.jpg';
import MikeSouthallImag from '@images/leadership/Mike-Southall.jpg';
import AlisaRuenprapanImg from '@images/leadership/Alisa-Ruenprapan.png';
import TommyeFrostImg from '@images/leadership/Tommye-Frost.jpg';
import DavidBacksImg from '@images/leadership/David_Backs.jpg';
import CassieKnechtImg from '@images/leadership/cassie-knecht.png';
import JosephFerreiraImg from '@images/leadership/joseph-ferreira.png';

export const leaderShipData = [
  {
    sequence: 1,
    imageName: MarkDaveyImg,
    alt: 'picture of Mark Davey',
    name: 'MARK DAVEY',
    title: 'President / Chief Executive Officer',
    text: `Mark has over 30 years in the insurance industry and has been leading the Stillwater Group (formerly known as Fidelity 
            National Property and Casualty Group) since its inception in 2000. In 2012, Mark and an investment group purchased 
            Fidelity National Finance's property and casualty insurance operations. Prior to joining FNF in 2000, Mark served as executive vice president 
            for Pacific Specialty Insurance Company and McGraw Insurance Services. He earned his bachelor's degree in Business Management at Montana State 
            University.`
  },
  {
    sequence: 2,
    imageName: JuliaEdmonstonImg,
    alt: 'picture of Julia Edmonston',
    name: 'JULIA EDMONSTON',
    title: 'Chief Financial Officer',
    text: ` Julia joined Stillwater in 2013 as VP of Accounting and was promoted in 2018 to Chief Financial Officer. With over 30 years 
        of leadership roles in finance, she is responsible for the GAAP (Generally Accepted Accounting Principles) and STAT (Statutory Accounting Principles) 
        financial statements, reporting cash flow and budgeting requirements of the company. She holds a Certified Public Accounting license and both a BA 
        and MA degree in Accountancy from the University of Alabama. She passed the Certified Financial Planners Examination in 2003.`
  },
  {
    sequence: 3,
    imageName: DanDuffyImg,
    alt: 'picture of Daniel Duffy',
    name: 'DANIEL DUFFY',
    title: 'Chief Financial Officer',
    text: `Dan started with the company in 1985 when it was Omaha Property and Casualty and has held various IT positions. In 2003, Stillwater
						 Group (formerly known as Fidelity National Property and Casualty Group) took over, and he was promoted to Chief Information Officer. Under his 
						 tenure, his team developed a scalable, web-based quoting system that allows agency partners and consumers to quote and sell Stillwater's insurance 
						 products. Dan is an Iowa State University graduate with a BS in Computer Science.`
  },
  {
    sequence: 4,
    imageName: MikeWhatleyImg,
    alt: 'picture of Mike Whatley',
    name: 'MIKE WHATLEY',
    title: 'Chief Actuary',
    text: `Mike joined Stillwater in 2010, and brings with him 35 years of actuary experience, which he uses to compute premium rates, 
						dividends and risks. He has been an Associate of the Casualty Actuarial Society (ACAS) since 1975 and is also a member of the American Academy of 
						Actuaries (MAAA). Mike holds a BS in Mathematics from Texas A&amp;M University.`
  },
  {
    sequence: 5,
    imageName: RameshMugalamImg,
    alt: 'picture of Ramesh Mugalam',
    name: 'RAMESH MUGALAM',
    title: 'Chief Strategy & Transformation Officer',
    text: `As Chief Strategy Officer, Ramesh has been with Stillwater 
                        since 2017 leading enterprise programs by identifying initiatives driven by innovation and the 
                        development of new capabilities. His responsibilities include the deployment of strategic 
                        transformation programs driven by technology modernization and cloud adoption. Ramesh's experience 
                        includes implementing insurance core platforms, enterprise data and analytics programs. 
                        He has a strong background in building technology practice and executing growth strategies. Ramesh 
                        holds an MBA from the Sloan School of Management at MIT and a Master's Degree from the Indian Institute of Technology.`
  },
  {
    sequence: 6,
    imageName: DawnBostonImg,
    alt: 'picture of Dawn Boston',
    name: 'DAWN BOSTON',
    title: 'Vice President - Advertising and Communications',
    text: `Dawn joined Stillwater in 2015 and brings over 30 years of experience building brands, implementing advertising strategies 
                            and managing internal and external communications in the insurance industry. In her role, she is responsible for building and promoting the Stillwater
                            brand through print, online, direct mail, video, public relations and reputation management. Dawn has a BS degree in Journalism with a field of concentration 
                            in public relations from Kent State University.`
  },
  {
    sequence: 7,
    imageName: AngelaVaughnImg,
    alt: 'picture of Angela Vaughn',
    name: 'ANGELA VAUGHN',
    title: 'Vice President - Advertising and Communications',
    text: `Angela joined Stillwater in 2024 and brings over 20 years of strategic Human Resources leadership experience. In her role, she is 
                            responsible for program development, business integration, talent acquisition and management, process improvement, and 
                            senior leadership advisor. She is skilled as a forward-thinking professional who is committed to developing a 
                            high-performance culture. Angela is a graduate of Purdue University with a BA in Psychology.`
  },
  {
    sequence: 8,
    imageName: DebbieCollazoImg,
    alt: 'picture of Debbie Collazo',
    name: 'DEBBIE COLLAZO',
    title: 'Vice President - Underwriting',
    text: `Debbie amassed industry experience during her nearly 20 year tenure at Tri-State Consumer Insurance Company, where she served as Executive Vice President for nine years. She has experience in customer service, underwriting, training and development, human resources, operations, and contingency planning and business continuity. When Stillwater acquired Tri-State in 2021, Debbie joined the company as Vice President of Underwriting. Her industry designations include Associate in Personal Insurance, Associate in Claims, Associate in Regulation and Compliance, and Chartered Property Casualty Underwriter`
  },

  {
    sequence: 9,
    imageName: CindyMcDonaldImg,
    alt: 'picture of Cindy Mcdonald',
    name: 'CINDY MCDONALD',
    title: 'Vice President - Customer Services',
    text: `Cindy's diverse customer service career began in 1981 with Omaha Property and Casualty, where she excelled in positions including claims adjuster, claims supervisor, customer service supervisor and customer service manager. When Stillwater acquired that book of business in 2003, Cindy continued in the Customer Service Manager role and was promoted in 2011 to Assistant VP - Customer Services. Cindy attended the University of Nebraska at Omaha.

`
  },
  {
    sequence: 10,
    imageName: LarryMortensenImg,
    alt: 'picture of Larry Mortensen',
    name: 'LARRY MORTENSEN',
    title: 'Vice President - Residential Property<',
    text: `Larry started with Mutual of Omaha in 1984, holding various positions related to pricing and product development. He joined Stillwater Group (formerly known as Fidelity National Property and Casualty Group) in 2003 and was promoted to Vice President of Residential Property in 2004. He holds both Chartered Property Casualty Underwriter (CPCU) and Certified Insurance Counselor (CIC) industry designations. He earned a BS in Applied Mathematics with a minor in Economics from the University of Nebraska at Omaha.`
  },
  {
    sequence: 11,
    imageName: KevinKarlImg,
    alt: 'picture of  Kevin Karl',
    name: 'KEVIN KARL',
    title: 'Vice President - Personal Lines Auto',
    text: `Kevin joined Stillwater in 2013 and brings with him years of personal and commercial lines experience. He has 
                            a proven track record in sales, claims, underwriting, actuarial and product management/development with several major insurance companies. He is 
                            responsible for all auto and umbrella operations nationally. Kevin has served on the board for the Society of Insurance Research, has a Master's 
                            Degree in Business from Cleveland State University and holds a Charter Property Casualty Underwriter (CPCU) designation.`
  },
  {
    sequence: 12,
    imageName: MarcCrawfordImg,
    alt: 'picture of Marc Crawford',
    name: 'MARC CRAWFORD',
    title: 'Vice President - National Accounts',
    text: `Marc joined Stillwater in 2007 and is responsible for the management of strategic partnerships and national accounts. He is a 
                            seasoned veteran with over 30 years of experience in sales, advertising and marketing. His career licenses include a FL 2-20 and FINRA Series 7, 24 and 63. 
                            He graduated from the University of Kansas with a BS Marketing/Advertising degree and a minor in Accounting.`
  },
  {
    sequence: 13,
    imageName: MikeSouthallImag,
    alt: 'picture of Michael Southall',
    name: 'PAUL MICHAEL SOUTHALL',
    title: 'Vice President - Sales and Marketing',
    text: `Mike joined Stillwater in 2008 as the Regional Sales Manager in Florida. In 2011, he was promoted to Vice President of Sales and 
						Marketing and is responsible for managing the Regional Sales operations in the East Region. Prior to joining Stillwater, he managed sales and marketing, 
						customer service, underwriting, human resources and licensing for a large agency. Mike has a BBA degree with a major in Marketing from the University of North Florida.`
  },
  {
    sequence: 14,
    imageName: AlisaRuenprapanImg,
    alt: 'picture of Alisa Ruenprapan',
    name: 'ALISA RUENPRAPAN',
    title: 'Vice President - Sales and Sales Systems',
    text: `Alisa joined Stillwater in 2016 to establish an inside sales team. She has extensive experience in claims management, inside sales 
						and marketing in the insurance industry. She was promoted to Vice President of Inside Sales and Sales Systems in 2020. In her role, she is responsible for growing our Independent Agency 
						channel throughout the country and oversees our sales CRM. Alisa is a graduate from the University of Florida with a Bachelor of Fine Arts degree.`
  },
  {
    sequence: 15,
    imageName: JerryReichImg,
    alt: 'picture of Jerry Reich',
    name: 'JERRY REICH',
    title: 'Vice President - Claims',
    text: `Jerry joined Stillwater in 2023 and brings over 30 years of senior leadership experience in claims, product, underwriting and 
					distribution. In his role, he is responsible for providing a responsive, customer-focused, and scalable claim solution to our policy-holders 
					and agents. Jerry is skilled in leveraging the team-approach to innovation and solution management. He is a graduate of Utah State University 
					and an alum of the University of Virginia, Darden School of Business.`
  },
  {
    sequence: 16,
    imageName: DeanDreherImg,
    alt: 'picture of Dean Dreher',
    name: 'DEAN DREHER',
    title: 'Assistant Vice President - IT',
    text: `Dean joined Stillwater in 2001 and has over 26 years of experience in the IT industry. He has held positions as Lead Systems Analyst 
						and Application Development Manager before being promoted to AVP of IT in 2018. In his role, he is responsible for the development and maintenance for all web-based applications 
						as well as leading integration and implementation with our partners. Dean has a BBA in Computer Information Systems from Oklahoma Panhandle State University and an MBA from 
						Southwestern Oklahoma State University.`
  },
  {
    sequence: 17,
    imageName: TommyeFrostImg,
    alt: 'picture of Tommy Frost',
    name: 'TOMMYE FROST',
    title: 'Assistant Vice President - Regulatory & Compliance',
    text: `Tommye joined Stillwater in 2015 and brings over 30 years of experience in corporate and insurance regulatory law. At Stillwater, 
						she is responsible for the regulatory compliance of our three companies: Stillwater Insurance Company, Stillwater Property and Casualty Insurance Company 
						and Stillwater Insurance Services, Inc. She has been a member of the Florida Bar Association since 2000. Tommye has a bachelor's and master's degree 
						from Texas A&amp;M University and a J.D. degree with high honors from Florida Coastal School of Law.`
  },
  {
    sequence: 18,
    imageName: DavidBacksImg,
    alt: 'picture of David Backs',
    name: 'DAVID BACKS',
    title: 'Assistant Vice President - Commercial Lines',
    text: `David has over 35 years of experience in the insurance industry, and 
						he specializes in product management, product development, strategic planning, pricing, underwriting, marketing, 
						leadership, and process improvement. He served as head of Commercial Lines Product Management before being promoted 
						to Assistant Vice President in 2024. He is responsible for product management, underwriting and processes related to 
						Commercial Lines. He is a graduate of the University of Cincinnati and holds a BBA in Finance and Insurance.`
  },

  {
    sequence: 19,
    imageName: CassieKnechtImg,
    alt: 'picture of Cassie Knecht',
    name: 'CASSIE KNECHT',
    title: 'Assistant Vice President - Property Product Development',
    text: `Cassie joined Stillwater in 2014 and has over 17 years of experience in the
						insurance industry. She served as Product Manager and Senior Product Manager before promoted to Assistant Vice President in
						2021. In her role, she is responsible for the country wide home and dwelling products. She has a BA in Mathematics and Accounting from
						Central Methodist University, and a MS in Mathematics with a concentration in Actuarial Mathematics from the University of
						Connecticut.`
  },
  {
    sequence: 20,
    imageName: JosephFerreiraImg,
    alt: 'picture of Joseph Ferreira',
    name: 'JOSEPH FERREIRA',
    title: 'Assistant Vice President - Personal Lines - Auto',
    text: `Joseph joined Stillwater in 2014 and has over 20 years of personal lines
						experience in auto, specialty, and property lines. He has worked in supervisory and management roles in product management,
						pricing, implementation in agency and direct to consumer channels for other major carriers. He is responsible for managing a risk
						portfolio of auto states nationally. Joseph holds a bachelor's degree in Business with a major in Risk Management and Insurance
						from Georgia State University.`
  }
];

export interface leadershipTypes {
  sequence: number;
  imageName: string;
  alt: string;
  name: string;
  title: string;
  text: string;
}
