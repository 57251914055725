import StringUtils from '../../../../utils/StringUtils';
import PolicyInsuranceBtn from '../PolicyInsuranceBtn';
import FindAnAgentListItem from './FindAnAgentListItem';
import {purchasePolicy} from './KnowHowDetailsTypes';

const getPolicyLine = (policyType: string) => {
  if (StringUtils.toLowerCase(policyType) == 'home') {
    return 'a Homeowners';
  } else if (StringUtils.toLowerCase(policyType) == 'umbrella') {
    return 'an Umbrella';
  } else if (StringUtils.toLowerCase(policyType) == 'auto') {
    return 'an Auto';
  } else if (StringUtils.toLowerCase(policyType) == 'business') {
    return 'a BOP';
  }
  return 'a ' + policyType;
};
const PurchasePolicy = ({policyType}: {policyType: String}) => {
  return (
    <>
      <p className="fw-bold">
        How to purchase {getPolicyLine(`${policyType}`)} policy from Stillwater:
      </p>
      <ul className="custom-bullet square">
        {purchasePolicy.purchaseOptions.map((prchsOpt, prchsIndex) =>
          policyType == 'Umbrella' ? (
            policyType == prchsOpt.type && (
              <li key={prchsIndex}> {prchsOpt.txt}</li>
            )
          ) : (
            <li key={prchsIndex}> {prchsOpt.txt}</li>
          )
        )}
        <li>
          <FindAnAgentListItem />
        </li>
      </ul>
      <br />
      {policyType != 'Umbrella' && (
        <PolicyInsuranceBtn policyType={policyType} />
      )}
    </>
  );
};

export default PurchasePolicy;
