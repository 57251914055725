import bbbLogo from '@images/bbb_logo.png';
import amBestLogo from '@images/am_best_logo.png';
import {Helmet} from 'react-helmet-async';

const AccreditationsAndAwardsLeftPanel = () => {
  const accreditationLeftPnlData = [
    {
      header: `When it comes to protecting your assets, we make the grade.`,
      subHeader: `Better Business Bureau "A+" Rated Business`,
      desc: `Stillwater's "A+" accreditation with the Better Business Bureau signifies that our customers always come first. We set ourselves apart 
            from other companies by walking the walk when it comes to responding to customer's needs, and we deliver on our promises with transparency 
            and integrity. Our focus will always be to take care of our customers when they need us most.`,
      image: bbbLogo,
      imgClass: `bbb_logo`,
      imgDesc: `Better Business Bureau`
    },
    {
      subHeader: `AM Best "A-" Rated Financial Strength: Excellent`,
      desc: `Not only does Stillwater stand tall - we stand firm. Our "A-" (Excellent) rating from AM Best, a credit rating agency focused on the insurance 
					industry, demonstrates we have the financial strength and capability to guarantee our customers' polices and keep them secure. This gives our customers 
					peace of mind knowing they can trust us to be there when life takes its unexpected turns. This rating is granted to Stillwater Insurance Company and 
					Stillwater Property and Casualty Insurance Company.`,
      image: amBestLogo,
      imgClass: `am-best-logo`,
      imgDesc: `AM Best Financial Strength`
    },
    {
      subHeader: `Demotech "A" Rated Financial Stability: Exceptional`,
      desc: `Our "A" (Exceptional) rating from Demotech Inc., with its proven track record of predicting financial stability in the insurance industry, substantiates that we are just as financially stable as the largest insurance providers. Our customers can be assured that we will always be able to take care of them, regardless of changes in the economy or marketplace. This rating is granted to Stillwater Insurance Company and Stillwater Property and Casualty Insurance Company.`
    }
  ];
  return (
    <div className="col-md-6 pe-md-4">
      {accreditationLeftPnlData.map((awards, awardIndex) => (
        <div key={awardIndex}>
          {awards.header && <h4 className="fw-bold">{awards.header}</h4>}
          {awards.subHeader && <h5 className="pt-3">{awards.subHeader}</h5>}
          <p>{awards.desc}</p>
          {awards.image && (
            <div className="text-center">
              <img
                src={awards.image}
                className={awards.imgClass}
                alt={awards.imgDesc}
              />
            </div>
          )}
        </div>
      ))}
      <div id="dc-seal" className="text-center pb-2"></div>
      <Helmet>
        <script
          id="dc-seal-embed"
          src="https://demotech.com/wp-content/plugins/demotech-companies/assets/js/dc-embed-seal.js"
          data-naic="25180"
          data-size="auto"
          async
        ></script>
      </Helmet>
    </div>
  );
};

export default AccreditationsAndAwardsLeftPanel;
