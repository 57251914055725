import ParagraphText from '@components/form/paragraph/ParagraphText';
import CustomBulletList from '../../../../../../components/custom-list/CustomBulletList';
import PurchasePolicy from '../../PurchasePolicy';
import RtrnKnowledgeBase from '../../RtrnKnowledgeBase';
import {useTranslation} from 'react-i18next';

const PricingAuto = () => {
  const {t} = useTranslation();

  const personalInfoOptions = {
    options: [
      {
        header: 'How old you are.',
        desc:
          ' According to the Insurance Institute for Highway Safety (IIHS),' +
          ' drivers between the ages of 16 and 19 are three times more likely to be in a car accident' +
          ' than older drivers. As a general rule, insurance rates start to drop after the age of 25.' +
          ' Because older drivers can experience slower reflexes and poor eyesight, drivers over 70' +
          ' may also see an increase in their insurance premium.'
      },
      {
        header: 'Your personal driving history.',
        desc:
          ' If you have a history of tickets and accidents,' +
          ' you appear more risky to an insurance company, generally resulting in having to pay a higher' +
          ' rate. Drivers with a clean record may qualify for a safe driver discount, which can be' +
          ' up to 30 percent.'
      },
      {
        header: "How long you've been driving.",
        desc:
          " The longer you've been driving, the more experience" +
          ' you gain - and that can translate to an insurance company viewing you as less risky.' +
          ' The combination of being licensed for several years and having a clean driving' +
          ' record will generally result in a better price for you.'
      },

      {
        header: 'Your credit score.',
        desc:
          ' It might seem a bit strange to correlate a credit score' +
          ' with an insurance premium, but research from the Federal Trade Commission has shown' +
          ' that those with lower credit scores are more likely to file a claim. And because insurance' +
          ' companies use claim data to help determine their price, a low credit score could result' +
          ' in paying a higher premium. California, Hawaii and Massachusetts prohibit the use of' +
          ' credit scores as a rating factor, but credit is commonly used in determining premiums' +
          ' almost everywhere else.'
      },
      {
        header: 'Where you live.',
        desc:
          ' In some states, your geographic location will be a factor' +
          ' in determining your insurance premium. Accidents are more likely to happen if you live' +
          ' in a highly populated area with lots of congestion than if you live in a rural area.' +
          ' Insurance companies also consider crime rates, the number of claims, and risk of damaging weather that' +
          ' occurs in your area to determine what the insurance rate should be.'
      }
    ]
  };
  const additionalInfoList = {
    options: [
      {
        header: 'Your marital status.',
        desc:
          '  A recent study by the National Institute of Health has' +
          ' shown that single drivers are twice as likely to be in an accident than married drivers.' +
          ' Married drivers tend to share driving duties, drive less, and drive safer,' +
          ' resulting in fewer claims.'
      },
      {
        header: 'Your insurance  history.',
        desc: `Having continual auto insurance will help get you a better rate, as most insurance companies see a lapse in coverage as a red flag. In addition, many insurance companies offer a loyalty discount for keeping continual coverage for several years, and that discount can help you save money. Insurance companies also view your previous claims history as an indicator of what might happen in the future; a history of several claims will likely result in a higher insurance premium.`
      },
      {
        header: 'How much you drive your car.',
        desc:
          ' Insurance companies want to know how often you' +
          ' drive your car because the more your car is on the road, the more likely you are to have' +
          " an accident and file a claim - whether it's your fault or someone else hits you. The" +
          ' less you drive, the less risky you seem.'
      }
    ]
  };
  const otherFacts = [
    {
      id: 1,
      label: `The car that you drive. `,
      desc: `Your car's safety rating, size and age all factor 
				into your insurance premium. In general, the safer the car, the cheaper your liability 
				premiums will be. Smaller, speedier cars have a statistically higher chance of being 
				involved in an accident. Insurance companies may charge a higher premium to account 
				for that risk. A brand-new car will typically cost you more in physical damage insurance
				than an older one. If the vehicle costs more to replace, insurance companies will 
				charge you more for insurance.`
    },
    {
      id: 2,
      label: `The coverage you select. `,
      desc: `A simple rule of thumb is the more coverage you 
				select, the more expensive your auto insurance premium. The reason is simple: when 
				you purchase more coverage, the insurance company is on the hook to pay out more in 
				the case of an accident or theft. Every state has a minimum amount of auto insurance 
				coverage that you are required to purchase. Check your state requirements, and keep 
				in mind that minimal coverage won't be enough to protect you if you're in a serious
				accident. Learn more about liability, uninsured motorist, comprehensive and collision
				coverage`
    }
  ];
  return (
    <div className="container mt-4">
      <RtrnKnowledgeBase />
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="asH3">{t('KNOWHOW.AUTO.PRICING_AUTO.HEADER')}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 pe-md-4">
          <p> {t('KNOWHOW.AUTO.PRICING_AUTO.MAIN_PARAGRAPH')}</p>
          <h4 className="pt-2">
            {t('KNOWHOW.AUTO.PRICING_AUTO.PERSONAL_INFO_HEADER')}
          </h4>
          <CustomBulletList listOptions={personalInfoOptions} />
        </div>
        <div className="col-md-6 ps-md-4">
          <CustomBulletList listOptions={additionalInfoList} />
          <h4 className="pt-2">Other factors include:</h4>
          {otherFacts.map(fact => (
            <ParagraphText key={fact.id} label={fact.label}>
              <>
                {fact.desc}
                {fact.id == 2 && (
                  <a
                    href="/know-how/learn-the-difference"
                    className="ps-1 link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
                  >
                    here.
                  </a>
                )}
              </>
            </ParagraphText>
          ))}
          <PurchasePolicy policyType="Auto" />
        </div>
      </div>
    </div>
  );
};

export default PricingAuto;
