import BgBluePanel from '@components/panel/header/BgBluePanel';
import AboutUsNavbar from './AboutUsNavbar';

const AboutUsHeader = (props: {activeOption: string}) => {
  return (
    <>
      <BgBluePanel headerName="ABOUT US" />
      <AboutUsNavbar activeOption={props.activeOption} />
    </>
  );
};

export default AboutUsHeader;
