import {useState} from 'react';
import {Tab, Tabs} from 'react-bootstrap';
import HomeInsCovgOptions from './HomeInsCovgOptions';

const HomeInsCovgPanel = () => {
  const [key, setKey] = useState('deductible');
  return (
    <>
      <HomeInsCovgOptions />
      <div className="container mt-5">
        <Tabs activeKey={key} onSelect={(k: any) => setKey(k)} className="mb-3">
          <Tab
            eventKey="deductible"
            title="Choose a deductible that fits your budget"
            tabClassName="col-12 nav-link-product"
          >
            <p>
              The deductible - which you choose when you buy a policy - is the
              amount you have to pay before the insurance comapny starts to pay
              for a covered loss. Generally speaking, the higher the deductible
              you choose, the lower your premium.
            </p>
          </Tab>
          <Tab
            eventKey="discounts"
            title="Save money with discounts"
            tabClassName="col-12 nav-link-product"
          >
            <>
              <p>
                The sense of peace that comes with a Stillwater Homeowners
                policy is available at an affordable rate.. and with discounts
                that will help you save.
              </p>
              <ul className="custom-bullet checkmark">
                <li>Accredited Builder</li>
                <li>Age of Insured</li>
                <li>Automatic Sprinkler</li>
                <li>Claim-Free</li>
                <li>Gated Community</li>
                <li>Home Alarm Protection</li>
                <li>Martial Status</li>
                <li>Multi Policy</li>
                <li>Newly Acquired Home</li>
                <li>Roof-Type</li>
                <li>Utility Upgrade</li>
              </ul>
              <p>
                Coverages and discounts may not be available in all states or
                situations.
              </p>
              <p>
                Wildfire Mitigation Benefits: Wildfire Risk Mitigation Actions
                undertaken by a community of owners or an individual property
                owner do not currently impact the eligibility of a property or
                provide a premium discount to existing policyholders.
              </p>
            </>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default HomeInsCovgPanel;
