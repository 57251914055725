import {ReactElement, ReactNode} from 'react';
import {GoogleMapLoadScripProvider} from './GoogleMapLoadScripProvider';
import {PageLoaderProvider} from './PageLoaderProvider';
import {StartQuoteProductProvider} from './StartQuoteProductProvider';

export interface RootContextControllerProps {
  children: ReactNode;
}

export function RootContextProvider({
  children
}: RootContextControllerProps): ReactElement {
  return (
    <GoogleMapLoadScripProvider>
      <StartQuoteProductProvider>
        <PageLoaderProvider>{children}</PageLoaderProvider>
      </StartQuoteProductProvider>
    </GoogleMapLoadScripProvider>
  );
}
