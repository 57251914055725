import CustomBulletList from '@components/custom-list/CustomBulletList';
import {ICON_LIST} from '../../icons';
import ThemeIcon from '../../icons/ThemeIcon';

const ClaimsRightPanel = () => {
  const reportedHomeNextOptions = {
    class: 'mt-3',
    options: [
      {
        header: 'Stay Safe.',
        desc: `Do not put yourself or anyone else at risk by re-entering a damaged structure unless you are absolutely sure it is safe to do so. 
						If you need to relocate because your home is uninhabitable, keep all receipts.`
      },
      {
        header: 'Prevent further damage to your home.',
        desc: `If the damage was caused by water from a non-weather related event, shut off the water source. Temporarily cover any holes, 
						broken windows or other breaches with tarps and/or boards. Contact a mitigation specialist to assist you, and keep track of any out-of-pocket expenses for time spent in mitigation.`
      },
      {
        header: 'Take inventory and save documentation.',
        desc: `Take an inventory and pictures of damage to your property and 
					     belongings before any repairs are made. Don't discard any damaged items until after you speak with one of our representatives. If possible, 
					     locate receipts for damaged belongings. If this is a theft or vandalism, obtain a copy of the police report.`
      },
      {
        header: 'Start repairs.',
        desc: `Get a repair estimate from an independent contractor. If you prefer, Stillwater can refer you to a professional credentialed contractor. This contractor 
						comes with a five-year workmanship warranty, ensuring they conform to generally accepted, local building repair practices.`
      },
      {
        header: 'Review your policy.',
        desc: `A Stillwater representative will help you understand which coverages apply to your home claim.`
      }
    ]
  };

  const reportedAutoNextOptions = {
    class: 'mt-3',
    options: [
      {
        header: 'Stay Safe.',
        desc: `Do not put yourself or anyone else at risk by driving a damaged vehicle (unless you are absolutely sure it is safe to do so).`
      },
      {
        header: 'Prevent further damage to your vehicle.',
        desc: `If any windows are broken, cover them until repairs are made.`
      },
      {
        header: 'Take inventory and save documentation.',
        desc: `Take pictures of all the vehicles involved in the accident. If possible, obtain a copy of the police report. Gather insurance and contact information from all other parties involved. Be polite, but do not tell anyone the accident was your fault. Only sign documents for the policy and tow company.`
      },
      {
        header: 'Start repairs.',
        desc: `Identify an auto shop that you want to perform the repairs. You can immediately make any minor repairs needed to make your car drivable, but be sure to save any receipts. We will provide you with a detailed estimate to take to your shop for the remaining repairs. Broken windshield? We provide 24/7 assistance for windshield repair - just call 1-800-220-1351.`
      },
      {
        header: 'Get a rental. ',
        desc: `If you need a rental car while your vehicle is out of commission, your Stillwater claim examiner will help you locate a preferred rental company.`
      },
      {
        header: 'Review your policy. ',
        desc: `A Stillwater representative will help you understand which coverages apply to your auto claim.`
      }
    ]
  };
  return (
    <div className="col-md-6 ps-md-4">
      <h5 className="fw-bold pe-1">
        <span className="mobile-text-center pe-2">
          <ThemeIcon
            iconName={ICON_LIST.AUTO}
            fill="#0099cc"
            width="37"
            height="40"
          />
          <br className="d-block d-sm-none" />
        </span>
        I reported my Auto claim. What's next?
      </h5>
      <CustomBulletList listOptions={reportedAutoNextOptions} />

      <h5 className="fw-bold">
        <br />
        <span className="mobile-text-center pe-2">
          <ThemeIcon
            iconName={ICON_LIST.HOME}
            fill="#0099cc"
            width="36"
            height="40"
          />
          <br className="d-block d-sm-none" />
        </span>
        I reported my Home claim. What's next?
      </h5>
      <CustomBulletList listOptions={reportedHomeNextOptions} />
    </div>
  );
};

export default ClaimsRightPanel;
