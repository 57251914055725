import RtrnKnowledgeBase from '../../RtrnKnowledgeBase';
import PurchasePolicy from '../../PurchasePolicy';
import CustomBulletList from '../../../../../../components/custom-list/CustomBulletList';

const ActualCashVSReplacementCost = () => {
  const reimbursementOptions = {
    options: [
      {
        header: 'Actual Cash Value: ',
        desc:
          'The cost of a brand-new item, minus depreciation.' +
          ' In other words, you would receive the estimated value of the current condition of your old, used item if you' +
          ' attempted to sell it today. It\'s often defined as "fair market value", and not what you paid for it originally.',

        nextParagraph:
          "For instance, let's say your have a TV stolen from your home during a robbery.  You purchased the TV for $3,000," +
          " but the value of your used TV in the marketplace is now $2,000.  If you have coverage for Actual Cash Value, that's" +
          ' the amount of money that your insurance company would reimburse you, minus your deductible.' +
          ' If your deductible was $500, you would receive $1,500 in reimbursement for your TV. Any money to purchase a new' +
          ' TV above $1,500 would have to come out of your pocket.'
      },
      {
        header: 'Replacement Cost: ',
        desc:
          " The cost of a brand-new item to replace what you've lost." +
          '  In other words, if you had Replacement Cost Coverage for that same $3,000 TV that was stolen in a robbery, you would receive' +
          ' the full amount of money that it would take to purchase a new, comparable TV, minus your deductible.',

        nextParagraph:
          'The most common coverage when it comes to replacing your dwelling (the actual home itself) is Replacement Cost Coverage.' +
          ' Why? Because it will cost more to replace your current home in the event of a total loss than what you paid originally.' +
          ' This is due to increased labor and supply costs, as well as ensuring that your home meets all current codes when being' +
          ' rebuilt. You should purchase enough coverage to be able to rebuild your home in the event of a catastrophic event.',
        lastParagraph:
          'When it comes to insuring your personal property though some homeowners opt for Actual Cash Value because their' +
          ' insurance premiums will usually be cheaper.'
      }
    ]
  };
  return (
    <div className="container mt-4">
      <RtrnKnowledgeBase />
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="asH3">
            What's the difference between Actual Cash Value and Replacement
            Cost?
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 pe-md-4 pe-lg-5">
          <p>
            You purchase a home insurance policy to ensure that your personal
            belongings are protected in the event of a loss or damage. Your
            expectation is that your insurance company will then reimburse you
            if something happens.
          </p>

          <p>
            But have you ever considered exactly how insurance companies will
            determine what amount of money you'll be reimbursed? If you're
            unsure, you're not alone. A recent survey by insurance.com indicated
            that 50% of homeowners don't understand what "actual cash value" or
            "replacement cost" means when it comes to their home insurance.
          </p>

          <p>
            We're here to make sure you're not one of those who are confused.
          </p>

          <p>First, some definitions of each:</p>
          <CustomBulletList listOptions={reimbursementOptions} />
        </div>

        <div className="col-md-6 ps-md-4 pe-lg-5">
          <p>
            <span className="fw-bold">
              So, what's better - Actual Cash Value or Replacement Cost
              Coverage?{' '}
            </span>
            <br />
            <br />
            As with most questions in personal finance, the answer is that it
            depends.
            <br />
            For most people, Replacement Cost Coverage is the better choice. You
            want to know for sure that you can rebuild your home and replace all
            of your belongings should the worst happen. Your out-of-pocket costs
            in this scenario would be your deductible.
          </p>
          <PurchasePolicy policyType="Home" />
        </div>
      </div>
    </div>
  );
};

export default ActualCashVSReplacementCost;
