import PurchasePolicy from '../../PurchasePolicy';
import RtrnKnowledgeBase from '../../RtrnKnowledgeBase';
import {learnDiffTypes} from './LearnTheDifferenceData';

const LearnTheDifference = () => {
  return (
    <div className="container mt-4">
      <RtrnKnowledgeBase />
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="asH3">{learnDiffTypes.header}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 pe-md-4">
          <p>{learnDiffTypes.paragraphTxt}</p>
          <p>
            {' '}
            <b>{learnDiffTypes.liabilityBoldTxt}</b>
            {learnDiffTypes.liabilityInsText}
          </p>
          <ul className="custom-bullet square">
            {learnDiffTypes.liabilityOptions.map((option, index) => (
              <li key={index}> {option.listItem}</li>
            ))}
          </ul>
          <br />
          <p> {learnDiffTypes.coverageParagraph1}</p>
          <p>
            {' '}
            <b>{learnDiffTypes.damageInsTxt}</b>{' '}
            {learnDiffTypes.coverageParagraph2}
          </p>

          <ul className="custom-bullet square">
            {learnDiffTypes.coverageTypes.map((covgTyp, covgTypIndex) => (
              <li key={covgTypIndex}>
                {' '}
                <b>{covgTyp.boldTxt}</b>
                {covgTyp.listItem}
              </li>
            ))}
          </ul>
          <br />
        </div>
        <div className="col-md-6 ps-md-4">
          <p>
            {' '}
            <b>{learnDiffTypes.comprehensiveCovgTxt}</b>{' '}
          </p>

          <ul className="custom-bullet square">
            {learnDiffTypes.coverageOptions.map((covgOpt, covgOptIndex) => (
              <li key={covgOptIndex}>{covgOpt.text}</li>
            ))}
          </ul>
          <br />

          <p>{learnDiffTypes.liabilityCovgTxt}</p>

          <PurchasePolicy policyType="Auto" />
        </div>
      </div>
    </div>
  );
};

export default LearnTheDifference;
