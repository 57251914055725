import {createContext, ReactNode, useState} from 'react';

export interface ProductTypProps {
  children: ReactNode;
}

const ProductTypeContext = createContext(null as any);
const StartQuoteProductProvider = ({children}: ProductTypProps) => {
  const [selectedProductTyp, setSelectedProductTyp] = useState('');

  const productTypContext = {selectedProductTyp, setSelectedProductTyp};

  return (
    <ProductTypeContext.Provider value={productTypContext}>
      {children}
    </ProductTypeContext.Provider>
  );
};

export {ProductTypeContext, StartQuoteProductProvider};
