import ThemeIcon from '../../../icons/ThemeIcon';
import {cardType} from './CardPanelTypes';
import {Link} from 'react-router-dom';

const CardPanel = ({cardData}: {cardData: cardType[]}) => {
  return (
    <div className="container pt-5">
      <div className="card-group">
        {cardData.map(card => (
          <div className={card.cardClass} key={card.cardHeader}>
            <p className="image mt-4  text-center">
              <ThemeIcon
                iconName={card.image}
                fill="#003295"
                width="40"
                height="35"
              />
            </p>
            <div className="card-body pt-2">
              <h6 className={`ard-title text-center ${card.headerClass}`}>
                {card.cardHeader}
              </h6>
              <p className="card-text text-14 light pt-1">
                {' '}
                {card.cardDescription}
              </p>
            </div>
            <div className="card-footer text-center border-0 bg-transparent mt-4">
              <Link
                className="btn btn-outline-primary d-block w-100 text-14"
                to={card.btnLink}
                role="button"
              >
                {card.btnText}
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardPanel;
