import TopNavItems from './TopNavItems';
import HeaderLogo from './headerlogo/HeaderLogo';
import NavQuoteItems from './quoteitems/NavQuoteItems';

const TopLargeNavBar = () => {
  return (
    <div className="d-block d-md-none d-xl-block">
      <nav className="navbar navbar-expand-lg navbar-inverse navbar-light py-3 ">
        <HeaderLogo />
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#topNavModal"
          aria-controls="topNavBar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="topNavBar">
          <ul className="navbar-nav me-auto" style={{marginBottom: '-2.2em'}}>
            <TopNavItems />
            <NavQuoteItems />
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default TopLargeNavBar;
