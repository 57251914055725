import PurchasePolicy from '../../PurchasePolicy';
import RtrnKnowledgeBase from '../../RtrnKnowledgeBase';

const PricingRenters = () => {
  return (
    <div className="container mt-4">
      <RtrnKnowledgeBase />
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="asH3">
            What goes into the price of Renters Insurance?
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 pe-md-4">
          <p>
            {' '}
            When shopping for a Renters insurance policy that covers your needs,
            you should be aware of several factors that affect the price
            insurance companies charge. Some of these factors are out of your
            control - like the location of the property or the type of
            residence. However, there are several factors you can control that
            will help you make the best choice.
          </p>
          <br />
          <p>Factors that affect the price of Renters insurance:</p>

          <ul className="custom-bullet square">
            <li>
              {' '}
              <b>Location</b> - Insurance fluctuates from one part of the
              country to another based on the risk in that area. Unless you
              research insurance rates before you sign your lease, there's not
              much you can do about the location of your building.
            </li>

            <li>
              {' '}
              <b>Credit and insurance history</b> - In some states, your
              insurance score could also factor into the cost of insurance. A
              higher insurance score could result in a lower premium. You can
              improve your credit history by paying your bills on time and not
              skipping payments. You should also check your credit report and
              look for possible errors.
            </li>

            <li>
              {' '}
              <b>Pets</b> - If you have a dog, you may be paying more for
              Renters insurance to cover the cost if your dog happens to bite
              someone. In addition, certain breeds may be deemed aggressive and
              would disqualify you from obtaining insurance.
            </li>

            <li>
              {' '}
              <b>Amount of coverage</b> - Generally, the more coverage you want,
              the higher your price will be.
            </li>

            <li>
              {' '}
              <b>Deductible</b> - A deductible determines how much money you
              will have to pay in the event of a claim. The higher your
              deductible, the more you will have to pay out of pocket in the
              event of a claim - but you'll also have a lower rate. A lower
              deductible means that you will have to pay more for insurance
              because the insurance company will take on more of the claim cost
              if you have a loss.
            </li>
          </ul>
        </div>
        <div className="col-md-6 ps-md-4">
          <p>
            {' '}
            You can save money by looking for opportunities to bundle, which
            means putting your Renters policy together with your Auto and/or
            Umbrella policy for a discount. On average, bundling can decrease
            your overall monthly insurance costs by 5 to 10 percent.
          </p>

          <p>
            {' '}
            Also keep in mind that when purchasing a Renters insurance policy,
            you will have a choice between Replacement Cost Coverage and Actual
            Cash Value for your contents. Replacement Cost Coverage costs more
            (typically between $20-50 more for your annual premium), but it may
            be worth it. If you have a claim, you'll get reimbursed for the cost
            to replace your items at current prices. If you choose Actual Cash
            Value and have a claim, you'll only get reimbursed for what that
            item would be worth if you sold it today.
          </p>

          <PurchasePolicy policyType="Renters" />
        </div>
      </div>
    </div>
  );
};

export default PricingRenters;
