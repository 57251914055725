import {NavLink} from 'react-router-dom';
import {ProductMenuOptions} from '../NavBarTypes';
import ThemeIcon from '../../../../../icons/ThemeIcon';
import {ICON_LIST} from '../../../../../icons';
import {getQuoteUrl} from '../../../../../environment/Environment';

function ProductsDropDown() {
  return (
    <>
      <div className="dropdown-menu" aria-labelledby="navbarProductsMenuLink">
        <div className="row">
          {ProductMenuOptions.sort((a, b) => a.id - b.id).map(product => (
            <div className="col-md-6  mt-2 " key={product.id}>
              <NavLink className="dropdown-item fw-bold" to={product.link}>
                {product.name}
              </NavLink>
            </div>
          ))}
          <div className="col-md-12 col-lg-12 dropdown-item">
            <br />
            <a
              className="btn btn-orange d-block w-100 font-weight-bold text-white pb-3"
              href={getQuoteUrl()}
            >
              <span className="align-text-bottom">
                <ThemeIcon
                  iconName={ICON_LIST.CLIPBORADCHECK}
                  width="20"
                  height="25"
                  fill="white"
                />
              </span>
              <span className="ms-2">Get a Quote Today</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductsDropDown;
